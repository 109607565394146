export const CarBrands = [
    { label: "Toyota", value: "Toyota" },
    { label: "Ford", value: "Ford" },
    { label: "Chevrolet", value: "Chevrolet" },
    { label: "Honda", value: "Honda" },
    { label: "Nissan", value: "Nissan" },
    { label: "Mercedes-Benz", value: "Mercedes-Benz" },
    { label: "BMW", value: "BMW" },
    { label: "Audi", value: "Audi" },
    { label: "Volkswagen", value: "Volkswagen" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Kia", value: "Kia" },
    { label: "Volvo", value: "Volvo" },
    { label: "Tesla", value: "Tesla" },
    { label: "Jaguar", value: "Jaguar" },
    { label: "Land Rover", value: "Land Rover" },
    { label: "Mini", value: "Mini" },
    { label: "Fiat", value: "Fiat" },
    { label: "Jeep", value: "Jeep" },
    { label: "Mitsubishi", value: "Mitsubishi" },
    { label: "Skoda", value: "Skoda" },
    { label: "Renault", value: "Renault" },
    { label: "Tata", value: "Tata" },
    { label: "MG", value: "MG" },
    { label: "Mahindra", value: "Mahindra" },
    { label: "Datsun", value: "Datsun" },
    { label: "Isuzu", value: "Isuzu" },
    { label: "Force", value: "Force" },
    { label: "Maruti Suzuki", value: "Maruti Suzuki" },
    { label: "Suzuki", value: "Suzuki" },
    { label: "Hindustan Motors", value: "Hindustan Motors" },
    { label: "Premier", value: "Premier" },
    { label: "Ambassador", value: "Ambassador" },
    { label: "Opel", value: "Opel" },
    { label: "Daewoo", value: "Daewoo" },
    { label: "Fiat", value: "Fiat" },
    { label: "Force Motors", value: "Force Motors" },
    { label: "Ashok Leyland", value: "Ashok Leyland" },
    { label: "Other Brand", value: "Other Brand" }
];


export const BikeBrands = [
    { label: "Hero", value: "Hero MotoCorp" },
    { label: "Honda", value: "Honda" },
    { label: "Bajaj", value: "Bajaj" },
    { label: "TVS", value: "TVS" },
    { label: "Yamaha", value: "Yamaha" },
    { label: "Suzuki", value: "Suzuki" },
    { label: "Royal Enfield", value: "Royal Enfield" },
    { label: "KTM", value: "KTM" },
    { label: "Mahindra", value: "Mahindra" },
    { label: "Jawa", value: "Jawa" },
    { label: "BMW Motorrad", value: "BMW Motorrad" },
    { label: "Ducati", value: "Ducati" },
    { label: "Triumph", value: "Triumph" },
    { label: "Harley-Davidson", value: "Harley-Davidson" },
    { label: "Kawasaki", value: "Kawasaki" },
    { label: "Benelli", value: "Benelli" },
    { label: "Aprilia", value: "Aprilia" },
    { label: "MV Agusta", value: "MV Agusta" },
    { label: "Indian Motorcycle", value: "Indian Motorcycle" },
    { label: "SWM", value: "SWM" },
    { label: "Husqvarna", value: "Husqvarna" },
    { label: "CFMoto", value: "CFMoto" },
    { label: "Tork Motors", value: "Tork Motors" },
    { label: "Kawasaki", value: "Kawasaki" },
    { label: "KTM", value: "KTM" },
    { label: "Ducati", value: "Ducati" },
    { label: "Harley-Davidson", value: "Harley-Davidson" },
    { label: "Triumph", value: "Triumph" },
    { label: "Benelli", value: "Benelli" },
    { label: "BMW Motorrad", value: "BMW Motorrad" },
    { label: "Suzuki", value: "Suzuki" },
    { label: "MV Agusta", value: "MV Agusta" },
    { label: "Aprilia", value: "Aprilia" },
    { label: "Indian Motorcycle", value: "Indian Motorcycle" },
    { label: "Daelim", value: "Daelim" },
    { label: "Motus", value: "Motus" },
    { label: "Norton", value: "Norton" },
    { label: "Victory", value: "Victory" },
    { label: "Peugeot", value: "Peugeot" },
    { label: "Moto Guzzi", value: "Moto Guzzi" },
    { label: "Hyosung", value: "Hyosung" },
    { label: "Other Brand", value: "Other Brand" }

]



export const yearData = [
    { label: '1995', value: '1995' },
    { label: '1996', value: '1996' },
    { label: '1997', value: '1997' },
    { label: '1998', value: '1998' },
    { label: '1999', value: '1999' },
    { label: '2000', value: '2000' },
    { label: '2001', value: '2001' },
    { label: '2002', value: '2002' },
    { label: '2003', value: '2003' },
    { label: '2004', value: '2004' },
    { label: '2005', value: '2005' },
    { label: '2006', value: '2006' },
    { label: '2007', value: '2007' },
    { label: '2008', value: '2008' },
    { label: '2009', value: '2009' },
    { label: '2010', value: '2010' },
    { label: '2011', value: '2011' },
    { label: '2012', value: '2012' },
    { label: '2013', value: '2013' },
    { label: '2014', value: '2014' },
    { label: '2015', value: '2015' },
    { label: '2016', value: '2016' },
    { label: '2017', value: '2017' },
    { label: '2018', value: '2018' },
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' }
];


export const ElectronicBrands = [
    { label: "Samsung", value: "Samsung" },
    { label: "Apple", value: "Apple" },
    { label: "Sony", value: "Sony" },
    { label: "LG", value: "LG" },
    { label: "Panasonic", value: "Panasonic" },
    { label: "Toshiba", value: "Toshiba" },
    { label: "Philips", value: "Philips" },
    { label: "Sharp", value: "Sharp" },
    { label: "Huawei", value: "Huawei" },
    { label: "Xiaomi", value: "Xiaomi" },
    { label: "Lenovo", value: "Lenovo" },
    { label: "Dell", value: "Dell" },
    { label: "HP", value: "HP" },
    { label: "Acer", value: "Acer" },
    { label: "Asus", value: "Asus" },
    { label: "MSI", value: "MSI" },
    { label: "Google", value: "Google" },
    { label: "Nokia", value: "Nokia" },
    { label: "Motorola", value: "Motorola" },
    { label: "OnePlus", value: "OnePlus" },
    { label: "BlackBerry", value: "BlackBerry" },
    { label: "JBL", value: "JBL" },
    { label: "Bose", value: "Bose" },
    { label: "Beats by Dre", value: "Beats by Dre" },
    { label: "Sonos", value: "Sonos" },
    { label: "Bang & Olufsen", value: "Bang & Olufsen" },
    { label: "Casio", value: "Casio" },
    { label: "Garmin", value: "Garmin" },
    { label: "Fitbit", value: "Fitbit" },
    { label: "GoPro", value: "GoPro" },
    { label: "Canon", value: "Canon" },
    { label: "Nikon", value: "Nikon" },
    { label: "Fujifilm", value: "Fujifilm" },
    { label: "Olympus", value: "Olympus" },
    { label: "Other Brand", value: "Other Brand" }

];

export const  MobileBrands = [
    { label: "Samsung", value: "Samsung" },
    { label: "Apple", value: "Apple" },
    { label: "Huawei", value: "Huawei" },
    { label: "Xiaomi", value: "Xiaomi" },
    { label: "OPPO", value: "OPPO" },
    { label: "Vivo", value: "Vivo" },
    { label: "OnePlus", value: "OnePlus" },
    { label: "Realme", value: "Realme" },
    { label: "Motorola", value: "Motorola" },
    { label: "Nokia", value: "Nokia" },
    { label: "Sony", value: "Sony" },
    { label: "LG", value: "LG" },
    { label: "Google", value: "Google" },
    { label: "ASUS", value: "ASUS" },
    { label: "BlackBerry", value: "BlackBerry" },
    { label: "HTC", value: "HTC" },
    { label: "Lenovo", value: "Lenovo" },
    { label: "Micromax", value: "Micromax" },
    { label: "iQOO", value: "iQOO" },
    { label: "Other Brand", value: "Other Brand" }
];

export const LaptopBrands = [
    { label: "Apple", value: "Apple" },
    { label: "Dell", value: "Dell" },
    { label: "HP", value: "HP" },
    { label: "Lenovo", value: "Lenovo" },
    { label: "Acer", value: "Acer" },
    { label: "Asus", value: "Asus" },
    { label: "Microsoft", value: "Microsoft" },
    { label: "Samsung", value: "Samsung" },
    { label: "MSI", value: "MSI" },
    { label: "Razer", value: "Razer" },
    { label: "LG", value: "LG" },
    { label: "Google", value: "Google" },
    { label: "Huawei", value: "Huawei" },
    { label: "Xiaomi", value: "Xiaomi" },
    { label: "Other Brand", value: "Other Brand" }


]