import React, { useState, useEffect } from "react";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";
import { getAuthToken } from "../../api/Auth";
import ProfileImage from "./ProfileImage";
import { getUserId } from "../../api/Auth";
import { toast } from "react-toastify";
import EyeImage from '../../Images/Eye.png';




const EditProfile = () => {
  const Navigate = useNavigate();
  const baseurl = API_BASE_URL;
  const userId = getUserId();

  const autheader = getAuthToken();

  const [inputData, setInputData] = useState({
    firstName: "",
    secondName: "",
    city: "",
    email: "",
    phone: "",  
  });


  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setInputData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };



  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [currentPassword, SetCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleCurrentPasswordChange = (e) => {
    SetCurrentPassword(e.target.value);
  };


  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const [fileObj, setFileObj] = useState({ files: null, path: "" });

  const handleImageSelect = (file) => {
    setFileObj({ files: file });
  };



  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/auth/username/${userId}`, {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        });

        setUserName(response.data);
      } catch (error) {
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const response = await axios.put(`${baseurl}/api/auth/updatePassword?username=${userName}&oldPassword=${currentPassword}&newPassword=${password}`);
      
      if (response.status === 200) {
        toast.success("Password Updtaed Successfully");
      } 

    } catch (error) {
      toast.error('Error updating password', error);
    }
  };

  // const handleSaveChanges = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("firstName", inputData.firstName);
  //     formData.append("lastName", inputData.lastName);
  //     formData.append("address", inputData.address);
  //     formData.append("email", inputData.email);
  //     formData.append("phoneNumber", inputData.phoneNumber);
  //     if (fileObj.files) {
  //       formData.append("files", fileObj.files);
  //     }
  //     const response = await axios.put(`${baseurl}/api/auth/profile/${userId}`, formData, {
  //       headers: {
  //         Authorization: autheader,        
  //       },
  //     });

  //     toast.success("Changes saved successfully:", response.data);

  //   } catch (error) {
  //     toast.error("Error saving changes:", error);
  //   }
  // };

  const handleSaveChanges = async () => {
    try {
      const profileData = {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        address: inputData.address,
        email: inputData.email,
        phoneNumber: inputData.phoneNumber,
      };
      const profileResponse = await axios.put(`${baseurl}/api/auth/profile/${userId}`, profileData, {
        headers: {
          Authorization: autheader,
        },
      });

      if (fileObj.files) {
        const formData = new FormData();
        formData.append("file", fileObj.files);
        const imageResponse = await axios.post(`${baseurl}/api/user/upload`, formData, {
          headers: {
            Authorization: autheader,
            'Content-Type': 'multipart/form-data'

          },
        });
        toast.success("Profile image uploaded successfully.");
      }

      toast.success("Changes saved successfully.");
    } catch (error) {
      toast.error("Error saving changes:", error);
    }
  };

  const handleNumericInput = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="profile-section my-5">
        <div className="container ">
          <div className="row user-profile justify-content-center">
            <div className="col-md-10">
              <div className="row user-profile-row">
                <div className="profile-page-heading col-12 py-3 border-bottom">
                  Edit Profile
                </div>
                <div className="col-lg-4 col-md-12 py-3">
                  <a href="upload profile" className="d-inline-block profile-image">
                    <ProfileImage
                      handleImageSelect={handleImageSelect}
                    />
                  </a>
                </div>
                <div className="col-lg-8 col-md-12 py-3">
                  <div className="profile-info-wrap">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="profile-info mb-20px">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            pattern="[A-Za-z]+"
                            className="form-control border-dark"
                            id="firstName"
                            aria-describedby="emailHelp"
                            placeholder="Name"
                            onChange={handleInputChange}
                            value={inputData.firstName}
                            required
                          />
                          <small className="form-text text-muted">
                            Only letters are allowed.
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="profile-info mb-20px">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            pattern="[A-Za-z]+"
                            className="form-control border-dark"
                            id="lastName"
                            aria-describedby="emailHelp"
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            value={inputData.lastName}
                            required
                          />
                          <small className="form-text text-muted">
                            Only letters are allowed.
                          </small>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="profile-info mb-20px">
                          <label htmlFor="address" className="form-label">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control border-dark"
                            id="address"
                            aria-describedby="emailHelp"
                            placeholder="Bangalore"
                            onChange={handleInputChange}
                            value={inputData.address}

                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="profile-info mb-20px">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control border-dark"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder="info@gmail.com"
                            onChange={handleInputChange}
                            value={inputData.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="profile-info mb-20px">
                          <label htmlFor="phoneNumber" className="form-label">
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            className="form-control border-dark"
                            id="phoneNumber"
                            aria-describedby="emailHelp"
                            placeholder="+91 7876787678"
                            onChange={handleInputChange}
                            value={inputData.phoneNumber}
                            onKeyPress={handleNumericInput}
                            required
                          />
                          <small className="form-text text-muted">
                            Please enter a valid phone number.
                          </small>
                        </div>
                      </div>

                    </div>
                    <div className="form-button my-3 row ps-3">
                      <div className="col">
                        <button type="submit"
                          className="border-0"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-6">
              <div className="row user-profile-row border">
                <div className="profile-page-heading col-12 py-3 border-bottom">
                  Change Password
                </div>
                <div className="col-lg-8 col-md-8 py-3">
                  <div className="profile-info-wrap">
                    <div className="row">
                      <div className="">
                        <div className="profile-info mb-20px">
                          <label htmlFor="currentpassword" className="form-label">
                            Current Password
                          </label>
                          <div className="d-flex align-items-center" style={{position:"relative"}}>
                            <input
                              type={showCurrentPassword ? "text" : "password"}
                              className="form-control border-dark"
                              id="password"
                              aria-describedby="emailHelp"
                              placeholder="8+ characters"
                              value={currentPassword}
                              onChange={handleCurrentPasswordChange}
                            />
                            <img src={EyeImage} alt=""
                              onClick={toggleCurrentPasswordVisibility} style={{position:"absolute", right:"12px"}}/>
                          </div>

                        </div>
                      </div>
                      <div className="">
                        <div className="profile-info mb-20px">
                          <label htmlFor="newpassword" className="form-label">
                            New Password
                          </label>
                          <div className="d-flex align-items-center" style={{position:"relative"}}>
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="password"
                              aria-describedby="emailHelp"
                              placeholder="8+ characters"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <img src={EyeImage} alt=""
                              onClick={togglePasswordVisibility} style={{position:"absolute", right:"12px"}} />

                          </div>

                        </div>
                      </div>
                      <div className="">
                        <div className="profile-info mb-20px">
                          <label htmlFor="confirm" className="form-label">
                            Confirm Password
                          </label>
                          <div className="d-flex align-items-center" style={{position:"relative"}}>
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              id="confirmPassword"
                              aria-describedby="emailHelp"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                            />
                            <img src={EyeImage} alt=""
                              onClick={toggleConfirmPasswordVisibility} style={{position:"absolute", right:"12px"}}/>
                          </div>



                        </div>
                      </div>
                    </div>
                    <div className="form-button my-3 row ps-3">
                      <div className="col">
                        <button type="submit"
                          className="border-0"
                          onClick={handleSubmit}
                          >
                          Change Password
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;