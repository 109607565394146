import React, { useEffect } from 'react'
import Searchbar from '../Products/SearchBar'
import './MobileMenuSearchBar.css'
import { useSearch } from '../../Context/SearchContext'


const MobileSearchBarListPage = () => {
    const { isShowSearchPage, setIsShowSearchPage, setSearchQuery, searchQuery } = useSearch()

    const backHandler = () => {
      setIsShowSearchPage(false)
      console.log(" backHandler ")
    }

    useEffect(()=>{
        if (isShowSearchPage) {
            console.log("=========== isShowSearchPage == MobileSearchBarListPage", isShowSearchPage)

            if (typeof window != 'undefined' && window.document) {
                document.body.style.overflow = 'hidden';
                console.log("=========== isShowSearchPage = document.body.style.overflow = 'hidden' = MobileSearchBarListPage", isShowSearchPage)

            } 
        } else {
            document.body.style.overflow = 'unset'
            console.log("=========== isShowSearchPage = document.body.style.overflow = 'unset' = MobileSearchBarListPage", isShowSearchPage)

        }

    },[isShowSearchPage])

  return (
    <div className='mobileSearchBarListPage'>
        <button onClick={backHandler} style={{margin: 20}}> Back </button>
        <Searchbar/>
    </div>
  )
}

export default MobileSearchBarListPage