import React from "react";
import "./TrendingArticle.css";

const MobileArticle = () => {
  return (
    <>
      <nav class="navbar navbar-fixed-top nabvar-default">
        <div class="container navbar-container">
          <div class="row">
            <div id="navbar" class="navbar-collapse collapse">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-right navbar-menu-left">
                  <li>
                    <a class="navbar-nav-link" href="#bio">
                      Short Bio
                    </a>
                  </li>
                  <li>
                    <a class="navbar-nav-link" href="#wronful_dead">
                      Wrongful Death
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-left navbar-menu-right">
                  <li>
                    <a class="navbar-nav-link" href="#drama_film">
                      Drama Film
                    </a>
                  </li>
                  <li>
                    <a
                      class="navbar-nav-link link-further-info"
                      href="https://en.wikipedia.org/wiki/Alan_Turing"
                      target="_blank"
                    >
                      Further Information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header>
        <div class="blog-title-wrapper container">
          <div class="row">
            <div class="col-sm-3 module-fields">
              <i class="fa fa-wrench fa-4x"></i>
              <h2 class="blog-subtitle">
                Explore the Best Deals on Mobile Phones with QuiksAle
              </h2>
              <ul class="fields_list">
                <li class="fields_item">
                  In today's fast-paced world, mobile phones have become an
                  essential part of our lives. Whether you're looking for the
                  latest iPhone models or budget-friendly options, QuiksAle is
                  your one-stop destination for all things mobile phones. From
                  cell phone stores near you to online deals, QuiksAle offers a
                  wide range of options to suit every need and budget.
                </li>
                {/* <li class="fields_item">Thorough Inspection by a Mechanic</li>
          <li class="fields_item">Test Drive in Varied Conditions</li>
          <li class="fields_item">Check for Signs of Wear and Tear</li>
          <li class="fields_item">Negotiate a Fair Price</li> */}
              </ul>
            </div>
            <div id="bio" class="col-sm-9 module-bio">
              {/* <div class="inline-meta date">
                <i class="fa fa-smile-o"></i> 23 June 1912 -{" "}
                <i class="fa fa-frown-o"></i> 7 June 1954
              </div> */}
              <h1 class="blog-title">
                Explore the Best Deals on Mobile Phones with QuiksAle
              </h1>
              <div class="blog-description">
                1.{" "}
                <b>Cell Phone Stores Near Me / Phone Store / Mobile Shop: </b><br />
                and budget. Cell Phone Stores Near Me / Phone Store / Mobile
                Shop: Looking for cell phone stores near you? QuiksAle's
                platform allows you to easily find mobile shops and phone stores
                in your area. Whether you're looking for a specific brand or
                model, QuiksAle's network of sellers ensures that you can find
                what you need right in your neighborhood.
                <br />
                2. <b>All iPhone Models:  </b><br />
                Explore all iPhone models on QuiksAle! Whether you're looking for the latest iPhone 13 or a budget-friendly option like the iPhone SE, QuiksAle offers a wide range of options to suit every need and budget.
                <br />
                3. <b>Best Buy Mobile:  </b><br />
                Looking for the best deals on mobile phones? QuiksAle offers competitive prices on a wide range of mobile phones, ensuring that you get the best value for your money. Whether you're looking for a brand new phone or a second-hand option, QuiksAle has you covered.
                <br />
                4. <b>Second Hand Mobile / 2nd Hand Phone / Used Phones / Refurbished Phones: </b><br />
                In the market for a second-hand or refurbished phone? QuiksAle offers a wide selection of used and refurbished phones at unbeatable prices. Whether you're looking for an iPhone, Samsung, or any other brand, QuiksAle's platform makes it easy to find the perfect phone for your needs.
                <br />
                5. <b>Cheap Phones:  </b><br />
                Looking for cheap phones? QuiksAle offers budget-friendly options to suit every need. Whether you're looking for a basic phone or a smartphone, QuiksAle's platform offers competitive prices on a wide range of devices.
                <br />
                6. <b>Mobile Phones / Mobile / 4G Mobile: </b><br />
                QuiksAle offers a wide range of mobile phones to suit every need and budget. Whether you're looking for a smartphone with the latest features or a basic phone for calls and texts, QuiksAle's platform has something for everyone.
                <br />
                7. <b>Old Phone on QuiksAle / Purana Mobile 4G:  </b><br />
                Looking to sell your old phone? QuiksAle makes it easy to list and sell your old mobile phones. Simply create a listing, add photos and descriptions, and wait for buyers to make offers.
                <br />
                8. <b>QuiksAle iPhone 11 / New Mobile QuiksAle:  </b><br />
                Looking for the latest iPhone 11 or a new mobile phone? QuiksAle offers the latest models at competitive prices. Whether you're looking for an iPhone, Samsung, or any other brand, QuiksAle's platform has you covered.
                <br />
                9. <b>Second Hand 4G Phone Under 2000 / Second Hand Mobile Phone 4G Under 2000: </b><br />
                Looking for a second-hand 4G phone under 2000? QuiksAle offers budget-friendly options to suit every need. Whether you're looking for a basic smartphone or a feature-rich device, QuiksAle's platform has something for everyone.
                <br />
                <b className="mt-3">
                In conclusion, QuiksAle is your ultimate destination for buying and selling mobile phones. Whether you're looking for the latest models or budget-friendly options, QuiksAle's platform offers a seamless buying and selling experience. So why wait? Join QuiksAle today and explore the world of mobile phones like never before!
                </b><br />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <footer class="footer">
  <div class="container">
    <a href="https://en.wikipedia.org/wiki/Alan_Turing" target="_blank" class="further_info_link">Further Information</a>
  </div>
</footer> */}
    </>
  );
};

export default MobileArticle;
