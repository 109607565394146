import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import m53 from "../../../Images/m53.png";



const MobileCategory = () => {
  return (
    <>
    <div className="main-container">
    <div className="container my-5 my-lg-0 border">
      <div className="discription-section row my-5 my-lg-0">
        <div className="image-section col-md-2">
          <div className="mainimage">
            <img className="mainimg1" src={m53} alt="mainimage" />
          </div>
        </div>
        <div className="product-discription col-md-10 my-5 my-md-0 ">
          <div className="row">
            <div className="discription col-12 my-3">
              <span className="text-discription">
                2020 Apple MacBook Pro with Apple M1 Chip (13-inch, 8GB RAM,
                256GB SSD Storage) - Space Gray
              </span>
            </div>
            <div className="col">
            <div className="adid">
                <span className="ad-id">AD ID : A2634671</span>
              </div>
              <div>
                <span className="brandname">Brand :Apple</span>
              </div>
              
            </div>
            <div className="col">
            <div className="location">
                <span className="ad-id">
                  Location: #123, kumarswami Layout Bangalore Karnataka 560092
                </span>
              </div>
              <div className="brand  my-2">
              
              <div>
                <span className="brandname">
                  Category: Electronics Devices
                </span>
              </div>
            </div>
            </div>            
           
            <div className="productprice col-12 my-4">
              <span className="saleprice"> &#8377;56,784</span>
              <del>&#8377;99,999</del>
              <button
                className="productoffer"
                style={{ "text-transform": "uppercase" }}
              >
                25% off
              </button>
            </div>
            {/* <hr /> */}

            {/* <div className=" row">
              <div className="seller-btn py-4">
                <button className="call-sellerbtn">CALL SELLER</button>
                <button className="call-sellerbtn">CHAT</button>
              </div>
            </div> */}

            <div className="share-add">
              <div className="add-wishlist">
                <FavoriteIcon />
                <span>Add to wishlist</span>
              </div>
              <div className="share-item">
                <span>Share: </span>
                <ContentCopyIcon />
                <FacebookIcon />
                <TwitterIcon />
                <PinterestIcon />
              </div>
            </div>

            <div className="payment-details  mt-4">
              <p className="mt-2">100% Guarantee Safe Checkout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div className="main-container">
    <div className="container my-5 my-lg-0 border">
      <div className="discription-section row my-5 my-lg-0">
        <div className="image-section col-md-2">
          <div className="mainimage">
            <img className="mainimg1" src={m53} alt="mainimage" />
          </div>
        </div>
        <div className="product-discription col-md-10 my-5 my-md-0 ">
          <div className="row">
            <div className="discription col-12 my-3">
              <span className="text-discription">
                2020 Apple MacBook Pro with Apple M1 Chip (13-inch, 8GB RAM,
                256GB SSD Storage) - Space Gray
              </span>
            </div>
            <div className="col">
            <div className="adid">
                <span className="ad-id">AD ID : A2634671</span>
              </div>
              <div>
                <span className="brandname">Brand :Apple</span>
              </div>
              
            </div>
            <div className="col">
            <div className="location">
                <span className="ad-id">
                  Location: #123, kumarswami Layout Bangalore Karnataka 560092
                </span>
              </div>
              <div className="brand  my-2">
              
              <div>
                <span className="brandname">
                  Category: Electronics Devices
                </span>
              </div>
            </div>
            </div>            
           
            <div className="productprice col-12 my-4">
              <span className="saleprice"> &#8377;56,784</span>
              <del>&#8377;99,999</del>
              <button
                className="productoffer"
                style={{ "text-transform": "uppercase" }}
              >
                25% off
              </button>
            </div>
            {/* <hr /> */}

            {/* <div className=" row">
              <div className="seller-btn py-4">
                <button className="call-sellerbtn">CALL SELLER</button>
                <button className="call-sellerbtn">CHAT</button>
              </div>
            </div> */}

            <div className="share-add">
              <div className="add-wishlist">
                <FavoriteIcon />
                <span>Add to wishlist</span>
              </div>
              <div className="share-item">
                <span>Share: </span>
                <ContentCopyIcon />
                <FacebookIcon />
                <TwitterIcon />
                <PinterestIcon />
              </div>
            </div>

            <div className="payment-details  mt-4">
              <p className="mt-2">100% Guarantee Safe Checkout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="main-container">
    <div className="container my-5 my-lg-0 border">
      <div className="discription-section row my-5 my-lg-0">
        <div className="image-section col-md-2">
          <div className="mainimage">
            <img className="mainimg1" src={m53} alt="mainimage" />
          </div>
        </div>
        <div className="product-discription col-md-10 my-5 my-md-0 ">
          <div className="row">
            <div className="discription col-12 my-3">
              <span className="text-discription">
                2020 Apple MacBook Pro with Apple M1 Chip (13-inch, 8GB RAM,
                256GB SSD Storage) - Space Gray
              </span>
            </div>
            <div className="col">
            <div className="adid">
                <span className="ad-id">AD ID : A2634671</span>
              </div>
              <div>
                <span className="brandname">Brand :Apple</span>
              </div>
              
            </div>
            <div className="col">
            <div className="location">
                <span className="ad-id">
                  Location: #123, kumarswami Layout Bangalore Karnataka 560092
                </span>
              </div>
              <div className="brand  my-2">
              
              <div>
                <span className="brandname">
                  Category: Electronics Devices
                </span>
              </div>
            </div>
            </div>            
           
            <div className="productprice col-12 my-4">
              <span className="saleprice"> &#8377;56,784</span>
              <del>&#8377;99,999</del>
              <button
                className="productoffer"
                style={{ "text-transform": "uppercase" }}
              >
                25% off
              </button>
            </div>
            {/* <hr /> */}

            {/* <div className=" row">
              <div className="seller-btn py-4">
                <button className="call-sellerbtn">CALL SELLER</button>
                <button className="call-sellerbtn">CHAT</button>
              </div>
            </div> */}

            <div className="share-add">
              <div className="add-wishlist">
                <FavoriteIcon />
                <span>Add to wishlist</span>
              </div>
              <div className="share-item">
                <span>Share: </span>
                <ContentCopyIcon />
                <FacebookIcon />
                <TwitterIcon />
                <PinterestIcon />
              </div>
            </div>

            <div className="payment-details  mt-4">
              <p className="mt-2">100% Guarantee Safe Checkout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  );
};


export default MobileCategory;
