import React from "react";
import "./Classified.css";

const Classified = () => {
  return (
    <>
      <div className="classified-sec my-5 p-4 ">
        <div className="container">
          <div className="row border px-5">
            <div className="headline py-4">
              <h1>Classifieds</h1>
            </div>
            <div className="col-md-6 ">
              <section class="section">
                <h2 class="section-tree-title">
                  <a href="/hc/en-us/sections/10910691269661-Account-Management">
                    Account Management
                  </a>
                </h2>

                <ul class="article-list">
                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10910745306653-Technical-Issue-s-Troubleshooting-App"
                      class="article-list-link"
                    >
                      Technical Issue(s) Troubleshooting - App
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10910928117661-How-to-create-an-account-on-quiksale-"
                      class="article-list-link"
                    >
                      How to create an account on quiksale?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10910966595357-All-about-profile-edition"
                      class="article-list-link"
                    >
                      All about profile edition
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10910921464093-How-do-I-change-my-phone-number-in-the-app-"
                      class="article-list-link"
                    >
                      How do I change my phone number in the app?{" "}
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10911121704477-Why-am-I-not-seeing-my-chats-"
                      class="article-list-link"
                    >
                      Why am I not seeing my chats?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10911047295901-New-quiksale-chat"
                      class="article-list-link"
                    >
                      New quiksale chat
                    </a>
                  </li>
                </ul>

                <a
                  href="/hc/en-us/sections/10910691269661-Account-Management"
                  class="see-all-articles"
                >
                  See all 14 articles
                </a>
              </section>
              <section class="section">
                <h2 class="section-tree-title">
                  <a href="/hc/en-us/sections/10833618233629-Buying-via-quiksale">
                    Buying via quiksale
                  </a>
                </h2>

                <ul class="article-list">
                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845316604061-How-do-I-search-for-a-product-"
                      class="article-list-link"
                    >
                      How do I search for a product?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845219297821-How-do-I-search-for-an-ad-based-on-location"
                      class="article-list-link"
                    >
                      How do I search for an ad based on location
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10833891194141-How-do-I-search-for-a-product-in-another-location"
                      class="article-list-link"
                    >
                      How do I search for a product in another location
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845175344669-Points-to-remember-while-checking-a-product-"
                      class="article-list-link"
                    >
                      Points to remember while checking a product?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845259300253-How-can-I-contact-a-Seller-"
                      class="article-list-link"
                    >
                      How can I contact a Seller?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10833978328605-How-can-I-find-Ads-that-I-have-seen-before-"
                      class="article-list-link"
                    >
                      How can I find Ads that I have seen before?
                    </a>
                  </li>
                </ul>

                <a
                  href="/hc/en-us/sections/10833618233629-Buying-via-quiksale"
                  class="see-all-articles"
                >
                  See all 8 articles
                </a>
              </section>
              <h2 class="section-tree-title">
                <a href="/hc/en-us/sections/10850501969693-Conducting-Safe-Transactions">
                  Conducting Safe Transactions
                </a>
              </h2>
              <div class="category-container">
                <div class="category-content">
                  <div class="section-tree">
                    <section class="section">
                      <ul class="article-list">
                        <li class="article-list-item">
                          <a
                            href="/hc/en-us/articles/10850623470877-Why-is-my-account-banned-"
                            class="article-list-link"
                          >
                            Why is my account banned?
                          </a>
                        </li>

                        <li class="article-list-item">
                          <a
                            href="/hc/en-us/articles/10850682293533-How-can-I-hide-my-phone-number-"
                            class="article-list-link"
                          >
                            How can I hide my phone number?{" "}
                          </a>
                        </li>

                        <li class="article-list-item">
                          <a
                            href="/hc/en-us/articles/10850529064861-Can-I-remove-my-personal-information-"
                            class="article-list-link"
                          >
                            Can I remove my personal information?
                          </a>
                        </li>

                        <li class="article-list-item">
                          <a
                            href="/hc/en-us/articles/10850700600861-Why-do-other-listings-show-a-verified-user-tag-but-my-listing-does-not-has-any-such-tag-"
                            class="article-list-link"
                          >
                            Why do other listings show a verified user tag but
                            my listing does not has any such tag?
                          </a>
                        </li>
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <section class="section">
                <h2 class="section-tree-title">
                  <a href="/hc/en-us/sections/10850511040541-Posting-Managing-Ads">
                    Posting &amp; Managing Ads
                  </a>
                </h2>

                <ul class="article-list">
                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10877414982557-How-can-I-post-an-ad-"
                      class="article-list-link"
                    >
                      How can I post an ad?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10877383543325-How-many-ads-can-I-post-in-a-month-"
                      class="article-list-link"
                    >
                      How many ads can I post in a month?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10910969828637-Items-Services-not-allowed-on-quiksale"
                      class="article-list-link"
                    >
                      Items/Services not allowed on quiksale
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10877092855709-How-do-I-get-maximum-replies-"
                      class="article-list-link"
                    >
                      How do I get maximum replies?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10975330393373-How-to-remove-my-ad-or-mark-my-Ad-as-sold-"
                      class="article-list-link"
                    >
                      How to remove my ad or mark my Ad as sold?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10877490246173-Premium-Services-Basic-definitions-differences"
                      class="article-list-link"
                    >
                      Premium Services - Basic definitions &amp; differences
                    </a>
                  </li>
                </ul>

                <a
                  href="/hc/en-us/sections/10850511040541-Posting-Managing-Ads"
                  class="see-all-articles"
                >
                  See all 13 articles
                </a>
              </section>
              <section class="section">
                <h2 class="section-tree-title">
                  <a href="/hc/en-us/sections/10850504575133-Sell-faster-with-premium-features">
                    Sell faster with premium features
                  </a>
                </h2>

                <ul class="article-list">
                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10850804586525-What-does-the-option-Sell-faster-now-means-"
                      class="article-list-link"
                    >
                      What does the option Sell faster now means?{" "}
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10866972708381-How-can-I-check-the-validity-of-my-featured-ads-"
                      class="article-list-link"
                    >
                      How can I check the validity of my featured ads?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10867032480669-Invoice-FAQs"
                      class="article-list-link"
                    >
                      Invoice - FAQs
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10866977382045-How-can-I-get-the-bills-invoices-of-purchased-packages-"
                      class="article-list-link"
                    >
                      How can I get the bills/invoices of purchased packages?{" "}
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10867031266845-Payment-and-refund-related-queries"
                      class="article-list-link"
                    >
                      Payment and refund related queries
                    </a>
                  </li>
                </ul>
              </section>
              <section class="section">
                <h2 class="section-tree-title">
                  <a href="/hc/en-us/sections/10833788349213-Verified-User">
                    Verified User
                  </a>
                </h2>

                <ul class="article-list">
                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10849280968861-What-does-verified-user-mean-"
                      class="article-list-link"
                    >
                      What does verified user mean?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10849225053853-Why-should-I-trust-a-verified-user-seller-"
                      class="article-list-link"
                    >
                      Why should I trust a verified user/seller?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845409360797-I-am-talking-to-an-unverified-seller-Can-quiksale-verify-them-"
                      class="article-list-link"
                    >
                      I am talking to an unverified seller? Can quiksale verify them?
                    </a>
                  </li>

                  <li class="article-list-item">
                    <a
                      href="/hc/en-us/articles/10845406355101-Can-I-view-the-information-submitted-by-verified-seller-"
                      class="article-list-link"
                    >
                      Can I view the information submitted by verified seller?
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Classified;
