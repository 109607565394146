import React from 'react'
import { Link } from 'react-router-dom';
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="card border-0 shadow">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Privacy Policy</h2>
              <p>
                This Privacy Policy applies to the QUIKSALE. QUIKSALE recognizes
                the importance of maintaining your privacy. We value your
                privacy and appreciate your trust in us. This Policy describes
                how we treat user information we collect on
                <Link to="https://www.quiksale.in" style={{ color: "#b20b00" }}>
                  {" "}
                  QuiksAle
                </Link>
                and other offline sources. This Privacy Policy applies to
                current and former visitors to our website and to our online
                customers. By visiting and/or using our website, you agree to
                this Privacy Policy. QUIKSALE is a property of WEBLY ONESTOP
                SOLUTIONS PRIVATE LIMITED, an Indian Company registered under
                the Companies Act, 2013 having its registered office at No9,
                KNo.380, Ground Floor, Doddathoguru Village, Electronics City,
                Bangalore South, Bangalore- 560100.
              </p>
              <h3 className="mt-4">Information we collect</h3>
              <p>
                Contact information. The personal information requested and the
                reasons for its collection will be transparent at the point of
                request. When you reach out to us directly, we may receive
                additional details such as your name, email address, phone
                number, message content, and any other information you choose to
                provide. Upon registration for an Account, we may ask for
                contact information, including your name, company name, address,
                email address, and telephone number. Payment and billing
                information. We might collect your billing name, billing address
                and payment method when you buy from us. We NEVER collect your
                credit card number or credit card expiry date or other details
                pertaining to your credit card on our website. Credit card
                information will be obtained and processed by our online payment
                partner CC Avenue.
              </p>
              <h3>Use of Information</h3>
              <p>
                We employ the collected information for various purposes,
                including:
                <ul>
                  <li>Providing, operating, and maintaining our website</li>
                  <li>Enhancing, personalizing, and expanding our website</li>
                  <li>Analyzing how you use our website</li>
                  <li>
                    Creating new products, services, features, and functionality
                  </li>
                  <li>
                    Communicating with you for customer service, updates, and
                    marketing purposes
                  </li>
                  <li>Sending emails</li>
                  <li>Detecting and preventing fraud</li>
                </ul>
              </p>
              <h3>Log Files</h3>
              <p>
                QUIKSALE follows standard log file procedures, collecting
                information such as IP addresses, browser type, ISP, date and
                time stamp, referring/exit pages, and clicks. This information
                is utilized for trend analysis, site administration, user
                movement tracking, and demographic data gathering. Importantly,
                it is not linked to personally identifiable information.
              </p>
              <p>
                We collect information in different ways. We collect information
                directly from you. We collect information directly from you when
                you register for an event or buy tickets. We also collect
                information if you post a comment on our websites or ask us a
                question through phone or email.
              </p>
              <p>
                We collect information from you passively. We use tracking tools
                like Google Analytics, Google Webmaster, browser cookies and web
                beacons for collecting information about your usage of our
                website.
              </p>
              We get information about you from third parties. For example, if
              you use an integrated social media feature on our websites. The
              third-party social media site will give us certain information
              about you. This could include your name and email address.
              <p>
                <p></p>
              </p>
              <h3>Use of your personal information</h3>
              <p>
              We use information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.
We use information to respond to your requests or questions. We might use your information to confirm your registration for an event or contest. 
We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.
We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.
We use information for security purposes. We may use information to protect our company, our customers, or our websites.
We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. 
Sharing of information with third-parties
We will share information with third parties who perform services on our behalf. We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. Some vendors may be located outside of India.
We will share information with the event organizers. We share your information with event organizers and other parties responsible for fulfilling the purchase obligation. The event organizers and other parties may use the information we give them as described in their privacy policies. 
We will share information with our business partners. This includes a third party who provide or sponsor an event, or who operates a venue where we hold events. Our partners use the information we give them as described in their privacy policies. 
We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud. 
We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction.
We may share your information for reasons not described in this policy. We will tell you before we do this. 
              </p>
              <h3>Email Opt-Out</h3>
              <p>You can opt out of receiving our marketing emails. To stop receiving our promotional emails, please email info@quiksale.in It may take about ten days to process your request. Even if you opt out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your purchases. </p>
              {/* Rest of the content */}
              <h3>
Third party sites</h3>
<p>If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third party sites.
GDPR Data Protection Rights
Users have rights including access, rectification, erasure, processing restriction, objection, and data portability. Requests for these rights will be addressed within one month.
Children's Information
We prioritize children's protection online and encourage parents to monitor their online activities. QUIKSALE does not knowingly collect Personal Identifiable Information from children under 13. If such information is inadvertently provided, contact us immediately for prompt removal.</p>
<h3>Grievance Officer</h3>
<p>
In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
Mr. Abhishek Sharma
3rd cross, Netaji nagar, Kempapura Hebbal
Bangalore, Karnataka, 560024,India
Email: quiksalefree@gmail.com
If you have any questions about this Policy or other privacy concerns, you can also email us at info@quiksale.in
Updates to this policy
This Privacy Policy was last updated on 31-Jan-2024 From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.
Jurisdiction
If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India. 
</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;