import React from 'react';
import './EmailVerification.css';
import './UserAuth.css';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const EmailVerification = () => {
  return (
    <div>
      <nav style={{'--bs-breadcrumb-divider': '>'}} aria-label="breadcrumb " className="breadcrumb-nav d-flex justify-content-center">
        <ol className="breadcrumb w-60 mb-0">
            <li className="breadcrumb-item d-flex"><a href="#">< HomeOutlinedIcon />Home</a></li>
            <li className="breadcrumb-item active" aria-current="page"><KeyboardArrowRightOutlinedIcon />Library</li>
            <li className="breadcrumb-item active" aria-current="page"><KeyboardArrowRightOutlinedIcon />Library</li>
    
        </ol>
    </nav>
    
    <section className="user-form-part">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                    {/* <!-- <div class="user-form-logo">
                        <a href="index.html">
                            <img src="images/logo.png" alt="logo">
                        </a>
                    </div> --> */}
                    <div className="user-form-card">
                        <div className="user-form-title">
                            <h2> Verify Your Email Address</h2>
                            <p>Nam ultricies lectus a risus blandit elementum. Quisque arcu arcu, tristique a eu in diam.</p>
                        </div>
                        <div className="form-heading d-flex ">
                            <a href="">Verification Code</a>
                            <a href="">Resend Code</a>
                        </div>
                        <form className="user-form">
                        

                            <label for="exampleInputEmail1" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        
                            <div className="form-button">
                                <button type="submit">Verify me <img src="images/ArrowRight-white.png" alt=""/></button>
                            </div>
                            
                        </form>
                    </div>
                   

                </div>
            </div>
        </div>
    </section>
   

    </div>
  )
}

export default EmailVerification;