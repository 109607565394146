import React from "react";
import "./Artical.css";
import BlogImage from "../../../Images/Blog Image.png";
import WhiteCar from "../../../Images/Blog Image (2).png";
import HouseArticle from "../../../Images/house-article.png";
import { NavLink } from 'react-router-dom';
import BikeArticle from "../../../Images/black-bike.png";
import MobileArticle from "../../../Images/5.png";
import PlotArticle from "../../../Images/Rectangle 15 (15).png";
import FurnitureArticle from "../../../Images/4.png";
import LaptopArticle from "../../../Images/laptops.jpg";
import quik2 from "../../../Images/QUIK 2.jpg";
import quik3 from "../../../Images/QUIK 3.jpg";
import quik4 from "../../../Images/QUIK 4.jpg";
import quik5 from "../../../Images/QUIK 5.jpg";
import quik from "../../../Images/QUIK.jpg";


import MultiCarousel from "../../MultiCarousel/MultiCarousel";
import Carousel from "react-multi-carousel";
import PlotsArticle from "./PlotsArticle";

const TrendingArticles = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="trendingContainer container">
      <section className=" shop-part trending-articles-one mt-5">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="top-filter">
                <div className="filter-show">
                  <label className="filter-label">
                    <span className="filter-label-one">Trending Articles</span>{" "}
                  </label>
                </div>
              </div>
            </div>
           
            <Carousel responsive={responsive} showDots={false} infinite={true}  showThumbs={false} autoPlay>
              <div className=" blog-container">
                <div className="article-media">
                    <NavLink to="/trendingarticleone" className="article-image">
                    <img src={BlogImage} alt="article_img"  className="img-blog"/>
                    </NavLink>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Your Ultimate Guide to Electronics Sales with QuiksAle
                  </h6>                 
                </div>
              </div>
              <div className=" blog-container">
                <div className="article-media">
                <NavLink to="/trendingarticletwo" className="article-image">
                    <img src={WhiteCar} alt="article_img" className="img-blog"/>
                    </NavLink>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Your Ultimate Guide to Buying and Selling Used Cars with QuiksAle
                  </h6>                 
                </div>
              </div>
              <div className=" blog-container">
              <NavLink to="/trendingarticlethree" className="article-image">
                <div className="article-media">                
                    <img src={HouseArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Find Your Dream Home: Explore Apartments and Houses for Sale with QuiksAle               
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/bikearticle" className="article-image">
                <div className="article-media">                
                    <img src={BikeArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Ride in Style: Find the Perfect Bike for You with QuiksAle             
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/mobilearticle" className="article-image">
                <div className="article-media">                
                    <img src={MobileArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Explore the Best Deals on Mobile Phones with QuiksAle     
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/plotarticle" className="article-image">
                <div className="article-media">                
                    <img src={PlotArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Find Your Perfect Plot: Explore Plots of Land for Sale with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/furniturearticle" className="article-image">
                <div className="article-media">                
                    <img src={FurnitureArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Transform Your Space: Discover the Perfect Furniture with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/laptoparticle" className="article-image">
                <div className="article-media">                
                    <img src={LaptopArticle} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Unleash Your Potential: Discover the Perfect Laptop with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/quikarticle2" className="article-image">
                <div className="article-media">                
                    <img src={quik2} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Unleash Your Potential: Discover the Perfect Plot with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/quikarticle" className="article-image">
                <div className="article-media">                
                    <img src={quik} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Unleash Your Potential: Discover the Perfect Bike with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/quikarticle3" className="article-image">
                <div className="article-media">                
                    <img src={quik3} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Unleash Your Potential: Discover the Perfect second hand Bike with QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/quikarticle4" className="article-image">
                <div className="article-media">                
                    <img src={quik4} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  Find Great Deals on Used Household Items for Sale at QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              <div className=" blog-container">
              <NavLink to="/quikarticle5" className="article-image">
                <div className="article-media">                
                    <img src={quik5} alt="article_img" className="img-blog"/>
                </div>
                <div className="article-content">
                  <h6 className="about-article">
                  	Sell Second-Hand Products Online at the Best Price on QuiksAle
                 
                  </h6>                 
                </div>
                    </NavLink>

              </div>
              
             
            </Carousel>
          </div>
        </div>
      </section>

     
    </div>
  );
};

export default TrendingArticles;
