import React from "react";
import { UncontrolledPopover, Button, PopoverBody, PopoverHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Menu } from "antd";
import { useAuth } from "../../Context/AuthContext";
import { getAuthToken } from "../../api/Auth";
import { API_BASE_URL } from "../../api/ApiService";
import { getUserId } from "../../api/Auth";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Popover = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const autheader = getAuthToken();
  const baseurl = API_BASE_URL;
  const userId = getUserId();

  function Off() {
    document.getElementById("overlay").style.display = "none";
  }

  const ProfileHandler = () => {
    navigate("/profile");
    
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const [userName, setUserName] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/auth/username/${userId}`, {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        });

        setUserName(response.data);
      } catch (error) {
        toast.error('Error fetching UserName:', error);
      }
    };

    fetchUserData();
  }, []);


  

  return (
    <>
      <div>
        <Button
          id="UncontrolledPopover"
          type="button"
          className="bg-none border-0 m-auto"
          style={{ background: "none", color: "#8B96A5", fontSize: "12px" }}
        >
          Profile
        </Button>
        <UncontrolledPopover
          placement="bottom"
          target="UncontrolledPopover"
          id="overlay"
          onClick={() => {
            Off();
          }}
        >
          <PopoverHeader className="text-center py-4">
            <a href="">{userName}</a>
          </PopoverHeader>
          <div className="border-bottom p-4 ">
            <Button
              id="btn"
              type="button"
              className="w-100"
              style={{ background: "#940D19" }}
              onClick={(e) => {
                e.preventDefault();
                ProfileHandler();
              }}
            >
              View  Profile
            </Button>
          </div>
          <PopoverBody>
            <Menu
              onClick={({ key }) => {
                if (key === '/logout') {
                  handleLogout();
                } else {
                  window.location.href = key;
                }
              }}
              className="py-3 sidebar-options"
              items={[
                {
                  label: "My ADS",
                  key: "/myadstable",
                  icon: <AiOutlineUnorderedList />,
                },
                {
                  label: "HELP",
                  key: "/Help",
                  icon: <AiOutlineUnorderedList />,
                },
                {
                  label: "Logout",
                  key: "/logout",
                  icon: <AiOutlineUnorderedList />,
                },
              ]}
            ></Menu>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    </>
  );
};

export default Popover;
