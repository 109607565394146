import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import {SearchProvider} from './Context/SearchContext'
import store from './Redux/store'; // Import your Redux store
import { hydrate, render } from "react-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}> 
 <SearchProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
      </SearchProvider>
  </Provider>,
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
reportWebVitals();
