import React, { useState, useEffect } from 'react'
import "./SignIn.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from "@mui/icons-material/Call";
import GoogleIcon from '@mui/icons-material/Google';
import EyeImage from "../../Images/Eye.png";
import { setAuthToken, setUserId } from "../../api/Auth";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from '../../Context/AuthContext';
import { API_BASE_URL } from '../../api/ApiService';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SignIn = () => {
	const navigate = useNavigate();
	const baseurl = API_BASE_URL;
	const { isAuthenticated, login } = useAuth();

	const [showPassword, setShowPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneError, setPhoneError] = useState("");


	const [userData, setUserData] = useState({
		username: "",
		email: "",
		phone: "",
		password: "",
	});
	const handleNumericInput = (event) => {
		const pattern = /^[0-9]*$/;
		const inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	};



	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUserData({
			...userData,
			[name]: value,
		});
		


		if (name === "phone") {
			const numericValue = value.replace(/\D/g, '');
			setUserData({
				...userData,
				[name]: numericValue.slice(0, 10),
			});

			if (numericValue.length === 10) {
				setPhoneError("");
			} else {
				setPhoneError("Phone number should be 10 digits");
			}
		} else {
			setUserData({
				...userData,
				[name]: value,
			});
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(userData.email)) {
			setEmailError("Invalid email");
		} else {
			setEmailError("");
		}

		if (emailError || phoneError) {
			toast.error("Please enter valid email and phoneNumber before submitting");
			return;
		}
		try {
			const response = await axios.post(
				`${baseurl}/api/auth/signin`,
				{
					email: userData.email,
					phone: userData.phone,
					password: userData.password,
				},

			);

			if (response.status === 200) {
				
				if (login && typeof login === 'function') {
					login(response.data.accessToken, response.data.id);
				}


				//   setUsername(response.data.username);

				// setAuthToken(response.data.accessToken);
				// setUserId(response.data.id);
				// const decodedToken = decodeAuthToken();
				// console.log('Decoded Token:', decodedToken);
				toast.success("Login Successfull");
				navigate("/");
			} else {
				toast.error("Sign-in failed");
			}
		} catch (error) {
			toast.error("Invalid credentials:", error);
		}
	};

	useEffect(() => {
	}, [isAuthenticated]);


	return (
		<>
			<div className="signin py-5">
				<div class="container">
					<div class="screen">
						<div class="screen__content">
							<form class="login" onSubmit={handleSubmit}>
								<div class="login__field">
									{/* <i class="login__icon fas fa-user"></i> */}
									<EmailIcon style={{ color: "#940d19" }} />
									<input
										type="email"
										class="login__input"
										value={userData.email}
										onChange={handleInputChange}
										name="email"
										placeholder="Email"
									/>
                         {emailError && <div style={{ color: "red" }}>{emailError}</div>}

								</div>
								<div class="login__field">
									{/* <i class="login__icon fas fa-user"></i> */}
									<CallIcon style={{ color: "#940d19" }} />
									<input
										type="text"
										class="login__input"
										placeholder="Phone Number"
										name='phone'
										value={userData.phone}
										onChange={handleInputChange}
										onKeyPress={handleNumericInput}
									/>
								</div>
								<div class="login__field">
									{/* <i class="login__icon fas fa-lock"></i> */}
									<HttpsIcon style={{ color: "#940d19" }} />

									<input
										type={showPassword ? "text" : "password"}
										class="login__input"
										placeholder="Password"
										value={userData.password}
										onChange={handleInputChange}
										name="password"
										// minLength={8}
									/>
									<img
										src={EyeImage}
										alt=""
										onClick={togglePasswordVisibility}
										className="eye-icon"
									/>
								</div>
								<div>
									<NavLink to="/forgotpassword">
										<p className='forgot-password'>Forget Password</p>
									</NavLink>
								</div>
								<button class="button login__submit">
									<span class="button__text">Log In </span>
									<i class="button__icon fas fa-chevron-right"></i>
								</button>
								{/* <div className="mt-5 text-center signup-btn">
									New Here?
									<NavLink
										to="/signup"
										className="fs-5"
									>
										Signup
									</NavLink>
								</div>
								<div> */}
				<div>								
                  <NavLink to="/signup">  
                  <button className="login__submit" >
                    SignUp
                  </button>
                  </NavLink>
                </div>


							</form>
							<div class="social-login">
								<h3>log in via</h3>
								<div className="d-flex justify-content-center">
									<div class="social-icons">
										{/* <a href="#" class="social-login__icon fab fa-instagram"></a> */}
										{/* <a href="#" class="social-login__icon fab fa-facebook"></a> */}
										<a href="">
											<GoogleIcon style={{ color: "#fff" }} />
										</a>

										<a href="">
											<InstagramIcon style={{ color: "#fff" }} />
										</a>
										<a href="">
											<FacebookIcon style={{ color: "#fff" }} />
										</a>

										{/* <a href="#" class="social-login__icon fab fa-twitter"></a> */}
									</div>
								</div>
							</div>
						</div>
						<div class="screen__background">
							<span class="screen__background__shape screen__background__shape4"></span>
							<span class="screen__background__shape screen__background__shape3"></span>
							<span class="screen__background__shape screen__background__shape2"></span>
							<span class="screen__background__shape screen__background__shape1"></span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SignIn