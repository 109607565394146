import React from "react";
import "./About.css";
import logo from "../../Images/about-5.jpg";
import { Helmet } from "react-helmet";


const About = () => {
  return (
    <>
     <div>
            <Helmet>
                <title>Sell Second hand Furniture | QuiksAle </title>
                <meta name="description" content="Learn how quciksale can fuel your business with all the capabilities of enterprise ecommerce—without the cost or complexity." />
                </Helmet>
      <div className="my-5 aboutUs">
        <div className="py-3">
        <div className="py-3 about-page">
          <div class="container">
            <div class="row ">
              <div class="col-lg-7 col-12">
                <div class="title_all_box style_one dark_color">
                  <div class="title_sections left">
                    <div class="about_title"> About Us </div>
                    {/* <h2 > Lorem ipsum dolor sit amet.</h2> */}
                    <p className="about_text">
                      {" "}
                      Welcome to Quik Sale, your one-stop destination for easy
                      buying and selling! Discover a diverse selection of sleek
                      cars, powerful bikes, and the latest tech gadgets in our
                      Electronics section. Stay connected with stylish mobile
                      phones and laptops that blend fashion with functionality.
                      Spruce up your space with our curated furniture collection
                      for added comfort and aesthetics. 
                    </p>
                  </div>
                </div>
                <div class="pd_bottom_30"></div>
                <div class="signature_wrapper">
                  <div class="row gutter_15px">
                    <div class="col-lg-4 col-md-12 ">
                      <div class="readmore_btn_all color_one">
                        {/* <a href={About.html} className="readmore_text">
                      Read More
                    </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" col-lg-5 col-12">
                <div class="service_wrapper">
                  <div class="move_image_absolute">
                    <img
                      src={logo}
                      class="class-fluid"
                      alt="aboutcompany_image"
                      style={{ height: "50vh", width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="py-3 ">
            <div class="container  py-3">
              <div class="row ">
                <div class=" col-lg-5 col-12">
                  <div class="service_wrapper">
                    <div class="move_image_absolute">
                      <img
                        src={logo}
                        class="class-fluid"
                        alt="aboutcompany_image"
                        style={{ height: "50vh", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-7">
                  <div class="title_all_box style_one dark_color">
                    <div class="title_sections left">
                      <h2> Our Mission</h2>
                      <p className="about_text">
                        {" "}
                        At Quik Sale, our mission is to empower individuals by providing a platform that simplifies and enhances the buying and selling experience. We strive to create a user-friendly space where everyone can easily connect, discover, and transact with confidence.
                      </p>
                    </div>
                  </div>
                  <div class="pd_bottom_30"></div>
                  {/* <div class="signature_wrapper">
              <div class="row gutter_15px">
                <div class="col-lg-4 col-md-12 ">
                  <div class="readmore_btn_all color_one">
                    <a href={About.html} className="readmore_text">
                      Read More
                    </a>
                  </div>
                </div>               
              </div>
            </div>            */}
                </div>
              </div>
            </div>
          </div>
          <div className="py-3 about-page">
            <div class="container  py-3">
              <div class="row ">
                <div class="col-12 col-lg-7">
                  <div class="title_all_box style_one dark_color">
                    <div class="title_sections left">
                      {/* <div class="about_title"> About Us </div> */}
                      <h2> Our Vision</h2>
                      <p className="about_text">
                        {" "}
                         Our vision at Quik Sale is to be the ultimate destination for individuals seeking a hassle-free and diverse marketplace. We envision a platform that not only meets but exceeds the evolving needs and expectations of our users, setting new standards in online transactions.
                      </p>
                    </div>
                  </div>
                  <div class="pd_bottom_30"></div>
                  {/* <div class="signature_wrapper">
              <div class="row gutter_15px">
                <div class="col-lg-4 col-md-12 ">
                  <div class="readmore_btn_all color_one">
                    <a href={About.html} className="readmore_text">
                      Read More
                    </a>
                  </div>
                </div>               
              </div>
            </div>            */}
                </div>
                <div class="col-12 col-lg-5">
                  <div class="service_wrapper">
                    <div class="move_image_absolute">
                      <img
                        src={logo}
                        class="class-fluid"
                        alt="aboutcompany_image"
                        style={{ height: "50vh", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3">
            <div class="container  py-3">
              <div class="row ">
                <div class="col-12">
                  <div class="title_all_box style_one dark_color">
                    <div class="title_sections left">
                      <h2> Values</h2>
                      <p className="about_text">
                        {" "}
                        <ul>
                          <li>•	User-Centricity: We prioritize our users, ensuring their needs are at the forefront of everything we do.</li>
                          <li>•	Integrity: We uphold the highest ethical standards, fostering trust and transparency in all our interactions.</li>
                          <li>•	Innovation: We embrace creativity and technology to continually improve and adapt to the ever-changing marketplace.</li>
                          <li>•	Diversity: We celebrate and respect the diversity of our users and the range of products and services on our platform.</li>
                          <li>•	Efficiency: We are committed to providing a seamless and efficient experience, making transactions quick and easy.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div class="pd_bottom_30"></div>                 
                </div>               
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 about-page">
        <div className="container">
      <div className="main-text">Join us at Quik Sale, where our mission, vision, and values converge to create a dynamic and user-centric online marketplace. Experience the simplicity of buying and selling like never before!</div>
      </div>
      </div>
      </div>
      </div>
     
    </>
  );
};

export default About;
