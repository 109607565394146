import React, { useState, useEffect } from "react";
import "./Profile.css";
import EyeImage from "../../Images/Eye.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useNavigate } from "react-router-dom";
import profileimg from "../../Images/Ellipse 431.png";
// import Breadcrumbs from "../../components/Pageprops/BreadCrumbs";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";
import { getAuthToken } from "../../api/Auth";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserId } from "../../api/Auth";




const Profile = () => {
  const Navigate = useNavigate();
  const baseurl = API_BASE_URL;
  const autheader = getAuthToken();
  const HomeHandler = () => {
    Navigate("/");
  };
  const userId = getUserId();


    const [userData, setUserData] = useState([]);
    const [userProfile, setUserProfile] = useState([]);

    useEffect(() => {
      const fetchUserPhoto = async () => {
        try {
          const response = await axios.get(
            `${baseurl}/api/auth/getUserProfile/${userId}`,
            {
              headers: {
                Authorization:` Bearer ${autheader}`,
              },
            }
          );
  
          setUserProfile(response.data);
        } catch (error) {
          toast.error("Error fetching Profile Photo:", error);
        }
      };
  
      fetchUserPhoto();
    }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/api/auth/getUserProfile/${userId}`,
          {
            headers: {
              Authorization:` Bearer ${autheader}`,
            },
          }
        );

        setUserData(response.data);
      } catch (error) {
        toast.error("Error fetching Users data:", error);
      }
    };

    fetchUserData();
  }, []);
  

  

  return (
    <>
      <div className="product-secondrow my-2">
        {/* <Breadcrumbs /> */}
      </div>
      <div className="profile-section my-5">
        <div className="container ">
          <div className="row user-profile justify-content-center">
            <div className="col-10">
              <div className="row user-profile-row">
                <div className="profile-page-heading col-12 py-3 border-bottom">
                  My Profile
                </div>
                <div className="col-lg-4 col-md-12 py-3">
                  <div>
                  <div>                  
                   <img src={userData?.images} alt="profile_img" style={{width:"80%" , height:"auto"}}/>                  
                  </div>                    
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 py-3">
                  <div className="profile-info-wrap">
                    <div className="row">
                      <div className="col-md-4 col-6">
                        {/* <div className="profile-info mb-20px "> */}
                          <label htmlFor="firstName" className="form-label">
                            First Name:
                          </label>                                                 
                        {/* </div> */}
                      </div>
                      <div className="col-md-4 col-6"><div htmlFor="">{userData?.firstName}</div> </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 col-6">
                          <label htmlFor="lastName" className="form-label">
                            Last Name:
                          </label>
                      </div>
                      <div className="col-md-4 col-6"><div htmlFor="">{userData?.secondName}</div> </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 col-6">
                          <label htmlFor="lastName" className="form-label">
                          City:
                          </label>
                      </div>
                      <div className="col-md-4 col-6"><div htmlFor="">{userData?.city}</div> </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 col-6">
                          <label htmlFor="lastName" className="form-label">
                          Email:
                          </label>                        
                         </div>
                      <div className="col-md-4 col-6"><div className="overflowX">{userData?.email}</div> </div>
                      </div>
                      <div className="row">
                      <div className="col-md-4 col-6">
                          <label htmlFor="lastName" className="form-label">
                          Phone Number:
                          </label>
                          <label htmlFor=""></label>                         
                      </div>
                      <div className="col-md-4 col-6"><div htmlFor="">{userData?.phone}</div> </div>
                      </div>                     
                      </div>                
                                                           
                    </div>
                    <div className="edit-button my-3">
                    <Link to="/editprofile">
                      <button type="submit" 
                      className="border-0"
                   
                      >
                       edit profile
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
     
    </>
  );
};

export default Profile;