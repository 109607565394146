import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";




const MultiCarousel = () => {
  const [offers, setOffers] = useState([]);
const baseurl = API_BASE_URL;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/product/category?category=MOBILE`);
		 console.log(response.data);
        setOffers(response.data);
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    fetchOffers();
  }, []); 

  return (
    <>
      <Carousel responsive={responsive} showDots={false} infinite={true} swipeable={true} draggable={true} autoPlay autoPlaySpeed={2000}   >
      {offers.map((item) => (
       <NavLink to="/mobiles">
      
        <div className="d-flex justify-content-center" style={{height:"12rem"}}>
        
                  <img src={item.images} alt=" " style={{objectFit:"contain", width:"100%", height:"auto"}}/>
                 
                                  
              </div>
              
         
        </NavLink>  
          ))}       
      </Carousel>
    </>
  );
};

export default MultiCarousel;
