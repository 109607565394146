import React from 'react';
import Marquee from "react-fast-marquee";
import samsungimg from '../../Images/samsungimg.png';
import joyroom from '../../Images/image.png';
import oppo from '../../Images/image (8).png';
import { NavLink } from 'react-router-dom';
import "./MarqueeSection.css";
const MarqueeSection = () => {

  return (
    <>
       <div className='mt-5'>
        <div className='marquee-container '>
        <Marquee pauseOnHover="true" speed="70">

        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={samsungimg} alt="" />
        </div>
        </NavLink>

        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={joyroom} alt="" />
        </div>
        </NavLink>

        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={oppo} alt="" />
        </div>
        </NavLink>

        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={samsungimg} alt="" />
        </div>
        </NavLink>
        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={samsungimg} alt="" />
        </div>
        </NavLink>
        <NavLink to="/mobiles">
        <div className="marquee-item m-lg-5 m-md-3 m-1">
          <img src={samsungimg} alt="" />
        </div>
        </NavLink>
        </Marquee>
        </div>
      </div>
    </>
  )
}

export default MarqueeSection;