import React from "react";
import "./TrendingArticle.css";

const FurnitureArticle = () => {
  return (
    <>
      <nav class="navbar navbar-fixed-top nabvar-default">
        <div class="container navbar-container">
          <div class="row">
            <div id="navbar" class="navbar-collapse collapse">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-right navbar-menu-left">
                  <li>
                    <a class="navbar-nav-link" href="#bio">
                      Short Bio
                    </a>
                  </li>
                  <li>
                    <a class="navbar-nav-link" href="#wronful_dead">
                      Wrongful Death
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-left navbar-menu-right">
                  <li>
                    <a class="navbar-nav-link" href="#drama_film">
                      Drama Film
                    </a>
                  </li>
                  <li>
                    <a
                      class="navbar-nav-link link-further-info"
                      href="https://en.wikipedia.org/wiki/Alan_Turing"
                      target="_blank"
                    >
                      Further Information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header>
        <div class="blog-title-wrapper container">
          <div class="row">
            <div class="col-sm-3 module-fields">
              <i class="fa fa-wrench fa-4x"></i>
              <h2 class="blog-subtitle">
              Transform Your Space: Discover the Perfect Furniture with QuiksAle
              </h2>
              <ul class="fields_list">
                <li class="fields_item">
                Looking to spruce up your home or office with new furniture? Look no further than QuiksAle! Whether you're in need of bedroom furniture, office furniture, or living room furniture, QuiksAle offers a wide range of options to suit every need and style. From Royal Oak to Supreme furniture, QuiksAle has everything you need to transform your space.
                </li>
                
              </ul>
            </div>
            <div id="bio" class="col-sm-9 module-bio">
              {/* <div class="inline-meta date">
                <i class="fa fa-smile-o"></i> 23 June 1912 -{" "}
                <i class="fa fa-frown-o"></i> 7 June 1954
              </div> */}
              <h1 class="blog-title">
              Transform Your Space: Discover the Perfect Furniture with QuiksAle
              </h1>
              <div class="blog-description">
                1.{" "}
                <b>Furniture Shop Near Me / Second Hand Furniture Near Me / Old Furniture Buyers Near Me:  </b>
                Looking for a furniture shop near you? QuiksAle's platform allows you to easily find furniture shops and old furniture buyers in your area. Whether you're looking to buy or sell furniture, QuiksAle's network of sellers ensures that you can find what you need right in your neighborhood.
                <br />
                2. <b>Royal Oak Furniture / Royal Oak Furniture Online: </b><br />
                Explore a wide range of Royal Oak furniture on QuiksAle! Whether you're looking for a classic dining table or a modern sofa set, QuiksAle offers competitive prices on a variety of Royal Oak furniture. Plus, with Royal Oak furniture available online, you can shop from the comfort of your home.
                <br />
                3. <b>Bedroom Furniture / Hall Furniture Design / Living Room Furniture / Room Furniture Design / Home Furniture Design:   </b><br />
                From bedroom furniture to living room furniture, QuiksAle offers a wide range of options to suit every need and style. Whether you're looking for a sleek and modern design or a classic and elegant style, QuiksAle's platform has something for everyone.
                <br />
                4. <b>Office Furniture:   </b><br />
                In need of office furniture? QuiksAle offers a range of options to suit every need. Whether you're looking for a desk, chair, or filing cabinet, QuiksAle's platform makes it easy to find the perfect office furniture for your workspace.
                <br />
                5. <b>Old Furniture Shop Near Me / Sell Old Furniture:    </b><br />
                Looking to sell your old furniture or buy second-hand furniture? QuiksAle makes it easy to list and sell your old furniture. Simply create a listing, add photos and descriptions, and wait for buyers to make offers. With QuiksAle's secure payment system, you can sell and buy furniture with confidence.
                <br />
                6. <b>PVC Furniture / Teak Wood Furniture:   </b><br />
                QuiksAle offers a range of furniture materials, including PVC and teak wood. Whether you're looking for a durable and affordable option or a luxurious and elegant choice, QuiksAle's platform has something for every taste and budget.
                <br />  
                <br />
                6. <b>Supreme Furniture:   </b><br />
                Explore a wide range of Supreme furniture on QuiksAle! Whether you're looking for a comfortable chair or a stylish dining table, QuiksAle offers competitive prices on a variety of Supreme furniture.
                <br />               
              
                <b className="mt-3">
                In conclusion, QuiksAle is your ultimate destination for buying and selling furniture. Whether you're looking for a specific brand like Royal Oak or Supreme, or simply searching for the perfect piece to complete your space, QuiksAle's platform offers a seamless buying and selling experience. So why wait? Join QuiksAle today and transform your space with the perfect furniture!
                </b><br />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <footer class="footer">
  <div class="container">
    <a href="https://en.wikipedia.org/wiki/Alan_Turing" target="_blank" class="further_info_link">Further Information</a>
  </div>
</footer> */}
    </>
  );
};

export default FurnitureArticle;
