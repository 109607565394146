// import axios from 'axios';
//base url
export const  API_BASE_URL = 'https://quiksale.in:8087';
// export const  API_BASE_URL = 'http://localhost:8087';
//product api
export const API_PRODUCT = 'api/product/allProduct';

 
//Selected category Product api
export const API_PRODUCTCATEGORY = 'api/product/category';

//productSummary api

export const API_PRODUCTSUMMARY = 'api/product';
