import React from "react";
import { Carousel as _carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import imgcamera from "../../Images/Rectangle 15 (12).png";
import mainimage from "../../Images/Main Image.png";
import m13 from "../../Images/laptops.jpg";
import m53 from "../../Images/laptopss.jpg";
import m33 from "../../Images/laptop.png";
import "./carousel.css"
const Carousel = () => {
  return <>
<_carousel showArrows="true" className="carousel" autoPlay showThumbs={false}>
                <div className="p-2">
                    <img src={mainimage} />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div className="p-2">
                    <img src={m13} />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div className="p-2">
                    <img src={m53} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                    <img src={m33} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                    <img src={imgcamera} />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </_carousel>
  </>;
};

export default Carousel;
