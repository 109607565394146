import React from 'react';
import headphone from '../../../Images/headphone.jpg';
import laptopss from '../../../Images/laptopss.jpg';

import phonec from '../../../Images/phonec.jpg';
import speaker from '../../../Images/speaker.jpg';

import smartwatch from '../../../Images/smartwatch.jpg';
import computerasses from '../../../Images/computerasses.jpg';



const Electronics = () => {
  return (
    <div>
        <section style={{backgroundColor: '#eee'}}>
  <div class="container py-5">
    <h4 class="text-center mb-5"><strong>Electronic Offers</strong></h4>

    <div class="row">
      <div class="col-lg-4 col-md-12 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
          <img src={headphone}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">50% offer on every Headphones you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor:'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
          <img src={laptopss}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">50% offer on every Laptops you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor:  'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
          <img src={phonec}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor:  "rgba(0, 0, 0, 0.3)"}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">40% offer on every Laptops you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor: 'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-12 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded ripple-surface">
          <img src={speaker}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor:  'rgba(0, 0, 0, 0.3)'}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">30% offer on every Speakers you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor: 'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
          <img src={smartwatch}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">60% offer on every SmartWatch you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor: 'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mb-4">
        <div class="bg-image hover-zoom ripple shadow-1-strong rounded">
          <img src={computerasses}
            class="w-100" />
          <a href="#!">
            <div class="mask" style={{backgroundColor: ' rgba(0, 0, 0, 0.3)'}}>
              <div class="d-flex justify-content-start align-items-start h-100">
                <h5><span class="badge bg-light pt-2 ms-3 mt-3 text-dark">70% offer on every Accessories you Buy</span></h5>
              </div>
            </div>
            <div class="hover-overlay">
              <div class="mask" style={{backgroundColor: 'rgba(253, 253, 253, 0.15)'}}></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default Electronics;