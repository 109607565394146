import React, { useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";
import './ForgotPassword.css';
import ResetPassword from "../ResetPassword/ResetPassword";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = ()=> {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetToken, setResetToken] = useState(null);

  const JoinNowHandler = () => {
    Navigate("/signup");
  };
  const LoginHandler = () => {
    Navigate("/signin");
  };
  const HomeHandler = () => {
    Navigate("/");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  const baseurl = API_BASE_URL;

  const handleSendCode = async (e) => {
    e.preventDefault();

    // Validate email format
    if (!validateEmail(email)) {
      console.error("Invalid email format");
      return;
    }

    try {
      const response = await axios.post(`${baseurl}/api/auth/forgot-password?email=${email}`);
      setResetToken(response.data.token);
      toast.success("Password reset link sent successfully!", response.data);
    } catch (error) {
      console.error("Error sending code/link", error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <nav
        style={{ "--bs-breadcrumb-divider": ">" }}
        aria-label="breadcrumb "
        className="breadcrumb-nav d-flex justify-content-center"
      >
        <ol className="breadcrumb w-60 mb-0">
          <li className="breadcrumb-item d-flex">
         
          </li>
          
        </ol>
      </nav>

      <section className="user-form-part">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
              <div className="user-form-card">
                <div className="user-form-title">
                  <h2> Forget Password?</h2>
                  <p>
                    Enter the email address or mobile phone number associated
                    with your account.
                  </p>
                </div>
                <form className="user-form">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <label htmlFor="phoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneNumber"
                    aria-describedby="emailHelp"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />

                  <div className="form-button">
                    <button type="submit" onClick={handleSendCode}>
                      Submit <img src="images/ArrowRight-white.png" alt="" />
                    </button>
                  </div>
                  <div className="text_para para-one">
                    Already have an account?
                    <span>
                      <a
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          LoginHandler();
                        }}
                      >
                        Sign In
                      </a>
                    </span>
                  </div>
                  <div className="text_para para-two">
                    Don't have an account?
                    <a
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        JoinNowHandler();
                      }}
                    >
                      Sign Up
                    </a>
                  </div>
                  <hr />
                  <p className="text_para para-3">
                    You may contact{" "}
                    <span className="font-red">Customer Service</span> for help
                    restoring access to your account.
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      
      </section>
    </div>
  );
};

export default ForgotPassword;
