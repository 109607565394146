import React, { useRef } from "react";
import { styled } from "styled-components";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined"; // Import video icon

function ImageUpload() {
  const inputRef = useRef(null);

  const handleVideoClick = () => {
    inputRef.current.click();
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Handle the video file, you can upload it to a server or perform other operations
      console.log("Selected video:", file);
    }
  };

  return (
    <>
      <MainDiv onClick={handleVideoClick}>
        <ImageDiv>
          <StyledIcon as={VideoLibraryOutlinedIcon} />
        </ImageDiv>
        <input
          type="file"
          ref={inputRef}
          className="d-none"
          accept="video/*" // Accept only video files
          onChange={handleVideoChange}
        />
      </MainDiv>
    </>
  );
}

export default ImageUpload;

const MainDiv = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  scale: 1;
  cursor: pointer;
`;

const ImageDiv = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
`;

const StyledIcon = styled(VideoLibraryOutlinedIcon)`
  position: "absolute";
  padding: 3px;
  color: #a0a2a5;
  border-radius: 100%;
  background-color: white;
  transform: translate(18px, -20px);
  cursor: pointer;
`;
