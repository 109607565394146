import React from "react";
import logo from "../../Images/about-5.jpg";
import ApplesignImage from "../../Images/apple-sign.png";
import IphoneImage from "../../Images/iphone.png";
const CarouselCard = () => {
  return (
    <>
      {
        // <div className="iphone p-2 ">
        // <div className="product-card d-flex">
        //   <div className="product-content w-50">
        //     <h6 className="product-name mt-3">
        //       <span>IPHONE</span>
        //     </h6>
        //     <div className="d-flex">
        //       {" "}
        //       <a href="" className="product-img">
        //         <img src={ApplesignImage} alt="apple_phone" />
        //       </a>
        //     </div>
        //     <p className="product-details">UP to 80% OFF</p>
        //   </div>
        //   <div className="product-media w-50">
        //     <img src={IphoneImage} alt="product" />
        //   </div>
        // </div>
        // </div>
        <div className="card p-2">
          <img src="../../Images/about-5.jpg" alt="" />
        </div>
      }
    </>
  );
};

export default CarouselCard;
