import React, { useState } from 'react';
import './Cart.css';
import CartImage1 from '../../Images/cart-img1.png';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { NavLink, useNavigate } from 'react-router-dom';

const Cart = () => {
    const Navigate = useNavigate();

    const HomeHandler = () => {
        Navigate("/");
    };

    const [cartItems, setCartItems] = useState([
        {
            id: 1,
            name: "4K UHD LED Smart TV with Chromecast Built-in",
            image: CartImage1,
            price: 2500,
            quantity: 1,
        },
    ]);

    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);

    const updateQuantity = (itemId, newQuantity) => {
        const updatedCart = cartItems.map((item) => {
            if (item.id === itemId) {
                return { ...item, quantity: newQuantity};
            }
            return item;
        });

        setCartItems(updatedCart);
    };

    const removeItem = (itemId) => {
        const updatedCart = cartItems.filter((item) => item.id !== itemId);
        setCartItems(updatedCart);
    };

    const calculateSubtotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    const applyCoupon = () => {
        // Implement coupon logic here (e.g., API call, validation)
       
        setDiscount(500);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        return subtotal - discount;
    };

    return (
        <div>
            {/* <div className=" product-secondrow my-2">
                <nav className="breadcrumb-nav py-3">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <HomeOutlinedIcon />
                            <a
                                href="."
                                onClick={(e) => {
                                    e.preventDefault();
                                    HomeHandler();
                                }}
                            >
                                Home
                            </a>
                            <KeyboardArrowRightOutlinedIcon />
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Cart
                        </li>
                    </ol>
                </nav>
            </div> */}
            <div className="cart-wrap my-5 pt-3">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="table-wishlist border">
                                <table className="cart-table">
                                    <thead>
                                        <tr className="cart-heading-row">
                                            <th width="55%" className='py-3'>Products</th>
                                            <th width="15%" className='py-3'>Price</th>
                                            <th width="15%" className='py-3'>Quantity</th>
                                            <th width="15%" className='py-3'>Sub-Total</th>
                                            <th width="15%" className='py-3'>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((item) => (
                                            <tr key={item.id}>
                                                <td width="55%">
                                                    <div className="d-flex w-100">
                                                        <div className="w-10"><i></i></div>
                                                        <div className="img-product w-25 py-3">
                                                            <img
                                                                src={item.image}
                                                                alt={item.name}
                                                                className="mCS_img_loaded"
                                                            />
                                                        </div>
                                                        <div className="product-details w-55 py-3">
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="price py-3">₹{item.price}</td>
                                                <td className='py-3'>
                                                    <span className="available-box">
                                                        <div className="cart-action-group">
                                                            <div className="product-action d-flex">
                                                                <button
                                                                    className="action-minus"
                                                                    title="Quantity Minus"
                                                                    onClick={() =>
                                                                        updateQuantity(item.id, item.quantity - 1)
                                                                    }
                                                                >
                                                                    <i className="bi bi-dash-lg"></i>
                                                                </button>
                                                                <input
                                                                    className="action-input"
                                                                    title="Quantity Number"
                                                                    type="text"
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                />
                                                                <button
                                                                    className="action-plus"
                                                                    title="Quantity Plus"
                                                                    onClick={() =>
                                                                        updateQuantity(item.id, item.quantity + 1)
                                                                    }
                                                                >
                                                                    <i className="bi bi-plus-lg"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </td>
                                                <td className='py-3'>₹{item.price * item.quantity}</td>
                                                <td className='py-3'>
                                                    <button
                                                        className='remove-btn'
                                                        onClick={() => removeItem(item.id)}
                                                    >
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="cart-buttons d-flex justify-content-between">
                                    <NavLink to="/products">
                                        <button className="return-btn">
                                            <img src="images/ArrowLeft.png" alt="" />Return to Shop
                                        </button>
                                    </NavLink>
                                    <button className="update-btn">Update cart</button>
                                </div>
                            </div>
                        </div>                
                        <div className="col-md-4 checkout-subsection">
                            <div className="row border checkout">
                                <div className="table-main-heading">Card Totals</div>
                                <ul className="d-flex">
                                    <li>Sub-total</li>
                                    <li>₹{calculateSubtotal()}</li>
                                </ul>
                                <ul className="d-flex">
                                    <li>Discount</li>
                                    <li>₹{discount}</li>
                                </ul>
                                <ul className="d-flex border-bottom">
                                    <li>Tax</li>
                                    <li>₹657</li>
                                </ul>
                                <ul className="d-flex">
                                    <li>Total</li>
                                    <li>₹{calculateTotal()}</li>
                                </ul>
                                <ul className="d-flex">
                                    <li>Sub-total</li>
                                    <li>₹{calculateSubtotal()}</li>
                                </ul>
                                <div className="checkout-btn-container">
                                    <button className="checkout-btn">Proceed to Checkout</button>
                                    <img src="images/ArrowRight-white.png" alt="" />
                                </div>
                            </div>
                            <div className="row border mt-3">
                                <div className="p-2">Coupon Code</div>
                                <div className="w-90 border p-1 coupon-input-container" style={{ margin: '12px auto' }}>
                                    <input
                                        type="text"
                                        placeholder="Enter coupon code"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                </div>
                                <div className="apply-coupon-container">
                                    <button className="apply-coupon-btn" onClick={applyCoupon}>
                                        Apply Coupon
                                    </button>
                                    <img src="images/ArrowRight-white.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;



