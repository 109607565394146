import React ,{useState}from 'react';
import Slider from "@mui/material/Slider";
import Logo from "../Images/Quick Sale Logo 1.png";
import './Sidebar.css';
import advertiseImg from "../Images/image 8.png";
import { Box, IconButton, InputBase, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { NavLink } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';



const PlotsSideBar = ({ Carcategories,
  handleBrandChange,
  priceRange,
  handlePriceRangeChange,
  checkboxData,
  tagData,
  selectedTags,
  handleTagChange,
  handleCategory,
  Carcategory,
  // priceMatch,
  // handlePriceMatch,
  // priceMatches,
  handlePriceSubmit,
  Label,
  YearOfPurchase,
  FuelType,
  handleSearchClick,
  checkboxCity,
  handleCityChange,
  selectedCity,
  handleSearchCity,
  selectedCategory
}) => {

  const [searchValueText, setSearchValueText] = useState("");
  const [error, setError] = useState("");
  const [searchValueCity, setSearchValueCity] = useState("");


  
  

  
  const isValidYear = (year) => {
    const parsedYear = parseInt(year, 10);
    return !isNaN(parsedYear) && parsedYear >= 1990 && parsedYear <= 2024;
  };

  const handleSearch = () => {
    if (isValidYear(searchValueText)) {
      handleSearchClick(searchValueText);
      setError(""); 
    } else {
      setError("Please enter a year between 1990 and 2024.");
    }
  };
  const [showSidebar, setShowSidebar] = useState(false);

  // show and hide sidebar by clicking button
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };


  return (
    <>
    <div className='d-lg-none d-block' style={{width:"80%",margin:"auto"}}>
      <TuneIcon style={{height:"20px", width:"20px"}}/><span onClick={toggleSidebar} >Filter</span></div>
    <div  className={`col-md-3 product-leftcol d-lg-block ${showSidebar ? 'show' : 'hide'}`}>
      <div className="category">
        <p className='pt-3'>
          <b>{Label}</b>        
        </p>
        
        
        <ul className="custom-list ps-0">
        {Carcategories.map((category) => (
            <li key={category} className="py-1">
              <label>
                <input
                  type="checkbox"
                  name="category"
                  checked={selectedCategory.includes(category)}
                  onChange={() => handleCategory(category)}
                  value={category}
                  className='mx-2'
                />
                {category}
              </label>
            </li>
          ))}
        </ul>
      </div>
      <div className="pricerange">
        <div className="Range">
          <p>
            <b>PRICE RANGE</b>
          </p>
          <div style={{ width: "15rem", padding: "20px" }}>
            <Slider
              value={priceRange}
              onChange={handlePriceRangeChange}
              valueLabelDisplay="auto"
              min={100000}
              max={1000000}
            />
            <Typography gutterBottom>{priceRange[0]} - {priceRange[1]}</Typography>
          </div>
        </div>
        <div className="price" onClick={() => handlePriceSubmit(priceRange)}>
          <div className="min text-center">
            <button type="button" onClick={() => handlePriceSubmit(priceRange)}>
              Submit
            </button>
          </div>

        </div>
        {/* <div className="lists">
        <ul className="custom-list ps-0">
        {priceMatch.map((item) => (
          <li key={item.value} className="py-1">
            <label>
              <input
                type="checkbox"
                name="category"
                value={item.value}
                className="mx-2"
                checked={priceMatches.includes(item.value)}
                onChange={() => handlePriceMatch(item.value)}
              />
              {item.label}
            </label>
          </li>
        ))}
      </ul>
        </div> */}
      </div>
      <div className="populorbrands">
        <p>
          <b>Enter City</b>
        </p>
        <TextField id="outlined-search" 
           label="Search City" 
           type="search" 
           size='small'
           
           value={searchValueCity}
           onChange={(e)=> setSearchValueCity(e.target.value)}
           InputProps={{
            endAdornment: ( 
              <IconButton onClick={()=>{handleSearchCity(searchValueCity)}}>
                <SearchIcon />
              </IconButton>
            )
          }}
          
          sx={{ width: 250, '& input': { fontSize: 'medium' } }}/>
       
       
 
         <div className="popularchecks">
          <div className="popularleft">
            <ul className="custom-list ps-0">
              {checkboxCity.slice(0, Math.ceil(checkboxCity.length / 2)).map((item, index) => (
                <li key={index}>
                  <label>
                    <input type="checkbox" name="city "
                      checked={selectedCity.includes(item.label)}
                      onChange={() => handleCityChange(item.label)}
                      value={item.label}
                      className='mx-2' />
                    {item.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="popularright">
            <ul className="custom-list">
              {checkboxCity.slice(Math.ceil(checkboxCity.length / 2)).map((item, index) => (
                <li key={index}>
                  <label>
                    <input type="checkbox"
                      checked={selectedCity.includes(item.label)}
                      onChange={() => handleCityChange(item.label)}
                      name="city" value={item.label} className='mx-2' />
                    {item.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>


      {/* <div className="populorbrands">
        <p>
          <b>{YearOfPurchase}</b>
        </p>
        <Box sx={{ display: "flex", justifyContent: "space-between", border: 1, borderRadius: 4 }}>

          <InputBase
            type="text"
            placeholder="Search by year"
            value={searchValueText}
            onChange={(e)=> setSearchValueText(e.target.value)}

          />

          <IconButton onClick={handleSearch}>        
              <SearchIcon />
          </IconButton>

        </Box>      
        {error && <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>}
 
         <div className="popularchecks">
          <div className="popularleft">
            <ul className="custom-list ps-0">
              {checkboxData.slice(0, Math.ceil(checkboxData.length / 2)).map((item, index) => (
                <li key={index}>
                  <label>
                    <input type="checkbox" name="category"
                      checked={selectedTags.includes(item.label)}
                      onChange={() => handleTagChange(item.label)}
                      value={item.label}
                      className='mx-2' />
                    {item.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="popularright">
            <ul className="custom-list">
              {checkboxData.slice(Math.ceil(checkboxData.length / 2)).map((item, index) => (
                <li key={index}>
                  <label>
                    <input type="checkbox"
                      checked={selectedTags.includes(item.label)}
                      onChange={() => handleTagChange(item.label)}
                      name="category" value={item.label} className='mx-2' />
                    {item.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="populartags">
        <p>
          <b>{FuelType}</b>
        </p>
        <div className="tags  ">
          {tagData.map((tag, index) => (
            <button key={index}
              onClick={() => handleBrandChange(tag)}
              className="tagbtn"
            >
              {tag}
            </button>
          ))}
        </div>
      </div> */}
      <NavLink to="/hotoffers">
      <div className="advertise py-4">
        <div className='advertise-img-container text-center'><img src={Logo} alt="discription" /></div>
        <h6 className='text-center'>
          <img src={advertiseImg} alt='advertise-img'></img>
        </h6>
        <h6 className='text-center'>Heavy on Features.</h6>
        <h6 className='text-center'>Light on Price.</h6>
        <p className='text-center'>
          only for: <button className='advertise-button p-2'><b>&#8377;2999 only </b></button>
        </p>
        {/* <div className='text-center'><button className='advertise-cart-button'>
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ShoppingCartIcon"><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></svg>Add to Cart</button></div>
        <div className='text-center my-2'><button className='advertise-details-button'>View Details &rarr;</button></div> */}

      </div>
      </NavLink>
    </div>
    </>
  );
}

export default PlotsSideBar;