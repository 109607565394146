// auth.js
import * as jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const AUTH_TOKEN_KEY = 'authToken';
const USER_ID_KEY = 'userId';


export const setAuthToken = (accessToken) => {
  sessionStorage.setItem(AUTH_TOKEN_KEY, accessToken);
  localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
};

export const setUserId = (userId) => {
  sessionStorage.setItem(USER_ID_KEY, userId);
  localStorage.setItem(USER_ID_KEY, userId);
};

export const getAuthToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN_KEY) || localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getUserId = () => {
  return sessionStorage.getItem(USER_ID_KEY) || localStorage.getItem(USER_ID_KEY);
};


export const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
};

export const removeUserId = () => {
  localStorage.removeItem(USER_ID_KEY);
  sessionStorage.removeItem(USER_ID_KEY);
};



export const logout = () => {
  removeAuthToken();
  removeUserId();
  window.location.href = '/signin';
};

export const decodeAuthToken = () => {
  const accessToken = getAuthToken();
  if (accessToken) {
    try {
      return jwtDecode(accessToken);
    } catch (error) {
      toast.error('Error decoding auth token:', error);
      return null;
    }
  }
  return null;
};

export const isAuthenticated = () => {
  const accessToken = getAuthToken();

  return !!accessToken;
};
