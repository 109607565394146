import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "@mui/material";

export default function CheckboxLabels() {
  return (
    <FormGroup>
      <FormControlLabel disabled control={<Checkbox defaultChecked sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}/>} 
      label="By Posting this ad you agree to our terms of service and acknowledge you have read our privacy policy."/>
       {/* <Link href="https://adm.quiksale.in/privacy" />Privacy Policy */}
    </FormGroup>
    
  );
}
