import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Pages/Home/Navbar";
import Footer from "./Components/Footer/Footer";
import routes from "./routes";
import './App.css';
import PrivateRoute from "./PrivateRoute"; 
import SignIn from "./Pages/SignIn/SignIn";
import SignUp from "./Pages/SignUp/SignUp";
import { useAuth } from "./Context/AuthContext";
import ScrollToTop from "./Pages/ScrollToTop/ScrollToTop";
import Mobilemenu from "./Pages/Mobile-menu/Mobilemenu";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

<link href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700" rel="stylesheet"></link>

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <ToastContainer />

        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.private ? (
                  <PrivateRoute
                    element={route.element}
                    isAuth={isAuthenticated}
                    fallbackPath="/signin" 
                  />
                ) : (
                  route.element                
                )
              }
            />
          ))}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
         <ScrollToTop/> 
        <Footer />
        <Mobilemenu />
      </BrowserRouter>
    </div>
  );
}

export default App;