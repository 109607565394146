// routes.js
import Home from "./Pages/Home/Home";
import Products from "./Pages/Products/Products";
import ProductSummary from "./Pages/ProductSummary/ProductSummary";
import Profile from "./Pages/Profile/Profile";
import Cart from "./Pages/Cart/Cart";
import EmailVerification from "./Pages/Email-verification/EmailVerification";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import SignUp from "./Pages/SignUp/SignUp";
import SignIn from "./Pages/SignIn/SignIn";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import WishList from "./Pages/Wishlist/WishList";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import SellSection from "./Pages/Sell/SellSection";
import Blogs from "./Pages/Blogs/Blogs";
import FreePost from "./Pages/FreePost/FreePost";
import HotOffers from "./Pages/HotOffers/HotOffers";
import Car from "./Pages/Sell/Car";
import Bike from "./Pages/Sell/Bike";
import Vehicles from "./Pages/Sell/Vehicles";
import MultiCarousel from "./Pages/MultiCarousel/CarouselCard";
import Popover from "./Pages/Profile/Popover";
import MyAds from "./Pages/Profile/MyAds";
import Electronics from "./Pages/HotOffers/Electronics/Electronics";
import Bikes from "./Pages/Products/Bikes";
import Houseapartments from "./Pages/Products/Houseapartments";
import Plots from "./Pages/Products/Plots";
import OtherSell from "./Pages/Products/OtherSell";
import Laptops from "./Pages/Products/Laptops";
import Furnitures from "./Pages/Products/Furnitures";
import ElectronicsCate from "./Pages/Products/Electronics";
import Cars from "./Pages/Products/Cars";
import Mobiles from "./Pages/Products/Mobiles";
import Others from "./Pages/Products/Others";
import GoogleSignup from "./Pages/SignUp/GoogleSignup";
import Carousel from "./Pages/Carousels/Carousel";
import MultiCarouselPage from "./Pages/Carousels/MultiCarousel";
import PlotsSummary from "./Pages/ProductSummary/PlotsSummary";
import { SearchBar } from "./Pages/filtersearch-input/SearchBar";
import ForSale from "./Pages/Sell/ForSale";
import ForRent from "./Pages/Sell/ForRent";
import SellMobiles from "./Pages/Sell/Mobiles";
import SellLaptops from "./Pages/Sell/Laptops";
import SellPlots from "./Pages/Sell/Plots";
import SellOthers from "./Pages/Sell/Others";
import Furniture from "./Pages/Sell/Furniture";
import SellElectronics from "./Pages/Sell/Electronics";
import TrendingArticles from "./Pages/Home/TrendingArticles/TrendingArticles";
import TrendingArticleOne from "./Pages/Home/TrendingArticles/TrendingArticleOne";
import TrendingArticleTwo from "./Pages/Home/TrendingArticles/TrendingArticleTwo";
import TrendingArticleThree from "./Pages/Home/TrendingArticles/TrendingArticleThree";
import VehiclesSummary from "./Pages/ProductSummary/VehiclesSummary";
import HouseApartSummary from "./Pages/ProductSummary/HouseApartSummary";
import FurnitureSummary from "./Pages/ProductSummary/FurnituresSummary";
import GadgetsSummary from "./Pages/ProductSummary/GadgetsSummary";
import MyAdsTable from "./Pages/Profile/MyAdsTable";
import Help from "./Pages/Help/Help";
import Privacy from "./Pages/Help/Privacy";
import Tnc from "./Pages/Help/Tnc"
import Classified from "./Pages/Help/Classified";
import MobileCategory from "./Pages/Home/Categories/MobileCategory";
import Search from "./Pages/Products/Search";
import BikeSummary from "./Pages/ProductSummary/BikeSummary";
import EditProfile from "./Pages/Profile/EditProfile";
import AllOffers from "./Pages/AllOffers/AllOffers";
import BikeArticle from "./Pages/Home/TrendingArticles/BikeArticle";
import MobileArticle from "./Pages/Home/TrendingArticles/MobileArticle";
import FurnitureArticle from "./Pages/Home/TrendingArticles/FurnitureArticle";
import PlotArticle from "./Pages/Home/TrendingArticles/PlotsArticle";
import LaptopArticle from "./Pages/Home/TrendingArticles/LaptopArticle";

import QuikArticle from "./Pages/Home/TrendingArticles/QuikArticle";

import QuikArticle2 from "./Pages/Home/TrendingArticles/QuikArticle2";
import QuikArticle3 from "./Pages/Home/TrendingArticles/QuikArticle3";
import QuikArticle4 from "./Pages/Home/TrendingArticles/QuikArticle4";
import QuikArticle5 from "./Pages/Home/TrendingArticles/QuikArticle5";
import Faq from "./Pages/Home/Faq/Faq";




const routes = [
  { path: "/", element: <Home /> },
  { path: "/products", element: <Products /> },
  { 
    path: "/cars/:selectedProductId", 
    element: <VehiclesSummary /> 
  },
  { 
    path: "/bikes/:selectedProductId", 
    element: <BikeSummary /> 
  },
  {
    path: "/plots/:selectedProductId",
    element: <PlotsSummary />,
  },
  {
    path: "/apartments/:selectedProductId",
    element: <HouseApartSummary />,
  },
  {
    path: "/furnitures/:selectedProductId",
    element: <FurnitureSummary />,
  },
  {
    path: "/electronics/:selectedProductId",
    element: <GadgetsSummary/>,
  },
  {
    path: "/mobiles/:selectedProductId",
    element: <GadgetsSummary/>,
  },
  {
    path: "/laptops/:selectedProductId",
    element: <GadgetsSummary/>,
  },
  {
    path: "/others/:selectedProductId",
    element: <ProductSummary/>,
  },
  { path: "/reset-password",element: <ResetPassword />, private: true },
  { path: "/profile", element: <Profile />, private: true },
  { path: "/cart", element: <Cart /> },
  { path: "/emailverification", element: <EmailVerification />,private: true},
  { path: "/forgotpassword", element: <ForgotPassword />, private: true },
  { path: "/signup", element: <SignUp /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/WishList", element: <WishList />, private: true },
  
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/blogs", element: <Blogs /> },
  { path: "/others", element: <Others /> },
  { path: "/freeposts", element: <FreePost /> },
  { path: "/hotoffers", element: <HotOffers /> },
  { path: "/sellsection", element: <SellSection />, private: true }, 
  { path: "/car", element: <Car />, private: true },
  { path: "/bike", element: <Bike />, private: true },
  { path: "/vehicles", element: <Vehicles /> },
  { path: "/multicarousel", element: <MultiCarousel /> },
  { path: "/carousel", element: <Carousel /> },
  { path: "/popover", element: <Popover /> },
  { path: "/myads", element: <MyAds /> },
  { path: "/electronicsOffer", element: <Electronics /> },
  { path: "/bikes", element: <Bikes /> },
  { path: "/houseandapartments", element: <Houseapartments /> },
  { path: "/plots", element: <Plots /> },
  { path: "/othersell", element: <OtherSell /> },
  { path: "/laptops", element: <Laptops /> },
  { path: "/furnitures", element: <Furnitures /> },
  { path: "/electronicsproduct", element: <ElectronicsCate /> },
  { path: "/cars", element: <Cars /> },
  { path: "/mobiles", element: <Mobiles /> },
  { path: "/sellmobiles", element: <SellMobiles /> , private: true},
  { path: "/selllaptops", element: <SellLaptops /> , private: true},
  { path: "/sellplots", element: <SellPlots /> , private: true},
  { path: "/sellother", element: <SellOthers />, private: true },
  { path: "/sellhouseapartments", element: <Houseapartments /> , private: true},
  { path: "/sellfruniture", element: <Furniture/>, private: true },
  { path: "/forrent", element: <ForRent/>, private: true },
  { path: "/forsale", element: <ForSale />, private: true },
  { path: "/sellelectronics", element: <SellElectronics />, private: true },
  { path: "/googlesignup", element: <GoogleSignup /> },
  { path: "/multiproduct", element: <MultiCarouselPage /> },
  { path: "/plotssummary", element: <PlotsSummary /> },
  { path: "/searchbar", element: <SearchBar /> },
  { path: "/trendingarticles", element: <TrendingArticles /> },
  { path: "/trendingarticleone", element: <TrendingArticleOne /> },
  { path: "/trendingarticletwo", element: <TrendingArticleTwo /> },
  { path: "/trendingarticlethree", element: <TrendingArticleThree /> },
  { path: "/quikarticle", element: <QuikArticle /> },
  { path: "/quikarticle2", element: <QuikArticle2 /> },
  { path: "/quikarticle3", element: <QuikArticle3 /> },
  { path: "/quikarticle4", element: <QuikArticle4 /> },
  { path: "/quikarticle5", element: <QuikArticle5 /> },


  { path: "/myadstable", element: <MyAdsTable />, private: true },
  { path: "/help", element: <Help/> },
  { path: "/privacy", element: <Privacy/> },
  { path: "/tnc", element: <Tnc/> },
  { path: "/faq", element: <Faq/> },
  { path: "/Classified", element: <Classified/> },
  { path: "/mobilecategory", element: <MobileCategory/> },
  { path: "/searchresult", element: <Search/> },
  { path: "/editprofile", element: <EditProfile/> , private: true},
  { path: "/alloffers", element: <AllOffers/> },
  { path: "/bikearticle", element: <BikeArticle /> },
  { path: "/mobilearticle", element: <MobileArticle /> },
  { path: "/furniturearticle", element: <FurnitureArticle /> },
  { path: "/plotarticle", element: <PlotArticle /> },
  { path: "/laptoparticle", element: <LaptopArticle /> },
];

export default routes;
