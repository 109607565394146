import React, { useState, useEffect } from "react";
import "./WishList.css";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api/ApiService";
import { getUserId } from "../../api/Auth";
import axios from "axios";
import { getAuthToken } from "../../api/Auth";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WishList = () => {
  const baseurl = API_BASE_URL;
  const userId = getUserId();
  const autheader = getAuthToken();
  const Navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState([]);

  const openWhatsAppChat = (phoneNumber) => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hello,%20I'm%20interested%20in%20your%20product.`;
    window.open(whatsappLink, "_blank");
  };


  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/api/wishlistProduct/${userId}`,
          {
            headers: {
              Authorization: ` Bearer ${autheader}`,
            },
          }
        );

        setWishlistData(response.data);
      } catch (error) {
        toast.error("Error fetching wishlist data:", error);
      }
    };

    fetchWishlistData();
  }, []);

  const HomeHandler = () => {
    Navigate("/");
  };

  return (
    <div className="cart-wrap mb-5">
      <div className="container pb-5">
        <div className="row">
          <div className="table-main-heading mb-10">My wishlist</div>
        </div>
        <div className="row border">
          <div className="col-md-12 wishlist-col">
            {/* <div className='row py-3'>
                            <div className='col-5'><span className='wishlist-heading'>Products</span></div>
                            <div className='col-1'><span className='wishlist-heading'>Price</span></div>
                            <div className='col-3'><span className='wishlist-heading'>Stock Status</span></div>
                            <div className='col-3'><span className='wishlist-heading'>Actions</span></div>
                        </div> */}
            <ul className="d-flex justify-content-between ps-0">
              <li className="wishlist-heading">Products</li>
              <li className="wishlist-heading">Price</li>
              <li className="wishlist-heading">Stock Status</li>
              <li className="wishlist-heading">Actions</li>
            </ul>
            {wishlistData.map((item) => (
              <ul
                key={item.productId}
                className=" py-3 border d-flex justify-content-between ps-2"
              >
                <li className="w-25 text-center d-flex align-items-center justify-content-center">
                  <div className="d-md-flex w-100">
                    <div className="img-product ">
                      <img
                        src={item?.images[0]}
                        alt=""
                        className="mCS_img_loaded w-100 "
                       style={{height:"100px"}}
                      />
                    </div>
                    <div className="product-details">{item?.adTitle}</div>
                  </div>
                </li>
                <li className="w-25 text-center d-flex align-items-center justify-content-center">
                  <span className="price">{`${item.setPrice}`}</span>
                </li>
                <li className="w-25 text-center d-flex align-items-center justify-content-center">
                  <span className="available-box">{item?.status}</span>
                </li>
                <li className="w-25 text-center d-flex align-items-center justify-content-center">
                  <button
                    className="cart-button"
                    onClick={() => openWhatsAppChat(item.phoneNumber)}
                  >
                    <WhatsAppIcon color="success"/>
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;