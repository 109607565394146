import React, { useEffect, useState, useRef } from 'react'
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { useSearch } from '../../Context/SearchContext'
import './SearchBar.css'
import SearchIcon from '@mui/icons-material/Search';

const Searchbar = () => {

  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const { isShowSearchPage, setIsShowSearchPage, setSearchQuery, searchQuery } = useSearch()

  // const { searchItem, setSearchItem, setSearchQuery, searchQuery } = useSearch()

  // const searchDivRef = useRef(null)

  useEffect(() => {
  //  document.addEventListener("click", handleClickedOutSideSearchDiv, true)
  }, [searchQuery])

  // const handleClickedOutSideSearchDiv = (event) => {
  //   if (!searchDivRef.current.contains(event.target)) {
  //     setSearchQuery("")
  //     setSearchItem("")
  //     setQuery("")
  //     setSuggestions([])
  //   }
  // }

  // Function to fetch suggestions from the backend
  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.get(`https://quiksale.in:8087/api/search?query=${query}`);
      console.log(response);
      setSuggestions(response.data);
      // console.log("===== suggestion response", response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  // Function to handle input change
  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    // console.log(inputValue);

    // SearchContext Context Value 
    // setSearchQuery(inputValue)
    // setSearchItem(inputValue)
    setQuery(inputValue);
    // if (searchQuery.trim() === "" || searchQuery === null || searchQuery.trim().length <= 0) {
    //   setSuggestions([]);
  if(inputValue){ 
    await fetchSuggestions(inputValue); 
  } else {
    setSuggestions([]);
    }
  };

  // Function to handle suggestion click
  // const handleSuggestionClick = (suggestion) => {
  //   setSearchQuery(suggestion.brand)
  //   setQuery(suggestion.brand);
  //   setSuggestions([]);
  // };
  const handleSuggestionClick = (suggestion) => {
    if (!suggestion.brand) {
      setSearchQuery(suggestion.type)
      setQuery(suggestion.type);
    } else {
      setSearchQuery(suggestion.brand)
      setQuery(suggestion.brand);
    }
    setIsShowSearchPage(false)
    setSuggestions([]);
  };

  const searchIconClicked = async() => {
    if (searchQuery.trim() === "" || searchQuery === null || searchQuery.trim().length <= 0) {
      alert('Field is mandatory')
    } else {
    setSearchQuery(searchQuery)
    setQuery(searchQuery);
    setSuggestions([]);
    }
    
  }
  const [key, setKey] = useState("");

  const handleKeyDown = (event) => {
      setKey(event.key);
  };

  return (
    <div className='search-bar-container' >
      {/* <h3>Search Products</h3> */}
      <div className="input-wrapper">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search Brands and more"
        />
        <SearchIcon onClick = {searchIconClicked} className='searchIcon' fontSize = "small"/>
      </div>
      <div className="results-list">
      {suggestions.map((suggestion, index) => {
        return (
          <div
          key={index}
          className="search-result"
          onKeyDown={handleKeyDown}
          tabIndex={0}
          onClick={() => handleSuggestionClick(suggestion)}
        >
          <b style={{fontSize: "13px"}}>{suggestion.brand  ? suggestion.brand : suggestion.adTitle}</b>
          <p style={{fontSize: "8px"}}>in {suggestion.category}S</p> 
          
        </div>
        )
      })}
    </div>
      {/* <ul>
        {suggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion.brand}
          </li>
        ))}
      </ul> */}
      </div>
  );
}

export default Searchbar;
