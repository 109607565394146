import React, { useState, useRef } from "react";
import { styled } from "styled-components";
import photo from "../../../src/Images/photo.png";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

function ProfileImage({ handleImageSelect, img }) {
  const [imageSrc, setImageSrc] = useState(img || photo);
  const fileInputRef = useRef(null);

  const imageHandler = (e) => {
    if (e.target.files[0]) {
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setImageSrc(imageUrl);
      handleImageSelect(e.target.files[0], e.target.value); 
    }
  };

  const handleImageClick = (e) => {
    fileInputRef.current.click();
  };

  return (
    <MainDiv>
      <ImageDiv>
        <StyledImage src={imageSrc} alt="Profile" />
        <SubDiv>
          <StyledIcon fontSize="15px" onClick={handleImageClick} />
        </SubDiv>
        <Upload
          type="file"
          onChange={(e) => {
            imageHandler(e);
          }}
          accept="image/png, image/jpeg, image/webp"
          ref={fileInputRef}
        />
      </ImageDiv>
    </MainDiv>
  );
}

export default ProfileImage;

const MainDiv = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  scale: 1;
`;
const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
`;
const StyledImage = styled.img`
  height: 100px;
  width: 100px;
  cursor: pointer;
`;
const SubDiv = styled.div`
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(10px, -40px);
`;
const Upload = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
`;
const StyledIcon = styled(CameraAltOutlinedIcon)`
  position: absolute;
  padding: 3px;
  color: #a0a2a5;
  border-radius: 100%;
  background-color: white;
  transform: translate(18px, -20px);
  cursor: pointer;
`;
