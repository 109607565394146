import React, { useState, useRef } from "react";
import { styled } from "styled-components";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CloseIcon from '@mui/icons-material/Close';

function ImageUpload({ handleImageSelect, img, onDelete ,showDelete}) {
  const fileInputRef = useRef(null);

  const imageHandler = (e) => {
    if (e.target.files[0]) {
      const imageUrl = [];
      const files = [...e.target.files]
      files.forEach(e=>{
        imageUrl.push(URL.createObjectURL(e));
      })
      handleImageSelect(e.target.files, e.target.value);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <MainDiv>
      <ImageDiv>
        <img src={img} alt="fs" />
        
        <SubDiv>
          <StyledIcon fontSize="15px" onClick={handleImageClick} style={{visibility:"hidden"}} />          
        </SubDiv>        
        <Upload
          type="file"
          multiple 
          onChange={(e) => {
            imageHandler(e);
          }}
          accept="image/png, image/jpeg, image/webp"
          ref={fileInputRef}
        />
        { showDelete &&
            <DeleteButton onClick={onDelete}><CloseIcon/></DeleteButton> 
        }
      </ImageDiv>
    </MainDiv>
  );
}

export default ImageUpload;

const MainDiv = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: center;
  scale: 1;
`;
const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 100%;
  position: relative; 
`;
const SubDiv = styled.div`
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(10px, -40px);
`;
const Upload = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
`;
const StyledIcon = styled(CameraAltOutlinedIcon)`
  position: absolute;
  padding: 3px;
  color: #a0a2a5;
  border-radius: 100%;
  background-color: white;
  transform: translate(18px, -20px);
  cursor: pointer;
`;
const DeleteButton = styled.button`
  position: absolute;    
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  right: -1px;
  top: -2px;
`;

