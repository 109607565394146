import React from "react";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import frame from "../../Images/Frame (1).png";
import "./ReviewSection.css";
const ReviewSection = () => {

  let items = document.querySelectorAll('.carousel .carousel-item')
		items.forEach((el) => {
			const minPerSlide = 4
			let next = el.nextElementSibling
			for (var i=1; i<minPerSlide; i++) {
				if (!next) {
            // wrap carousel by using first child
            next = items[0]
        }
        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
    }
})
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="text-white py-4 my-lg-5 review-section " style={{background:"#413D45" }} >
        {/* <h3 className="review-heading">
          Trusted by 80 thousands + Happy Users and Major Brands since 2015
        </h3> */}
        <div className="container">
        <Carousel
          responsive={responsive}
          showDots={true}
          infinite={true}
          className="pb-3 "
          
        >
         
          <div
            className="border card  my-3 mx-2  d-inline-block text-center"
            style={{ width: "" }}
          >
            <div className="card-body text-center">
              <img src={frame} alt="img" className="mb-3" />
              <p className="review_text  ">
              "Quiksale has made reselling a breeze for me – easy listings, QuiksAle, and a supportive community"
              </p>
              {/* <p className="d-flex align-items-center">
                <img src={elipse} alt="img" />
                <p className="p-2 m-0 about">
                  <span>Peter, Belgium</span>
                  <br />
                  <span>on what he learned when sitting with himself</span>
                </p>
              </p> */}
            </div>
          </div>
          <div
            className="border card  my-3 mx-2  d-inline-block text-center"
            style={{ width: "" }}
          >
            <div className="card-body text-center">
              <img src={frame} alt="img" className="mb-3" />
              <p className="review_text ">
              "Effortless transactions. Quiksale has given my small business a big boost!"
              </p>
              {/* <p className="d-flex align-items-center">
                <img src={elipse} alt="img" />
                <p className="p-2 m-0 about">
                  <span>Peter, Belgium</span>
                  <br />
                  <span>on what he learned when sitting with himself</span>
                </p>
              </p> */}
            </div>
          </div>
          <div
            className="border card  my-3 mx-2  d-inline-block text-center"
            style={{ width: "" }}
          >
            <div className="card-body">
              <img src={frame} alt="img" className="mb-3" />
              <p className="review_text  ">
              "Quiksale has made reselling a breeze for me – easy listings, QuiksAle, and a supportive community"
              </p>
              {/* <p className="d-flex align-items-center">
                <img src={elipse} alt="img" />
                <p className="p-2 m-0 about">
                  <span>Peter, Belgium</span>
                  <br />
                  <span>on what he learned when sitting with himself</span>
                </p>
              </p> */}
            </div>
          </div>
          <div
            className="border card  my-3 mx-2  d-inline-block text-center"
            style={{ width: "" }}
          >
            <div className="card-body">
              <img src={frame} alt="img" className="mb-3" />
              <p className="review_text  ">
              "Selling on Quiksale is a game changer! Love the analytics tools responsive customer support."
              </p>
              {/* <p className="d-flex align-items-center">
                <img src={elipse} alt="img" />
                <p className="p-2 m-0 about">
                  <span>Peter, Belgium</span>
                  <br />
                  <span>on what he learned when sitting with himself</span>
                </p>
              </p> */}
            </div>
          </div>
          <div
            className="border card  my-3 mx-2  d-inline-block text-center"
            style={{ width: "" }}
          >
            <div className="card-body">
              <img src={frame} alt="img" className="mb-3" />
              <p className="review_text  ">
              "Quiksale is the secret sauce to my successful reselling journey. Simple, effective, and results-driven."
              </p>
              {/* <p className="d-flex align-items-center">
                <img src={elipse} alt="img" />
                <p className="p-2 m-0 about">
                  <span>Peter, Belgium</span>
                  <br />
                  <span>on what he learned when sitting with himself</span>
                </p>
              </p> */}
            </div>
          </div>
        </Carousel>
        </div>
      </div>
    
    </>
  );
};

export default ReviewSection;