import React, { useState, useEffect } from "react";
import "./Products.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
// import Card from "./Card";
import Pagination from "@mui/material/Pagination";
import Sidebar from "./SideBar/Sidebar";
// import SearchIcon from '@mui/icons-material/Search';
import "./Card.css";
import icon from "./Images/flat-color-icons_like.png";
import Car from "./Images/Rectangle 15.png";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Card from "./CardOne";
import axios from "axios";

const options = ["Option 1", "Option 2"];
const OtherSell = () => {
  const Navigate = useNavigate();
  const ProductSummaryHandler = () => {
    Navigate("/productsummary");
  };


  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://192.168.1.5:8081/product/category?category=BIKE");
        if (response.data.products) {
          setProducts(response.data.products);
          console.log(response.data.products); 
        } else {
          console.error("No products found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


  
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [priceRange, setPriceRange] = useState([100, 1000000]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const categories = [
    "Electronic devices",
    "Computer Laptop",
    "Computer Accessories",
    "SmartPhone",
    "HeadPhone",
    "Gaming Console",
    "Camera & Photo",
    "Tv & Home Appliances",
    "Watches & Accessories",
    "GPS & Navigation",
    "Warable Technology",
  ];
  const checkboxData = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Microsoft', value: 'Microsoft' },
    { label: 'Dell', value: 'Dell' },
    { label: 'Symphony', value: 'Symphony' },
    { label: 'Sony', value: 'Sony' },
    { label: 'LG', value: 'LG' },
    { label: 'One Plus', value: 'One Plus' },
    { label: 'Google', value: 'Google' },
    { label: 'Samsung', value: 'Samsung' },
    { label: 'HP', value: 'HP' },
    { label: 'Xiaomi', value: 'Xiaomi' },
    { label: 'Panasonic', value: 'Panasonic' },
    { label: 'Intel', value: 'Intel' },
  ];
  const tagData = [
    'Game',
    'iPhone',
    'TV',
    'Asus Laptop',
    'Macbook',
    'SSD',
    'Graphic Card',
    'Power bank',
    'Smart TV',
    'Speaker',
    'Tablet',
    'Microwave',
    'Samsung',
  ];

  const handleCategoryChange = (event) => {
    const categoryValue = event.target.value;
    setSelectedCategory(categoryValue);
    filterProducts();
    console.log("Selected Category:", categoryValue);
  };

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
    filterProducts();
    console.log("Selected Price:", newValue);
  };

 
  const filterProducts = () => {
    const filtered = products.filter((product) => {
      const isCategoryMatch =
        selectedCategory === null || product.category === selectedCategory;

      const isPriceInRange =
        product.price >= priceRange[0] && product.price <= priceRange[1];

      return isCategoryMatch && isPriceInRange;
    });

    setFilteredProducts(filtered);
    console.log("Filtered Products:", filtered);
  };

  return (
    <>
    <div className="product-page-container d-flex justify-content-center">
      <div className="product-page-main" style={{"width":"95%"}}>
        <div className=" row border product-page-row">
          <Sidebar
            categories={categories}
              selectedCategory={selectedCategory}
              handleCategoryChange={handleCategoryChange}
              priceRange={priceRange}
              // defaultPriceRange={defaultPriceRange}
              handlePriceRangeChange={handlePriceRangeChange}
              filterProducts={filterProducts}
              checkboxData= {checkboxData}
              tagData={tagData}

               />
          <div className="col-md-9 border product-rightcol">
            <div className=" product-firstrow py-2" >
              <div className="Searchfield row">
                <div className="search  col-md-8">
                  <Stack spacing={2} sx={{ width: 300 }}>
                    {/* <SearchIcon /> */}
                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      // options={top100Films.map((option) => option.title)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search input"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Stack>
                </div>
                <div className="dropdown col-md-4">
                  <div className="sort">
                    <span>Sort By :</span>
                  </div>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={options}
                    sx={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className=" product-secondrow my-2">
               <nav className="breadcrumb-nav py-3">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">Active Filters:</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Electronics &times;
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    5 star Rating &times;
                  </li>
                </ol>
              </nav> 
            </div>
            <div className=" product-thirdrow ">
               {/* {Array.isArray(products) ? (
              products.map((product) => (
                <Card key={product.category.id} product={product.category} />
              ))
            ) : (
              <p>No Products found</p>
            )}  */}
            
              <Card products={filteredProducts}/>
              {/* start  */}
              {/* <Card/> */}
              {/* end */}
            </div>   
            <div className="pagination product-fourthrow d-flex justify-content-center">
              <Stack spacing={2}>
                <Pagination
                  // onPageChange={handleChangePage}
                  // rowsPerPage={rowsPerPage}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                  count={10}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
            </div>        
          </div>          
        </div>
      </div>
      </div>
    </>
  );
};
export default OtherSell;