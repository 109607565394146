import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,

} from "reactstrap";
import ImageUpload from "../Profile/ImageUpload";
import './style.css';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import IconButton from '@material-ui/core/IconButton';
import axios from "axios";
import VideoUpload from "./ImageUpload";
import photo from "../../Images/photo.png";
import Logo from "../Products/Images/Quick Sale Logo 1.png";
import advertiseImg from"../Products/Images/image 8.png";
import { API_BASE_URL } from "../../api/ApiService";
import { getAuthToken,getUserId } from "../../api/Auth";
import { useNavigate } from "react-router-dom";
import { StateData } from "../../state";
import Select from 'react-select';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { yearData } from "../../brand";
import { CarBrands } from "../../brand";
import userProfile from "../../Images/user (1).png";
import CheckboxLabels from "../Home/CheckBoxLabels";
import CircularProgress from '@mui/material/CircularProgress';



const Car = () => {
  // filter dropdowns for products
// for select button 
  const [highlightedButton, setHighlightedButton] = useState(null);
  const [highlightedButton1, setHighlightedButton1] = useState(null);

  const baseurl = API_BASE_URL;

  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setHighlightedButton(buttonName);
    setInputData({ ...inputData, fuel: buttonName }); 
  }

  const handleButtonClick1 = (buttonNames) => {
    setHighlightedButton1(buttonNames);
    setInputData({ ...inputData, transmission: buttonNames }); 
  }

  const [inputData, setInputData] = useState({
    brand: "",
    year:"",
    fuel: "",
    engineCapacity:"",
    transmission: "",
    kmDriven: "",
    owners: "",    
    adTitle: "",
    description: "",
    setPrice: "",
    bodyType:"",
    color:"",
    state: "",
    city: "",
    pin:"",
    phoneNumber: "",
  });
  // const files = [...e.target.files]
  // files.forEach(e=>{
  //   imageUrl.push(URL.createObjectURL(e));
  // })
  const [errors, setErrors] = useState({});
  const [fileObj, setFileObj] = useState({ files: [], path: "" });
  // for multiple image upload
  const handleImageSelect = (file, path) => {
    setFileObj({ files: [...fileObj.files,...file], path });
    // console.log(inputData);
  };


  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectYear, setSelectYear] = useState(null);
  const [selectBrand, setSelectBrand] = useState(null);

  const [isLoading, setIsLoading] = useState(false)

  
  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedState(selectedOption.value);
      setInputData({ ...inputData, state: selectedOption.value, city: "" });
    } else {
      setSelectedState("");
      setInputData({ ...inputData, state: "", city: "" });
    }
  };

  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCity(selectedOption.value);
      setInputData({ ...inputData, city: selectedOption.value });
    } else {
      setSelectedCity("");
      setInputData({ ...inputData, city: "" });
    }
  };

  const handleYearChange = (selectedOption) => {
    if (selectedOption) {
      setSelectYear(selectedOption); // Set selected year
      setInputData({ ...inputData, year: selectedOption.value }); // Set year value in inputData
    } else {
      setSelectYear(null);
      setInputData({ ...inputData, year: "" });
    }
  };

  const handleDelete = (index) => {
    const updatedFiles = [...fileObj.files];
    updatedFiles.splice(index, 1);
    setFileObj({ ...fileObj, files: updatedFiles });
  };

  const handleBrandChange = (selectedOption) => {
    if (selectedOption) {
      setSelectBrand(selectedOption); // Set selected year
      setInputData({ ...inputData, brand: selectedOption.value }); // Set year value in inputData
    } else {
      setSelectBrand(null);
      setInputData({ ...inputData, brand: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 'none',
      paddingLeft: 6,
      paddingRight:6
     

    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: 1,
      paddingRight: 1
    })
  };

  
  const handleData = (e) => {
    const { name, value } = e.target;
  
    setInputData({ ...inputData, [name]: value });
  
    const newErrors = { ...errors };
    if (!value.trim()) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };
  
  const handleNumericInput = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };
  const autheader= getAuthToken();

  const userId = getUserId();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${baseurl}/api/auth/username/${userId}`, {
                headers: {
                    Authorization: `Bearer ${autheader}`,
                },
            });

            setUserName(response.data);
        } catch (error) {
        }
    };

    fetchUserData();
}, []);
  

  

const handleSubmit = (e) => {
  e.preventDefault();
  setIsLoading(true)
  const newErrors = {};

  for (const key in inputData) {
    if (!inputData[key]) {
      newErrors[key] = `${key} is required`;
    }
  }

  if (Object.keys(newErrors).length === 0) {
    const formData = new FormData();
    formData.append('category', "CAR");
    formData.append('brand', inputData.brand);
    formData.append('year', inputData.year);
    formData.append('fuel', inputData.fuel);
    formData.append('engineCapacity', inputData.engineCapacity);
    formData.append('transmission', inputData.transmission);
    formData.append('kmDriven', inputData.kmDriven);
    formData.append('owners', inputData.owners);
    formData.append('adTitle', inputData.adTitle);
    formData.append('description', inputData.description);
    formData.append('setPrice', inputData.setPrice);
    formData.append('bodyType', inputData.bodyType);
    formData.append('color', inputData.color);
    formData.append('state', inputData.state);
    formData.append('city', inputData.city);
    formData.append('pin', inputData.pin);
    formData.append('phoneNumber', inputData.phoneNumber);
    formData.append('review_by', userName);
    fileObj.files.forEach((file, index) => {
      formData.append(`files`, file);
    });
   


    // if (Object.keys(newErrors).length === 0) {
    axios.post(
      
      `${baseurl}/api/product/adpost/${userId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${autheader}`,
        },
      }
    )
    .then((response) => {
      console.log(response);
      
      if (response.status === 201) {
        toast.success("Product has been posted!");
        navigate("/cars");
      } else if (response.status === 400) {
        handleBadRequestError(response);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    })
    .catch((error) => {
      console.error(error);
      if (error.response && error.response.status === 400) {
        handleBadRequestError(error.response);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    });
  } else {
    setErrors(newErrors);
    console.log("errors", errors);
    toast.error("Please fill in all required fields before submitting.");
  }
};

const handleBadRequestError = (response) => {
  if (response.data && response.data.status === "BAD_REQUEST" && response.data.message === "You have exceed the limit for posting ") {
    toast.error("You have exceeded the limit for posting");
  } else {
    toast.error("Bad Request. Please check your input and try again.");
  }
};


  return (
    <>
      <div className="py-5 " style={{ background: "#F5F5F5" }}>
        <h2 className="text-center mb-5 postPage-heading">POST YOUR AD</h2>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-lg-7 " style={{ background: "#ffff" }}>
              <h6 className="ps-2 pt-3">
                <span className="sidebar-heading" style={{ color: "#940D19" }}>
                  SELECTED CATEGORY
                </span>
              </h6>
              <div>{/* <Breadcrumbs /> */}</div>
              <h6 className="pt-3 ps-2 sub-heading">
                <span>INCLUDE DETAILS</span>
              </h6>
              <div>
                <span>You are posting this property for</span>{" "}
                <div className="free-post  btn-rounded">Free !</div>
              </div>            

              <Form className="post-form">
               <FormGroup className="city-container" style={{ width: "65%"  }}>
                  <label>Brand *</label>               
                  <Select
                      className="mb-3" 
                     value={selectBrand}
                    onChange={handleBrandChange}
                    options={CarBrands}
                    isSearchable={true}
                    styles={customStyles}
                    isClearable
                    isCreatable
                  />
                {errors.brand && <div style={{ color: "red" }}>{errors.brand}</div>}

                </FormGroup>                  
                <FormGroup className="city-container" style={{ width: "65%" }}>
                  <label>Year *</label>
                  <Select
                    className="mb-3"
                    value={selectYear}
                    onChange={handleYearChange}
                    options={yearData}
                    isSearchable={true}
                    styles={customStyles}
                    isClearable
                    isCreatable
                  />
                  {errors.year && <div style={{ color: "red" }}>{errors.year}</div>}
                </FormGroup>
                <FormGroup style={{}}>
                  <label>Fuel </label>
                  <div
                    className="mb-3 type-options "
                    type="select"
                    name="fuel"
                    value={inputData.fuel}
                    onChange={handleData}
                  >
                    <button
                      type="button"
                      onClick={() => handleButtonClick("CngHybrids")}
                      className={
                        highlightedButton === "CngHybrids"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      CNG & Hybrids
                    </button>
                    <button
                      type="button"
                      onClick={() => handleButtonClick("diesel")}
                      className={
                        highlightedButton === "diesel"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      Diesel
                    </button>
                    <button
                      type="button"
                      onClick={() => handleButtonClick("Electric")}
                      className={
                        highlightedButton === "Electric"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      Electric
                    </button>
                    <button
                      type="button"
                      onClick={() => handleButtonClick("Lpg")}
                      className={
                        highlightedButton === "Lpg"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      LPG
                    </button>
                    <button
                      type="button"
                      onClick={() => handleButtonClick("Petrol")}
                      className={
                        highlightedButton === "Petrol"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      PETROL
                    </button>
                  </div>
                  {errors.fuel && (
                    <div style={{ color: "red" }}>{errors.fuel}</div>
                  )}
                </FormGroup>
                <FormGroup style={{}}>
                  <label>Body Type </label>
                  <Input
                    bsSize="lg"
                    className="mb-3 border border"
                    type="select"
                    name="bodyType"
                    value={inputData.bodyType}
                    onChange={handleData}
                  >
                    <option>Body Type</option>
                    <option>Xuv</option>
                    <option>Suv</option>
                    <option>Sedan</option>
                    <option>Hatchback </option>
                    <option>Compact SUV </option>
                    <option>Compact Sedan </option>
                    <option>MUV </option>
                    <option>Convertible </option>
                    <option>Coupe </option>
                    <option>Minivan/Van </option>
                    <option>Station Wagon </option>
                    <option>Truck </option>
                  </Input>
                  {errors.bodyType && (
                    <div style={{ color: "red" }}>{errors.bodyType}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="engine capacity">Engine Capacity</Label>
                  <Input
                    className="border border"
                    id="engineCapacity"
                    name="engineCapacity"
                    placeholder=""
                    type="text"
                    value={inputData.engineCapacity}
                    onChange={handleData}
                  />
                 
                  {errors.engineCapacity && <div style={{ color: "red" }}>{errors.engineCapacity}</div>}
                </FormGroup>

                <FormGroup>
                  <Label for="color">Color</Label>
                  <Input
                    className="border border"
                    id="color"
                    name="color"
                    placeholder=""
                    type="text"
                    value={inputData.color}
                    onChange={handleData}
                  />
                  {errors.color && (
                    <div style={{ color: "red" }}>{errors.color}</div>
                  )}
                </FormGroup>

                {/* <FormGroup>
                  <Label for="owners">Varient</Label>
                  <Input
                    className="border border"
                    id="varient"
                    name="varient"
                    placeholder=""
                    type="text"
                    value={inputData.varient}
                    onChange={handleData}
                  />
                  {errors.varient && <div style={{ color: "red" }}>{errors.varient}</div>}
                </FormGroup> */}

               
                <FormGroup style={{}}>
                  <label>Transmission </label>
                  <div
                    className="mb-3 type-options "
                    type="select"
                    name="transmission"
                    value={inputData.transmission}
                    onChange={handleData}
                  >
                    <button
                      type="button"
                      onClick={() => handleButtonClick1("Automatic")}
                      className={
                        highlightedButton1 === "Automatic"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      Automatic
                    </button>
                    <button
                      type="button"
                      onClick={() => handleButtonClick1("Manual")}
                      className={
                        highlightedButton1 === "Manual"
                          ? "highlighted btn btn-light border me-1 my-1"
                          : "btn btn-light border me-1 my-1"
                      }
                    >
                      Manual
                    </button>
                  </div>
                  {errors.transmission && (
                    <div style={{ color: "red" }}>{errors.transmission}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="owners">Owners</Label>
                  
                  <Input
                    bsSize="lg"
                    id="owners"
                    className="mb-3 border border"
                    type="select"
                    name="owners"
                    value={inputData.owners}
                    onChange={handleData}
                  >
                    <option value=""></option>
                    <option>1st</option>
                    <option>2nd</option>
                    <option>3rd</option>
                    <option>4+</option>
                    
                  </Input>
                  {errors.owners && <div style={{ color: "red" }}>{errors.owners}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="driven">KM driven *</Label>
                  <Input
                    className="border border"
                    id="kmDriven"
                    name="kmDriven"
                    placeholder=""
                    type="text"
                    onChange={handleData}
                    onKeyPress={handleNumericInput}
                    value={inputData.kmDriven}
                  />
                  {errors.kmDriven && (
                    <div style={{ color: "red" }}>{errors.kmDriven}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="adtitle">Ad title *</Label>
                  <Input
                    className="border "
                    id="adtitle"
                    name="adTitle"
                    placeholder=""
                    type="text"
                    value={inputData.adTitle}
                    onChange={handleData}
                  />
                  {errors.adTitle && (
                    <div style={{ color: "red" }}>{errors.adTitle}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label >Description *</Label>
                  <Input id="description" name="description" type="textarea" className="border border" value={inputData.description} onChange={handleData} />
                  {errors.description && (
                    <div style={{ color: "red" }}>{errors.description}</div>
                  )}
               </FormGroup>
                {/* <div className="">
                  <label htmlFor="description">Description</label>

                  <InputTextarea
                    autoResize
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    rows={5}
                    cols={30}
                  />
                </div> */}

                <FormGroup>
                  <Label for="exampleText">Set a Price </Label>
                  <Input
                    id="setPrice"
                    name="setPrice"
                    type="text"
                    className="border border"
                    value={inputData.setPrice}
                    onChange={handleData}
                    onKeyPress={handleNumericInput}
                  />
                  {errors.setPrice && <div style={{ color: "red" }}>{errors.setPrice}</div>}
                </FormGroup>
                <div className="container">
                  <div className="row py-2">
                    <h6>UPLOAD UP TO 10 PHOTOS</h6>
                  </div>                 
                  <div className="row py-2">
                      {
                      fileObj.files.map((file, index) => {
                        const url = URL.createObjectURL(file);
                        return (
                          <div className="col-4" key={index}>
                            <ImageUpload
                              handleImageSelect={handleImageSelect}
                              img={url}
                              showDelete={index == fileObj.files.length ? false : true}
                              onDelete={() => handleDelete(index)}
                            />
                          </div>
                        );
                      })
                    }
                    <div className="col-4 ">
                      <span

                        className="d-inline-block profile-image-link"
                      >

                        <ImageUpload handleImageSelect={handleImageSelect} img={photo} />
                      </span>

                    </div>
                    {/* <ImageUpload  handleImageSelect={handleImageSelect}/> */}

                  </div>
                  <div className="row py-3 my-4 border border">
                    <h5>CONFIRM YOUR LOCATION</h5>

                    <FormGroup style={{ width: "100%" }}>
                      <label>Pin Code</label>
                      <Input
                        id="pin"
                        className="mb-3 border border"
                        type="text"
                        name="pin"
                        value={inputData.pin}
                        onChange={handleData}
                        onKeyPress={handleNumericInput}
                      ></Input>
                   {errors.pin && <div style={{ color: "red" }}>{errors.pin}</div>}

                    </FormGroup>
                    
                    <FormGroup className="state-container" style={{ width: "66%" }}>
                      <label>State *</label>
                      <Select
                        className="mb-3"
                        value={selectedState ? { label: selectedState, value: selectedState } : null}
                        onChange={handleStateChange}
                        options={StateData.map((state) => ({ value: state.name, label: state.name }))}
                        isSearchable={true}
                        styles={customStyles}
                        isClearable
                        isCreatable
                      />
                      {errors.state && <div style={{ color: "red" }}>{errors.state}</div>}
                    </FormGroup>

                    <FormGroup className="city-container" style={{ width: "66%" }}>
                      <label>City *</label>
                      <Select
                        className="mb-3"
                        value={selectedCity ? { label: selectedCity, value: selectedCity } : null}
                        onChange={handleCityChange}
                        options={selectedState
                          ? StateData.find((state) => state.name === selectedState)?.cities.map((city) => ({ value: city, label: city }))
                          : []
                        }
                        isSearchable={true}
                        styles={customStyles}
                        isClearable
                        isCreatable
                      />
                      {errors.city && <div style={{ color: "red" }}>{errors.city}</div>}
                    </FormGroup>

                  </div>
                  <div className="row py-2">
                    <div className="col-8">
                      <strong>REVIEW YOUR DETAILS</strong>

                      <div className="row p">
                        <div className="col-4">
                          <img src={userProfile} alt="profile" height="60px" width="60px"/>
                        </div>
                        <div className="col-8">
                          <FormGroup>
                            <Label>Name</Label>
                            <p style={{padding:"10px"}}>{userName}</p>
                          </FormGroup>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <FormGroup className="py-3">
                  <Label >Mobile Phone Number *</Label>
                  <Input
                    className="border border"
                    id="phoneNumber"
                    value={inputData.phoneNumber}
                    name="phoneNumber"
                    placeholder="123-45-678"
                    type="text"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    onChange={handleData}
                    onKeyPress={handleNumericInput}
                  />
                {errors.phoneNumber && <div style={{ color: "red" }}>{errors.phoneNumber}</div>}

                </FormGroup>
                <CheckboxLabels />

                <FormGroup className="py-4">
                  {/* <Button
                    color="danger"
                    onClick={handleSubmit}
                    className="btn-lg btn-block"
                  >
                    Post Now
                  </Button> */}
                  <Button color="danger" disabled = {isLoading} onClick={handleSubmit}> 
                  {isLoading ? <CircularProgress color="inherit"/> : 'Post Now'}
                  </Button>
                </FormGroup>
              </Form>
            </div>
            <div className="col-lg-5">
              <div className="advertise py-4">
                <div className="advertise-img-container text-center">
                  <img src={Logo} alt="discription" />
                </div>
                <h6 className="text-center">
                  <img src={advertiseImg} alt="advertise-img"></img>
                </h6>
                <h6 className="text-center">Heavy on Features.</h6>
                <h6 className="text-center">Light on Price.</h6>
                <p className="text-center">
                  only for:{" "}
                  <button className="advertise-button p-2">
                    <b>&#8377;299 only </b>
                  </button>
                </p>
                <div className="text-center">
                  <button className="advertise-cart-button">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ShoppingCartIcon"
                    >
                      <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                    </svg>
                    Add to Cart
                  </button>
                </div>
                <div className="text-center my-2">
                  <button className="advertise-details-button">
                    View Details &rarr;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Car;