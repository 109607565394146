import React,{useState} from 'react';
import './Faq.css';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';

const Faq = () => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
  return (    
    <div className='container'> 
    <div className='row'>
        <div className='col-12'>
        <div class="faq-parent">
                 <div class="faq-child  first-faq-child row py-3">
                           <div class="faq-que faq-heading"><button>Frequently asked questions</button></div>
                            {/* <div class="faq-ans col-2">
                               <div className='d-flex justify-content-around'>
                                <span class="faq-btns">Sell</span>
                               <span class="faq-btns">Buy</span>
                               </div>
                                
                           </div> */}
                       </div>
        </div>
        </div>
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1" className='fs-3'>How do I list an item for sale on QuiksAle?</AccordionHeader>
        <AccordionBody accordionId="1">
          <strong>To list an item on QuiksAle, simply create an account, click on the "Sell" button, and fill out the required information about your item.  </strong>
          Include clear photos, a detailed description, and set a fair price. Once submitted, your listing will be visible to potential buyers.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2">How does the buying process work on QuiksAle?</AccordionHeader>
        <AccordionBody accordionId="2">
          <strong>When you find an item you'd like to purchase, click on "Call Now" or "WhatsApp chat" if available. </strong>
          You can also choose to contact QuiksAle Team (We are just one call away). Check out the detailed address and contact info on our platform.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="3">How to Find the Best Deals on Used Household Items at QuiksAle?</AccordionHeader>
        <AccordionBody accordionId="3">
          <strong> To find great deals, use specific search terms related to the item you need. </strong>
          Filter results by price, location, and condition to narrow down options. Keep an eye on new listings and act quickly when you find a desirable item
        </AccordionBody>
      </AccordionItem>
    </Accordion>
    </div>
  </div>
  )
}

export default Faq;