import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

 export const SearchProvider = ({ children }) => {
  const [isShowSearchPage, setIsShowSearchPage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
   console.log("=========== SearchProvider  searchQuery ", searchQuery)
   console.log("=========== SearchProvider  isShowSearchPage ", isShowSearchPage)

  return (
    <SearchContext.Provider value={{ isShowSearchPage, setIsShowSearchPage, searchQuery, setSearchQuery }}>
      {children}
    </SearchContext.Provider>
  );
};

