import React, { useState, useEffect } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import EyeImage from '../../Images/Eye.png';
import '../Email-verification/UserAuth.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../api/ApiService';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";


const ResetPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const otp = "";
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const Navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const HomeHandler = () => {
    Navigate("/");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  
  


const baseurl = API_BASE_URL;
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
        try {
      const response = await axios.post(`${baseurl}/api/auth/reset-password?token=${token}&newPassword=${password}&otp=${otp}`

        
      );

      toast.success('Password updated successfully');
    } catch (error) {
      // Handle errors from the API request
      console.error('Error updating password', error);
    }
  };

  return (
    <div>
      <section className="user-form-part">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
              <div className="user-form-card">
                <div className="user-form-title">
                  <h2> Reset Password</h2>
                
                </div>

                <form className="user-form" onSubmit={handleSubmit}>
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="input-container">
                    <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                      id="password"
                      aria-describedby="emailHelp"
                      placeholder="8+ characters"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <img src={EyeImage} alt=""
                    onClick={togglePasswordVisibility} />
                  </div>

                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password
                  </label>
                  <div className="input-container">
                    <input
                      type={showConfirmPassword ? "text" : "password"}                  
                      className="form-control"
                      id="confirmPassword"
                      aria-describedby="emailHelp"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <img src={EyeImage} alt=""
                    onClick={toggleConfirmPasswordVisibility} />
                  </div>

                  <div className="form-button">
                    <button type="submit">Verify me</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
