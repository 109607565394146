import React, { useState, useEffect } from 'react';
import './AllOffer.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../api/ApiService';
import { toast } from 'react-toastify';

const AllOffers = () => {
  const [offers, setOffers] = useState([]);
  const baseurl = API_BASE_URL

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/offer/getAllOffer`);
		console.log(response)
        setOffers(response.data);
      } catch (error) {
        toast.error('Error fetching offers:', error);
      }
    };

    fetchOffers();
  }, []); 

  return (
    <>
      <div className="container">
        <div className="row">
          {offers.map((offer, index) => (
            <div className="col-md-3" key={index}>
              <NavLink to={`/electronicOffer/${offer.id}`}>
                <div className={`offer offer-${offer.type}`}>
                  <div className="shape">
                    <div className="shape-text">
                      Top {offer.discount}%
                    </div>
                  </div>
                  <div className="offer-content">
                    <h3 className="lead">
                      <p className="fw-bold">{offer.category}</p>
                      <span className="fw-bold light">{offer.discount}% off on every product</span>
                      <p>{offer.description}</p>
                    </h3>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AllOffers;