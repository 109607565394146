import React from "react";

import MultiCarousel from "../../MultiCarousel/MultiCarousel";

import "./GrabDeals.css"

const GrabDeals = () => {
  // css for carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // end
  const productData = [
    {
      imgUrl: "../../Images/about-5.jpg",
    },
    {
      imgUrl: "../../Images/about-5.jpg",
    },
    {
      imgUrl: "../../Images/about-5.jpg",
    },
    {
      imgUrl: "../../Images/about-5.jpg",
    },
  ];

  return (
    <>
      <section className=" shop-part deal-section-two">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12">
              <div className="top-filter row">
                <div className="filter-show col-9">
                  <label className="filter-label">
                    <span className="filter-label-one ">
                      Grab best deal on
                    </span>
                    <span className="filter-label-two ms-2">Smartphones</span>
                  </label>
                </div>
                {/* <div className="filter-short col-3">
                  <label className="filter-view">View All</label>
                </div> */}
              </div>
            </div>
            <div className="container">
              <MultiCarousel />
              {/* </Carousel> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GrabDeals;
