import React from "react";
import "./Help.css";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Help = () => {
  return (
    <>
     <div>
            <Helmet>
                <title>Second hand electronics | Sell @ QuiksAle </title>
                <meta name="description" content="Learn how quciksale can fuel your business with all the capabilities of enterprise ecommerce—without the cost or complexity." />
                </Helmet>
      <div className="help-section py-3 my-4">
        <div className="container">
          <div className="row ">
            <div className="col-md-4">
              <div className="help-options">
                <NavLink to="/privacy">
                  <h4>Privacy Policy</h4>
                </NavLink>
              </div>
            </div>
            <div className="col-md-4">
              <div className="help-options">
                <NavLink to="/tnc">
                  <h4>Terms and Conditions</h4>
                </NavLink>
              </div>
            </div>
            <div className="col-md-4">
              <div className="help-options">
                <NavLink to="">
                  <h4>User Guide (under review)</h4>
                </NavLink>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="help-options">
                <NavLink to="/Faq">
                  <h4>Easy Faq's</h4>
                </NavLink>
              </div>
            </div> */}
          </div>
          <div className="row ">
          <div className="col-md-4">
              <div className="help-options">
                <NavLink to="">
                  <h4>Classified (under review)</h4>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Help;
