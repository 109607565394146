import React, { useEffect } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import carousleImage from "../../../Images/Banner-board-800x420 2.png";
import Person2Icon from "@mui/icons-material/Person2";
import carImg from "../../../Images/3.png";
import mobileImg from "../../../Images/5.png";
import laptopimg from "../../../Images/1 (1).png";
import bikeimg from "../../../Images/2 (1).png";
import furnitureimg from "../../../Images/4.png";
import desktop from "../../../Images/Rectangle 15 (19).png";
import pulsur220 from "../../../Images/computerasses.jpg";
import computer from "../../../Images/Rectangle 15 (17).png";
import { Carousel as _carousel } from "react-responsive-carousel";
import { useSearch } from '../../../Context/SearchContext'

import "./CategoryAdd.css";
import Dropdown from "react-bootstrap/Dropdown";

const CategoryAdd = () => {
  const { isShowSearchPage } = useSearch()

  const Navigate = useNavigate();
  const JoinNowHandler = () => {
    Navigate("/signup");
  };
  const LoginHandler = () => {
    Navigate("/signin");
  };
  
  let zindexCrousel = '1';

  useEffect(()=> {

    if(isShowSearchPage) {
      zindexCrousel = '-1'
    } else {
      zindexCrousel = '1'
      document.body.style.overflow = 'unset'
    }

  },[isShowSearchPage])

  return (
    <>
      <div className="section main-section navbar-two ">
        <div className="container ">
          <div className="row navbar-row ">
            <div className="col-sm-1 col-md-1 col-lg-2 p-lg-0 d-none d-md-block">
              <ul className="nav flex-column flex-column ms-0  d-md-none d-lg-block py-2">
                <li
                  className="navbar-item menubar-link ps-3 "
                  onClick={() => Navigate("/Car")}
                >
                  Cars
                </li>
                <li
                  className="navbar-item menubar-link ps-3"
                  onClick={() => Navigate("/Bike")}
                >
                  Bikes
                </li>
                <li
                  className="navbar-item menubar-link ps-3"
                  onClick={() => Navigate("/sellelectronics")}
                >
                  Electronics
                </li>
                <li
                  className="navbar-item menubar-link ps-3"
                  onClick={() => Navigate("/SellMobiles")}
                >
                  Mobiles
                </li>
                <li
                  className="navbar-item menubar-link ps-3 "
                  onClick={() => Navigate("/selllaptops")}
                >
                  Laptop
                </li>
                <li
                  className="navbar-item menubar-link ps-3 "
                  onClick={() => Navigate("/sellfruniture")}
                >
                  Furniture
                </li>
                <li className="navbar-item menubar-link ps-3 list-group-item dropdown">
                  {/* <li className=""> */}
                  <a
                    className="dropdown-toggle "
                    href="#"
                    role="button"
                    id="houseApartmentsDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    House and Apartments
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="houseApartmentsDropdown"
                  >
                    <li
                      className="dropdown-item"
                      onClick={() => Navigate("/ForSale")}
                      style={{ width: "94%" }}
                    >
                      For Sale
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => Navigate("/ForRent")}
                      style={{ width: "94%" }}
                    >
                      For Rent
                    </li>
                  </ul>
                  {/* </li> */}
                </li>
                <li
                  className="navbar-item menubar-link ps-3 "
                  onClick={() => Navigate("/sellplots")}
                >
                  Plots
                </li>
                <li className="navbar-item dropdown">
                  {/* <Dropdown className="outline-none more-dropdown w-100">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="fw-bold w-100 text-start "
                    >
                      <span className="">More</span>
                    </Dropdown.Toggle>
                    <NavLink to="/others">

                    <Dropdown.Menu>
                      <Dropdown.Item>Others</Dropdown.Item>
                    </Dropdown.Menu>
                    </NavLink>

                  </Dropdown> */}
                   <li
                  className="navbar-item menubar-link ps-3 "
                  onClick={() => Navigate("/sellother")}
                >
                  Others
                </li>

                </li>
              </ul>
            </div>
            <div className=" col-md-12 col-lg-8 main-carousel " style={{zIndex: isShowSearchPage ? -1 : 1}}>
              <_carousel showArrows="true" className="carousel" infiniteLoop autoPlay showThumbs={false}>
                <div className="p-2">
                  <NavLink to="/cars">
                    <img src={carImg} />
                  </NavLink>

                  {/* <p className="legend">Legend 1</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/Electronicsproduct">
                <img src={carousleImage} />
                  </NavLink>
                 
                  {/* <p className="legend">Legend 2</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/laptops">
                <img src={laptopimg} />
                  </NavLink>
                 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/furnitures">
                <img src={furnitureimg} />
                  </NavLink>                 
                  {/* <p className="legend">Legend 3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor voluptatem obcaecati, nemo a accusamus corrupti illum minima? Quisquam, omnis earum.</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/mobiles">
                <img src={mobileImg} />
                  </NavLink> 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/Bikes">
                <img src={bikeimg} />
                  </NavLink>   
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/mobiles">
                <img src={mobileImg} />
                  </NavLink> 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/Bikes">
                <img src={pulsur220} />
                  </NavLink> 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/Others">
                <img src={desktop} />
                  </NavLink> 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
                <div className="p-2">
                <NavLink to="/Others">
                <img src={computer} />
                  </NavLink> 
                  {/* <p className="legend">Legend 3</p> */}
                </div>
              </_carousel>
              <h3 className="punchline_text animate-charcter">
                {" "}
                Buy & Sell Anything INDIA'S LARGEST FREE CLASSIFIED MARKETPLACE
              </h3>
            </div>
            <div className=" col-12 col-lg-2 col-md-12 d-none d-md-block ">
              <div className="row">
                <div className="userdetail-container">
                  <div className="subUserDetail1st py-3">
                    <div className="userGreeting-container">
                      <div className="userProfile-img ">
                        <Person2Icon />
                      </div>
                      <div className="greeting-msg">
                        <abbr>Join QuiksAle and get early joining bonus !!</abbr>
                      </div>
                    </div>
                    <div className="d-flex d-lg-block">
                      <a
                        href="."
                        className="userJoinNow"
                        onClick={(e) => {
                          e.preventDefault();
                          JoinNowHandler();
                        }}
                      >
                        {/* <span className="py-1">Join now</span> */}
                      </a>
                      <a
                        href="."
                        className="userLogIn"
                        onClick={(e) => {
                          e.preventDefault();
                          LoginHandler();
                        }}
                      >
                        {/* <span className="py-1">Log in</span> */}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex d-lg-block justify-content-center">
                    <div className="pe-2 p-lg-0">
                      <div className="subUserDetail2nd">
                        <span className="py-3">
                          Refer QuiksAle and get extra perks for referral !
                        </span>
                      </div>
                    </div>
                    <div className="pe-2 p-lg-0">
                      <div className="subUserDetail3rd">
                        <span className="py-3">
                          More bonus and discounts for joining QuiksAle as a Seller !
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryAdd;