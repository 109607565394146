import React, { useEffect, useState } from 'react';
import "./Contact.css";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from "../../api/ApiService";
import axios from 'axios';
import { toast } from 'react-toastify';

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
const baseurl= API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
				`${baseurl}/api/auth/submitEnquiry?name=${formData.name}&email=${formData.email}&subject=${formData.subject}&message=${formData.message}`,
			);
      if (response.status === 200) {
				toast.success("Your Message Sent To QuiksAle");
      }
      
      else {
				toast.error("Error submiting Form!");
			}
    } catch (error) {
      toast.error('Error submitting form:', error);
    }
  };


  useEffect(() => {
    const showMapWithCurrentLocation = () => {
      // Get the iframe element
      const iframe = document.getElementById('gmap_canvas');

      // Use HTML Geolocation API to get the current location
      navigator.geolocation.getCurrentPosition(function (position) {
       // const latitude = position.coords.latitude;
        // const longitude = position.coords.longitude;
        const latitude = 12.8504096;
        const longitude = 77.652262;

        // Set the source of the iframe to display the map with the current location
        iframe.src = `https://maps.google.com/maps?q=${latitude},${longitude}&z=10&ie=UTF8&iwloc=&output=embed`;
      });
    };
    showMapWithCurrentLocation();
  }, []); // Empty dependency array to run the effect only once when the component mounts
  return (
    <>
      <div>
        <Helmet>
          <title>Buy And Sell Your Things| India at QuiksAle</title>
          <meta name="description" content="Please reach out to us on the below mentioned details" />
        </Helmet>
        <div className="contact-us my-5">
          <div className="container">
            <div className="row " >
              <div className="col-md-6">
                <div className='contact-map my-3'>
                  <div class="mapouter">
                    <div class="gmap_canvas">
                      <iframe
                        width="100%"
                        height="510"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=california&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                      <a href="https://2yu.co"></a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <form class="contact-form my-3" onSubmit={handleSubmit}>
                  <h4>Drop Your Thoughts</h4>
                  <div class="form-group">
                    <div class="form-input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <i class="icofont-user-alt-3">
                      </i>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <i class="icofont-email">
                      </i>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      <i class="icofont-book-mark">
                      </i>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-input-group">
                      <textarea
                        className="form-control"
                        placeholder="Your Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      >
                      </textarea>
                      <i class="icofont-paragraph">
                      </i>
                    </div>
                  </div>
                  <button type="submit" class="form-btn-group">
                    <i class="fas fa-envelope">
                    </i>
                    <span>send message</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;