import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,

} from "reactstrap";
// import Breadcrumbs from "../../Components/Pageprops/BreadCrumbs";
import ImageUpload from "../Profile/ImageUpload";
import './style.css';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
// import IconButton from '@material-ui/core/IconButton';
import { styled } from "styled-components";
import axios from "axios";
import "./Sell.css";
import Logo from "../Products/Images/Quick Sale Logo 1.png";
import advertiseImg from "../Products/Images/image 8.png";
import photo from "../../Images/photo.png";
import { API_BASE_URL } from "../../api/ApiService";
import { getAuthToken, getUserId } from "../../api/Auth";
import { useNavigate } from "react-router-dom";
import { StateData } from "../../state";
import Select from 'react-select';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./plots.css";
import userProfile from "../../Images/user (1).png";
import CheckboxLabels from "../Home/CheckBoxLabels";
import CircularProgress from '@mui/material/CircularProgress';

const Plots = () => {
  const baseurl = API_BASE_URL;
  const autheader = getAuthToken();

  const navigate = useNavigate();

  const [fileObj, setFileObj] = useState({ files: [], path: "" });
  // for multiple image upload
  const handleImageSelect = (file, path) => {
    setFileObj({ files: [...fileObj.files, ...file], path });
    // console.log(inputData);
  };


  const [inputData, setInputData] = useState({
    type: "",
    listedBy: "",
    plotArea: "",
    plotLength: "",
    plotBreath: "",
    projectName: "",
    adTitle: "",
    description: "",
    setPrice: "",
    state: "",
    city: "",
    pin: "",
    phoneNumber: "",
    numberOfOpenSides: "",
    widthOfRoadFacingPlot: "",
    anyConstrutionDone: "",
    boundaryWallMade: "",
    isInGatedColony: "",
    thisIsCornerPlot: "",
   
  });

  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedState(selectedOption.value);
      setInputData({ ...inputData, state: selectedOption.value, city: "" });
    } else {
      setSelectedState("");
      setInputData({ ...inputData, state: "", city: "" });
    }
  };

  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCity(selectedOption.value);
      setInputData({ ...inputData, city: selectedOption.value });
    } else {
      setSelectedCity("");
      setInputData({ ...inputData, city: "" });
    }
  };

  const handleDelete = (index) => {
    const updatedFiles = [...fileObj.files];
    updatedFiles.splice(index, 1);
    setFileObj({ ...fileObj, files: updatedFiles });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 'none',
      paddingLeft: 6,
      paddingRight:6
     

    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: 1,
      paddingRight: 1
    })
  };
  const [errors, setErrors] = useState({});

  const handleData = (e) => {
    const { name, value } = e.target;

    setInputData({ ...inputData, [name]: value });

    const newErrors = { ...errors };
    if (!value.trim()) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const userId = getUserId();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/auth/username/${userId}`, {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        });

        setUserName(response.data);
      } catch (error) {
        console.error('Error fetching wishlist data:', error);
      }
    };

    fetchUserData();
  }, []);


  const handleNumericInput = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    const newErrors = {};

    for (const key in inputData) {
      if (!inputData[key]) {
        newErrors[key] = `${key} is required`;
      }
    }
    if (Object.keys(newErrors).length === 0) {

      const formData = new FormData();
      formData.append('category', "PROPERTIES");
      formData.append('propertiesSubCategory', "lANDS_AND_PLOT");
      formData.append('type', inputData.type);
      formData.append('listedBy', inputData.listedBy);
      formData.append('plotArea', inputData.plotArea);
      formData.append('plotLength', inputData.plotLength);
      formData.append('plotBreath', inputData.plotBreath);
      formData.append('projectName', inputData.projectName);
      formData.append('adTitle', inputData.adTitle);
      formData.append('description', inputData.description);
      formData.append('setPrice', inputData.setPrice);
      formData.append('state', inputData.state);
      formData.append('city', inputData.city);
      formData.append('pin', inputData.pin);
      formData.append('phoneNumber', inputData.phoneNumber);
      formData.append('review_by', userName);
      formData.append('numberOfOpenSides', inputData.numberOfOpenSides);
      formData.append('widthOfRoadFacingPlot', inputData.widthOfRoadFacingPlot);
      formData.append('anyConstrutionDone', inputData.anyConstrutionDone);
      formData.append('boundaryWallMade', inputData.boundaryWallMade);
      formData.append('isInGatedColony', inputData.isInGatedColony);
      formData.append('thisIsCornerPlot', inputData.thisIsCornerPlot);
      formData.append('priceNegotiable', inputData.priceNegotiable);
      formData.append('review_by', userName);
      fileObj.files.forEach((file) => {
        formData.append(`files`, file);
      });
      // formData.append('fileName', file.name);

      // const newErrors = {}; 
      // for (const key in inputData) {
      //   if (!inputData[key]) {a
      //     newErrors[key] = ${key} is required;
      //   }
      // }
      // setErrors(newErrors);


      // if (Object.keys(newErrors).length === 0) {
      axios.post(
        `${baseurl}/api/product/adpost/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        }
      )
        .then((response) => {
          console.log(response);

          if (response.status === 201) {
            toast.success("Product has been posted!");
            navigate("/plots");
          } else if (response.status === 400) {
            handleBadRequestError(response);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 400) {
            handleBadRequestError(error.response);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    } else {
      setErrors(newErrors);
      console.log("errors", errors);
      toast.error("Please fill in all required fields before submitting.");
    }
  };

  const handleBadRequestError = (response) => {
    if (response.data && response.data.status === "BAD_REQUEST" && response.data.message === "You have exceed the limit for posting ") {
      toast.error("You have exceeded the limit for posting");
    } else {
      toast.error("Bad Request. Please check your input and try again.");
    }
  };


  return (
    <>
      <div className="py-lg-5 " style={{ background: "#F5F5F5" }}>
        <h2 className="text-center mb-5 ">POST YOUR AD</h2>
        <div className="container">
          <div className="row ">
            <div className="col-lg-7 " style={{ background: "#ffff" }}>
              <div className="ms-lg-5">
                <h6 className="ps-2 pt-3">
                  <span
                    className="sidebar-heading"
                    style={{ color: "#940D19" }}
                  >
                    SELECTED CATEGORY
                  </span>
                </h6>
                <div>{/* <Breadcrumbs /> */}</div>
                <h6 className="pt-3 ps-2 sub-heading">
                  <span>INCLUDE  DETAILS</span>
                </h6>

                <div>
                  <span>You are posting this property for</span>{" "}
                  <span className="free-post">Free !</span>
                </div>
                <Form className="post-form">
                  <FormGroup style={{}}>
                    <label>Type</label>
                    <Input
                      className="mb-3"
                      type="select"
                      name="type"
                      onChange={handleData}
                      value={inputData.type}
                    >
                      <option>type</option>
                      <option>Residential Plots</option>
                      <option>Agriculture Plots</option>
                    </Input>
                    {errors.type && (
                      <div style={{ color: "red" }}>{errors.type}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label for="listed_by">Listed By</Label>
                    <Input
                      className="border border"
                      id="listedBy"
                      name="listedBy"
                      placeholder=""
                      type="text"
                      value={inputData.listedBy}
                      onChange={handleData}
                    />
                    {errors.listedBy && (
                      <div style={{ color: "red" }}>{errors.listedBy}</div>
                    )}
                  </FormGroup>
                  <div>
                    <strong style={{ fontSize: "20px" }}>
                      Property Features
                    </strong>{" "}
                  </div>
                  {/* <FormGroup style={{}}>
                    <label>No of Floors</label>
                    <Input
                      className="mb-3"
                      type="select"
                      name="brand"
                      onChange={handleData}
                    >
                      <option>Select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                      <option>Total Floor</option>
                    </Input>
                    {errors.brand && (
                      <div style={{ color: "red" }}>{errors.brand}</div>
                    )}
                  </FormGroup> */}
                  <FormGroup style={{}}>
                    <label>No of open sides</label>
                    <Input
                      className="mb-3"
                      type="select"
                      name="numberOfOpenSides"
                      onChange={handleData}
                      value={inputData.numberOfOpenSides}
                    >
                      <option>Select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </Input>
                    {errors.numberOfOpenSides && (
                      <div style={{ color: "red" }}>{errors.numberOfOpenSides}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="">
                    <Label >Width of road facing the plot</Label>
                    <div className="d-flex">
                      <Input
                        className="border"
                        name="widthOfRoadFacingPlot"
                        placeholder="Road Width"
                        type="text"
                        value={inputData.widthOfRoadFacingPlot}
                        onChange={handleData}
                      />
                    </div>
                  </FormGroup>
                  <div  className="mb-3 width-min row">
                    <div className="col">
                    <label class="form-check-label w-100" style={{ float: "left", font: "19px"}}
                      value="anyConstrutionDone"
                    >
                      Any Construction done
                    </label>
                    </div>
                    <div className="col">
                    <div className="d-flex ">
                      <div class="form-check" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="anyConstrutionDone"
                          checked={inputData.anyConstrutionDone === "Yes"}
                          onChange={() => handleData({ target: { name: "anyConstrutionDone", value: "Yes" } })}
                        />
                        <label htmlFor="" value="inputdata.numberOfOpenSides">Yes</label>
                      </div >

                      <div class="form-check ms-3" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="anyConstrutionDone"
                          checked={inputData.anyConstrutionDone === "No"}
                          onChange={() => handleData({ target: { name: "anyConstrutionDone", value: "No" } })}

                        />
                        <label htmlFor="">No</label>
                      </div>
                    </div>
                    </div>
                   
                   
                  </div>
                  <div  className="mb-3 width-min row">
                    <div className="col">
                    <label class="form-check-label w-100"  style={{ float: "left", font: "19px", width: "45%" }}>
                      Boundary wall made
                    </label>
                    </div>
                    <div className="col">
                    <div className="d-flex ">
                      <div class="form-check" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="boundaryWallMade"
                          checked={inputData.boundaryWallMade === "Yes"}
                          onChange={() => handleData({ target: { name: "boundaryWallMade", value: "Yes" } })}  
                        />

                        <label htmlFor="">Yes</label>
                      </div >

                      <div class="form-check ms-3" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="boundaryWallMade"
                          checked={inputData.boundaryWallMade === "No"}
                          onChange={() => handleData({ target: { name: "boundaryWallMade", value: "No" } })} 
                        />

                        <label htmlFor="">No</label>
                        {errors.boundaryWallMade && (
                          <div style={{ color: "red" }}>{errors.boundaryWallMade}</div>
                        )}
                      </div>
                    </div>
                    </div>

                   
                   
                  </div>
                  <div  className="mb-3 width-min row">
                    <div className="col">
                    <label class="form-check-label w-100"  style={{ float: "left", font: "19px", width: "45%" }}>
                      Is in a gated colony
                    </label>
                    </div>
                    <div className="col">
                    <div className="d-flex ">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isInGatedColony"
                          checked={inputData.isInGatedColony === "Yes"}
                          onChange={() => handleData({ target: { name: "isInGatedColony", value: "Yes" } })} 
                        />
                        <label htmlFor="">Yes</label>
                      </div >

                      <div class="form-check ms-3" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="isInGatedColony"
                          checked={inputData.isInGatedColony === "No"}
                          onChange={() => handleData({ target: { name: "isInGatedColony", value: "No" } })} 
                        />
                        <label htmlFor="">No</label>
                        {errors.isInGatedColony && (
                          <div style={{ color: "red" }}>{errors.isInGatedColony}</div>
                        )}
                      </div>
                    </div>
                    </div>                   
                    </div>
                  <div className="mt-5">
                    <strong style={{ fontSize: "20px" }}>Area</strong>{" "}
                  </div>
                  <FormGroup style={{}} className="plot-area">
                    <label>Plot Area</label>
                    <div
                      className="d-flex border"
                      style={{ height: "39px" }}
                    >
                      <input
                        type="text"
                        className="ps-3"
                        placeholder="Plot Area"
                        name="plotArea"
                        value={inputData.plotArea}
                        // onKeyPress={handleNumericInput}
                        onChange={handleData}
                      />                     

                    </div>
                  </FormGroup>
                  <FormGroup style={{}} className="">
                    <label>Plot Length</label>
                    <div
                      className="d-flex "
                      style={{ height: "39px" }}
                    >
                      <input
                        type="text"
                        className="ps-3"
                        placeholder="Plot Length"
                        name="plotLength"
                        value={inputData.plotLength}
                        // onKeyPress={handleNumericInput}
                        onChange={handleData}
                      />   
                       {errors.plotLength && (
                        <div style={{ color: "red" }}>{errors.plotLength}</div>
                      )}                  
                      {/* <span className="border">yrd</span> */}

                    </div>
                  </FormGroup>
                  {/* <FormGroup className="plot-length">
                    <Label for="length">Plot Length</Label>
                    <div className="d-flex border">
                      <Input
                        className="border "
                        id="plotLenght"
                        name="plotLength"
                        placeholder="Plot Length"
                        type="text"
                        onChange={handleData}
                        value={inputData.plotLenght}
                      />
                      {errors.plotLenght && (
                        <div style={{ color: "red" }}>{errors.plotLenght}</div>
                      )}
                    </div>
                  </FormGroup> */}

                  <FormGroup className="plot-breath ">
                    <Label for="length">Plot Breadth</Label>
                    <div className="d-flex">
                      <Input
                        className=" "
                        id="plotBreath"
                        name="plotBreath"
                        placeholder="Plot Breath"
                        type="text"
                        value={inputData.plotBreath}
                        onChange={handleData}
                      />
                      {errors.plotBreath && (
                        <div style={{ color: "red" }}>{errors.plotBreath}</div>
                      )}
                      {/* <span className="border">yrd</span> */}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div  className="mb-3 row ">
                      <div className="col">
                      <label class="form-check-label w-100"  style={{ float: "left", font: "19px" }}>
                    This is a corner plot
                    </label>
                      </div>
                      <div className="col">
                      <div className="d-flex ">
                      <div class="form-check form-check-plot" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="thisIsCornerPlot"
                          checked={inputData.thisIsCornerPlot === "Yes"}
                          onChange={() => handleData({ target: { name: "thisIsCornerPlot", value: "Yes" } })} 
                        />
                        <label htmlFor="">Yes</label>
                      </div >

                      <div class="form-check ms-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="thisIsCornerPlot"
                          checked={inputData.thisIsCornerPlot === "No"}
                          onChange={() => handleData({ target: { name: "thisIsCornerPlot", value: "No" } })} 
                        />
                        <label htmlFor="">No</label>
                        {errors.thisIsCornerPlot && (
                          <div style={{ color: "red" }}>{errors.thisIsCornerPlot}</div>
                        )}
                      </div>
                    </div>
                      </div>

                    
                   
                  </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="project_name">Project Name</Label>
                    <Input
                      className="border border"
                      id="projectName"
                      name="projectName"
                      placeholder=""
                      type="text"
                      value={inputData.projectName}
                      onChange={handleData}
                    />
                    {errors.projectName && (
                      <div style={{ color: "red" }}>{errors.projectName}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="adtitle">Ad Title *</Label>
                    <Input
                      className="border border"
                      id="adTitle"
                      name="adTitle"
                      placeholder=""
                      type="text"
                      value={inputData.adTitle}
                      onChange={handleData}
                    />
                    {errors.adTitle && (
                      <div style={{ color: "red" }}>{errors.adTitle}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label for="description" className="textarea">
                      {" "}
                      Description
                    </Label>
                    <Input
                      className="border border"
                      id="description"
                      name="description"
                      placeholder=""
                      type="text"
                      value={inputData.description}
                      onChange={handleData}
                    />
                    {errors.description && (
                      <div style={{ color: "red" }}>{errors.description}</div>
                    )}
                  </FormGroup>

                  <div>
                    <strong style={{ fontSize: "20px" }}>Price Details</strong>{" "}
                  </div>

                  <FormGroup>
                    <Label for="setPrice">SET A PRICE </Label>
                    <Input
                      id="setPrice"
                      name="setPrice"
                      type="text"
                      className="border "
                      value={inputData.setPrice}
                      onChange={handleData}
                      onKeyPress={handleNumericInput}
                    />
                    {errors.setPrice && (
                      <div style={{ color: "red" }}>{errors.setPrice}</div>

                    )}
                  </FormGroup>
                  <FormGroup>
                  <div  className="mb-3 row">
                    <div className="col">
                    <label class="form-check-label"  style={{ float: "left", font: "19px" }}>
                    Price Negotiable
                    </label>
                    </div>
                    <div className="col">
                    <div className="d-flex ">
                      <div class="form-check" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="priceNegotiable"
                          checked={inputData.priceNegotiable === "Yes"}
                          onChange={() => handleData({ target: { name: "priceNegotiable", value: "Yes" } })} 
                        />
                        <label htmlFor="">Yes</label>
                      </div >

                      <div class="form-check ms-3" >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="priceNegotiable"
                          checked={inputData.priceNegotiable === "No"}
                          onChange={() => handleData({ target: { name: "priceNegotiable", value: "No" } })} 
                        />
                        <label htmlFor="">No</label>
                        {errors.priceNegotiable && (
                          <div style={{ color: "red" }}>{errors.priceNegotiable}</div>
                        )}
                      </div>
                    </div>
                    </div>

                    
                   
                  </div>
                  </FormGroup>

                  <div className="container">
                    <div className="row py-2">
                      <h6>UPLOAD UP TO 10 PHOTOS</h6>
                    </div>
                    <div className="row py-2">
                    {
                      fileObj.files.map((file, index) => {
                        const url = URL.createObjectURL(file);
                        return (
                          <div className="col-4" key={index}>
                            <ImageUpload
                              handleImageSelect={handleImageSelect}
                              img={url}
                              showDelete={index == fileObj.files.length ? false : true}
                              onDelete={() => handleDelete(index)}
                            />
                          </div>
                        );
                      })
                    }
                      <div className="col-4 ">
                        <a className="d-inline-block profile-image-link">
                          {/* { <img src={profileimg} alt="profile_img" /> */}

                          <ImageUpload
                            handleImageSelect={handleImageSelect}
                            img={photo}
                          />
                        </a>
                      </div>
                      {/* <ImageUpload  handleImageSelect={handleImageSelect}/> */}
                    </div>
                    <div className="row py-3 my-4 ">
                      <div>
                        <strong style={{ fontSize: "20px" }}>
                          CONFIRM YOUR LOCATION
                        </strong>{" "}
                      </div>
                      <FormGroup style={{ width: "100%" }}>
                        <label>Pin Code</label>
                        <Input
                          id="pin"
                          className="mb-3 border border"
                          type="text"
                          name="pin"
                          value={inputData.pin}
                          onChange={handleData}
                          onKeyPress={handleNumericInput}
                        />
                        {errors.pin && (
                          <div style={{ color: "red" }}>{errors.pin}</div>

                        )}
                      </FormGroup>

                      <FormGroup className="state-container" style={{ width: "66%" }}>
                      <label>State *</label>
                      <Select
                        className="mb-3"
                        value={selectedState ? { label: selectedState, value: selectedState } : null}
                        onChange={handleStateChange}
                        options={StateData.map((state) => ({ value: state.name, label: state.name }))}
                        isSearchable={true}
                        styles={customStyles}
                        isClearable
                        isCreatable
                      />
                      {errors.state && <div style={{ color: "red" }}>{errors.state}</div>}
                    </FormGroup>

                    <FormGroup className="city-container" style={{ width: "66%" }}>
                      <label>City *</label>
                      <Select
                        className="mb-3"
                        value={selectedCity ? { label: selectedCity, value: selectedCity } : null}
                        onChange={handleCityChange}
                        options={selectedState
                          ? StateData.find((state) => state.name === selectedState)?.cities.map((city) => ({ value: city, label: city }))
                          : []
                        }
                        isSearchable={true}
                        styles={customStyles}
                        isClearable
                        isCreatable
                      />
                      {errors.city && <div style={{ color: "red" }}>{errors.city}</div>}
                    </FormGroup>
                    </div>
                    <div className="row py-2">
                      <div className="col-8">
                        <strong>REVIEW YOUR DETAILS</strong>

                        <div className="row p">
                          <div className="col-4">
                            <img src={userProfile} alt="profile" height="60px" width="60px"/>
                          </div>
                          <div className="col-8">
                            <FormGroup>
                              <Label for="examplePassword">Name</Label>
                              <p>{userName}</p>
                            </FormGroup>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <FormGroup className="py-3">
                    <Label >Mobile Phone Number *</Label>
                    <Input
                      className="border border"
                      id="examplePhone"
                      value={inputData.phoneNumber}
                      name="phoneNumber"
                      placeholder="123-45-678"
                      type="text"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      onChange={handleData}
                      onKeyPress={handleNumericInput}
                    />
                    {errors.phoneNumber && (
                      <div style={{ color: "red" }}>{errors.phoneNumber}</div>
                    )}
                  </FormGroup>
                  <CheckboxLabels />
                <FormGroup className="py-4">
                <Button color="danger" disabled = {isLoading} onClick={handleSubmit}> 
                  {isLoading ? <CircularProgress color="inherit"/> : 'Post Now'}
                  </Button>
                  </FormGroup>
                </Form>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="advertise py-4">
                <div className="advertise-img-container text-center">
                  <img src={Logo} alt="discription" />
                </div>
                <h6 className="text-center">
                  <img src={advertiseImg} alt="advertise-img"></img>
                </h6>
                <h6 className="text-center">Heavy on Features.</h6>
                <h6 className="text-center">Light on Price.</h6>
                <p className="text-center">
                  only for:{" "}
                  <button className="advertise-button p-2">
                    <b>&#8377;299 only </b>
                  </button>
                </p>
                <div className="text-center">
                  <button className="advertise-cart-button">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="ShoppingCartIcon"
                    >
                      <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                    </svg>
                    Add to Cart
                  </button>
                </div>
                <div className="text-center my-2">
                  <button className="advertise-details-button">
                    View Details &rarr;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Plots;