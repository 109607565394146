import React from "react";
import "./TrendingArticle.css";

const BikeArticle = () => {
  return (
    <>
      <nav class="navbar navbar-fixed-top nabvar-default">
        <div class="container navbar-container">
          <div class="row">
            <div id="navbar" class="navbar-collapse collapse">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-right navbar-menu-left">
                  <li>
                    <a class="navbar-nav-link" href="#bio">
                      Short Bio
                    </a>
                  </li>
                  <li>
                    <a class="navbar-nav-link" href="#wronful_dead">
                      Wrongful Death
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <ul class="nav navbar-nav navbar-left navbar-menu-right">
                  <li>
                    <a class="navbar-nav-link" href="#drama_film">
                      Drama Film
                    </a>
                  </li>
                  <li>
                    <a
                      class="navbar-nav-link link-further-info"
                      href="https://en.wikipedia.org/wiki/Alan_Turing"
                      target="_blank"
                    >
                      Further Information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header>
        <div class="blog-title-wrapper container">
          <div class="row">
            <div class="col-sm-3 module-fields">
              <i class="fa fa-wrench fa-4x"></i>
              <h2 class="blog-subtitle">
              Ride in Style: Find the Perfect Bike for You with QuiksAle
              </h2>
              <ul class="fields_list">
                <li class="fields_item">
                Looking for a new ride? Whether you're in the market for a TVS XL 100 or a second-hand electric bike, QuiksAle has you covered! With a wide range of options available, finding the perfect bike has never been easier. From second-hand bikes near you to discount bikes online, QuiksAle offers a seamless buying and selling experience for all your biking needs.
                </li>
                {/* <li class="fields_item">Thorough Inspection by a Mechanic</li>
          <li class="fields_item">Test Drive in Varied Conditions</li>
          <li class="fields_item">Check for Signs of Wear and Tear</li>
          <li class="fields_item">Negotiate a Fair Price</li> */}
              </ul>
            </div>
            <div id="bio" class="col-sm-9 module-bio">
              {/* <div class="inline-meta date">
                <i class="fa fa-smile-o"></i> 23 June 1912 -{" "}
                <i class="fa fa-frown-o"></i> 7 June 1954
              </div> */}
              <h1 class="blog-title">
              Ride in Style: Find the Perfect Bike for You with QuiksAle
              </h1>
              <div class="blog-description">
                1. <b>TVS XL 100:   </b><br />
                Looking for a TVS XL 100? QuiksAle offers a range of options to suit your needs. Whether you're looking for a brand new model or a second-hand option, QuiksAle's platform makes it easy to find the perfect TVS XL 100 for you.
                <br />
                2. <b>Second Hand Bikes Near Me / 2nd Hand Bikes Near Me:  </b><br />
                Looking for second-hand bikes near you? QuiksAle's platform allows you to easily find 2nd hand bikes in your area. Whether you're looking for a mountain bike, a road bike, or a hybrid bike, QuiksAle's network of sellers ensures that you can find what you need right in your neighborhood.
                <br />
                3. <b>Second Hand Electric Bikes / Used Electric Bikes / Used Ebikes Near Me:  </b><br />
                In the market for a second-hand electric bike? QuiksAle offers a wide selection of used electric bikes at unbeatable prices. Whether you're looking for a commuter bike or a mountain bike, QuiksAle's platform has something for everyone.
                <br />
                4. <b>Bike Sales Near Me / Second Hand Bike Shop / Second Hand Bike Shop Near Me: </b><br />
                Looking for bike sales near you? QuiksAle's platform allows you to easily find second-hand bike shops in your area. Whether you're looking to buy or sell a bike, QuiksAle's network of sellers ensures that you can find what you need right in your neighborhood.
                <br />
                5. <b>Sell Used Bikes / Buy Used Bikes / Sell Old Bike / Sell Second Hand Bike: </b>
                Looking to sell your old bike or buy a used one? QuiksAle makes it easy to list and sell your used bikes. Simply create a listing, add photos and descriptions, and wait for buyers to make offers. With QuiksAle's secure payment system, you can sell and buy bikes with confidence.
                <br />
                6. <b>Old Bike Price / Second Hand Bike Price / Used Bike Sale:  </b><br />
                Wondering about the price of an old or second-hand bike? QuiksAle offers competitive prices on a wide range of used bikes. Whether you're looking for a budget-friendly option or a top-of-the-line model, QuiksAle's platform has something for every budget.
                <br />
                7. <b>Discount Bikes Online / Cheap Hybrid Bikes / Mens Bike Sale / Used Trek Bikes:  </b><br />
                Looking for discount bikes online or cheap hybrid bikes? QuiksAle offers a wide range of options to suit every need and budget. Whether you're looking for a commuter bike, a mountain bike, or a road bike, QuiksAle's platform has something for everyone.
                <br />
                <b>In conclusion, QuiksAle is your ultimate destination for buying and selling bikes. Whether you're looking for a TVS XL 100, a second-hand electric bike, or a discount bike online, QuiksAle's platform offers a seamless buying and selling experience. So why wait? Join QuiksAle today and start exploring the world of biking like never before!</b><br />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <footer class="footer">
  <div class="container">
    <a href="https://en.wikipedia.org/wiki/Alan_Turing" target="_blank" class="further_info_link">Further Information</a>
  </div>
</footer> */}
    </>
  );
};

export default BikeArticle;
