import React from 'react'

import "./Blogs.css"
import TrendingArticles from '../Home/TrendingArticles/TrendingArticles';
const Blogs = () => {
  return (
    <>
    {/* <section class="inner-section blog-grid my-5">
 	<div class="container">
 		<div class="row justify-content-center">
 			<div class="col-lg-12">				
 				<div class="row">
 					<div class="col-md-4 col-lg-4">
 						<div class="blog-card">
 							<div class="blog-media py-4">
 								<a class="blog-img" href="#">
 									<img src={laptop} alt="laptop"/>
 								</a>
 							</div>
 							<div class="blog-content">
 							
 								<h4 class="blog-title">
 									<a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a>
 								</h4>
 								<p class="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p>
 								<a class="blog-btn" href="#">
 									<span>read more</span>
 									<ArrowForwardSharpIcon/>
 								</a>
 							</div>
 						</div>
 					</div>
					
 					<div class="col-md-4 col-lg-4">
 						<div class="blog-card">
 							<div class="blog-media py-4">
 								<a class="blog-img" href="#">
 									<img src={watch} alt="blog"/>
 								</a>
 							</div>
 							<div class="blog-content">
 								
 								<h4 class="blog-title">
 									<a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a>
 								</h4>
 								<p class="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p>
 								<a class="blog-btn" href="#">
 									<span>read more</span>
 									<ArrowForwardSharpIcon/>
 								</a>
 							</div>
 						</div>
 					</div>
					
 					<div class="col-md-4 col-lg-4">
 						<div class="blog-card">
 							<div class="blog-media py-4">
 								<a class="blog-img" href="#">
 									<img src={mobile} alt="blog"/>
 								</a>
 							</div>
 							<div class="blog-content">
 								
 								<h4 class="blog-title">
 									<a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a>
 								</h4>
 								<p class="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p>
 								<a class="blog-btn" href="#">
 									<span>read more</span>
 									<ArrowForwardSharpIcon/>
 								</a>
 							</div>
 						</div>
 					</div>
					
 					<div class="col-md-4 col-lg-4">
 						<div class="blog-card">
 							<div class="blog-media py-4">
 								<a class="blog-img" href="#">
 									<img src={headphone} alt="blog"/>
 								</a>
 							</div>
 							<div class="blog-content">
 								
 								<h4 class="blog-title">
 									<a href="blog-details.html">Voluptate blanditiis provident Lorem ipsum dolor sit amet</a>
 								</h4>
 								<p class="blog-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias autem recusandae deleniti nam dignissimos sequi ... </p>
 								<a class="blog-btn" href="#">
 									<span>read more</span>
 									<ArrowForwardSharpIcon/>
 								</a>
 							</div>
 						</div>
 					</div>
 					
					
 				</div>
				
 				
				
 			</div>
 			
 		</div>
 	</div>
 </section> */}
 <TrendingArticles/>
    </>
  )
}

export default Blogs;