import React, { useState, useEffect } from "react";
import "./Card.css";
import { useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";
import { getAuthToken } from "../../api/Auth";
import { getUserId } from "../../api/Auth";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import logo from "../Products/Images/Quick Sale Logo 1.png"


const Card = ({ product, selectedCategory }) => {
  const id = product.id;
  const baseurl = API_BASE_URL;
  const userId = getUserId(); 
  const autheader= getAuthToken();


  const navigate = useNavigate();
  //  const ProductSummaryHandler = () => { 
  //   const categoryToNavigate = selectedCategory || product.category;
  //   fetchData();
  //   navigate(`/products/${categoryToNavigate}/${id}`);
  // };
  const ProductSummaryHandler = () => {
    const categoryToNavigate = selectedCategory || product.category;

    if (categoryToNavigate === 'CAR') {
      // Navigate to the ProductSummary component for cars
      navigate(`/cars/${id}`);
    }
    else if (categoryToNavigate === 'BIKE') {
      // Navigate to the PlotSummary component for plots
      navigate(`/bikes/${id}`);
    }
    else if (categoryToNavigate === 'lANDS_AND_PLOT') {
      navigate(`/plots/${id}`);
    }
    else if (categoryToNavigate === 'HOUSE_AND_APARTMENT') {
      navigate(`/apartments/${id}`);
    }
    else if (categoryToNavigate === 'PROPERTIES') {
      navigate(`/plots/${id}`);
    }
    else if (categoryToNavigate === 'PROPERTIES') {
      navigate(`/apartments/${id}`);
    }
    else if (categoryToNavigate === 'FURNITURE') {
      navigate(`/furnitures/${id}`);
    }
    else if (categoryToNavigate === 'GADGET') {
      navigate(`/electronics/${id}`);
    }
    else if (categoryToNavigate === 'LAPTOP') {
      navigate(`/laptops/${id}`);
    }
    else if (categoryToNavigate === 'MOBILE') {
      navigate(`/mobiles/${id}`);
    }
    else if (categoryToNavigate === 'OTHER') {
      navigate(`/others/${id}`);
    }

    else {
      // Handle other categories as needed
      console.warn(`Unhandled category: ${categoryToNavigate}`);
    }

    fetchData();
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        // `${baseurl}/api/addDetailForView/id?id=${id}&userId=${userId}`,{},{
          `${baseurl}/api/addDetailForView/id?id=${product.id}`,{

    });
      console.log("ResponseData: ",response)
    } catch (error) {
      console.error("Error registering user:", error);
    }    
  };


  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6">
        <div className="product-card" onClick={ProductSummaryHandler}>
          <div className="product-media">
            <a className="product-image">
              {/* {
                product.images.map(i => {
                  return i.endsWith(".mp4") ? (<video width="100%" height="100%" controls>
                    <source src={product.images[0]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>) : (<img src={i} alt="Product Image" />)
                }) 
              }   */}

              {product.images.length > 0 && (
                product.images[0].endsWith(".mp4") ? (
                  <video width="100%" height="100%" controls>
                    <source src={product.images[0]} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={product?.images[0]} alt="Product Image" onError={(e) => { e.target.onerror = null; e.target.src = '../Products/Images/Quick Sale Logo 1.png'}}  className="cardimage"/>
                )
              )}
            </a>
          </div>
          <div className="product-content">
            <span className="product-name">
              <span>{product?.brand}</span>
              <a href="" style={{ position: "absolute", bottom: "13rem" }}>
              </a>
            </span>
            <span className="product-details">{product?.adTitle}</span>
            {/* <span>{product?.kmDriven}</span> */}
            <h6 className="product-price">
              <span><CurrencyRupeeIcon/>{product?.setPrice}</span>

            </h6>
            <p className="product-location" style={{fontSize: '16px'}}>Location</p>
            <p className="product-location-name">
              <span>{product?.city}</span>
              {/* <span>
                <a href="" className="eye-img">
                  <VisibilityOutlinedIcon />
                </a>
                <span className="product-views">{product?.view}</span>
              </span> */}
            </p>
          </div>
        </div>

      </div>


    </>
  );
};

export default Card;