import React, {useState} from "react";
import "./mobilemenu.css";
import HomeIcon from "@mui/icons-material/Home";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import CableOutlinedIcon from '@mui/icons-material/CableOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddIcon from '@mui/icons-material/Add';
import { NavLink } from "react-router-dom";
import Person2Icon from '@mui/icons-material/Person2';
import PlotIcon from "../../Images/plot (1).png";

const Mobilemenu = () => {
  const [drawerState, setDrawerState] = useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
 
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 100 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
       <List className="d-flex">
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/cars">
            <ListItemIcon>
              <TimeToLeaveOutlinedIcon  sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Cars" />
          </ListItemButton>
        </ListItem>

        {/*  */}
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/mobiles">
            <ListItemIcon>
              <PhoneIphoneOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Mobiles" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/Electronicsproduct">
            <ListItemIcon>
              <CableOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Electronics" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <List className="d-flex">
       
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/Bikes">
            <ListItemIcon>
              <TwoWheelerOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Bikes" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/laptops">
            <ListItemIcon>
              <LaptopChromebookOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Laptops" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/furnitures">
            <ListItemIcon>
              <ChairOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Furniture" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />

      <List className="d-flex">
       

        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/plots">
            <ListItemIcon>
              <img src={PlotIcon} alt="plotIcon" height="25px" width="25px" />
            </ListItemIcon>
            <ListItemText primary="Plots" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/Others">
            <ListItemIcon>
              <ShoppingCartOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Others" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/houseandapartments">
            <ListItemIcon>
              <ApartmentOutlinedIcon sx={{  color: "#940D19"}}/>
            </ListItemIcon>
            <ListItemText primary="Apartments" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="d-lg-none mt-5 padding-">
      <div className="mobile-menu">
        <button className="menu-btn">
        <Link to="/" className="menu-link">
          {" "}
          <span>
            <HomeIcon onClick={() => setDrawerState({ ...drawerState, bottom: false })}/>
            <span onClick={() => setDrawerState({ ...drawerState, bottom: false })}>Home</span>
            </span>
        </Link>
        </button>

        <button className="cate-btn menu-btn" title="Category List">
          <span>
            {" "}
            <div>
              {["bottom"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <div className="d-flex flex-column align-items-center justify-content-center" >

                  <ShoppingCartOutlinedIcon />
                  <span onClick={toggleDrawer(anchor, !drawerState[anchor])} >
                    Category
                  </span>
                  </div>

                  <Drawer
                    anchor={anchor}
                    open={drawerState[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </span>
        </button>
        <button className="sell-btn">          
        <Link to="/sellsection" className="">
          {" "}
          <span style={{position:"relative",top:"-21px", left:"-8px"}}>
          <AddIcon  onClick={() => setDrawerState({ ...drawerState, bottom: false })}/>
            <span onClick={() => setDrawerState({ ...drawerState, bottom: false })}> Sell</span>
            </span>
        </Link>
        </button>
        <button className="menu-btn">          
        <Link to="/myadstable" className="menu-link">
          {" "}
          <span>
          <Person2Icon  onClick={() => setDrawerState({ ...drawerState, bottom: false })}/>
            <span onClick={() => setDrawerState({ ...drawerState, bottom: false })}>My Ads</span>
            </span>
        </Link>
        </button>
        <button className="menu-btn">          
        <Link to="/wishlist" className="menu-link">
          {" "}
          <span>
          <FavoriteIcon onClick={() => setDrawerState({ ...drawerState, bottom: false })}/>
            <span onClick={() => setDrawerState({ ...drawerState, bottom: false })}>wishlist</span>
            </span>
        </Link>
        </button>
        
        
        {/* <Link to="wishlist">
          <a href="#" title="Wishlist">
            <FavoriteIcon />
            <span></span>
            <sup>0</sup>
          </a>
        </Link> */}
      </div>
    </div>
  );
};

export default Mobilemenu;