import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './MobileMenuSearchBar.css'
import { useSearch } from '../../Context/SearchContext'


const MobileMenuSearchBar = ({searchpageOpenhandler}) => {
  const [mobileMeuSearchQuery, setMobileMeuSearchQuery] = useState()
  const [isFocused, setIsFocused] = useState(false);
  const { isShowSearchPage, setIsShowSearchPage, setSearchQuery, searchQuery } = useSearch()


  const handleInputFocus = () => {
    setIsFocused(true);
    setIsShowSearchPage(true)
  };
  const searchIconClicked = () => {
    alert("search icon clicked")
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setMobileMeuSearchQuery(inputValue)
    // if (isFocused && !mobileMeuSearchQuery) {
    //   setIsShowSearchPage(true)
    // }
    console.log(inputValue);

  }

  return (
    <div className='search-container'>
      <div className='input-container'>
      <input
          type="text"
          value={mobileMeuSearchQuery}
          onChange={handleInputChange}
          placeholder="Search Brands and more"
          onFocus={handleInputFocus}
        />
      <SearchIcon onClick = {searchIconClicked} className='searchIcon' fontSize = "small"/>
      </div>
      
    </div>
  )
}

export default MobileMenuSearchBar