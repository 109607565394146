import React from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import icon from "./Images/flat-color-icons_like.png";
import Car from "../Products/Images/blue-car.png";
// import Car2 from "../Products/Images/";
// import yelloBike from "./Images/Rectangle 15 (2).png";
// import woodenChair from "./Images/wooden-chair.png";
// import hyundai from "./Images/Rectangle 15 (13).png";
// import macbookPro from "./Images/macbook-pro.png";
// import blackBike from "./Images/black-bike.png";
// import blueCar from "./Images/blue-car.png";
// import houseArticle from "./Images/house-article.png";
// import whiteHeadPhone from "./Images/Rectangle 15 (14).png";
// import laptop from "./Images/Rectangle 15 (5).png";
// import property from "./Images/Rectangle 15 (15).png";
const MultiCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} showThumbs={false}>
        <Carousel.Item>
          <img src={Car} />
          <Carousel.Caption>
            {/* <h3>First slide label</h3> */}
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={Car} />
        </Carousel.Item>
        <Carousel.Item>
          <img src={Car} />
        </Carousel.Item>
      </Carousel>
    </>
  );
};
export default MultiCarousel;
