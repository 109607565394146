import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../../api/ApiService';

const Search = () => {

    const  baseurl = API_BASE_URL;
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const search = useSelector((state) => state.search);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${baseurl}/api/product/globalSerach/value?value=${search}`);
      const data = await response.json();
      setItems(data);
      console.log("search", items)
    };

    fetchData();
  }, [search]);

  useEffect(() => {
    if (items) {
      const searchedItems = items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredItems(searchedItems);
    }
  }, [items, search]);

  return (
    
<div className="itemLists">
      {filteredItems.map((item) => (
        <div className="itemCard" key={item.id}>
          {/* Render your item */}
          <img src={item.img} alt="clothes" />
          <div className="itemText">
            <h4>{item.brand}</h4>
            <p>{item.description}</p>
            <p>{item.color}</p>
            <h3>
              <strong>${item.price}</strong>
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
};

   
export default Search;