import React from "react";
import "./Footer.css";
import Logo from "./Quick Sale Logo 1.png";
import { NavLink } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import PlaceIcon from "@mui/icons-material/Place";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AppleIcon from "@mui/icons-material/Apple";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link } from "react-router-dom";
import twitterimg from "../../Images/twitterimg.png";
import CheckboxLabels from "../../Pages/Home/CheckBoxLabels";



const Footer = () => {
  return (
    <div>
      <footer class="footer-decor footer-dark bg-extra-dark-gray border mt-5">
        <div class="footer-top padding-three-tb lg-padding-eight-tb sm-padding-50px-tb">
          <div class="container">
            <div class="row row-cols-sm-2 row-cols-1">
              {/* <!-- start footer column --> */}
              <div class="col-lg-4 col  footer-col1 ">
                <Link
                  to=""
                  class="footer-logo margin-25px-bottom margin-5px-top d-inline-block"
                >
                  <img
                    src={Logo}
                    data-at2x="images/logo-gradient-sky-blue-pink-white@2x.png"
                    alt=""
                    width="178"
                    height="100"
                  />
                </Link>
                <ul>
                  <li className="pt-1 pe-1">
                    <a href="" className="customer"> Quik Support: </a>
                  </li>
                  <li className="pt-1 pe-1">
                  <a href="tel:+918151818184">
                      {" "}
                      <CallIcon sx={{ color: "#940D19" }} /> +91 8151818184
                    </a>
                  </li>
                  <li className="pt-1 pe-1">
                  <a href="https://www.google.com/maps/search/?api=1&query=9+Sri+Sai+Ram+Enclave,+Vinayaka+Layout,+2nd+Cross+Rd,+Electronics+City+Phase+1,+Doddathoguru,+Bengaluru,+Karnataka+560100" target="_blank" rel="noopener noreferrer" className="d-flex">
                      <PlaceIcon sx={{ color: "#940D19" }} className="me-2" />
                      <div className="ps-1">
                        {" "}
                       #9, Sri Sai Ram Enclave, Vinayaka Layout, 2nd Cross Rd, Electronics City Phase 1, Doddathoguru,Bengaluru,Karnataka 560100                     
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@quiksale.in">
                      {" "}
                      <MailIcon
                        sx={{ color: "#940D19" }}
                        className="me-2"
                      />{" "}
                      info@quiksale.in
                    </a>
                  </li>
                  <li>
                    <a href="contact-us.html">
                     
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!-- end footer column -->

                <!-- start footer column --> */}
              <div class="col-lg-3 col footer-col2">
                <p class=" ">Top Category</p>
                <ul>
                  <li>
                    <button ><Link to="/laptops" className="navbar-link ">  Computer's & Laptops</Link>  </button>
                  </li>

                  <li>
                    <button> <Link to="/Bikes" className="navbar-link "> Bikes</Link> </button>
                  </li>

                  <li>
                    <button>  <Link to="/cars" className="navbar-link "> Cars</Link> </button>
                  </li>
                  <li>
                    <NavLink to="/products">
                    <button>
                      Browse All categories
                      <img src="images/ArrowRight.png" alt="" />
                    </button>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* <!-- end footer column -->

                <!-- start footer column --> */}
              <div class="col-lg-2 col footer-col3">
                <p class=" ">Quik links</p>
                <ul>
                  <NavLink to="/about">
                    <li className="mb-1">
                      <a href=""> About us </a>
                    </li>
                  </NavLink>
                  
                  {/* <NavLink to="/WishList">
                    <li className="mb-1">
                      <a href=""> Shoping Cart  </a>
                    </li>
                  </NavLink> */}
                  
                  <NavLink to="/blogs">
                    <li className="mb-1">
                      <a href=""> Blogs </a>
                    </li>
                  </NavLink>
                 
                  <NavLink to="/help">
                    <li className="mb-1">
                      <a href=""> Help </a>
                    </li>
                  </NavLink>
                  <NavLink to="/tnc">
                    <li className="mb-1">
                      <a href=""> Terms And Conditions </a>
                    </li>
                  </NavLink>

                  <NavLink to="/privacy">
                    <li className="mb-1">
                      <a href=""> Privacy Policy </a>
                    </li>
                  </NavLink>
                </ul>
              </div>
              {/* <!-- end footer column -->

                <!-- start footer column --> */}
              <div class="col-lg-3 col  footer-col4" style={{position:"relative"}}>
                <p class="alt-font  d-block  margin-20px-bottom xs-margin-10px-bottom ">
                  Download APP
                </p>
                <ul>
                  <li class="mobile-app-container">
                    <a href="index.html" class="d-flex bg-black width-62 py-1">
                      <div class="width-30 d-flex align-items-center padding-10px-lr mobile-app-img">
                        <PlayArrowIcon className="text-white" />
                      </div>

                      <div class="mobile-app-text-container">
                        <span class="text-dark-white text_mobile_app">
                          Android
                        </span>
                        <br />
                        <span class="text-dark-white text_mobile_app">
                          Coming Soon
                        </span>
                      </div>
                    </a>
                  </li>
                  <li class="mobile-app-container">
                    <a href="index.html" class="d-flex bg-black width-62 py-1">
                      <div class="width-30 d-flex align-items-center padding-10px-lr mobile-app-img">
                        <AppleIcon className="text-white" />
                      </div>

                      <div class="mobile-app-text-container">
                        <span class="text-dark-white text_mobile_app">
                          Apple
                        </span>
                        <br />
                        <span class="text-dark-white text_mobile_app">
                          Coming Soon
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
                
                <div class="footer-share">
                     <ul>
                        <a href="https://www.facebook.com/photo?fbid=122113052360173168&set=a.122112768290173168" className="mx-1">
                          <FacebookIcon />
                        </a>
                        <a href="https://twitter.com/quik_sale/status/1747966455541538859/photo/1" className="mx-1 twittersvg">
                        <svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp r-18jsvk2 r-16y2uox r-8kz0gk"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" ></path></g></svg>
                        </a>
                        <a href="https://www.instagram.com/p/C2RhWkKqwXi/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" className="mx-1">
                        <InstagramIcon />
                        </a>
                        
                       
                         <a href="" className="mx-1">
                          <YouTubeIcon />
                        </a>
                        
                     </ul>
                  </div>
                  
              </div>
              {/* <!-- end footer column --> */}
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row">
          <div > <span>A Venture of </span><br /> <strong>WEBLY ONESTOP SOLUTIONS PVT.LTD.</strong> </div>
        
        </div>
        </div>
                {/* <CheckboxLabels /> */}
        <div class="footer-bottom padding-20px-tb border-top border-color-white-transparent">
          <div class="container">
            <div>  
            </div>
            <div class="row justify-content-center">
            &copy;Copyright &reg;Registered by "QuiksAle" | 2024 | All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;