import React, { useState } from "react";
import "./SignUp.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PersonIcon from "@mui/icons-material/Person";
import HttpsIcon from "@mui/icons-material/Https";
import EyeImage from "../../Images/Eye.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { NavLink } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from "@mui/icons-material/Call";
import GoogleIcon from "@mui/icons-material/Google";
import { API_BASE_URL } from "../../api/ApiService";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { SocialIcon } from 'react-social-icons'

const SignUp = () => {
  // for confirm password start

  // confirm password end
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState({
    username:"",
    email:"",
    phone:"",
    password:"",
  });

  const [emailError, setEmailError] = useState("");
	const [phoneError, setPhoneError] = useState("");


  const Navigate = useNavigate();
  const baseurl = API_BASE_URL;

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  }

  const isPasswordMatch = () => {
    return userData.password === confirmPassword;
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleNumericInput = (event) => {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };
  const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUserData({
			...userData,
			[name]: value,
		});
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(userData.email)) {
			setEmailError("Invalid email");
		} else {
			setEmailError("");
		}

		if (name === "phone") {
			const numericValue = value.replace(/\D/g, '');
			setUserData({
				...userData,
				[name]: numericValue.slice(0, 10),
			});

			if (numericValue.length === 10) {
				setPhoneError("");
			} else {
				setPhoneError("Phone number should be 10 digits");
			}
		} else {
			setUserData({
				...userData,
				[name]: value,
			});
		}
	};



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordMatch()) {
      alert("Passwords do not match");
      return;
    }
    if (emailError || phoneError) {
			alert("Please enter valid email and phoneNumber errors before submitting");
			return;
		}

    try {
      const response = await axios.post(
        `${baseurl}/api/auth/signup`,
        userData
      );
      toast.success("SignUp Successful");
      Navigate("/signin");
    } catch (error) {
      if (error.response) {
        const responseData = error.response.data;
        if (responseData.message.includes("phone number")) {
          toast.error("This phone number is already registered");
        } else if (responseData.message.includes("Username")) {
          toast.error("UserName is already in use!")
        } else if (responseData.message.includes("Email")) {
          toast.error("Email is already in use!")
        } else {
          toast.error("Error registering user:", error);
        }
      } else {
        toast.error("Error registering user:", error.message);
      }
    }
  };
  
  return (
    <>
      <div className="signup py-5">
        <div class="container">
          <div class="screen">
            <div class="screen__content">
              <form class="login" onSubmit={handleSubmit}>
                <div class="login__field">
                  {/* <i class="login__icon fas fa-user"></i> */}
                  <PersonIcon style={{ color: "#940d19" }} />
                  <input
                    id="Name"
                    type="text"
                    class="login__input"
                    placeholder=" Name"
                    value={userData.username}
                    name="username"
                    onChange={handleInputChange}
                  />
                </div>
                <div class="login__field">
                  {/* <i class="login__icon fas fa-user"></i> */}
                  <EmailIcon style={{ color: "#940d19" }} />
                  <input
                    id="Email"
                    type="email"
                    class="login__input"
                    placeholder=" Email"
                    value={userData.email}
                    name="email"
                    onChange={handleInputChange}
                  />
            {/* {emailError && <p className="error-message">{emailError}</p>} */}

                </div>
                <div class="login__field">
                  {/* <i class="login__icon fas fa-user"></i> */}
                  <CallIcon style={{ color: "#940d19" }} />
                  <input
                    type="tel"
                    className="login__input"
                    id="Phone"
                    name="phone"
                    aria-describedby="emailHelp"
                    value={userData.phone}
                    onChange={handleInputChange}
                    onKeyPress={handleNumericInput}
                    placeholder="Phone"
                  />

                </div>
                <div class="login__field">
                  {/* <i class="login__icon fas fa-lock"></i> */}
                  <HttpsIcon style={{ color: "#940d19" }} />
                  <input
                    name="password"
                    value={userData.password}
                    class="login__input"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    className="login__input"
                    onChange={handleInputChange}
                  />
                  <img
                    src={EyeImage}
                    alt=""
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                </div>
                <div class="login__field">
                  {/* <i class="login__icon fas fa-lock"></i> */}
                  <HttpsIcon style={{ color: "#940d19" }} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    class="login__input"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    minLength={8}
                  />
                  <img
                    src={EyeImage}
                    alt=""
                    onClick={toggleConfirmPasswordVisibility}
                    className="eye-icon"
                  />
                </div>

                <button class="button login__submit">
                  SignUp
                </button>
                {/* <div className="mt-5  text-center signin-btn">
                  Already a user?
                  <NavLink
                    to="/signin"
                    className="fs-5"
                  >
                    Signin
                  </NavLink>
                </div> */}

                <div>
                  <NavLink to="/signin">
                  <button className="login__submit">
                    Login
                  </button>
                  </NavLink>
                </div>
              </form>
              <div class="social-login">
                <h3>Sign Up via</h3>
                <div className="d-flex justify-content-center">
                  <div class="social-icons">
                    {/* <a href="#" class="social-login__icon fab fa-instagram"></a> */}
                    {/* <a href="#" class="social-login__icon fab fa-facebook"></a> */}
                    <a href="">
                      <GoogleIcon style={{ color: "#fff" }} />
                    </a>
                    <a href="">
                      <InstagramIcon style={{ color: "#fff" }} />
                    </a>
                    <a href="">
                      <FacebookIcon style={{ color: "#fff" }} />
                    </a>
                    <a href="">
                      {/* <twitterimg/> */}
                      {/* <SocialIcon url="https://twitter.com" /> */}
                    </a>

                    {/* <a href="#" class="social-login__icon fab fa-twitter"></a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="screen__background">
              <span class="screen__background__shape screen__background__shape4"></span>
              <span class="screen__background__shape screen__background__shape3"></span>
              <span class="screen__background__shape screen__background__shape2"></span>
              <span class="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;