import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../api/ApiService';
import { getAuthToken } from '../../api/Auth';
import { getUserId } from '../../api/Auth';

const MyAdsTable = () => {
  const [myAds, setMyAds] = useState([]);
  const baseurl = API_BASE_URL;
  const userId = getUserId();
  const authheader = getAuthToken();

  useEffect(() => {
    // Define the API endpoint for fetching your ads data
    const apiUrl = `${baseurl}/api/product/myAds/${userId}`;

    // Perform the API call using fetch or your preferred HTTP library
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        // Update the state with the fetched data
        setMyAds(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); 

  return (
    <div className="cart-wrap mb-5">
      <div className="container pb-5">
        <div className='row'>
          <div className="table-main-heading mb-10">My Ads</div>
        </div>
        <div className="row border">
          <div className="col-md-12 wishlist-col border">
            <div className='row py-3'>
              <div className='col-5 border'><span className='wishlist-heading'>Product Image</span></div>
              <div className='col-2 border'><span className='wishlist-heading'>Model</span></div>
              <div className='col-2 border'><span className='wishlist-heading'>Status</span></div>
              <div className='col-3 border'><span className='wishlist-heading'>Actions</span></div>
            </div>

            {/* Map over the fetched ads data and display each ad */}
            {myAds.map(ad => (
              <div key={ad.id} className='row py-3 border'>
                <div className='col-5 border'>
                  {/* Display product image or details based on your API response */}
                  <div className="d-flex">
                    <div className="img-product">
                      <span className="price">{ad.productName}</span>
                    </div>
                    <div className='col-2 border '>
                      <img src={ad.imageUrl} alt="Product Image" className="price" />
                    </div>
                    <div className="product-details">
                      {/* Display additional product details if needed */}
                    </div>
                  </div>
                </div>
                <div className='col-2 border'><span className="price">{ad.model}</span></div>
                <div className='col-2 border'><span className="available-box">{ad.status}</span></div>
                <div className='col-3 border d-flex justify-content-center align-items-center '>
                  <button className="cart-button w-3 me-4" style={{ height: "2rem" }}>Edit</button>
                  <button className="cart-button w-4" style={{ height: "2rem" }}>Delete</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAdsTable;