import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios"; 
import "./OfferSection.css";
import { API_BASE_URL } from "../../../api/ApiService";

const OffersSection = () => {
  const [offersData, setOffersData] = useState([]);
  const baseurl =API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/offer/grabOffer`);
        console.log(response);
        setOffersData(response.data); 
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  return (
    <div className="container mb-lg-5 mt-lg-4 offer-section border pb-lg-5">
      <div class="container">
        <div class="top-filter row">
          <div class="filter-show col-9">
            <label class="filter-label">
              <span class="filter-label-one">GRAB THE OFFER</span>
              <span class="filter-label-two">From Categories</span>
            </label>
          </div>
          <div class="filter-short col-3">
            <label class="filter-view">
            </label>
          </div>
        </div>
      </div>
      <div className="row py-2">
        {offersData.map((offer, index) => (

          <div className="col-lg-3" key={index}>
            <NavLink to={`/OfferDescription/${offer.id}`}>
              <div className="card text-center pt-3">
                {
                  offer.images.map(i => {
                    return (<img src={i} alt="Product Image" />)
                  })
                }
                {/* <img src={offer.images[0]} alt="offer-img" /> */}
                <div className="card-body">
                  <h5 className="card-title">{offer.category}</h5>
                  <p className="card-text">{offer.description}</p>
                  <button className="btn offer-button" style={{ background: "#940D19", color: "white" }}>
                    Grab the Offer
                  </button>
                </div>
              </div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OffersSection;