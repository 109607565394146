import { useRef, useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import "./Sell.css";
import { useNavigate } from "react-router-dom";
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import CableIcon from '@mui/icons-material/Cable';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import BungalowIcon from '@mui/icons-material/Bungalow';
import ForestIcon from '@mui/icons-material/Forest';
import HeadphonesBatteryIcon from '@mui/icons-material/HeadphonesBattery';
import { height } from "@mui/system";
import Logo from "../Products/Images/Quick Sale Logo 1.png";
import advertiseImg from"../Products/Images/image 8.png";

const SellSection = () => {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const toggleSidebar = () => setOpen((prev) => !prev);

  return (
    <>
      <div className="py-5 mt-5 mt-lg-0 sell-section">
        <h2 className="text-center mb-5 postPage-heading">POST YOUR AD</h2>
        <div className="" >
        <div className="container" >
        <div className="row border d-flex align-items-center" >
            <div className="col-lg-4 p-0">
              <h6 className="ps-5 py-3">
                <span className="sidebar-heading" style={{ color: "#940D19" }}>
                  CHOOSE A CATEGORY
                </span>
              </h6>

              <ul className="list-group sidebar-options list-group-flush border-end border-top border-bottom my-2">
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/Car")}
                  
                >
                  <span className="ps-1 pe-5"> <ElectricCarIcon /></span>                
                  Cars
                </li>
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/Bike")}
                >
                  <span className="ps-1 pe-5"> <ElectricBikeIcon /></span>  
                  

                  Bikes
                </li>
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/sellelectronics")}
                >
                  <span className="ps-1 pe-5">  <CableIcon /></span>  
                 

                  Electronics
                </li>
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/SellMobiles")}
                >
                   <span className="ps-1 pe-5">  <PhoneIphoneIcon /></span> 
                  

                  Mobiles
                </li>
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/selllaptops")}
                >
                   <span className="ps-1 pe-5"> <LaptopChromebookIcon /></span> 
                  

                  Laptops
                </li>
                <li
                  className="list-group-item "
                  onClick={() => Navigate("/sellfruniture")}
                >
                   <span className="ps-1 pe-5"> <BedroomChildIcon /></span>                  
                  Furniture
                </li>
                <li className="list-group-item dropdown">
                  <a
                    className="dropdown-toggle "
                    href="#"
                    role="button"
                    id="houseApartmentsDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                     <span className="ps-1 pe-5"> <BungalowIcon className="sale-icons"/></span>                    
                    House & Apartments
                  </a>
                  <ul className="dropdown-menu w-100" aria-labelledby="houseApartmentsDropdown">
                    <li
                      className="dropdown-item"
                      onClick={() => Navigate("/ForSale")}
                    >
                      For Sale
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => Navigate("/ForRent")}
                    >
                      For Rent
                    </li>
                  </ul>
                </li>
                <li
                  className="list-group-item"
                  onClick={() => Navigate("/sellplots")}
                >
                   <span className="ps-1 pe-5">  <ForestIcon /></span> 
                  

                  Plots
                </li>
                <li
                  className="list-group-item"
                  onClick={() => Navigate("/sellother")}
                >
                   <span className="ps-1 pe-5">  <HeadphonesBatteryIcon /></span> 
                  
                  Others
                </li>
              </ul>
            </div>
            <div className="col-lg-8">
            <div className="advertise py-4">
        <div className='advertise-img-container text-center'><img src={Logo} alt="discription" /></div>        
        <h6 className='text-center'>
          <img src={advertiseImg} alt='advertise-img'></img>
          </h6>
        <h6 className='text-center'>Heavy on Features.</h6>
        <h6 className='text-center'>Light on Price.</h6>
        <p className='text-center'>
          only for: <button className='advertise-button p-2'><b>&#8377;2999 only </b></button>
        </p>
        <div className='text-center'><button className='advertise-cart-button'>
          <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ShoppingCartIcon"><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></svg>Add to Cart</button></div>
        <div className='text-center my-2'><button className='advertise-details-button'>View Details &rarr;</button></div>
      </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default SellSection;