import React, { useState, useEffect } from "react";
import "./ProductSummary.css";
import mainimage from "../../Images/Main Image.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import Profile from "../../Images/download.png";
import imgcamera from "../../Images/Imagecamera.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MultiCarousel from "../MultiCarousel/MultiCarousel";
import { useParams } from "react-router";
import axios from "axios";
import Carousel from "../Carousels/Carousel";
import { getUserId } from "../../api/Auth";
import { API_BASE_URL } from "../../api/ApiService";

import { Carousel as _carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { getAuthToken } from "../../api/Auth";
import { NavLink } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite"; 
import { toast } from "react-toastify";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatIcon from '@mui/icons-material/Chat';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';


const PlotsSummary = () => {
  const baseurl = API_BASE_URL;
  const { selectedCategory, selectedProductId } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false); 


  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(1);

  const [products,setProducts] = useState([]);

  const openWhatsAppChat = (phoneNumber) => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hello,%20I'm%20interested%20in%20your%20product.`;
    window.open(whatsappLink, "_blank");
  };
  useEffect(() => {
    axios
      .get(
        `${baseurl}/api/product/id?id=${selectedProductId}&category=PROPERTIES`
      )
      .then((response) => {
        setProductDetails(response.data);
        console.log("ProductDetails", productDetails);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  }, [selectedProductId]);

  // if (!productDetails) {
  //   return <p>Loading...</p>;
  // }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/product/category?category=PROPERTIES`);
        if (response.data) {
          
        const totalCount = response.data.length;
          setTotalPages(Math.ceil(totalCount / rowsPerPage));
          const startIndex = (currentPage - 1) * rowsPerPage;
          const endIndex = startIndex + rowsPerPage;
          const paginatedData = response.data.slice(startIndex, endIndex);

          setProducts(paginatedData);     
        
        } else {
          console.error("No products found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleWishlist = () => {
    const autheader = getAuthToken();
    const userId = getUserId();

    axios
      .post(
        `${baseurl}/api/wishListAdd/id?id=${selectedProductId}&userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${autheader}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsAddedToWishlist(!isAddedToWishlist);
      })
      .catch((error) => {
        toast.error("Error adding to wishlist:", error);
      });
  };
  return (
    <div className="main-container">
      <div className="container ">
        <div className="discription-section row ">
          <div className="image-section col-md-6 border">
            {/* <div className="mainimage">
              <img className="mainimg1" src={mainimage} alt="mainimage" />
            </div> */}
            <_carousel showArrows={true} className="carousel" autoPlay>
              {productDetails?.images.map((image, index) => (
                <div key={index} className="p-2">
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </_carousel>{" "}
          </div>
          <div className="product-discription col-md-6  border">
            <div className="row">
              <div className=" description-col">
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3"> Title</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.adTitle}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Type</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.type}</span>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">PlotArea</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.plotArea}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">plotLength</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.plotLength}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">PlotBreadth</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.plotBreath}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">ProjectName</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.projectName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Price</span>
                  </div>
                  <div className="col-8">
                  <span >&#x20B9;{productDetails?.setPrice}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Number of open sides</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.numberOfOpenSides}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">
                      Width of road facing plot
                    </span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.widthOfRoadFacingPlot}</span>
                  </div>
                </div>               
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Any constrution done</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.anyConstrutionDone}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Boundry wall made</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.boundaryWallMade}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Is in gated colony</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.isInGatedColony}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Corner plot</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.thisIsCornerPlot}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Desciption</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.description}</span>
                  </div>
                </div>
              <div className=" description-col">
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Pin Code</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.pin}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">State</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.state}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">City</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.city}</span>
                  </div>
                </div>
                
               
              </div>

              <div className="productprice col-12 ">
                <div className="share-add">
                  <div className="add-wishlist text-end">
                  {isAddedToWishlist ? ( 
              <FavoriteIcon onClick={handleWishlist} style={{ color: "#940D19" }} />
            ) : (
              <FavoriteBorderIcon onClick={handleWishlist} />
            )}           
                     <span>Add to wishlist </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className=" row">
          <div className="seller-btn py-4 col-6">
            {/* <button className="call-sellerbtn">CALL SELLER</button> */}
            <a href={"tel:" + productDetails?.phoneNumber} className="">
            <Button variant="outlined" startIcon={<CallIcon />} >
              Call Now
            </Button>
        </a>
            {/* <button className="call-sellerbtn"><ChatIcon /></button> */}
            {/* <IconButton aria-label="call" size="medium">
              <CallIcon fontSize="medium" />
            </IconButton> */}
          </div>
          <div className=" col-6  seller-btn py-4 ">
            <div className="sellerbtn d-flex align-items-center">
              <Button
                variant="outlined" startIcon={<WhatsAppIcon />}
                onClick={() => openWhatsAppChat(productDetails?.phoneNumber)}
              >Whats App
                {/* <WhatsAppIcon color="success"/> */}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="section3 ">
        <div className="container">
          <div className="row ">
            <span className="related-productsSummary col-lg-3">
              RELATED PRODUCTS
            </span>
          </div>
          
          <div className="row ">
            <div className="container ">
              <NavLink to="/plots">
              <div className="row productSummary ">
                {products.map((item) => (
                <div className=" col-lg-3 col-sm-4">
                  <div className="card-related py-3 px-2 mb-2 border">
                    <div className="card-image-holder">
                      <img
                        className="card-image-holder1"
                        src={item.images[0]}
                        alt="..."
                      />
                    </div>
                    <div className="card-image-text-holder">
                      <abbr>
                        {item.description}
                      </abbr>
                      <br />
                      <abbr className="product-rate">₹ {item.setPrice}</abbr>
                    </div>
                  </div>
                </div>
                 ))}
               
              </div>
              </NavLink>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default PlotsSummary;
