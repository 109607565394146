
import { createContext, useContext, useState, useEffect } from 'react';
import * as auth from "../api/Auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());

  useEffect(() => {
    setIsAuthenticated(auth.isAuthenticated());
  }, [auth.getAuthToken()]);

  const login = (accessToken, userId) => {
    auth.setAuthToken(accessToken);
    auth.setUserId(userId);
    setIsAuthenticated(true);
  };
  
  const setAuthToken = (token) => {
    auth.setAuthToken(token);
    setIsAuthenticated(true);
  };

  const setUserId = (userId) => {
    auth.setUserId(userId);
  };


  const logout = () => {
    auth.logout();
    setIsAuthenticated(false);
  };
  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthToken,setUserId, logout,login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const contextValue = useContext(AuthContext);
  return contextValue;
};