import React from "react";
// import "./Help.css";
import "./Privacy.css";

const Tnc = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="card border-0 shadow">
            <div className="card-body">
              <div>
              <h2 className="card-title text-center mb-4">Terms and Conditions</h2>   
   {/* <p> Terms and Conditions</p> */}
<h3>Welcome to QuiksAle ! </h3>

<p>These terms and conditions lay out the rules and regulations for using the QUIKSALE website/platform.</p>

<p>By accessing this website, we assume that you agree to abide by these terms and conditions. If you do not agree to all the terms and conditions stated on this page, please refrain from using QUIKSALE.
The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice, and all Agreements: "Client," "You," and "Your" refer to you, the person logging on this website and compliant with the Company’s terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us" refer to our Company. "Party," "Parties," or "Us" refer to both the Client and us. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect to the provision of the Company’s stated services, in accordance with and subject to, the prevailing law of the Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they, is taken as interchangeable and, therefore, as referring to the same.</p>
<h3>Cookies</h3>
<p>We use cookies. By accessing QUIKSALE, you agree to the use of cookies in accordance with the QUIKSALE Privacy Policy.
Most interactive websites utilize cookies to retrieve user details for each visit. Cookies on our website enable the functionality of certain areas to make it easier for visitors. Some affiliate/advertising partners may also use cookies.</p>
<h3>License</h3>
Unless otherwise stated, QUIKSALE and/or its licensors own the intellectual property rights for all material on QUIKSALE. All intellectual property rights are reserved. You may access this material from QUIKSALE for your personal use, subject to the restrictions set in these terms and conditions.
<p>You must not:</p>
Republish material from QUIKSALE
Sell, rent, or sublicense material from QUIKSALE
Reproduce, duplicate, or copy material from QUIKSALE
Redistribute content from QUIKSALE
This Agreement begins on the date hereof.
Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas. QUIKSALE does not filter, edit, publish, or review Comments before their presence on the website. Comments do not reflect the views and opinions of QUIKSALE, its agents, and/or affiliates. QUIKSALE is not liable for Comments or any liability, damages, or expenses caused as a result of their use or posting on this website.
QUIKSALE reserves the right to monitor all Comments and to remove any considered inappropriate, offensive, or in breach of these Terms and Conditions.
<p>You warrant and represent that:</p>
You are entitled to post Comments on our website and have all necessary licenses and consents to do so.
The Comments do not invade any intellectual property right, including copyright, patent, or trademark of any third party.
The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material invading privacy.
The Comments will not be used to solicit or promote business, custom, or present commercial or unlawful activity.
You grant QUIKSALE a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit your Comments in any and all forms, formats, or media.
Hyperlinking to our Content
<p>The following organizations may link to our website without prior written approval:
Government agencies
Search engines
News organizations
Online directory distributors may link to our website like they hyperlink to other listed businesses
System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups
These organizations may link to our home page, publications, or other website information as long as the link is not deceptive, does not falsely imply sponsorship, endorsement, or approval of the linking party and its products/services, and fits within the context of the linking party’s site.
</p>
We may consider and approve other link requests from:
Commonly known consumer and/or business information sources
Dot.com community sites
Associations or other groups representing charities
Online directory distributors
Internet portals
Accounting, law, and consulting firms
Educational institutions and trade associations
We will approve link requests if we decide that the link would not make us look unfavorably to ourselves or our accredited businesses; the organization has no negative records with us; the benefit to us from the visibility of the hyperlink compensates the absence of QUIKSALE; and the link is in the context of general resource information.
<p>These organizations may link to our home page if the link is not deceptive, does not falsely imply sponsorship, endorsement, or approval of the linking party and its products/services, and fits within the context of the linking party’s site.
If you are one of the organizations listed above and wish to link to our website, inform us by sending an e-mail to info.quiksale.in and include your name, organization name, contact information, the URL of your site, a list of URLs from which you intend to link to our website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
Approved organizations may hyperlink to our website by using our corporate name, the uniform resource locator being linked to, or any other description of our website that makes sense within the context and format of content on the linking party’s site. No use of QUIKSALE's logo or other artwork is allowed for linking without a trademark license agreement.
</p>
<h3>iFrames</h3>
<p>Without prior approval and written permission, you may not create frames around our webpages that alter the visual presentation or appearance of our website.</p>

<h3>Content Liability</h3>
<p>We are not responsible for any content that appears on your website. You agree to protect and defend us against all claims rising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene, or criminal, or which infringes, violates, or advocates the infringement or violation of any third-party rights.
</p>

<h3>Reservation of Rights</h3>
<p>We reserve the right to request removal of all links or any particular link to our website. You agree to immediately remove all links to our website upon request. We also reserve the right to amend these terms and conditions and their linking policy at any time. By continuously linking to our website, you agree to be bound by and follow these linking terms and conditions.</p>

<h3>Removal of Links from Our Website</h3>
<p>If you find any link on our website offensive, contact and inform us. We will consider requests to remove links but are not obligated to do so or respond directly.
We do not ensure that the information on this website is correct, warrant its completeness or accuracy, nor promise that the website remains available or that the material on the website is kept up to date.</p>

<h3>Disclaimer</h3>
<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website.
<ul>Nothing in this disclaimer will:
<li>Limit or exclude our or your liability for death or personal injury.</li>
<li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation.</li>
<li>Limit any of our or your liabilities in any way not permitted under applicable law.</li>
</ul>
</p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Tnc;
