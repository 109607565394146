import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_BASE_URL } from '../../api/ApiService';
import { getAuthToken, getUserId } from '../../api/Auth';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import "./myadstable.css"

const MyAdsTable = () => {
  const baseurl = API_BASE_URL;
  const userId = getUserId();
  const autheader = getAuthToken();
  const [myAds, setMyAds] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [adToEdit, setAdToEdit] = useState(null);
  const [editedAd, setEditedAd] = useState({});

  useEffect(() => {
    const fetchAdsData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/product/myAds/${userId}`, {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        });

        setMyAds(response.data);
      } catch (error) {
        console.error('Error fetching wishlist data:', error);
      }
    };

    fetchAdsData();
  }, []);

  const handleDeleteClick = (ad) => {
    setAdToEdit(ad);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (adToEdit) {
        const response = await axios.delete(`${baseurl}/api/product/category?category=${adToEdit.category}&productId=${adToEdit.id}`, {
          headers: {
            Authorization: `Bearer ${autheader}`,
          },
        });

        if (response.status === 200) {
          setShowConfirmation(false);

          const updatedAdsResponse = await axios.get(`${baseurl}/api/product/myAds/${userId}`, {
            headers: {
              Authorization: `Bearer ${autheader}`,
            },
          });

          setMyAds(updatedAdsResponse.data);
        }
      }
    } catch (error) {
      console.error('Error deleting ad:', error);
    }
  };

  const handleEditClick = (ad) => {
    setAdToEdit(ad);
    setEditedAd(ad);
    setShowEditModal(true);
  };

  const handleUpdateClick = async () => {
    try {
      if (adToEdit) {
        const response = await axios.put(
          `${baseurl}/api/product/adpostUpdate/${adToEdit.id}`,
          editedAd,
          {
            headers: {
              Authorization: `Bearer ${autheader}`,
            },
          }
        );

        if (response.status === 200) {
          setShowEditModal(false);

          // Optionally, fetch the updated ads data and update the state
          const updatedAdsResponse = await axios.get(`${baseurl}/api/product/myAds/${userId}`, {
            headers: {
              Authorization: `Bearer ${autheader}`,
            },
          });

          setMyAds(updatedAdsResponse.data);
        }
      }
    } catch (error) {
      console.error('Error updating ad:', error);
    }
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    setAdToEdit(null);
    setEditedAd({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAd((prevAd) => ({
      ...prevAd,
      [name]: value,
    }));
  };

  return (
    <div className="cart-wrap mb-5">
      <div className="container pb-5">
        <div className="row">
          <div className="table-main-heading mb-10">My Ads</div>
        </div>
        <div className="table-scroll">
          {/* <div className="row border"> */}
            {/* <div className="col-md-12 wishlist-col border"> */}
            <div className="container">
              <div className="row myads-row py-3">
                <div className="col-3 text-center myads-column border">
                  <span className="myads-heading">Product </span>
                </div>
                <div className="col-3 text-center myads-column border">
                  <div className="myads-heading">Product Name </div>
                </div>
                <div className="col-2 text-center myads-column border">
                  <span className="myads-heading">Brand</span>
                </div>
                <div className="col-1 text-center myads-column border">
                  <span className="myads-heading">Status</span>
                </div>
                <div className="col-3 text-center myads-column border">
                  <span className="myads-heading">Actions</span>
                </div>
              </div>
              </div>
              <div className="container">
              {myAds.map((ad) => (
                <div key={ad.id} className="row py-3 myads-row">
                  <div className="col-3 myads-column border">
                    <div className=" ">
                      <img
                        src={ad.images[0]}
                        alt="Product Image"
                        className="price w-100"
                      />
                    </div>
                  </div>
                  <div className="col-3 myads-column border">
                   
                      <div className="img-product">
                        <span className="price">{ad.adTitle}</span>
                      </div>
                   
                  </div>
                  <div className="col-2 myads-column  border ">
                    <div>
                    <span className="price">{ad.brand}</span>
                    </div>
                  </div>
                  <div className="col-1 myads-column border ">
                    <div>
                    <span className="available-box">{ad.status}</span>
                    </div>
                  </div>
                  <div className="col-3 myads-column border  ">
                    <div className='d-flex justify-content-center align-items-center '>
                    <button
                      className="cart-button w-3 me-4"
                      style={{ height: "2rem" }}
                      onClick={() => handleEditClick(ad)}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className="cart-button w-4"
                      style={{ height: "2rem" }}
                      onClick={() => handleDeleteClick(ad)}
                    >
                      <DeleteIcon />
                    </button>
                    </div>
                  </div>
                </div>
              ))}
              </div>
            {/* </div> */}
          {/* </div> */}
        </div>
      </div>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Ad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Object.keys(editedAd).map((key) => (
              <Form.Group key={key} className="mb-3" controlId={key}>
                <Form.Label>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Form.Label>
                <Form.Control
                  type="text"
                  name={key}
                  value={editedAd[key]}
                  onChange={handleInputChange}
                />
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateClick}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the ad?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyAdsTable;