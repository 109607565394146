import React, { useEffect } from "react";
import Logo from "../Products/Images/Quick Sale Logo 1.png";
import "./Navbar.css";
import Person2Icon from "@mui/icons-material/Person2";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "../Profile/Popover";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Dropdown1 from "../dropdowns/Dropdown";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useAuth } from "../../Context/AuthContext";
import ListIcon from "@mui/icons-material/List";
import { API_BASE_URL } from "../../api/ApiService";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LogoutIcon from '@mui/icons-material/Logout';
// global input
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';

// sidebar
import Offcanvas from "react-bootstrap/Offcanvas";
import SearchToggle from "../Mobile-menu/SearchToggle";
import { Nav } from "react-bootstrap";
import Searchbar from "../Products/SearchBar";
// import AutoComplete from "./PlaceSearch";
import { useSearch } from '../../Context/SearchContext';
import MobileMenuSearchBar from "../Mobile-menu/MobileMenuSearch";
import MobileSearchBarListPage from "../Mobile-menu/MobileSearchBarListPage";

const Navbar = () => {

  const [age, setAge] = React.useState("");

  const [isToggleSearchMobilemenuShow, setIsToggleSearchMobileMenuShow] = useState(false)

  const { isShowSearchPage, setIsShowSearchPage, setSearchQuery, searchQuery } = useSearch()

  useEffect(() => {
    console.log("=========== isShowSearchPage == Navbar", isShowSearchPage)
  }, [isShowSearchPage])

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const toggleSearchMobileMenuShow = () => {
    //console.log("=========== toggleSearchMobileMenuShow == Navbar = isShow ", isShow)
    setIsToggleSearchMobileMenuShow(!isToggleSearchMobilemenuShow)
   // setSearchItem("")
    console.log("=========== isToggleSearchMobilemenuShow == Navbar", isToggleSearchMobilemenuShow)
  }

  const toggleSearchMenuClick = () => {
    console.log("===== clickeddd search menu toggle")
  }

  const toggleOnChangeSearch = (e) => {
    console.log("===== toggleOnChangeSearch", e.target.value)
   // setSearchItem(e.target.value)
  }

  const baseurl = API_BASE_URL;
  // sidebar function
  function OffCanvasExample({ name, ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <button
          variant="primary"
          onClick={handleShow}
          className="text-center"
        >
          <MenuIcon />
        </button>
        <Offcanvas show={show} onHide={handleClose} {...props} className="mobile-offcanvas w-100  bg-gradiant">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="mx-auto my-2" onClick={(e) => {
              e.preventDefault();
              HomeHandler();
            }}>
              <img src={Logo} ></img>
            </Offcanvas.Title>
          </Offcanvas.Header>
          {/* MobileViewHambuger */}
          <Offcanvas.Body>  
            <div >
              <ul className="  my-3 px-0 offcanvas-navbar " >
                <li className="navbar-item  my-1">
                  <a
                    className="navbar-link "
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      SellPageHandler();
                    }}
                  >
                    Sell &#43;
                  </a>
                </li>
                <Link to="/profile">
                  <li className="navbar-item  my-1">
                    <a
                      className="navbar-link "
                    >
                      My Profile
                    </a>
                  </li>
                </Link>

                <li className="navbar-item  my-1">
                  <Link to="/sellother" className="navbar-link  ">
                    {" "}
                    Other Sell{" "}
                  </Link>

                </li>
                <li className="navbar-item ">
                  <a
                    className="navbar-link "
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      HotOfferHandler();
                    }}
                  >
                    Hot offers
                  </a>
                </li>

                <li className="navbar-item my-2">
                  <a
                    className="navbar-link "
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      AboutPageHandler();
                    }}
                  >
                    About us
                  </a>
                </li>
                <li className="navbar-item my-2">
                  <a
                    className="navbar-link my-1"
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      ContactPageHandler();
                    }}
                  >
                    contact us
                  </a>
                </li>
                <li className="navbar-item my-2">
                  <a
                    className="navbar-link "
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      BlogsPageHandler();
                    }}
                  >
                    Blogs
                  </a>
                </li>
                <li className="navbar-item my-2">

                  <a
                    className="navbar-link "
                    href="."
                    onClick={(e) => {
                      e.preventDefault();
                      HelpPageHandler();
                    }}
                  >
                    Help
                  </a>
                </li>

              </ul>
              <ul className="offcanvas-list d-flex justify-content-evenly pb-5 pt-3 ps-0  " >
                {!isAuthenticated ? (
                  <>
                    <li className="navbar-item">
                      <NavLink to="/signin">
                        <a className="navbar-link" href=".">
                          <div className="profile-container">
                            <div className="offcanvas-profile text-center w-100">
                              <Person2Icon />
                            </div>
                            <span className="offcanvas-text">Login</span>
                          </div>
                        </a>
                      </NavLink>
                    </li>
                    <li className="navbar-item">
                      <NavLink to="/signup">
                        <a className="navbar-link" href=".">
                          <div className="profile-container text-center">
                            <div className="offcanvas-profile w-100">
                              <Person2Icon />
                            </div>
                            <span className="offcanvas-text">Sign Up</span>
                          </div>
                        </a>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li className="navbar-item">
                    <NavLink to="/signup">
                      <a className="navbar-link" href=".">
                        <div className="profile-container text-center">
                          <div className=" w-100">
                            <LogoutIcon onClick={handleLogout} />
                          </div>
                          <span className="">Logout</span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
  // End Hamb

  const { isAuthenticated, logout } = useAuth();
  const handleLogout = () => {
    logout();
    Navigate("/signin");
  };
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [
    { name: "Maruti Suzuki", code: "AU" },
    { name: "Hyundai", code: "BR" },
    { name: "Tata Motors", code: "CN" },
    { name: "Mahindra", code: "EG" },
    { name: "Honda", code: "FR" },
    { name: "Skoda", code: "DE" },
    { name: "Volkswagen", code: "IN" },
    { name: "Renault", code: "JP" },
    { name: " Toyota", code: "ES" },
    { name: "Nissan", code: "US" },
    { name: "Samsung", code: "US" },
    { name: "Vivo", code: "US" },
    { name: "OnePlus", code: "US" },
    { name: "Realme", code: "US" },
  ];

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center justify-content-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };
  // const [searchTerm, setSearchTerm] = useState("");
  // const handleSearch = (e, value) => {
  //   setSearchTerm(value);
  //   console.log(searchTerm);
  // }
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    dispatch({ type: 'setSearch', payload: e.target.value });
  }

  const Navigate = useNavigate();

  const HomeHandler = () => {
    Navigate("/");
  };

  const AboutPageHandler = () => {
    Navigate("/about");
  };
  const wishListHandler = () => {
    Navigate("/WishList");
  };
  const cartHandler = () => {
    Navigate("/Cart");
  };

  const ProductHandler = () => {
    Navigate("/products");
  };

  const ContactPageHandler = () => {
    Navigate("/contact");
  };

  const SellPageHandler = () => {
    Navigate("/sellsection");
  };
  const BlogsPageHandler = () => {
    Navigate("/blogs");
  };
  const OthersPageHandler = () => {
    Navigate("/others");
  };
  const FreePostHandler = () => {
    Navigate("/sellSection");
  };
  const HelpPageHandler = () => {
    Navigate("/help");
  };
  const HotOfferHandler = () => {
    Navigate("/hotoffers");
  };
  const CarPageHandler = () => {
    Navigate("/cars");
  };
  const BikePageHandler = () => {
    Navigate("/Bikes");
  };
  const ElectronicsPageHandler = () => {
    Navigate("/electronics");
  };
  const MobilePageHandler = () => {
    Navigate("/bikes");
  };

  const LaptopPageHandler = () => {
    Navigate("/cars");
  };
  const FurniturePageHandler = () => {
    Navigate("/furniture");
  };
  const HouseApartmentsPageHandler = () => {
    Navigate("/cars");
  };
  const PlotsPageHandler = () => {
    Navigate("/plot");
  };
  const OtherPageHandler = () => {
    Navigate("/plot");
  };
  
  return (
    <div>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12 col col-lg-5">
              <div className="header-top-welcome d-flex">
                <NavLink to="/" classNam="w-100">
                  <p className="welcome-note w-100">Welcome to QuiksAle</p>
                </NavLink>

                <p></p>
              </div>
            </div>
            <div className="col-md-12 col-12 col col-lg-7 d-lg-block d-none">
              <ul className="header-top-list mb-0">
                <li className="vertical-line ms-0 ps-0 text-center">
                  <LocationOnIcon />
                  <a href="offer.html" className="font-11">
                    Bengaluru
                  </a>
                </li>

                <li className="ms-1 text-center">
                  <NavLink to="/alloffers">
                    <a href="" className="font-11">
                      <LocalOfferIcon />
                      All Offers
                    </a>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page Top End -->
    <!-- Header Part Start --> */}
      <header className="header-part d-none d-lg-block">
        <div className="container">
          <div className="header-content row  ">
            <div className="col-lg-2 col-md-2  ">
              <a
                href="."
                className="header-logo"
                onClick={(e) => {
                  e.preventDefault();
                  HomeHandler();
                }}
              >
                <img src={Logo} alt="logo" />
              </a>
            </div>

            <div className="col-lg-7 col-md-8  ">
              <div className="row ">
                <div className="col-lg-5">
                  <div className="flex justify-content-center">
                    {/* <AutoComplete /> */}
                    <span
                      className="p-input-icon-left d-flex"
                      style={{
                        width: "100%",
                        border: "1px solid #940D19",
                        borderRadius: "32px",
                      }}
                    >
                      {/* <span
                      className="input-group-text border-0 bg-transparent"
                      id="addon-wrapping"
                    >
                      <SearchIcon />{" "} */}
                      {/* </span> */}
                      <InputText
                        placeholder="Search by city"
                        style={{ width: "100%" }}
                      />
                    </span>

                  </div>
                </div>
                <div className="col-lg-7  pe-3 ">
                  <span
                    // className="p-input-icon-left d-flex"
                    // style={{
                    //   width: "100%",
                    //   border: "1px solid #940D19",
                    //   borderRadius: "32px",
                    // }}
                  >
                    {/* <span
                      className="input-group-text border-0 bg-transparent"
                      id="addon-wrapping"
                    >
                      <SearchIcon />{" "}
                    </span> */}
                    {/* <InputText
                      placeholder="Search by categories"
                      style={{ width: "100%" }}
                      onChange={handleSearch}
                      /> */}
                    <Searchbar />

                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3   ">
              <div className="  d-flex justify-content-between">
                <button
                  className="header-button "
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                    HotOfferHandler();
                  }}
                >
                  Hot offers
                </button>
                <button
                  className="header-button "
                  title=""
                  onClick={(e) => {
                    e.preventDefault();
                    FreePostHandler();
                  }}
                >
                  Free Post
                </button>
              </div>
            </div>

            {/* <div className="col-sm-1 col-md-1 pe-lg-3 ps-lg-0 ">
              
            </div> */}
          </div>
        </div>
      </header>
      {/* <!-- Header Part End -->
    <!-- Header Part for Mobile  --> */}
      <header className="header-part d-md-block d-lg-none">
        <div className="container">
          <div className="header-content row">
            {/* <div className="col-2">
              <div className="d-inline-flex gap-1">
                <button
                  className="btn  header-src p-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa-solid fa-magnifying-glass d-flex justify-content-center"></i>
                </button>
              </div>
            </div> */}

            {/* <div className="col-1">
              <div className="dropdown d-lg-none ">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-mdb-toggle="collapse"
                  aria-label="Toggle navigation"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  data-mdb-target="#navbarSupportedContent"
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>
            </div> */}
            <div className="col-4 text-center">
              {["start"].map((placement, idx) => (
                <OffCanvasExample
                  key={idx}
                  placement={placement}
                  name={placement}
                />
              ))}
            </div>
            <div className="col-5 text-center">
              <a
                href="index.html"
                className="header-logo"
                onClick={(e) => {
                  e.preventDefault();
                  HomeHandler();
                }}
              >
                <img src={Logo} alt="logo" className="w-50" />
              </a>
            </div>
            {/* <div className="col-3">
              <SearchToggle toggleSearchMenu={toggleSearchMobileMenuShow} />
            </div> */}
            {/* { isToggleSearchMobilemenuShow &&  <Searchbar/>   } */}
            <div style={{position: 'relative'}}>
            {isShowSearchPage ? <MobileSearchBarListPage/> : <MobileMenuSearchBar/>}
            </div>
          </div>
    
          {/* <div className="collapse" id="collapseExample">
            <form className="header-form active">
              <input type="text" placeholder="Search Here..." />
              <button>
                <FontAwesomeIcon icon={faSearch} className="me-4" />
              </button>
            </form>
          </div> */}
        </div>
      </header>
      {/* <!-- Header Part for Mobile End-->

    <!-- Navbar-1 Start  --> */}
      <nav className="navbar-part navbar-part-one border-bottom border-top d-none d-lg-block ">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-7 col-5">
              <div className="navbar-content">
                <ul className="navbar-list m-0 ps-0 d-none d-lg-flex">
                  <li className="navbar-item ">
                    <a
                      className="navbar-link"
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}
                    >
                      {/* All category */}
                    </a>
                  </li>

                  <li className="navbar-item ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        AboutPageHandler();
                      }}
                    >
                      About us
                    </a>
                  </li>
                  <li className="navbar-item ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        ContactPageHandler();
                      }}
                    >
                      Contact us
                    </a>
                  </li>
                  <li className="navbar-item ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        BlogsPageHandler();
                      }}
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="navbar-item">

                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        HelpPageHandler();
                      }}
                    >
                      Help
                    </a>
                  </li>

                  <li className="navbar-item dropdown">
                    <a
                      className="navbar-link nav-red-btn"
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        SellPageHandler();
                      }}
                    >
                      Sell &#43;
                    </a>
                  </li>
                  <li className="navbar-item dropdown">
                    <Link to="/sellother" className="navbar-link nav-other-btn">
                      {" "}
                      Other Sell{" "}
                    </Link>
                    {/* <a
                      className="navbar-link nav-red-btn"
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        SellPageHandler();
                      }}
                    >
                      Sell &#43;
                    </a> */}
                  </li>
                </ul>
                {/* <!-- horizontal navbar navbar dropdown --> */}
                {/* <div className="dropdown d-lg-none ">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    All category
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          ProductHandler();
                        }}
                      >
                        All category
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          HotOfferHandler();
                        }}
                      >
                        Hot offers
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          AboutPageHandler();
                        }}
                      >
                        About us
                      </a>
                    </li>
                    <li className="navbar-link">
                      <a
                        className="dropdown-item"
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          ContactPageHandler();
                        }}
                      >
                        contact us
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="."
                        onClick={(e) => {
                          e.preventDefault();
                          BlogsPageHandler();
                        }}
                      >
                        Blogs
                      </a>
                    </li>
                  </ul>
                </div> */}
                {/* <!-- horizontal navbar navbar dropdown end--> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-7">
              <div className="navbar-content">
                <ul className="navbar-list m-0 ps-0 ">
                  <li className="navbar-item ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        // ProfileHandler();
                      }}
                    >
                      <NavLink>
                        <div className="profile-container">
                          <div className="profile text-center w-100">
                            <Person2Icon />
                          </div>

                          {isAuthenticated ? (
                            <div className="popover-container">
                              <Popover />
                            </div>
                          ) : (
                            <NavLink to="/signin">
                              {/* <button>Login</button> */}
                              <span className="profile-text">Login</span>
                            </NavLink>
                          )}
                        </div>
                      </NavLink>
                    </a>
                  </li>

                  {/* <li className="navbar-item "> */}
                  <a
                    className="navbar-link "
                    href="."
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   cartHandler();
                  // }}
                  >
                    {/* <NavLink to="/signup">
                        <div className="profile-container text-center">
                          <div className="profile w-100">
                            <Person2Icon />
                          </div>
                          <span className="profile-text">Sign Up</span>
                        </div>
                      </NavLink> */}
                  </a>
                  {/* </li> */}
                  <li className="navbar-item ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        wishListHandler();
                      }}
                    >
                      <div className="profile-container text-center">
                        <div className="profile w-100">
                          <FavoriteIcon />
                        </div>
                        <span className="profile-text">Wishlist</span>
                      </div>
                    </a>
                  </li>
                  {isAuthenticated ?
                    null : (
                      // User is not authenticated, render the Sign Up button
                      <li className="navbar-item ">
                        <NavLink to="/signup">
                          <a className="navbar-link " href=".">
                            <div className="profile-container text-center">
                              <div className="profile w-100">
                                <Person2Icon />
                              </div>
                              <span className="profile-text">Sign Up</span>
                            </div>
                          </a>
                        </NavLink>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- Navbar-1 End -->
    <!-- Navbar-2 Start  --> */}
      <nav className="navbar-part navbar-part-two pt-4 pb-2 d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="navbar-content pt-4">
                <ul className="navbar-list ps-0">
                  <li className="navbar-item-two  ">
                    <a
                      className="navbar-link "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}
                    >
                      All Categories
                    </a>
                  </li>

                  {/* </li> */}
                  <li className="navbar-item-two ">
                    {/* <a
                      className="navbar-link  "
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}
                    >
                     Cars
                    </a> */}
                    <Link to="/cars" className="navbar-link ">
                      {" "}
                      Cars
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    <Link to="/Bikes" className="navbar-link ">
                      {" "}
                      Bikes
                    </Link>
                  </li>
                  {/* <li className="navbar-item-two ">
                    <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      Electronics
                    </a>
                  </li> */}
                  <li className="navbar-item-two ">
                    <Link to="/Electronicsproduct" className="navbar-link ">
                      {" "}
                      Electronics
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    {/* <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      Mobiles
                    </a> */}
                    <Link to="/mobiles" className="navbar-link ">
                      {" "}
                      Mobiles
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    {/* <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      Laptop
                    </a> */}
                    <Link to="/laptops" className="navbar-link ">
                      {" "}
                      Laptops
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    {/* <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      Furniture
                    </a> */}
                    <Link to="/furnitures" className="navbar-link ">
                      {" "}
                      Furniture
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    {/* <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      House and appaertments
                    </a> */}
                    <Link to="/houseandapartments" className="navbar-link ">
                      {" "}
                      House & Apartments
                    </Link>
                  </li>
                  <li className="navbar-item-two ">
                    {/* <a className="navbar-link " href="#" onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}>
                      plots
                    </a> */}
                    <Link to="/plots" className="navbar-link ">
                      {" "}
                      Plots
                    </Link>
                  </li>
                  <li className="navbar-item-two dropdown-megamenu ">
                    {/* <a
                      className="navbar-link  text-red weight700"
                      href="."
                      onClick={(e) => {
                        e.preventDefault();
                        ProductHandler();
                      }}
                    >
                     Other Sell 
                    </a> */}
                    <Link to="/Others" className="navbar-link ">
                      Others
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;