import React, { useState, useEffect } from "react";
import "./ProductSummary.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useParams } from "react-router";
import axios from "axios";
import { getUserId } from "../../api/Auth";
import { API_BASE_URL } from "../../api/ApiService";
import { Carousel as _carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { getAuthToken } from "../../api/Auth";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import FavoriteIcon from "@mui/icons-material/Favorite"; 
import { isAuthenticated } from "../../api/Auth"; 
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatIcon from '@mui/icons-material/Chat';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';

const BikeSummary = () => {
  const baseurl = API_BASE_URL;
  const { selectedCategory, selectedProductId } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");

  
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(1);
  const [products,setProducts] = useState([]);

  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false); 



  const openWhatsAppChat = (phoneNumber) => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hello,%20I'm%20interested%20in%20your%20product.`;
    window.open(whatsappLink, "_blank");
  };

  useEffect(() => {
    axios
      .get(`${baseurl}/api/product/id?id=${selectedProductId}&category=BIKE`)
      .then((response) => {
        setProductDetails(response.data);
        console.log("ProductDetails", productDetails);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  }, [selectedProductId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/product/category?category=BIKE`);
        if (response.data) {
          
        const totalCount = response.data.length;
          setTotalPages(Math.ceil(totalCount / rowsPerPage));
          const startIndex = (currentPage - 1) * rowsPerPage;
          const endIndex = startIndex + rowsPerPage;
          const paginatedData = response.data.slice(startIndex, endIndex);

          setProducts(paginatedData);     
        
        } else {
          console.error("No products found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleWishlist = () => {
    const autheader = getAuthToken();
    const userId = getUserId();


    if (!isAuthenticated()) {
      toast.error('Please login to add to wishlist');
      return; 
    }

    axios
      .post(
        `${baseurl}/api/wishListAdd/id?id=${selectedProductId}&userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${autheader}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsAddedToWishlist(!isAddedToWishlist);

      })
      .catch((error) => {
        toast.error("Error adding to wishlist:", error);
      });
  };
  return (
    <div className="main-container">
      <div className="container">
        <div className="discription-section row ">
          <div className="image-section col-md-6 border">
            {/* <div className="mainimage">
              <img className="mainimg1" src={mainimage} alt="mainimage" />
            </div> */}
            <_carousel showArrows={true} className="carousel" autoPlay>
              {productDetails?.images.map((image, index) => (
                <div key={index} className="p-2">
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </_carousel>{" "}
          </div>
          <div className="product-discription col-md-6  border">
            <div className="row">             
              <div className=" description-col">
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Title</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.adTitle}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Brand </span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.brand}</span>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Year</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.year}</span>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Fuel</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.fuel}</span>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">EngineCapacity</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.engineCapacity}</span>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Color</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.color}</span>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Transmission</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.transmission}</span>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">kmDriven</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.kmDriven}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Owners</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.owners}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Price</span>
                  </div>
                  <div className="col-8">
                    <span >&#x20B9;{productDetails?.setPrice}</span>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">BodyType</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.bodyType}</span>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Description</span>
                  </div>
                  <div className="col-8">
                    <span> {productDetails?.description}</span>
                  </div>
                </div>
               
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">Pin Code</span>
                  </div>
                  <div className="col-8">
                    <span>{productDetails?.pin}</span>
                  </div>
                </div>
              </div>
              <div className="   description-col">
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">State</span>
                  </div>
                  <div className="col-8">{productDetails?.state}</div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">City</span>
                  </div>
                  <div className="col-8">
                    <span> {productDetails?.city}</span>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-4">
                    <span className="fw-bold me-3">ListedBy</span>
                  </div>
                  <div className="col-8">
                    <span> {productDetails?.review_by}</span>
                  </div>
                </div>
              </div>
                        
              <div className="productprice col-12 ">               
                <div className="share-add">
                  <div className="add-wishlist text-end">
                  {isAddedToWishlist ? ( 
              <FavoriteIcon onClick={handleWishlist} style={{ color: "#940D19" }} />
            ) : (
              <FavoriteBorderIcon onClick={handleWishlist} />
            )}
                    <span>Add to wishlist </span>
                  </div>                  
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className=" row">
          <div className="seller-btn py-4 col-6">
            {/* <button className="call-sellerbtn">CALL SELLER</button> */}
            <a href={"tel:" + productDetails?.phoneNumber} className="">
            <Button variant="outlined" startIcon={<CallIcon />} >
              Call Now
            </Button>
        </a>
            {/* <button className="call-sellerbtn"><ChatIcon /></button> */}
            {/* <IconButton aria-label="call" size="medium">
              <CallIcon fontSize="medium" />
            </IconButton> */}
          </div>
          <div className=" col-6  seller-btn py-4 ">
            <div className="sellerbtn d-flex align-items-center">
              <Button
                variant="outlined" startIcon={<WhatsAppIcon />}
                onClick={() => openWhatsAppChat(productDetails?.phoneNumber)}
              >Whats App
                {/* <WhatsAppIcon color="success"/> */}
              </Button>
            </div>
          </div>
        </div>
      </div>     
      <div className="section3 ">
        <div className="container">
          <div className="row ">           
            <span className="related-productsSummary col-lg-3 ">
              RELATED PRODUCTS
            </span>
          </div>
          <div className="row ">
            <div className="container ">
              <NavLink to="/Bikes">
              <div className="row productSummary">
                {products.map((item) => (
                <div className=" col-lg-3  col-sm-4">
                  <div className="card-related py-3 px-2 mb-2 border">
                    <div className="card-image-holder">
                      <img
                        className="card-image-holder1"
                        src={item.images[0]}
                        alt="..."
                      />
                    </div>
                    <div className="card-image-text-holder">
                      <abbr>
                        {item.description}
                      </abbr>
                      <br />
                      <abbr className="product-rate">₹ {item.setPrice}</abbr>
                    </div>
                  </div>
                </div>
                 ))}
               
              </div>
              </NavLink>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  );
};

export default BikeSummary;
