import React, { useState, useEffect } from "react";
import watcheImage from '../../../Images/watch.png';
import HeadphoneImage from "../../../Images/gaming-set.png";
import laptop from "../../../Images/laptop.png";
import canon from "../../../Images/canon.png";
import Gopro from "../../../Images/camera.png";

import {NavLink} from "react-router-dom";
import { Camera, Phone } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import axios from "axios";
import { API_BASE_URL } from "../../../api/ApiService";


// import "./Main-section.css";

const MainSectionCard = () => {
  const baseurl =  API_BASE_URL;

  const [sectionData, setSectionData]  = useState([]);
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
        },
      };

   
      useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}/api/offer/hotOffer`               
                );

                setSectionData(response.data);
                console.log("sectiondata", response.data);
            } catch (error) {
                console.error('Error fetching wishlist data:', error);
            }
        };

        fetchData();
    }, []);


  return (
    <>
      <div className="sale-section mt-2 mb-4">
        <div className="container">          
          <div className="row border">
            <div className="col-lg-2 ">
              <div className=" saleitems-container text-center">
                <div className="dealsandoffer-container ">
                  <span className="offer">Deals and offers</span>
                  <br />
                  <span className="offer-item">Exiciting premium offerings available soon in </span>
                </div>
                <div className="countdown-container">
                  <div className="countdown-subcontainer">
                    <span className="countdown-number">2</span>
                    <span className="countdown-text">Weeks</span>
                  </div>
                  {/* <div className="countdown-subcontainer">
                    <span className="countdown-number">13</span>
                    <span className="countdown-text">Hour</span>
                  </div>
                  <div className="countdown-subcontainer">
                    <span className="countdown-number">34</span>
                    <span className="countdown-text">Min</span>
                  </div>
                  <div className="countdown-subcontainer">
                    <span className="countdown-number">56</span>
                    <span className="countdown-text">Sec</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-10 ">
              <Carousel
                responsive={responsive}
                infinite={true}                
                // removeArrowOnDeviceType={["tablet", "mobile","desktop"]}  
                autoPlay              
              >
                {sectionData.map((item) => (
                <div className="saleitems-container col-6 col-md-2">
                 
                  <div className="saleitemimg-container mb-2">
                    <img src={item.images} alt="watch" />
                  </div>
                  
                  <div className="saleitemdetail-container">
                    <span>{item.description}</span>
                    <button>
                      <span>-{item.offerDiscount}</span>
                    </button>
                  </div>
                </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default MainSectionCard;