import React, { useState, useEffect } from "react";
import "./Main-section.css";
import "./Home.css";
import { NavLink, useNavigate } from "react-router-dom";
import Card from "../Products/CardOne";
import Faq from "./Faq/Faq";
import MainSectionCard from "./MainSectionCard/MainSectionCard";
import CategoryAdd from "./CategoryandAdd-Section/CategoryAdd";
import TrendingArticles from "./TrendingArticles/TrendingArticles";
import GrabDeals from "./GrabBestDeals/GrabDeals";
import OffersSection from "./Offers/OffersSection";
import MarqueeSection from "./MarqueeSection";
import ReviewSection from "./ReviewSection";
import axios from "axios";
import { API_BASE_URL } from "../../api/ApiService";
import {useSearch}  from '../../Context/SearchContext'; 
import useDebounce from "../../hooks/useDebounce";


const Home = () => {
  const baseurl = API_BASE_URL;

  const Navigate = useNavigate();
  const ProductHandler = () => {
    Navigate("/products");
  };


  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [productCount, setProductCount] = useState(12);

    // Search Context 
    const {searchItem, searchQuery, setSearchQuery}  = useSearch()
    const [filteredProduct, setFilteredProduct] = useState([])

    // debounce search hooks

    const debouncedSearch = useDebounce(searchQuery, 1000);

    
  useEffect(() => {
    if (debouncedSearch) {
      fetchFiteredProduct()
    } else {
      fetchData();
    }

  }, [debouncedSearch]);

  // const showFilterProduct = () => {
  //   if (displayedProducts && Array.isArray(displayedProducts)) {
  //     let filteredProducts = displayedProducts
  //     if (searchItem) {
  //       filteredProducts = filteredProducts.filter(
  //           item =>
  //           item.adTitle.toLowerCase().includes(searchItem.toLowerCase()) ||
  //           item.category.toLowerCase().includes(searchItem.toLowerCase()) ||
  //           item.city.toLowerCase().includes(searchItem.toLowerCase())

  //         );    

  //         setFilteredProduct(filteredProducts.length > 0 ? filteredProducts : displayedProducts);
  //       } 
  //       } else {
  //         setFilteredProduct(displayedProducts)
  //       }

  // }

//   const filteredProducts = displayedProducts.filter((product) =>
//   //product.brand.toLowerCase().includes(searchItem.toLowerCase()) ||
//   product.adTitle.toLowerCase().includes(searchItem.toLowerCase()) ||
//   product.category.toLowerCase().includes(searchItem.toLowerCase()) ||
//   product.city.toLowerCase().includes(searchItem.toLowerCase())

// );

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseurl}/api/product/getTopCategoriesByNewest?limit=${productCount}`
      );
      if (response.data) {
        setProducts(response.data);
        setDisplayedProducts(response.data.slice(0, productCount));
        setFilteredProduct(response.data.slice(0, productCount))
      } else {
        console.error("No products found in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

    // Function to fetch suggestions from the backend
    const fetchFiteredProduct = async () => {
      // console.log("===== fetchSuggestions searchQuery === Home.js ",searchQuery);
      // console.log("===== fetchSuggestions debouncedSearch === Home.js ",debouncedSearch);

      try {
        const response = await axios.get(`https://quiksale.in:8087/api/search?query=${debouncedSearch}`);
        console.log(response);
        setFilteredProduct(response.data)
        // console.log("===== fetchSuggestions response === Home.js ",response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        // console.log("===== fetchSuggestions error === Home.js ",error);
      }
    };

  const loadMoreProducts = () => {
    const newProductCount = productCount + 12;
    setProductCount(newProductCount);
    setDisplayedProducts(products.slice(0, newProductCount));
    setFilteredProduct(products.slice(0, newProductCount))
  };

  


  return (
    <div>
      {/* Add and Category Section */}
      <div>
        <CategoryAdd />
      </div>
      {/* offers section  */}
      <OffersSection />
      {/* Main section card */}
      <div>
        <MainSectionCard />
      </div>
      {/* Card Section */}

      <div style={{ background: "#F5F5F5" }}>
        <div className="container">
          <div className="top-filter row">
            <div className="filter-show col-9">
              <label className="filter-label">
                <span className="filter-label-one">Shop From</span>{" "}
                <span className="filter-label-two">Top Categories</span>
              </label>
            </div>
            <div className="filter-short col-3">
              <NavLink to="/products">
              <label className="filter-view" style={{fontSize: "15px"}}>View All</label>
              </NavLink>
            </div>
          </div>
        </div>

        <div >
          <div className=" product-thirdrow ">
            <section className="inner-section shop-part categories-section mt-3 mb-2 pb-5">
              <div className="container">
                <div className="row  row-cols-md-2 row-cols-lg-3 row-cols-xl-3">
                {filteredProduct.length > 0 ? (
                    filteredProduct.map((product) => (
                      <Card key={product.id} selectedCategory={product.category} product={product} />
                    ))
                  ) : (
                    <p>No Products Found</p>
                  )}
                </div>
              </div>
              <div className="text-center mt-4">
        <button className="btn btn-primary" onClick={loadMoreProducts}>
          Load More
        </button>
      </div>

            </section>
          </div>
        </div>
      </div>

   

      {/* Best Deals Section  */}
      <div>
        <GrabDeals />
      </div>
       {/* marquee section */}
       <div className="container">
        <MarqueeSection />
      </div>
      {/* TrendingArticles Section */}
      <div>
        <TrendingArticles />
      </div>
     
      {/* review section */}
      <ReviewSection />

      {/* Faq Section */}
      <div>
        <Faq />
      </div>
      {/* About Us Section */}
      <div>
        {/* <About/> */}
      </div>


    </div>
  );
};

export default Home;
