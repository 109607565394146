import React, { useState, useEffect } from "react";
import "./Products.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
// import Card from "./Card";
import Pagination from "@mui/material/Pagination";
import Sidebar from "./SideBar/Sidebar";
// import SearchIcon from '@mui/icons-material/Search';
import "./Card.css";
import { useNavigate } from "react-router-dom";
import Card from "./CardOne";
import axios from "axios";
import CarSidebar from "./SideBar/CarSidebar";
import { API_BASE_URL } from "../../api/ApiService";
import { getUserId } from "../../api/Auth";
import OthersSideBar from "./SideBar/OthersSideBar";
import { Helmet } from "react-helmet";


// const options = ["Newest", "Most Popular", "Lowest Price"];
const Others = () => {

  const [searchValue, setSearchValue] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [searchCity, setSearchCity] = useState("");


  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(1);
  const Navigate = useNavigate();

  const [products, setProducts] = useState([]);
  // const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [priceRange, setPriceRange] = useState([50, 1000000]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [Carcategory, SetCarcategory] = useState([]);
  const [priceMatches, SetpriceMatches] = useState([]);
  const [submittedPriceRange, setSubmittedPriceRange] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);



  const Carcategories = [
    "CAMERA",
    "TV",
    "SPORTS",
    "FASHION",
    "PETS",
    
  ];

  const checkboxCity = [
    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Mumbai', value: 'Mumbai' },
    { label: 'Jaipur', value: 'Jaipur' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Kochi', value: 'Kochi' },
    { label: 'Hyderabad', value: 'Hyderabad' },

  ];

  const checkboxData = [
    { label: 'Apple', value: 'Apple' },
    { label: 'Microsoft', value: 'Microsoft' },
    { label: 'Dell', value: 'Dell' },
    { label: 'Symphony', value: 'Symphony' },
    { label: 'Sony', value: 'Sony' },
    { label: 'LG', value: 'LG' },
    { label: 'One Plus', value: 'One Plus' },
    { label: 'Google', value: 'Google' },
    { label: 'Samsung', value: 'Samsung' },
    { label: 'HP', value: 'HP' },
    { label: 'Xiaomi', value: 'Xiaomi' },
    { label: 'Panasonic', value: 'Panasonic' },
    { label: 'Intel', value: 'Intel' },

  ];


  const tagData = [
    'Game',
    'iPhone',
    'TV',
    'Asus Laptop',
    'Macbook',
    'SSD',
    'Graphic Card',
    'Power bank',
    'Smart TV',
    'Speaker',
    'Tablet',
    'Microwave',
    'Samsung',
  ];


  
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const priceMatch = [ 
  //   { label: "2Lac to 3Lac", value: "200000 to 300000" },
  //   { label: "3Lac to 4Lac", value: "300000 to 400000" },
  //   { label: "4Lac to 5Lac", value: "400000 to 500000" },
  //   { label: "5Lac to 6Lac", value: "500000 to 600000" },
  //   { label: "7Lac to 8Lac", value: "600000 to 700000" },
  //   { label: "8Lac to 9Lac", value: "900000 to 1000000" },
  //   {label: "9Lac and Above", value: "900000 to 10000000"}

  // ];

  const Label = ["Brands"];
  const YearOfPurchase = ["POPULAR BRANDS"];
  const FuelType = ["POPULAR TAGS"]
  const baseurl = API_BASE_URL;
  const userId = getUserId();



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/product/category?category=OTHER`);
        if (response.data) {
          const totalCount = response.data.length;
          setTotalPages(Math.ceil(totalCount / rowsPerPage));
          const startIndex = (currentPage - 1) * rowsPerPage;
          const endIndex = startIndex + rowsPerPage;
          const paginatedData = response.data.slice(startIndex, endIndex);

          setProducts(paginatedData);
        } else {
          console.error("No products found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currentPage, rowsPerPage]);



  useEffect(() => {
    const filterProducts = async () => {
      try {
        const [priceFrom, priceTo] = submittedPriceRange;


        const response = await axios.get(`${baseurl}/api/product/filter?category=OTHER`, {
          params: {
            brand: `${selectedCategory.join(",")}${selectedCategory.length > 1 ? ',' : ''}${searchBrand}`,           
            year: `${selectedTags.join(",")}${selectedTags.length ? ',' : ''}${searchValue}`,
            type: selectedBrands,
            priceFrom,
            priceTo,
            city: `${selectedCity.join(",")}${selectedCity.length ? ',' : ''}${searchCity}`,
          },
        });
        if (response.data) {
          const totalCount = response.data.length;
          setTotalPages(Math.ceil(totalCount / rowsPerPage));
          const startIndex = (currentPage - 1) * rowsPerPage;
          const endIndex = startIndex + rowsPerPage;
          const paginatedData = response.data.slice(startIndex, endIndex);

          setProducts(paginatedData);        
        } else {
          console.error("No products found in the API response.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    filterProducts();
  }, [selectedBrands, searchBrand, selectedTags, selectedCity, searchCity,selectedCategory, submittedPriceRange, searchValue,currentPage, rowsPerPage]);


  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
    console.log("Selected Price:", newValue);
  };

  const handlePriceSubmit = (priceRange) => {
    console.log("Price range submitted:", priceRange);
    setSubmittedPriceRange(priceRange);

  };
  const handleBrandChange = (tag) => {
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(tag)) {
        return prevSelectedBrands.filter((brand) => brand !== tag);
      } else {
        return [...prevSelectedBrands, tag];
      }
    });
    console.log("selectedBrands", selectedBrands)
  };

  const handlePriceMatch = (price) => {
    SetpriceMatches((prevSelected) => {
      if (prevSelected.includes(price)) {
        return prevSelected.filter((brand) => brand !== price);
      } else {
        return [...prevSelected, price];
      }
    });
    console.log("SetpriceMatches", priceMatches)
  };

  const handleTagChange = (tag) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag)) {
        return prevSelectedTags.filter((selectedTag) => selectedTag !== tag);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
    console.log("setSelectedTags", selectedTags)
  };

  const handleCityChange = (city) => {
    setSelectedCity((prevSelectedCity) => {
      if (prevSelectedCity.includes(city)) {
        return prevSelectedCity.filter((selectedCity) => selectedCity !== city);
      } else {
        return [...prevSelectedCity, city];
      }
    });
    console.log("setSelectedCity", selectedCity)
  };



  const handleCategory = (category) => {
    setSelectedCategory((prevSelectedCategory) => {
      if (prevSelectedCategory.includes(category)) {
        return prevSelectedCategory.filter((selectedCategory) => selectedCategory !== category);
      } else {
        return [...prevSelectedCategory, category];
      }
    });
    console.log("setSelectedCategory", selectedCategory)
  };

 

  const handleSearchClick = (e) => {
    setSearchValue(e);
  }
  const handleSearchCity = (e) => {
    setSearchCity(e);
  }

  const handleSearchBrand = (e) => {
    setSearchBrand(e);
  }


  return (
    <>
     <div>
            <Helmet>
                <title>apartments for sale | Quik sAle</title>
                <meta name="description" content="Buy & Sell anything Online in the quiksale marketplace" />
                </Helmet>
      <div className="product-page-container d-flex justify-content-center">
        <div className="product-page-main container">
          <div className=" row border product-page-row">
            <OthersSideBar
              Carcategories={Carcategories}
              Carcategory={Carcategory}
              priceRange={priceRange}
              // defaultPriceRange={defaultPriceRange}
              handlePriceRangeChange={handlePriceRangeChange}
              handleCategory={handleCategory}
              selectedCategory={selectedCategory}

              checkboxData={checkboxData}
              tagData={tagData}
              handleBrandChange={handleBrandChange}
              handleTagChange={handleTagChange}
              selectedTags={selectedTags}
              selectedBrands={selectedBrands}
              // priceMatch={priceMatch}
              handlePriceMatch={handlePriceMatch}
              priceMatches={priceMatches}
              handlePriceSubmit={handlePriceSubmit}
              Label={Label}
              YearOfPurchase={YearOfPurchase}
              FuelType={FuelType}
              handleSearchClick={handleSearchClick}
              handleSearchBrand={handleSearchBrand}
              checkboxCity={checkboxCity}
              handleCityChange={handleCityChange}
              selectedCity={selectedCity}
              handleSearchCity={handleSearchCity}
            />
            <div className="col-md-9 border product-rightcol"  style={{background:"#F5F5F5"}}>
              <div className=" product-firstrow py-2" >
                <div className="Searchfield row">
                  {/* <div className="search  col-md-8">
                    <Stack spacing={2} sx={{ width: 300 }}>
                      <SearchIcon />
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={top100Films.map((option) => option.title)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search input"
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </div> */}
                  <div className="dropdown col-md-4">
                    {/* <div className="sort">
                      <span>Sort By :</span>
                    </div>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      options={options}
                      sx={{ width: 150 }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    /> */}
                  </div>
                </div>
              </div>
              {/* <div className=" product-secondrow my-2">
                <nav className="breadcrumb-nav py-3">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="#">Active Filters:</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Electronics &times;
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      5 star Rating &times;
                    </li>
                  </ol>
                </nav>
              </div> */}
              <div className=" product-thirdrow ">
                <section className="inner-section shop-part categories-section mt-3 mb-2 pb-5">
                  <div className="container">
                    <div className="row  row-cols-md-2 row-cols-lg-3 row-cols-xl-3">
                      {Array.isArray(products) && products.length > 0  ? (
                        products.map((product) => (
                          <Card key={product.id} selectedCategory={"OTHER"} product={product} />
                        ))
                      ) : (
                        <p>No Products found</p>
                      )}
                    </div>
                  </div>
                </section>


              </div>
              <div className="pagination product-fourthrow d-flex justify-content-center">
                <Stack spacing={2}>
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Others;