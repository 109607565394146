export const StateData = [
  {
    name: 'Andhra Pradesh',
    cities: ["Achanta",
      "Adoni",
      "Allur",
      "Amadalavalasa",
      "Amalapuram",
      "Anakapalle",
      "Anantapur",
      "Anaparthy",
      "Annavaram",
      "Araku",
      "Atchutapuram APSEZ",
      "Atmakur",
      "Atmakur Sri Potti Sriramulu Nellore District",
      "Attilli",
      "Badampudi",
      "Badvel",
      "Banaganapalle",
      "Bandarulanka",
      "Banumukkala",
      "Bapatla",
      "Bestawaripeta",
      "Bethamcherla",
      "Bheemunipatnam",
      "Bhimadole",
      "Bhimavaram",
      "Bobbili",
      "Bowluvada",
      "Bugganipalle",
      "Chagallu",
      "Chebrole Khandrika",
      "Chebrolu",
      "Chennamukkapalle",
      "Chennur",
      "Chilakaluripet",
      "Chimakurthy",
      "Chintalavalasa",
      "Chintapalle",
      "Chippada Pharma SEZ",
      "Chirala",
      "Chittoor",
      "Chodavaram",
      "Cumbum",
      "Denduluru",
      "Devarapalli",
      "Dharmavaram",
      "Dhone",
      "Dommara Nandyala",
      "Dondapadu Kakathiya Cement Factory",
      "Dondapadu KCP Cement Factory",
      "Dowleswaram",
      "Draksharamam",
      "Dwaraka Tirumala",
      "Edlapadu",
      "Ekambarakuppam",
      "Eluru",
      "Gajapathinagaram",
      "Gajulamandhyam Industrial Area",
      "Gajularega",
      "Ganapavaram",
      "Gangapatnam",
      "Gavaravaram",
      "Giddaluru",
      "Golugonda",
      "Gooty",
      "Gopalapuram",
      "Gopavaram",
      "Gudilova",
      "Gudivada",
      "Gudur",
      "Gundugolanu",
      "Guntakal",
      "Guntur",
      "Gurzala",
      "Hanuman Junction",
      "Hindupur",
      "Hiramandalam",
      "Horseley Hills",
      "Hukumpeta",
      "Ibrahimpatnam",
      "Ichchapuram",
      "India Cement Factory Chilamakuru",
      "Indukurpet",
      "Industrial Growth Centre Thimmanapalem",
      "Jaggaiahpet",
      "Jammalamadugu",
      "Jangareddigudem",
      "Jarjapupeta",
      "Jaypee Cement Factory Budawada",
      "Kadapa",
      "Kadiri",
      "Kaikaram",
      "Kakinada",
      "Kakkalapalle",
      "Kallur",
      "Kallur Anantapur District",
      "Kaluvaya",
      "Kalyandurg",
      "Kanapaka",
      "Kandukur",
      "Kanigiri",
      "Kankipadu",
      "Kantabamsuguda",
      "Kathipudi",
      "Kavali",
      "Kavali Industrial Area",
      "Kodumur",
      "Kodur",
      "Koikuntla",
      "Komarolu",
      "Kondapalle",
      "Kota",
      "Kotabammal",
      "Kothapatnam",
      "Kothavalasa",
      "Kovurpalle",
      "Kovvur",
      "Kudithipalem",
      "Kuppam",
      "Kurnool",
      "Macherla",
      "Machilipatnam",
      "Madanapalle",
      "Madhurawada",
      "Mandapeta",
      "Mangalagiri",
      "Mangampeta",
      "Markapur",
      "Maruteru",
      "Medarametla",
      "Medikonduru",
      "Meliaputti",
      "Moragudi",
      "Morampudi",
      "Muddanur",
      "Mulaguntapadu",
      "Mulakuddu",
      "Mummidivaram",
      "Murakambattu",
      "Muttukuru",
      "Mydukur",
      "Mylavaram",
      "Nadim Tiruvuru",
      "Nagaram",
      "Nagari",
      "Nagireddipalle",
      "Nakkapalle",
      "Nallajerla",
      "Nandigama",
      "Nandyal",
      "Narasannapeta",
      "Narasapur",
      "Narasaraopet",
      "Narayanapur Vizianagaram District",
      "Narayanavanam",
      "Narsipatnam",
      "Nayudupeta",
      "Nellimarla",
      "Nellore",
      "Nidadavole",
      "NTPC Simhadri",
      "Nuzvid",
      "Ongole",
      "Paderu",
      "Palacole",
      "Palakonda",
      "Palamaner",
      "Palasa Kasibugga",
      "Pamidi",
      "Pamur",
      "Paravada Industrial Area",
      "Parvathipuram",
      "Pathapatnam",
      "Payakaraopeta",
      "Peda Boddepalle",
      "Pedana",
      "Pedatadepalli",
      "Peddapuram",
      "Pentapadu",
      "Perecherla",
      "Phirangipuram",
      "Piduguralla",
      "Pileru",
      "Pippara",
      "Pithapuram",
      "Podalakur",
      "Podili",
      "Pondura",
      "Ponnekallu",
      "Ponnur",
      "Porumamilla",
      "Prattipadu",
      "Proddatur",
      "Pulivendula",
      "Pulla",
      "Punganur",
      "Puttaparthi",
      "Puttur",
      "Rajahmundry",
      "Rajam",
      "Rajampet",
      "Ramabhadrapuram",
      "Ramachandrapuram",
      "Ramapuram",
      "Ramathirtham",
      "RAMCO Cement Factory Dharmavarappadu",
      "Rameswaram",
      "Rampachodavaram",
      "Rayachoti",
      "Rayadurg",
      "Razole",
      "Repalle",
      "Rushikonda APIIC",
      "Salur",
      "Samalkot",
      "Sanivarapupeta",
      "Santhanuthalapadu",
      "Satrampadu",
      "Sattenapalle",
      "Singarayakonda",
      "Somandepalle",
      "Sompeta",
      "Sricity",
      "Srikakulam",
      "Srikalahasti",
      "Sriramnagar",
      "Srisailam",
      "Srungavarapukota",
      "Sulluru",
      "Sunnipenta",
      "Tada",
      "Tadepalle",
      "Tadepalligudem",
      "Tadpatri",
      "Tangellamudi",
      "Tanuku",
      "Tekkali",
      "Tenali",
      "Thagarapuvalasa",
      "Thatipaka",
      "Thullur",
      "Thumakunta Industrial Area",
      "Tiruchanur",
      "Tirumala",
      "Tirupati",
      "Tummikapalle",
      "Tuni",
      "Unguturu",
      "Uppada",
      "Upper Sileru Project Site Camp",
      "Uravakonda",
      "Vaddeswaram",
      "Vavilla",
      "Velvadam",
      "Venkatachalam Industrial Area",
      "Venkatagiri",
      "Veparala",
      "Vetapalem",
      "Vijayapuri South",
      "Vijayawada",
      "Vinnamala",
      "Vinukonda",
      "Visakhapatnam",
      "Vissannapet",
      "Vizianagaram",
      "other",
      "VSP Township",
      "Vuyyuru",
      "Yanam",
      "Yelamanchili",
      "Yemmiganur",
      "Yenumalapalle",
      "Yernagudem",
      "Yerraguntla"],

  },

  {
    name: 'Arunachal Pradesh',
    cities: ["Anini",
      "Basar",
      "Boleng",
      "Bomdila",
      "Changlang",
      "Daporijo",
      "Deomali",
      "Dirang",
      "Hawai",
      "Itanagar",
      "Jairampur",
      "Khonsa",
      "Koloriang",
      "Longding",
      "Miao",
      "Naharlagun",
      "Namsai",
      "Pasighat",
      "Roing",
      "Rupa",
      "Sagalee",
      "Seppa",
      "Tawang",
      "Tezu",
      "Yingkiong",
      "Yupia",
      "Ziro",
      "other"
    ],
  },
  {
    name: 'Assam',
    cities: [
      "Abhayapuri",
      "Amguri",
      "Anand Nagar",
      "Asudubi",
      "Azara",
      "Badarpur",
      "Badarpur Railway Town",
      "Bamun Sualkuchi",
      "Bandardewa IID",
      "Bangaon",
      "Barpathar",
      "Barpeta",
      "Barpeta Road",
      "Basugaon",
      "BCPL Township",
      "Behiating AIDC",
      "Belsor",
      "Bhomoraguri AIDC",
      "Bhuragaon",
      "Bihpuria",
      "Bijni",
      "Bilasipara",
      "Biswanath Chariali",
      "Bohari",
      "Bokajan",
      "Bokakhat",
      "Bongaigaon",
      "Bongaigaon RPCL Township",
      "Borgolai Gt 11",
      "Borpukhuri",
      "Chabua",
      "Chalantapara Pt IV",
      "Chandrapur Bagicha",
      "Changsari",
      "Chapakhowa Town",
      "Chapar",
      "Charingia Gaon",
      "Chekonidhara",
      "Dahali",
      "Dalgaon",
      "Damara Patpara",
      "Dergaon",
      "Dhakuakhana",
      "Dharapur",
      "Dhekiajuli",
      "Dhekorgorha",
      "Dhemaji",
      "Dhing",
      "Dhubri",
      "Dibrugarh",
      "Digaru Gaon",
      "Digboi",
      "Diphu",
      "Dispur",
      "Doboka",
      "Dokmoka",
      "Donkamokam",
      "Doom Dooma",
      "Dudhpatil Pt V",
      "Dudhpatil Pt VI",
      "Duliajan",
      "Duliajan No 1",
      "Durga Nagar Part-V",
      "Garal",
      "Gauripur",
      "Goalpara",
      "Gohpur",
      "Golaghat",
      "Golokganj",
      "Goreswar",
      "Gossaigaon",
      "Guwahati",
      "Haflong",
      "Hailakandi",
      "Hamren",
      "Hindustan Paper Corporation Ltd Township Panchgram",
      "Hojai",
      "Howli",
      "Howraghat",
      "Irongmara",
      "Jagiroad",
      "Jamunamukh",
      "Jhagra Pt III",
      "Jonai Bazar",
      "Jorhat",
      "Kakaya",
      "Kalaigaon Town Part",
      "Kamalabaria NC",
      "Kampur Town",
      "Kanisail Pt I",
      "Karimganj",
      "Khaira Bari",
      "Kharijapikon",
      "Kharupatia",
      "Kochpara",
      "Kokrajhar",
      "Kumar Kaibarta Gaon",
      "Lakhipur",
      "Lakhipur, Cachar District",
      "Lala",
      "Lanka",
      "Lido Tikok",
      "Lido Town",
      "Lumding",
      "Lumding Rly Colony",
      "Mahur",
      "Maibong",
      "Majgaon",
      "Makum",
      "Mangaldoi",
      "Mankachar",
      "Margherita",
      "Mariani",
      "Marigaon",
      "Marowa",
      "Moran Town",
      "Moranhat",
      "Mornoi Industrial Area",
      "Mushalpur",
      "Nagaon",
      "Naharkatiya",
      "Nahira",
      "Nalbari",
      "Namrup",
      "Narayanpur",
      "Naubaisa Gaon",
      "Nazira",
      "New Bongaigaon Railway Colony",
      "Niz Bahjani",
      "Niz Hajo",
      "Niz Katigorah Pt III",
      "Niz Mankata",
      "North Guwahati",
      "North Lakhimpur",
      "Numaligarh Refinery Township",
      "Palasbari",
      "Pathsala",
      "Pub Dhaniram Pather",
      "Raha",
      "Rangapara",
      "Rangia",
      "Rangia IIDC",
      "Rani",
      "Rupahi Town",
      "Rupiabathan",
      "Salakati",
      "Salpara Molandubi Pt I",
      "Sanpara",
      "Sapatgram",
      "Sarbhog",
      "Sarthebari",
      "Sarupathar",
      "Sarupathar Bengali",
      "Sepon",
      "Sibsagar",
      "Silapathar",
      "Silchar",
      "Sonapur Gaon",
      "Sonari",
      "Sualkuchi",
      "Takhlibilar Pathar",
      "Tangla",
      "Tegheria",
      "Teok",
      "Tezpur",
      "Thekashu Pt I",
      "Thekashu Pt II",
      "Tihu",
      "Tinsukia",
      "Titabor Town",
      "Udalguri",
      "Umrangso",
      "Other"
    ]
  },
  {
    name: 'Bihar',
    cities: [
      "Amarpur",
      "Anwari",
      "Araria",
      "Areraj",
      "Arrah",
      "Arwal",
      "Asarganj",
      "Aurangabad",
      "Bagaha",
      "Bahadurganj",
      "Bahadurpur",
      "Bairgania",
      "Bakhri",
      "Bakhtiarpur",
      "Balia",
      "Banka",
      "Banmankhi Bazar",
      "Bara",
      "Barahiya",
      "Barauli",
      "Barauni IOC Township",
      "Barbigha",
      "Barh",
      "Baruni",
      "Baruni Thermal Power Station Township",
      "Begusarai",
      "Behea",
      "Belsand",
      "Benipur",
      "Bettiah",
      "Bhabua",
      "Bhagalpur",
      "Bhagirathpur",
      "Biharsharif",
      "Bihta",
      "Bihta Patna District",
      "Bikram",
      "Bikramganj",
      "Birpur",
      "Bodh Gaya",
      "Buxar",
      "Chakia",
      "Chanari",
      "Chand Chaur",
      "Chanpatia",
      "Chapra",
      "Dalsinghsarai",
      "Darbhanga",
      "Dariapur",
      "Daudnagar",
      "Dehri",
      "Dhaka",
      "Dighwara",
      "Dumari",
      "Dumraon",
      "Ekangar Sarai",
      "Fatwah",
      "Forbesganj",
      "Gaya",
      "Gazipur",
      "Ghoghardiha",
      "Gogri Jamalpur",
      "Gopalganj",
      "Habibpur",
      "Hajipur",
      "Hanspura",
      "Harnaut",
      "Hilsa",
      "Hisua",
      "Islampur",
      "Jagdishpur",
      "Jainagar",
      "Jamalpur",
      "Jamhaur",
      "Jamui",
      "Janakpur Road",
      "Janpur",
      "Jehanabad",
      "Jhajha",
      "Jhanjharpur",
      "Jogabani",
      "Kahalgaon",
      "Kanti",
      "Kargahia Purab",
      "Kasba",
      "Kataiya",
      "Katihar",
      "Kesaria",
      "Khagaria",
      "Kharagpur",
      "Khusrupur",
      "Kishanganj",
      "Koath",
      "Koilwar",
      "Kumarbagh",
      "Lakhisarai",
      "Lalganj",
      "Laruara",
      "Madhepura",
      "Madhuban",
      "Madhubani",
      "Maharajganj",
      "Mahnar Bazar",
      "Mairwa",
      "Makhdumpur",
      "Malhipur",
      "Maner",
      "Manihari",
      "Mansur Chak",
      "Marhaura",
      "Masaurhi",
      "Mathurapur",
      "Mehsi",
      "Mirganj",
      "Mohiuddinagar",
      "Mokameh",
      "Motihari",
      "Motipur",
      "Munger",
      "Murliganj",
      "Muzaffarpur",
      "Nabinagar",
      "Narkatiaganj",
      "Nasrigagnj",
      "Naubatpur",
      "Naugachhia",
      "Nawada",
      "Nirmali",
      "Nokha",
      "NTPC Barh",
      "Obra",
      "Padri",
      "Pakri Dayal",
      "Pandual",
      "Pareo",
      "Paria",
      "Patna",
      "Piro",
      "Puraini",
      "Purnia",
      "Rafiganj",
      "Raghunathpur",
      "Rajauli",
      "Rajgir",
      "Ramgarh",
      "Ramnagar",
      "Raxaul Bazar",
      "Revelganj",
      "Saharsa",
      "Sahebganj",
      "Sahebganj Banka District",
      "Saidpura",
      "Samastipur",
      "Saraiya",
      "Sasaram",
      "Satghara",
      "Shahpur",
      "Sheikhpura",
      "Sheohar",
      "Sherghati",
      "Silao",
      "Singhesar Asthan",
      "Sitamarhi",
      "Siwan",
      "Sonepur",
      "Sugauli",
      "Sultanganj",
      "Supaul",
      "Teghra",
      "Telkap",
      "Thakurganj",
      "Tikari",
      "Tola Baliadih",
      "Tola Chain",
      "Tola Mansaraut",
      "Tola Pairamatihana",
      "Vaishali",
      "Warisaliganj",
      "Other"
    ]
  },
  {
    name: 'Chandigarh Metro',
    cities: [
      "Chandigarh",
      "Mohali",
      "Panchkula",
      "Other"
    ]
  },
  {
    name: "Dadra & Nagar Haveli",
    cities: [
      "Alok City",
      "Amli",
      "Dungra",
      "Masat Industrial Area",
      "Naroli",
      "Silvassa",
      "Surangi",
      "Other"
    ]
  },
  {
    name: 'Chandigarh',
    cities: ['Amritsar', 'Jalandhar ', 'Patiala', 'Bathinda', 'Mohali', 'Moga', 'Batala', 'Pathankot', 'Ferozpur',
      'Abohar', 'Malerkotla', 'Khanna', 'Phagwara', 'Kapurthala ', 'Rajpura', 'Muktsar', 'Hoshiarpur', 'Faridkot ',
      'Barnala ', 'Other'],
  },

  {
    name: 'Chhattisgarh',
    cities: [
      "Ramdi",
      "Abhanpur",
      "Adawal",
      "Adbhar",
      "Ahiwara",
      "Akaltara",
      "Ambagarh Chowki",
      "Ambikapur",
      "Antagarh",
      "Arang",
      "Arjunda",
      "Bade Bacheli",
      "Badla",
      "Bagbahara",
      "Bagicha",
      "Baikunthpur",
      "Balod",
      "Baloda",
      "Baloda Bazar",
      "Balrampur",
      "Banarsi",
      "Banki Mongra",
      "Baramkela",
      "Barsur",
      "Basna",
      "Bastar",
      "Bemetra",
      "Berla",
      "Bhairamgarh",
      "Bhakhara",
      "Bhanupratappur",
      "Bhatapara",
      "Bhatgaon",
      "Bhilai",
      "Bhopalpattanam",
      "Bijapur Bilaigarh",
      "Bilaspur",
      "Bilha",
      "Birkoni IIDC",
      "Bodri",
      "Borai CSIDC",
      "Century Cement Baikunth",
      "Champa",
      "Chandrapur",
      "Charama",
      "Chharchha",
      "Chhuikhadan",
      "Chhura",
      "Chhurikala",
      "Chhuriya Kalan",
      "Chikhal Kasa",
      "Chirmiri",
      "Dabhra",
      "Dalli Rajhara",
      "Dantewada",
      "Daundi Lohara",
      "Deori",
      "Devkar",
      "Dhamdha",
      "Dhamtari",
      "Dharamjaigarh",
      "Dipka",
      "Dongargaon",
      "Dongargarh",
      "Dornapal",
      "Doundi",
      "Durg",
      "Farasgaon",
      "Fingeshwar",
      "Gandai",
      "Gariaband",
      "Gaurela",
      "Gharghoda",
      "Gidam",
      "Girwarganj IIDC",
      "Gobra Nawapara",
      "Govindapur",
      "Gunderdehi",
      "Gurur",
      "Hatkachora",
      "Hirmi",
      "Jagdalpur",
      "Jaijepur",
      "Janjgir",
      "Jarhi",
      "Jarwe",
      "Jashpurnagar",
      "Jhagrakhand",
      "Kanker",
      "Kasdol",
      "Katghora",
      "Katkona",
      "Kawardha",
      "Keskal",
      "Khairagarh",
      "Khamharia",
      "Kharod",
      "Kharora",
      "Kharsia",
      "Khongapani",
      "Kirandul",
      "Kondagaon",
      "Koni",
      "Konta",
      "Korba",
      "Kota",
      "Kotba",
      "Kumhari",
      "Kunkuri",
      "Kurasia",
      "Kurud",
      "Kusmi",
      "Lafarge Cement Factory Arasmeta",
      "Lailunga",
      "Lakhnapur",
      "Lawan",
      "Lormi",
      "Magarlod",
      "Mahasamund",
      "Mahendragarh",
      "Malhar",
      "Mandhar Industrial Area",
      "Maro",
      "Mehmand",
      "Mungeli",
      "Nagari",
      "Nai Ledri",
      "Namna Kalan",
      "Narayanpur",
      "Narharpur",
      "Nawagarh Janjgir - Champa District",
      "Naya Baradwar",
      "New Raipur",
      "NTPC Sipat",
      "Pakhanjur",
      "Palari",
      "Pandaria",
      "Pandatarai",
      "Paratappur",
      "Parpodi",
      "Patan",
      "Pathalgaon",
      "Patharia",
      "Pathariya",
      "Pendra",
      "Phunderdihari",
      "Pipariya",
      "Pithora",
      "Pusaur",
      "Rahaud",
      "Raigarh",
      "Raipur",
      "Rajgamar",
      "Rajnandgaon",
      "Rajpur",
      "Ramanujganj",
      "Rani Durgavati Industrial Area Anjani",
      "Ratanpur",
      "Rawan Ambuja Cement Plant",
      "Sahaspur Lohara",
      "Saja",
      "Sakti",
      "Saragaon",
      "Saraipali",
      "Sarangarh",
      "Sargaon",
      "Sariya",
      "Sheorinarayan",
      "Silpahari Industrial Area",
      "Simga",
      "Sirgitti",
      "Sitapur",
      "Sukma",
      "Surajpur",
      "Takhatpur",
      "Telgaon",
      "Tendua IID",
      "Tifra",
      "Tilda",
      "Tumgaon",
      "Tundra",
      "Ultratech Cement Plant Rawan",
      "Utai",
      "Vishrampur",
      "Wadrafnagar",
      "Other"
    ],
  },

  {
name:'Dadra & Nagar Haveli',
cities:[
  "Alok City",
  "Amli",
  "Dungra",
  "Masat Industrial Area",
  "Naroli",
  "Silvassa",
  "Surangi",
  "Other"
]
  },
  {
    name:'Delhi',
    cities:
      ['Sadar bazar', 'Lajpat Nagar ', 'Rohini Sector 12', 'Ashok Nagar', 'Hauz Khas', 'Rohini Sector 9', 'Rohini Sector 8',
      'Rohini Sector 5', 'Rohini Sector 16', 'Gautam Nagar', 'Nehru Place', 'Krishna Nagar', 'Kalkaji', 'Tilak Nagar', 'Dwarka',
      'Ajmeri Gate', 'Mayapuri', 'Rajender Nagar', 'Karol Bagh', 'Indraprastha Extension', 'Rajauri Garden', 'Civil Lines',
      'Vikas Puri ', 'Panchsheel Park', 'Chandni Chowk', 'Pushp Vihar', 'Tagore Garden', 'Uttam Nagar', 'Sri Niwaspuri', 'Other']
    
  },
  {
    name: 'Goa',
    cities: [
      "Aldona",
    "Anjuna",
    "Aquem",
    "Arambol",
    "Bambolim",
    "Bandora",
    "Benaulim",
    "Bicholim",
    "Borim",
    "Calangute",
    "Calapor",
    "Canacona",
    "Candola",
    "Candolim",
    "Carapur",
    "Chicalim",
    "Chimbel",
    "Chinchinim",
    "Colva",
    "Colvale",
    "Cumbarjua",
    "Cuncolim",
    "Curchorem",
    "Curti",
    "Curtorim",
    "Davorlim",
    "Guirim",
    "Jua",
    "Mandrem",
    "Mapusa",
    "Marcaim",
    "Margao",
    "Mercurim",
    "Moira",
    "Morjim",
    "Navelim",
    "Nerul",
    "Nuvem",
    "Old Goa",
    "Onda",
    "Orgao",
    "Pale",
    "Panaji",
    "Parcem",
    "Penha De Franca",
    "Pernem",
    "Ponda",
    "Porvorim",
    "Priol",
    "Quepem",
    "Queula",
    "Raia",
    "Reis Magos",
    "Saligao",
    "Sancoale",
    "Sanguem",
    "Sanquelim",
    "Sanvordem",
    "Sao Jose de Areal",
    "Siolim",
    "Usgao",
    "Valpoi",
    "Varca",
    "Vasco Da Gama",
    "Xeldem",
    "Other"
    ],
  },

  {
    name: 'Gujarat',
    cities: ["Abrama",
    "Adalaj",
    "Adityana",
    "Ahmedabad",
    "Ahwa",
    "Alang",
    "Alikherva",
    "Ambaji",
    "Ambaliyasan",
    "Amboli",
    "Amreli",
    "Anand",
    "Anandpar",
    "Anandpar Jamnagar District",
    "Andada",
    "Anjar",
    "Anklav",
    "Ankleshwar",
    "Antaliya",
    "Arambhada",
    "Arsodiya",
    "Atul",
    "Baben",
    "Babra",
    "Bagasara",
    "Balasinor",
    "Baliyasan",
    "Bansda",
    "Bantwa",
    "Bardoli",
    "Bareja",
    "Barwala",
    "Bavla",
    "Bayad",
    "Bechar Alias Becharaji",
    "Bedi",
    "Bhabhar",
    "Bhachau",
    "Bhadkodara",
    "Bhagal",
    "Bhagal Valsad District",
    "Bhalpara",
    "Bhanvad",
    "Bharuch",
    "Bhat",
    "Bhavnagar",
    "Bhayavadar",
    "Bhilad",
    "Bhiloda",
    "Bhuj",
    "Bhurivel",
    "Bilimora",
    "Bodeli",
    "Boriavi",
    "Borsad",
    "Botad",
    "Chaklasi",
    "Chalala",
    "Chalthan",
    "Chanasma",
    "Chanod",
    "Chhapi",
    "Chhapra",
    "Chhatral",
    "Chhiri",
    "Chhota Udaipur",
    "Chikhli",
    "Chorwad",
    "Chotila",
    "Dabhoi",
    "Dahej SIR",
    "Dakor",
    "Damnagar",
    "Dediapada",
    "Deesa",
    "Dehari",
    "Dehgam",
    "Deodar",
    "Devgadbaria",
    "Devsar",
    "Dhandhuka",
    "Dhanera",
    "Dharampur",
    "Dhasa Vishi",
    "Dhola",
    "Dholka",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Digvijaygram",
    "Dohad",
    "Dungarpur",
    "Dungarpur Bhavnagar District",
    "Dwarka",
    "Gadhada",
    "Gamdi",
    "Gandevi",
    "Gandhidham",
    "Gandhinagar",
    "Gariadhar",
    "Ghogha",
    "GSFC Motikhavdi Sikka INA",
    "GSFC Reliance Complex",
    "GSFC Reliance Complex RGWC Township Kovaya",
    "Halol",
    "Halvad",
    "Hansalpur",
    "Harij",
    "Himmatnagar",
    "Ichchhapor",
    "Ichhapor",
    "Idar",
    "Jafrabad",
    "Jalalpore",
    "Jamavala",
    "Jambusar",
    "Jamjodhpur",
    "Jamnagar",
    "Jarod",
    "Jasdan",
    "Jetalsar",
    "Jetpur",
    "Jetpur Navagadh",
    "Jhalod",
    "Joshipura",
    "Junagadh",
    "Kadi",
    "Kadodara",
    "Kakoshi",
    "Kalavad",
    "Kalol",
    "Kalol Panch Mahals District",
    "Kandla",
    "Kanjari",
    "Kanodar",
    "Kapadvanj",
    "Karamsad",
    "Karjan",
    "Kathlal",
    "Katpar",
    "Kavant",
    "Keshod",
    "Kevadiya",
    "Khambhalia",
    "Khambhat",
    "Khapat",
    "Kharach",
    "Kharaghoda",
    "Kheda",
    "Khedbrahma",
    "Kheralu",
    "Kim",
    "Kodinar",
    "Kosamba",
    "Kothria",
    "Kutiyana",
    "Lathi",
    "Lavachha",
    "Lilia",
    "Limbdi",
    "Limkheda",
    "Limla",
    "Lodhikas",
    "Lunawada",
    "Madhapar",
    "Mahesana",
    "Mahudha",
    "Mahuva",
    "Mahuvar",
    "Malanka",
    "Maliya",
    "Malpur",
    "Manavadar",
    "Mandvi",
    "Mandvi Surat District",
    "Mangrol",
    "Mankuva",
    "Mansa",
    "Meghraj",
    "Mehmedabad",
    "Mithapur",
    "Modasa",
    "Modhera",
    "Morbi",
    "Mundra",
    "Nadiad",
    "Nalsarovar",
    "Nandej",
    "Nari",
    "Nasvadi",
    "Nava Bhildi",
    "Navlakhi SIR",
    "Navsari",
    "Ode",
    "Okha Port",
    "Orvad",
    "Paddhari",
    "Padra",
    "Palaj",
    "Palanpur",
    "Palej",
    "Palitana",
    "Panoli INA",
    "Parabada",
    "Pardi",
    "Parnera",
    "Patan",
    "Patdi",
    "Pethapur",
    "Petlad",
    "Porbandar",
    "Prantij",
    "Radhanpur",
    "Rajkot",
    "Rajpipla",
    "Rajula",
    "Ranavav",
    "Ranpur",
    "Rapar",
    "Raval",
    "Ravapara",
    "Sagbara",
    "Salaya",
    "Salvav",
    "Sanand",
    "Sanand GIDC",
    "Sanjan",
    "Sanjeli",
    "Santrampur",
    "Saputara",
    "Sarigam",
    "Sathamba",
    "Savarkundla",
    "Savli",
    "Sayan",
    "Selamba",
    "Shapur",
    "Shapur Rajkot District",
    "Shehera",
    "Sidhpur",
    "Sidsar",
    "Sihor",
    "Sikka",
    "Simar SIR",
    "Sojitra",
    "Somnath",
    "Songadh",
    "Songadh Bhavnagar District",
    "Sukhpar",
    "Surajkaradi",
    "Surat",
    "Surendranagar",
    "Sutrapada",
    "Talaja",
    "Talala",
    "Talod",
    "Thangadh",
    "Thara",
    "Tharad",
    "Thasra",
    "Ukai",
    "Umbergaon",
    "Umrala",
    "Umreth",
    "Una",
    "Undach",
    "Unjha",
    "Upleta",
    "Vadali",
    "Vadia",
    "Vadnagar",
    "Vadodara",
    "Vaghodia",
    "Valia",
    "Vallabhipur",
    "Valsad",
    "Vanthali",
    "Vapi",
    "Vartej",
    "Vasna",
    "Veraval",
    "Veraval Rajkot District",
    "Vijalpor",
    "Vijapur",
    "Vijaynagar",
    "Vilayat GIDC",
    "Viramgam",
    "Virpur",
    "Virpur Rajkot District",
    "Visavadar",
    "Visnagar",
    "Vyara",
    "Wadhwan",
    "Waghai",
    "Wankaner",
     "Other"
    ],
  },

  {
    name: 'Haryana',
    cities: [
      "Adampur",
    "Ambala",
    "Asankhurd",
    "Assandh",
    "Ateli",
    "Badh Malak",
    "Badli",
    "Baghola",
    "Bahadurgarh",
    "Barara",
    "Barhi Industrial Area",
    "Barwala",
    "Bawal",
    "Bawani Khera",
    "Beri",
    "Bhakali",
    "Bhiwani",
    "Bhondsi",
    "Bhuran",
    "Bilaspur",
    "Binola Industrial Area",
    "Boh",
    "Buria",
    "Chandi Mandir",
    "Chandimandir Bir Ghaggar",
    "Charkhi Dadri",
    "Cheeka",
    "Chhachhrauli",
    "Dharuhera",
    "Ellenabad",
    "Faridabad",
    "Farrukhnagar",
    "Fatehabad",
    "Ferozepur Jhirka",
    "Ganaur",
    "Gangwa",
    "Garhi Harsaru",
    "Gharaunda",
    "Gohana",
    "Gurgaon",
    "Haileymandi",
    "Hansi",
    "Hassanpur",
    "Hathin",
    "Hisar",
    "Hodal",
    "HSIIDC Dudhola Industrial Area",
    "HSIIDC Manakpur Industrial Area",
    "Indri",
    "Industrial Estate Barwala",
    "Ismailabad",
    "Jakhalmandi",
    "Jhajjar",
    "Jind",
    "Julana",
    "Kabri",
    "Kachrauli",
    "Kaithal",
    "Kakar Majra",
    "Kalanaur",
    "Kalanwali",
    "Kalayat",
    "Kalka",
    "Nagina",
    "Naraingarh",
    "Narnaul",
    "Narnaund",
    "Narwana",
    "Nilokheri",
    "Nissing",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Panipat Refinery Township",
    "Pataudi",
    "Pehowa",
    "Piala",
    "Pinagwan",
    "Pinjore",
    "Kanina",
    "Karnal",
    "Kharkhoda",
    "Khori Kalan",
    "Kundli",
    "Kundli Industrial Area",
    "Kurukshetra",
    "Ladrawan",
    "Ladwa",
    "Loharu",
    "Maham",
    "Mahendragarh",
    "Majra",
    "Mandi Dabwali",
    "Manesar",
    "Manethi",
    "Mayyer",
    "Mustafabad",
    "Punahana",
    "Pundri",
    "Adaure",
    "Rai Industrial Area",
    "Raipur",
    "Raipur Rani",
    "Ram Garh",
    "Rania",
    "Ratia",
    "Rewari",
    "Rohtak",
    "Roz Ka Meo Industrial Area",
    "Sadaura",
    "Safidon",
    "Saha",
    "Salamba",
    "Samalkha",
    "Sampla",
    "Sankhol",
    "Shahbad",
    "Sikri Industrial Area",
    "Sirsa",
    "Siwani",
    "Sohna",
    "Sonipat",
    "Sunari Kalan",
    "Taoru",
    "Taraori",
    "Tohana",
    "Tosham",
    "Uchana",
    "Ugra Kheri",
    "Uklanamandi",
    "Uncha Siwana",
    "Yamunanagar",
      "Other"],
  },

  {
    name: 'Himachal Pradesh',
    cities: [
      "Amb Industrial Area",
    "Arki",
    "Baddi",
    "Baddi Industrial Area",
    "Badhal Industrial Area",
    "Bagbania Industrial Area",
    "Bain Attarian Industrial Area",
    "Bakloh",
    "Banjar",
    "Barotiwala Industrial Area",
    "Bhambla Industrial Area",
    "Bhota",
    "Bhuntar",
    "Bilaspur",
    "Chail",
    "Chamba",
    "Chaupal",
    "Chuari Khas",
    "Dagshai",
    "Dalhousie",
    "Daulatpur",
    "Dera Gopipur",
    "Dharampur Industrial Estate",
    "Dharmsala",
    "Gagret",
    "Gagret Industrial Area",
    "Garnota Industrial Area",
    "Ghumarwin",
    "Golthai Industrial Area",
    "Gondpur Industrial Area",
    "Hamirpur",
    "Hatil Industrial Area",
    "Hill Top Industrial Estate",
    "Indora",
    "Jais Industrial Area",
    "Jawalamukhi",
    "Jhakhri",
    "Jharmajri EPIP Phase 1",
    "Jogindarnagar",
    "Jubbal",
    "Jutogh",
    "Kala Amb Industrial Area",
    "Kangra",
    "Kasauli",
    "Kasol",
    "Keylong",
    "Keylong Industrial Estate",
    "Khajjiar",
    "Kinnaur",
    "Kotkhai",
    "Kullu",
    "Lodhi Majra Industrial Area",
    "Manali",
    "Mandi",
    "Manikaran",
    "Mehatpur",
    "Moginand Industrial Area",
    "Nadaun",
    "Nagri Industrial Area",
    "Nagrota Bagwan",
    "Nahan",
    "Naina Devi",
    "Nalagarh",
    "Narkanda",
    "Nurpur",
    "Palampur",
    "Paonta Sahib",
    "Parel Industrial Estate",
    "Parwanoo",
    "Pragpur",
    "Raja Ka Bagh Industrial Area",
    "Rajgarh",
    "Rampur",
    "Ratti Industrial Area",
    "Rawalsar",
    "Reckong Peo",
    "Renuka",
    "Rohru",
    "Sabathu",
    "Sansarpur Terrace Growth Centre Industrial Area",
    "Bhaderwah",
    "Santokhgarh",
    "Sarkaghat",
    "Sauli Khad Industrial Area",
    "Seoni",
    "Shamshi Industrial Area",
    "Shimla",
    "Shivnagari Holi Industrial Estate",
    "Shoghi",
    "Shoghi Industrial Area",
    "Solan",
    "Solang",
    "Sundarnagar",
    "Tahliwala Industrial Area",
    "Talai",
    "Theog",
    "Tira Sujanpur",
    "Una",
    "YOI",
   "Other"],
  },
{
name:'Jammu and Kashmir',
cities:[
  "Achabal",
    "Akhnoor",
    "Anantnag",
    "Arnia",
    "Arwani",
    "Ashmuji Khalsa",
    "Awantipora",
    "Bandipore",
    "Banihal",
    "Baramulla",
    "Bari Brahmana EPIP Industrial Estate",
    "RBillawar",
    "Batote",
    "Bijbehara",
    "Billawar",
    "Bishna",
    "Chenani",
    "Choglamsar",
    "Dachigam",
    "Doda",
    "Dras",
    "Duru Verinag",
    "Ganderbal",
    "Gho Manhasan",
    "Gorah Salathian",
    "Gulmarg",
    "Hajan",
    "Handwara",
    "Hanle",
    "Hiranagar",
    "Jammu",
    "Jourian",
    "Kargil",
    "Kathua",
    "Katra",
    "Khan Sahib",
    "Khilanmarg",
    "Khour",
    "Khrew",
    "Kishtwar",
    "Kud",
    "Kukernag",
    "Kulgam",
    "Kunzer",
    "Bari Brahmana EPIP Industrial Estate",
    "Kupwara",
    "Leh",
    "Magam",
    "Mattan",
    "Mohmmad Pora",
    "Nowgam",
    "Nowshera",
    "Pahalgam",
    "Parole",
    "Patnitop",
    "Pattan",
    "Pulwama",
    "Punch",
    "Qaimoh",
    "Qazigund",
    "Rajauri",
    "Ramban",
    "Ramgarh",
    "Ramnagar",
    "Ranbirsinghpora",
    "Reasi",
    "Rehambal",
    "Samba",
    "Seer Hamdan",
    "Shangus",
    "Shopian",
    "Soolkoot",
    "Sopore",
    "Srinagar",
    "Sumbal",
    "Sunderbani",
    "Talwara",
    "Thanamandi",
    "Tral",
    "Udhampur",
    "Uri",
    "Vijay Pore",
    "Other"
]
},

  {
    name: 'Jharkhand',
    cities: ["AEC Complex",
    "Akdoni Khurd",
    "Alagdiha",
    "Amlabad",
    "Angarpathar",
    "Ara",
    "Bachra",
    "Baharagora",
    "Baliapur",
    "Baliari",
    "Balkundra",
    "Barajamda",
    "Bardubhi",
    "Barharwa",
    "Barhi",
    "Barkakana",
    "Barki Saraiya",
    "Barora",
    "Barughutu",
    "Barwadih",
    "Basaria",
    "Basukinath",
    "Baua Kalan",
    "Bekobar",
    "Berhait Bazar",
    "Bermo",
    "Bhagatdih",
    "Bhandra",
    "Bhandra Bokaro District",
    "Bhim Kanari",
    "Bhojudih",
    "Howrah",
    "Bhuli",
    "Bishnugarh",
    "Bishrampur",
    "Bishrampur Latehar District",
    "Bokaro Steel City",
    "Bokaro Thermal",
    "Bongabar",
    "Borio",
    "Bundu",
    "Bursera",
    "Chaibasa",
    "Chain Pur",
    "Chakradharpur",
    "Chakulia",
    "Chandaur",
    "Chandil",
    "Chandrapura",
    "Chandwa",
    "Charhi",
    "Chatra",
    "Chauparan",
    "Chhatatanr",
    "Chhotaputki",
    "Chiria",
    "Chirkunda",
    "Churi",
    "Daltonganj",
    "Danguwapasi",
    "Dari",
    "Deoghar",
    "Deorikalan",
    "Dhanbad",
    "Dhanwar",
    "Dhaunsar",
    "Domchanch",
    "Dugda",
    "Dumarkunda",
    "Dumka",
    "Egarkunr",
    "Garhwa",
    "Ghagra",
    "Ghatshila",
    "Gidi",
    "Giridih",
    "Godda",
    "Godhar",
    "Gomoh",
    "Gua",
    "Gumia",
    "Gumla",
    "Haludpukhur",
    "Hariharpur",
    "Harina",
    "Hasir",
    "Hazaribagh",
    "Hesla",
    "Hussainabad",
    "Irba",
    "Isri",
    "Jadugora",
    "Jagannathpur",
    "Jai Nagar",
    "Jamadoba",
    "Jamshedpur",
    "Jamtara",
    "Jangalpur",
    "Jaridih Bazar",
    "Jasidih",
    "Jena",
    "Jharia",
    "Jharia Khas",
    "Jhinghipahari",
    "Jhinkpani",
    "Jhumri Tilaiya",
    "Jorapokhar",
    "Kailudih",
    "Kalikapur",
    "Kandra",
    "Kanke",
    "Karma",
    "Karma Chatra District",
    "Karma Tanr",
    "Karmatanr",
    "Kathhara",
    "Katras",
    "Kedla",
    "Kenduadih",
    "Kharkhari",
    "Kharsawan",
    "Khelari",
    "Khunti",
    "Kiriburu",
    "Kodarma",
    "Kuju",
    "Kumarpur",
    "Kumarpur Sahibganj District",
    "Kurpania",
    "Kustai",
    "Lakarka",
    "Lalpania",
    "Lapanga",
    "Latehar",
    "Lohardaga",
    "Loyabad",
    "Madhuban",
    "Madhupur",
    "Mahagma",
    "Mahesh Mundi",
    "Mahuda",
    "Maithon",
    "Majhion",
    "Makoli",
    "Malkera",
    "Mandu",
    "Manohar Pur",
    "Marar",
    "Marma",
    "Matigara",
    "Mera",
    "Meru",
    "Mihijam",
    "Mugma",
    "Muraidih",
    "Muri",
    "Musabani",
    "Nagri Kalan",
    "Narra",
    "Netarhat",
    "Nirsa",
    "Noamundi",
    "Okni No 2",
    "Orla",
    "Pakaur",
    "Palawa",
    "Panchet",
    "Panrra",
    "Paratdih",
    "Pathardih",
    "Patra",
    "Patratu",
    "Phusro",
    "Pondarkanali",
    "Rajbhita",
    "Rajmahal",
    "Ramgarh Cantonment",
    "Ranchi",
    "Ratu",
    "Rehla",
    "Religara",
    "Rohraband",
    "Sahibganj",
    "Sahnidih",
    "Sanri",
    "Saraidhela",
    "Saram",
    "Sarauni",
    "Satgawan",
    "Saunda",
    "Seraikela",
    "Sewai",
    "Shahpur",
    "Sijhua",
    "Sijua",
    "Simdega",
    "Sindri",
    "Sinduria",
    "Sini",
    "Sirka",
    "Siuliban",
    "Suranga",
    "Tapin",
    "Tati",
    "Telo",
    "Telodih",
    "Tenu",
    "Tin Pahar",
    "Tisra",
    "Topa",
    "Topchanchi",
    "Torpa",
    "Toto",
    "Tundiul",
    "Urimari",
     "Other"]
  },

  {
    name: 'Karnataka',
    cities: ["Bangalore",
    "Addur",
    "Adityapatna",
    "Adyar",
    "Afzalpur",
    "Agumbe",
    "Aland",
    "Alevoor",
    "Allipura",
    "Alnavar",
    "Alur",
    "Amaravathi",
    "Ambikanagara",
    "Amble Industrial Area",
    "Aminagad",
    "Ankola",
    "Annigeri",
    "Arasinakunte",
    "Arkalgud",
    "Arkula",
    "Arsikere",
    "Athni",
    "Aurad",
    "Aversa",
    "Bada",
    "Badagaulipady",
    "Badami",
    "Bagalkot",
    "Bagepalli",
    "Bail Hongal",
    "Bandipur",
    "Bangarapet",
    "Bangarpet Industrial Area",
    "Bankapura",
    "Bannur",
    "Bantval",
    "Basavakalyan",
    "Basavana Bagevadi",
    "Belgaum",
    "Bellary",
    "Beltangadi",
    "Belur",
    "Belur Industrial Area",
    "Benakanahalli",
    "Bengaluru",
    "Bethamangala",
    "Bhadravati",
    "Bhalki",
    "Bhatkal",
    "Bhimarayanagudi",
    "Bidadi",
    "Bidadi Industrial Area",
    "Bidar",
    "Bijapur",
    "Bilgi",
    "Birur",
    "Bobruwada",
    "Byadgi",
    "Challakere",
    "Chamarajanagar",
    "Channagiri",
    "Channapatna",
    "Channarayapatna",
    "Chelur",
    "Chik Ballapur",
    "Chikballapur Industrial Area",
    "Chikkajajur",
    "Chikmagalur",
    "Chiknayakanhalli",
    "Chikodi",
    "Chincholi",
    "Chintamani",
    "Chitapur",
    "Chitgoppa",
    "Chitradurga",
    "Dandeli",
    "Dargajogihalli",
    "Davanagere",
    "Devadurga",
    "Devanahalli",
    "Dharwad",
    "Dobaspet Industrial Area",
    "Dod Ballapur",
    "Doddaballapur",
    "Doddaballapur Industrial Area",
    "Doddaballapura Apparel Park",
    "Donimalai Township",
    "Elwala",
    "Gadag Betigeri",
    "Gajendragarh",
    "Gangawati",
    "Ganjimutt",
    "Gargeswari",
    "Gauribidanur",
    "Gejjalagere Industrial Area",
    "Hoovina Hadagalli",
    "Gogipeth",
    "Gokak",
    "Gokak Falls",
    "Gonikoppal",
    "Gubbi",
    "Gudibanda",
    "Gudur",
    "Gulbarga",
    "Guledgudda",
    "Gundlupet",
    "Gurmatkal",
    "Halga",
    "Haliyal",
    "Hampi",
    "Hangal",
    "Hanur",
    "Hosanagara",
    "Hosdurga",
    "Hoskote",
    "Hoskote Industrial Area",
    "Hospet",
    "Hubli",
    "Hukeri",
    "Huliyar",
    "Humnabad Industrial Area",
    "Hungund",
    "Hunsur",
    "Ilkal",
    "Indi",
    "Jagalur",
    "Jamkhandi",
    "Jevargi",
    "Mudalgi",
    "Mudbidri",
    "Muddebihal",
    "Mudgal",
    "Mudhol",
    "Mudigere",
    "Muduperar",
    "Mulbagal",
    "Mulgund",
    "Mulki",
    "Mulur",
    "Mundargi",
    "Mundargi Industrial Area",
    "Mundgod",
    "Munirabad Project Area",
    "Munnuru",
    "Mutga",
    "Mutga Gulbarga District",
    "Mysore",
    "Pavagada",
    "Periyapatna",
    "Pudu",
    "Puttur",
    "Rabkavi-Banhatti",
    "Raichur",
    "Raichur Growth Centre Industrial Area",
    "Ramanagara",
    "Ramdurg",
    "Ranebennur",
    "Raybag",
    "Sadalgi",
    "Sagagur",
    "Sagar",
    "Sajipanadu",
    "Sakleshpur",
    "Saligram",
    "Shirhatti",
    "Shorapur",
    "Shravanabelgola",
    "Siddapur",
    "Sidlaghatta",
    "Sindgi",
    "Sindhnur",
    "Sira",
    "Siralkoppa",
    "Sirsi",
    "Siruguppa",
    "Solebhavi",
    "Somvarpet",
    "Sorab",
    "Sringeri",
    "Srinivaspur",
    "Srirampura",
    "Srirangapatna",
    "Sulya",
    "T Narsipura",
    "Talikota",
    "Talipady",
    "Tarikere",
    "Tattilli",
    "Tekkalakote",
    "Terdal",
    "Thandya Industrial Estate",
    "Thimmenahalli Industrial Area",
    "Thumbe",
    "Tiptur",
    "Tirthahalli",
    "Tumkur",
    "Turuvekere",
    "Udupi",
    "Ullal",
    "Uppinangady",
    "Vaddu",
    "Varamballi",
    "Vasantha Narasapura Industrial Area",
    "Venkatapura",
    "Vijayapura",
    "Virajpete",
    "Vittal",
    "Wadi",
    "Yadgir",
    "Yelandur",
    "Yelbarga",
    "Yellapur",
    "Yellur",
    "Nadsal",
    "Nagamangala",
    "Nandi",
    "Nandur Kesartigi Industrial Area",
    "Sankeshwar",
      "Koramangala",
      "Indiranagar",
      "Jayanager",
      "Whitefield",
      "Electronic City",
      "BTM Layout",
      "Malleshwaram",
      "Jayanagar",
      "Marathahalli",
      "Bannerghatta Road",
      "HSR Layout",
      "Banashankari",
      "Hebbal",
      "Basavanagudi",
      "Yelahanka",
      "Rajajinagar",
      "HSR Layout",
      "Basaveshwaranagar",
      "Kengeri",
      "Vijayanagar",
      "Ulsoor",
      "KR Puram",
      "Sarjapur Road",
      "Peenya",
      "C V Raman Nagar",
      "Shivaji Nagar",
      "Domlur",
      "Kumaraswamy Layout",
      "Brookefield",
      "Bellandur",
      "Hennur",
      "HBR Layout",
      "Nagarbhavi",
      "Jalahalli",
      "Mahadevapura",
      "Kaggadasapura",
      "Hosur Road",
      "Devanahalli",
      "Sahakara Nagar",
      "Vidyaranyapura",
      "Basavangudi",
      "Wilson Garden",
      "Koramangala 5th Block",
      "Koramangala 7th Block",
      "Langford Town",
      "Richmond Town",
      "Cox Town",
      "Frazer Town",
      "Shanti Nagar",
      "Banaswadi",
      "Yeshwanthpur",
      "Kammanahalli",
      "Rajaji Nagar",
      "Padmanabhanagar",
      "RT Nagar",
      "Hulimavu",
      "Bommanahalli",
      "Chamrajpet",
      "Akshaya Nagar",
      "Thanisandra",
      "Old Airport Road",
      "Varthur",
      "Ramamurthy Nagar",
      "Kalyan Nagar",
      "Yelahanka New Town",
      "Kasturi Nagar",
      "Nagawara",
      "Begur Road",
      "Jeevan Bhima Nagar",
      "Kudlu Gate",
      "Bommasandra",
      "Amruthahalli",
      "Yelahanka Old Town",
      "Arekere",
      "Bilekahalli",
      "Gottigere",
      "HSR Layout",
      "Koramangala 1st Block",
      "Koramangala 2nd Block",
      "Koramangala 3rd Block",
      "Koramangala 4th Block",
      "Koramangala 6th Block",
      "Koramangala 8th Block",
      "Koramangala 9th Block",

    ],
  },

  {
    name: 'Kerala',
    cities: [ "Abdu Rahiman Nagar",
    "Adat",
    "Adichanalloor",
    "Adinad",
    "Adoor",
    "Aimanam",
    "Ajanur",
    "Akathiyoor",
    "Ala",
    "Alamcode",
    "Alamcode Thiruvananthapuram District",
    "Alappuzha",
    "Alathur",
    "Alur",
    "Aluva",
    "Amballur",
    "Ancharakandy Angadipuram",
    "Angamaly",
    "Anthicad",
    "Ariyallur",
    "Arookutty",
    "Aroor",
    "Athirampuzha",
    "Athiyannur",
    "Atholi",
    "Attingal",
    "Avanur",
    "Avinissery",
    "Avittathur",
    "Ayancheri",
    "Ayanivelikulangara",
    "Azhikode",
    "Azhiyur",
    "Azhoor",
    "Balusseri",
    "Bangramanjeshwar",
    "Beypore",
    "Bharanikkavu",
    "Brahmakulam",
    "Chala",
    "Chalakudy",
    "Changanacherry",
    "Chavakkad",
    "Chavara",
    "Chekkiad",
    "Chelakkara",
    "Chelannur",
    "Cheleri",
    "Chelora",
    "Chemancheri",
    "Chendamangalam",
    "Chendrappini",
    "Chengala",
    "Chengamanad",
    "Chengannur",
    "Chennithala",
    "Cheppad",
    "Cheranallur",
    "Cheriyamundam",
    "Cherthala",
    "Cherukavu",
    "Cheruthazham",
    "Cheruthuruthi",
    "Chevvoor",
    "Chingoli",
    "Chirakkal",
    "Chiramanangad",
    "Chiranellur",
    "Chittanda",
    "Chittur-Thathamangalam",
    "Chockli",
    "Choolissery",
    "Choondal",
    "Choornikkara",
    "Chowwara",
    "Desamangalam",
    "Dharmadom",
    "Edacheri",
    "Edakkode",
    "Edappal",
    "Edathala",
    "Edathirinji",
    "Edathiruthy",
    "Edavilangu",
    "Elampalloor",
    "Elayavoor",
    "Eloor",
    "Enkakkad",
    "Eramala",
    "Eramalloor",
    "Eranellur",
    "Eranholi",
    "Erattupetta",
    "Eravattur",
    "Eravu",
    "Ernakulam",
    "Eruvatti",
    "Ettumanoor",
    "Eyyal",
    "Feroke",
    "Guruvayur",
    "Haripad",
    "Hosabettu",
    "Idukki Township",
    "Irikkur",
    "Irimbiliyam",
    "Iringal",
    "Iringaprom",
    "Irinjalakuda",
    "Iriveri",
    "Iroopara",
    "Kadachira",
    "Kadamakkudy",
    "Kadannappalli",
    "Kadavallur",
    "Kadikkad",
    "Kadirur",
    "Kadungalloor",
    "Kaipamangalam",
    "Kalady",
    "Kalady Ernakulam District",
    "Kalamassery",
    "Kallelibhagom",
    "Kallettumkara",
    "Kalliasseri",
    "Kalliyoor",
    "Kallur- Thekkummuri",
    "Kalpetta",
    "Kandamkunnu",
    "Kandanassery",
    "Kanhangad",
    "Kanhirode",
    "Kaniyarkode",
    "Kanjikkuzhi",
    "Kanjiramkulam",
    "Kannadiparamba",
    "Kannamangalam",
    "Kannamangalam Alappuzha District",
    "Kannapuram",
    "Kannur",
    "Karamuck",
    "Karikkad",
    "Karivellur",
    "Kariyannur",
    "Karthikappally",
    "Karunagappally",
    "Karuvanthuruthy",
    "Kasaragod",
    "Kattakampal",
    "Kattanam",
    "Kattappana",
    "Kattipparuthi",
    "Kattur",
    "Kayamkulam",
    "Keekan",
    "Keerikkad",
    "Keezhallur",
    "Keezhariyur",
    "Kinfra Small Industries Park",
    "Kizhakkummuri",
    "Kizhuparamba",
    "Kizhuppillikkara",
    "Kizhuvalam -Koonthalloor",
    "Kochi",
    "Kodungallur",
    "Kodur",
    "Koipady",
    "Kolacherry",
    "Kolavelloor",
    "Kolazhy",
    "Kollam",
    "Komalapuram",
    "Kondotty",
    "Koodali",
    "Koothali",
    "Koothuparamba",
    "Koottilangadi",
    "Koratty",
    "Kothamangalam",
    "Kottakal",
    "Kottamkara",
    "Kottappally",
    "Kottappuram",
    "Kottarakkara",
    "Kottayam",
    "Kottayam-Malabar",
    "Kottuvally",
    "Kovalam",
    "Kozhenchery",
    "Kozhikode",
    "Kozhukkallur",
    "Kozhukkully",
    "Krishnapuram",
    "Kudlu",
    "Kulasekharapuram",
    "Kulathummal",
    "Kumarakom",
    "Kumarapuram",
    "Kumbalangy",
    "Kumily",
    "Kunjathur",
    "Kunnamkulam",
    "Kunnathunad",
    "Kunnummal",
    "Kurattissery",
    "Kureekkad",
    "Kurumathur",
    "Kurumpilavu",
    "Kuruvattur",
    "Kuthiathode",
    "Kuttiattoor",
    "Kuttippuram",
    "Madhur",
    "Malappuram",
    "Manakkody",
    "Manakunnam",
    "Manalur",
    "Manantheri",
    "Manavalassery",
    "Mangalpady",
    "Mangattidam",
    "Maniyat",
    "Maniyoor",
    "Maniyur",
    "Manjeri",
    "Manjeshwar",
    "Mannanchery",
    "Mannar",
    "Mannarkad-I",
    "Marampilly",
    "Marancheri",
    "Marathakkara",
    "Mattannur",
    "Mavelikkara",
    "Mavilayi",
    "Mavoor",
    "Mayyanad",
    "Mayyil",
    "Meenad",
    "Menhaniam",
    "Meppayyur",
    "Mokeri",
    "Moonniyur",
    "Muhamma",
    "Mulamthuruthy",
    "Mulavukad",
    "Mullassery",
    "Mundathikode",
    "Munderi",
    "Munnar",
    "Muthukulam",
    "Muthuthala",
    "Muvattupuzha",
    "Muzhappilangad",
    "Nadapuram",
    "Nadathara",
    "Naduvannur",
    "Naduvattom",
    "Nanmanda",
    "Nannambra",
    "Narath",
    "Nattakam",
    "Nedumangad",
    "Nedumpana",
    "Nedumpura",
    "Neduva",
    "Nelluwaya",
    "Nenmenikkara",
    "New Mahe",
    "Neyyattinkara",
    "Nilambur",
    "Njarackal",
    "North -Thrikkaripur",
    "Oachira",
    "Olavanna",
    "Ongallur- I",
    "Ongallur- II",
    "Othukkungal",
    "Ottappalam",
    "Padiyam",
    "Padiyur",
    "Paduvilayi",
    "Palai",
    "Palakkad",
    "Palayad",
    "Palissery",
    "Pallichal",
    "Pallikkal",
    "Pallikkara",
    "Pallikkunnu",
    "Pallippuram",
    "Pallippuram Thiruvananthapuram District",
    "Paluvai",
    "Panachikkad",
    "Panangad",
    "Panangad Kozhikode District",
    "Panayam",
    "Panmana",
    "Panniyannur",
    "Panoor",
    "Parakkad",
    "Parappukkara",
    "Parappur",
    "Parassala",
    "Parasuvaikkal",
    "Paravoor",
    "Paravur",
    "Pariyaram",
    "Pathanamthitta",
    "Pathirappally",
    "Pathiriyad",
    "Pathiyoor",
    "Pattiom",
    "Pavaratty",
    "Payyannur",
    "Pazhanji",
    "Peermade",
    "Perakam",
    "Perakam",
    "Perakam",
    "Perakam",
    "Perambra",
    "Perinad",
    "Peringathur",
    "Perinjanam",
    "Perinthalmanna",
    "Perole",
    "Perumanna",
    "Perumbaikad",
    "Perumbavoor",
    "Peruvallur",
    "Pilicode",
    "Pinarayi",
    "Ponmundam",
    "Ponnani",
    "Poomangalam",
    "Poothakkulam",
    "Porathissery",
    "Pottore",
    "Pudussery Central",
    "Pudussery West",
    "Punalur",
    "Punnayur",
    "Punnayurkulam",
    "Puranattukara",
    "Puthencruz",
    "Puthukkad",
    "Puthunagaram",
    "Puthuppally",
    "Puthur",
    "Puzhathi",
    "Quilandy",
    "Ramanattukara",
    "Shiriya",
    "Shoranur",
    "South - Thrikkaripur",
    "Talakkad",
    "Talikkulam",
    "Taliparamba",
    "Tanalur",
    "Thaikattussery",
    "Thaikkad",
    "Thalakkulathur",
    "Thalassery",
    "Thangalur",
    "Thanniyam",
    "Thazhakara",
    "Thazhecode",
    "Thazhuthala",
    "Thekkumkara",
    "Thennala",
    "Thikkody",
    "Thirumittacode-II",
    "Thirunavaya",
    "Thiruvalla",
    "Thiruvananthapuram",
    "Thiruvankulam",
    "Thodiyoor",
    "Thodupuzha",
    "Thottada",
    "Thrikkadavoor",
    "Thrikkaruva",
    "Thrikkovilvattom",
    "Thrippunithura",
    "Thrissur",
    "Thrithala",
    "Thuneri",
    "Thurayur",
    "Tirur",
    "Tirurangadi",
    "Trikkur",
    "Triprangode",
    "Udma",
    "Uliyazhathura",
    "Ulliyeri",
    "Uppala",
    "Urakam",
    "Vadakara",
    "Vadakkekad",
    "Vadakkumkara",
    "Vadakkummuri",
    "Vadakkumthala",
    "Vadama",
    "Vadanappally",
    "Vaikom",
    "Vakkom",
    "Valapattanam",
    "Valayam",
    "Vallachira",
    "Vandiperiyar",
    "Vaniyamkulam-II",
    "Varam",
    "Varappuzha",
     "Other"],
  },

  {
name:'Lakshadweep',
cities:[
  "Agatti",
    "Amini",
    "Kavaratti",
    "Minicoy",
    "Other"
]
  },

  {
    name: 'Madhya Pradesh',
    cities: [
      "Agar",
    "Ahirkhedi",
    "Ahirkhedi, Indore District",
    "Ajaygarh",
    "Akoda",
    "Akodia",
    "Alampur",
    "Alirajpur",
    "Alot",
    "Amanganj",
    "Amarkantak",
    "Amarpatan",
    "Amarwara",
    "Ambada",
    "Ambah",
    "Amkhera",
    "Amla",
    "Amlai",
    "Anjad",
    "Antari",
    "Anuppur",
    "Aron",
    "Ashoknagar",
    "Ashta",
    "Athner",
    "Babai",
    "Bada-Malhera",
    "Badarwas",
    "Badi",
    "Badkuhi",
    "Badnagar",
    "Badnawar",
    "Badod",
    "Badoni",
    "Badra",
    "Bagh",
    "Bagli",
    "Baihar",
    "Baikunthpur",
    "Balaghat",
    "Baldeogarh",
    "Bamhani",
    "Bamor",
    "Banda",
    "Bandhawagarh",
    "Bangarda Bada",
    "Bangawan",
    "Bank",
    "Bansatar Kheda",
    "Baragaon",
    "Baragaon Shajapur",
    "Barauda",
    "Barela",
    "Bareli",
    "Barghat",
    "Bargi",
    "Barhi",
    "Barigarh",
    "Barman Kalan",
    "Baroda",
    "Barwaha",
    "Barwani",
    "Basoda",
    "Begamganj",
    "Beohari",
    "Berasia",
    "Betma",
    "Betul",
    "Betul Bazar",
    "Bhainsa",
    "Bhainsdehi",
    "Bhander",
    "Bhanpura",
    "Bharveli",
    "Bhavra",
    "Bhedaghat",
    "Bhicholi Hapsi",
    "Bhikangaon",
    "Bhilakhedi",
    "Bhind",
    "Bhitarwar",
    "Bhonrasa",
    "Bhopal",
    "Biaora",
    "Bichhiya",
    "Bichhiya Vidisha District",
    "Bijawar",
    "Bijuri",
    "Bilaua",
    "Bina",
    "Birsinghpur",
    "Boda",
    "Borgaon",
    "Budni",
    "Burhanpur",
    "Burhar",
    "Buxwaha",
    "Chachaura-Binaganj",
    "Chakghat",
    "Chandameta Butaria",
    "Chanderi",
    "Chandia",
    "Chandla",
    "Chaurai Khas",
    "Chhapiheda",
    "Chhatarpur",
    "Chhindwara",
    "Chhipabad",
    "Chhota Chhindwara",
    "Chichli",
    "Chicholi",
    "Chitrakoot",
    "Churhat",
    "Daboh",
    "Dabra",
    "Dahi",
    "Damoh",
    "Damua",
    "Datia",
    "Dehrisaray",
    "Deodara",
    "Deohara",
    "Deori",
    "Deori, Shahdol District",
    "Depalpur",
    "Devendranagar",
    "Dewas",
    "Dhamnod",
    "Dhamnod Ratlam District",
    "Dhana",
    "Dhanpuri Nargada Hari Dafai",
    "Dhar",
    "Dharampuri",
    "Dhodaramohar Alias Bhoura",
    "Dighawani",
    "Diken",
    "Dindori",
    "Dola",
    "Dongar Parasia",
    "Dumar Kachhar",
    "Dungariya Chhapara",
    "Emlikheda Industrial Area",
    "Gadarwara",
    "Gairatganj",
    "Gamiria Sagar",
    "Gandhi Sagar Haidel",
    "Garhakota",
    "Garhi -Malhara",
    "Garoth",
    "Garra",
    "Gautampura",
    "Ghansaur",
    "Ghoda Dongri Ryt",
    "Ghuwara",
    "Gogapur",
    "Gohad",
    "Gormi",
    "Govindgarh",
    "Guna",
    "Gurh",
    "Gwalior",
    "Hanumana",
    "Harda",
    "Harduli",
    "Harpalpur",
    "Harrai",
    "Harsud",
    "Hat Piplia",
    "Hatod",
    "Hatpiplya",
    "Hatta",
    "Hindoria",
    "Hirapur",
    "Hirdepur",
    "Hoshangabad",
    "Ichhawar",
    "Iklehra",
    "Indergarh",
    "Indore",
    "Industrial Area Jagakhedi",
    "Isagarh",
    "Itarsi",
    "Jabalpur",
    "Jabera",
    "Jaisinghnagar",
    "Jaithari",
    "Jaitpur",
    "Jaitpur Chhatarpur District",
    "Jaitwara",
    "Jamai",
    "Jaora",
    "Jata Chhapar",
    "Jatara",
    "Jawad",
    "Jawar",
    "Jaypee Nagar",
    "Jeron Khalsa",
    "Jhabua",
    "Jhundpura",
    "Jiran",
    "Jirapur",
    "Jobat",
    "Joura",
    "Kailaras",
    "Kakarhati",
    "Kali Chhapar",
    "Kanad",
    "Kannod",
    "Kantaphod",
    "Kareli",
    "Karera",
    "Kari",
    "Karnawad",
    "Karrapur",
    "Kasrawad",
    "Katangi",
    "Katangi, Jabalpur District",
    "Kelhauri",
    "Khargapur",
    "Khargone",
    "Khetia",
    "Khilchipur",
    "Khirkiya",
    "Khor",
    "Khujner",
    "Khurai",
    "Kirnapur",
    "Kolar",
    "Kolaras",
    "Kosmi",
    "Kotar",
    "Kothi",
    "Kothri",
    "Kotma",
    "Kukdeshwar",
    "Kukshi",
    "Kumbhraj",
    "Kundam",
    "Kurwai",
    "Kymore",
    "Ladhaura",
    "Lahar",
    "Lakhnadon",
    "Lanji",
    "Lateri",
    "Laundi",
    "Lodhikheda",
    "Loharda",
    "Machalpur",
    "Madai",
    "Madhawgdha",
    "Maharajpur",
    "Maheshwar",
    "Mahidpur",
    "Mahura",
    "Maihar",
    "Maihar Cement Factory Sarlanagar",
    "Majhgawan",
    "Majhgawan Jabalpur District",
    "Majhgawan Township",
    "Majholi",
    "Makdon",
    "Makronia Buzurg",
    "Maksi",
    "Malajkhand",
    "Malanpur Industrial Area",
    "Malhargarh",
    "Maliya Guda",
    "Manasa",
    "Manawar",
    "Mandideep",
    "Mandla",
    "Mandleshwar",
    "Mandsaur",
    "Mandu",
    "Maneri Industrial Growth Centre",
    "Mangalya",
    "Mangawan",
    "Manpur",
    "Mau",
    "Mauganj",
    "Meghnagar",
    "Mehgaon",
    "Mhow",
    "Mihona",
    "Mohgaon",
    "Morar Cantt",
    "Morena",
    "Multai",
    "Mundi",
    "Mungaoli",
    "Murwara Katni",
    "Nagda",
    "Nagod",
    "Nagri",
    "Nai-Garhi",
    "Nainpur",
    "Nalkheda",
    "Namli",
    "Nanpur",
    "Narayangarh",
    "Narsimhapur",
    "Narsingarh",
    "Narwar",
    "Nasrullaganj",
    "Naudhia",
    "Neemuch",
    "Nepanagar",
    "New Harsud",
    "Newton Chikhli Kalan",
    "Niwari",
    "Niwas",
    "Nowgong",
    "Nowrozabad",
    "Omkareshwar",
    "Orchha",
    "Ordnance Factory Itarsi",
    "Pachmarhi",
    "Pachore",
    "Pal Chaurai",
    "Palera",
    "Palsud",
    "Panagar",
    "Panara",
    "Pandhana",
    "Pandhurna",
    "Pandhurna Industrial Area",
    "Pankhedi",
    "Panna",
    "Pansemal",
    "Pasan",
    "Patan",
    "Patharia",
    "Pawai",
    "Petlawad",
    "Phuphkalan",
    "Pichhore",
    "Pichhore, Gwalior District",
    "Pindrai",
    "Pipalrawan",
    "Pipariya",
    "Pipariya Jabalpur District",
    "Pipariya, Jabalpur District",
    "Piplanarayanwar",
    "Piploda",
    "Piplya Mandi",
    "Pipri",
    "Pithampur",
    "Pithampur Industrial Hub",
    "Polaykalan",
    "Porsa",
    "Prithvipur",
    "Purena",
    "Raghogarh",
    "Rahatgarh",
    "Raisen",
    "Rajakhedi",
    "Rajgarh",
    "Rajgarh, Dhar District",
    "Rajnagar",
    "Rajpur",
    "Rampur",
    "Rampur Baghelan",
    "Rampura",
    "Ranapur",
    "Ranipur",
    "Ratangarh",
    "Ratlam",
    "Rau",
    "Raymond Chhindwara Raymond Colony Vijay Gram",
    "Rehli",
    "Rehti",
    "Rewa",
    "Rosera",
    "Sabalgarh",
    "Sabo",
    "Sagar",
    "Sailana",
    "Sanawad",
    "Sanchi",
    "Sangvi",
    "Sanjay Gandhi Thermal Power Station",
    "Sanwer",
    "Sarangpur",
    "Sardarpur",
    "Sarni",
    "Satai",
    "Satna",
    "Satwas",
    "Sausar",
    "Sehore",
    "Semaria",
    "Sendhwa",
    "Seondha",
    "Seoni",
    "Seoni-Malwa",
    "Sethiya",
    "Shahdol",
    "Shahgarh",
    "Shahpur",
    "Shahpur, Betul District",
    "Shahpur, Sagar District",
    "Shahpura",
    "Shahpura, Jabalpur District",
    "Shajapur",
    "Shamgarh",
    "Shamshabad",
    "Sheopur",
    "Shivpuri",
    "Shujalpur",
    "Sidgunwa Industrial Area",
    "Sidhi",
    "Sihora",
    "Silwani",
    "Singoli",
    "Singrauli",
    "Sinhasa",
    "Sirgora",
    "Sirmaur",
    "Sironj",
    "Sitamau",
    "Siya Industrial Area",
    "Sohagi",
    "Sohagpur",
    "Sonkatch",
    "Soyatkalan",
    "Sultanpur",
    "Susner",
    "Suswasara",
    "Suthaliya",
    "Tal",
    "Talen",
    "Tarana",
    "Taricharkalan",
    "Tekanpur",
    "Tendukheda",
    "Tendukheda Damoh District",
    "Teonthar",
    "Thandla",
    "Tikamgarh",
    "Timarni",
    "Tirodi",
    "Tonk Khurd",
    "Ubaidullahganj",
    "Udaipura",
    "Udaypur",
    "Ujjain",
    "Ukwa",
    "Umaria",
    "Unchahara",
    "Unhel",
    "Vidisha",
    "Vijaypur Sheopur District",
    "Vijayraghavgarh",
    "Vindhya Nagar NTPC",
    "Waraseoni",
       "Other"],
  },

  {
    name: 'Maharashtra',
    cities: [
      "Aamby Valley",
      "Achalpur",
      "Achalpur MIDC",
      "Additional Chandrapur MIDC",
      "Aheri",
      "Ahmadnagar",
      "Ahmadpur",
      "Ahmadpur MIDC",
      "Ajanta",
      "Ajara",
      "Ajara MIDC",
      "Akkalkot",
      "Akkalkuwa",
      "Akluj",
      "Akola",
      "Akot",
      "Alandi",
      "Alibag",
      "Allapalli",
      "Amalner",
      "Amalner MIDC",
      "Ambad",
      "Ambarnath",
      "Ambejogai",
      "Ambepur",
      "Ambivali Tarf Wankhal",
      "Amboli",
      "Amgaon",
      "Amravati",
      "Anjangaon",
      "Anjarle",
      "Arvi",
      "Asangaon",
      "Asangaon Industrial Area",
      "Ashta",
      "Badlapur",
      "Balapur",
      "Balapur Akola District",
      "Balapur MIDC",
      "Ballarpur",
      "Banda",
      "Baramati",
      "Barshi",
      "Basmat MIDC",
      "Basmath",
      "Bela",
      "Bhabale MIDC",
      "Bhadgaon",
      "Bhadravati",
      "Bhadravati MIDC",
      "Bhalar Township",
      "Bhandara",
      "Bhandara MIDC",
      "Bhatkuli",
      "Bhatkuli MIDC",
      "Bhiwandi",
      "Bhiwapur",
      "Bhiwapur MIDC",
      "Bhokar",
      "Bhokardan",
      "Bhor",
      "Bhum",
      "Bhusawal",
      "Bhusawal MIDC",
      "Bid",
      "Biloli",
      "Borkhedi",
      "Borli Panchtan",
      "Brahmanvel MIDC",
      "Brahmapuri",
      "Budhagon",
      "Budhgaon",
      "Buldana",
      "Butibori",
      "Butibori MIDC",
      "Chakan",
      "Chalisgaon",
      "Chandgad",
      "Chandrapur",
      "Chandrapur CGGC",
      "Chandrapur MIDC",
      "Chandur",
      "Chandur Amravati District",
      "Chandurbazar",
      "Chandvad",
      "Chicholi",
      "Chikhala",
      "Chikhaldara",
      "Chikhli",
      "Chimur",
      "Chinchani",
      "Chincholi MIDC",
      "Chiplun",
      "Chitegaon",
      "Chopda",
      "Chowk",
      "Dabhol",
      "Dadar",
      "Dahanu",
      "Dahivadi",
      "Dandi",
      "Dapoli",
      "Dapoli Camp",
      "Dashti",
      "Dashti Gadchiroli District",
      "Dashti MIDC",
      "Dashti Wardha District",
      "Atgaon Industrial Complex",
      "Birwadi",
      "Aurangabad",
      "Ausa",
      "Awalpur",
      "Babhaleshwar",
      "Babhulgaon",
      "Bisur",
      "Boisar",
      "Borgaon",
      "Bori",
      "Borivali Tarf Rahur",
      "Darwha",
      "Daryapur Banosa",
      "Dattapur",
      "Dhamangaon",
      "Dehu",
      "Dehu Cantt",
      "Deogiri",
      "Deola",
      "Deolali Pravara",
      "Deoli",
      "Deoni",
      "Deori",
      "Desaiganj",
      "Deulgaon Raja",
      "Deulgaon Raja MIDC",
      "Devgad",
      "Devrukh",
      "Dharangaon",
      "Dharmabad",
      "Dharni",
      "Dharni MIDC",
      "Dharur",
      "Dharur MIDC",
      "Dhopatala",
      "Dhule",
      "Digras",
      "Dindori",
      "Dindori MIDC",
      "Dodamarg",
      "Dondaicha Warwade",
      "Dudhani",
      "Durgapur",
      "Eklahare",
      "Elephanta Caves",
      "Ellora",
      "Erandol",
      "Etapalli",
      "Faizpur",
      "Gadchiroli",
      "Gadhinglaj",
      "Gadhinglaj MIDC",
      "Gane MIDC",
      "Ganeshpur",
      "Gangakhed",
      "Gangapur",
      "Ganpatipule",
      "Gargoti",
      "Genesis Industrial",
      "Hinganghat MIDC",
      "Hingna",
      "Hingoli",
      "Hingoli MIDC",
      "Honad Industrial Area",
      "Hudkeshwar",
      "Hupari",
      "Ichalkaranji",
      "Igatpuri",
      "Indapur",
      "Indapur MIDC",
      "Jafrabad",
      "Jafrabad MIDC",
      "Jalgaon",
      "Jalgaon Jamod",
      "Jalkot",
      "Jalna",
      "Jalochi",
      "Jamkhed",
      "Jamner",
      "Jath",
      "Jawhar",
      "Jawharnagar",
      "Jaysingpur",
      "Jejuri",
      "Kalameshwar",
      "Kalameshwar MIDC",
      "Kalamnuri",
      "Kallam",
      "Kalmath",
      "Kalwan",
      "Kalyan",
      "Kamalapur MIDC",
      "Kambe",
      "Kamptee",
      "Kamptee Cantt",
      "Kamshet",
      "Kandari",
      "Kandhar",
      "Kandhar MIDC",
      "Kandri",
      "Kandri Mines",
      "Kandri Nagpur",
      "Kanhan",
      "Kanhe Industrial Area",
      "Kanholibara",
      "Kankavli",
      "Kannad",
      "Karad",
      "Karalewadi",
      "Karanja",
      "Karjat",
      "Karjat Ahmadnagar District",
      "Karmala",
      "Kasara",
      "Katangi Kala",
      "Katol",
      "Katol MIDC",
      "Kavalapur",
      "Kavathe Mahankal",
      "Kelapur Township",
      "Georai",
      "Ghansawangi",
      "Ghatanji",
      "Ghoti",
      "Ghugus",
      "Ghulewadi",
      "Gokunda",
      "Gondia MIDC",
      "Gondiya",
      "Gondpipri",
      "Goregaon",
      "Goregaon Gondiya District",
      "Jejuri MIDC",
      "Goregaon MIDC",
      "Guhagar",
      "Hadgaon",
      "Hajarmachi",
      "Halkarni MIDC",
      "Harihareshwar",
      "Harsul",
      "Hatkanangle",
      "Himayatnagar",
      "Hindnagar",
      "Hinganghat",
      "Jintur",
      "Jintur MIDC",
      "Jiwati",
      "Junnar",
      "Kabnur",
      "Kadegaon",
      "Kadegaon MIDC",
      "Kagal",
      "Kagal MIDC",
      "Kaij",
      "Kalamb Khamari",
      "Khamgaon",
      "Khanapur",
      "Khandala",
      "Khandbara",
      "Khapa",
      "Khapar",
      "Kharbav",
      "Khardi",
      "Khatav",
      "Khed",
      "Kherdi",
      "Khopoli",
      "Kinwat",
      "Kolhapur",
      "Kolki",
      "Kondumal",
      "Kopargaon",
      "Koregaon",
      "Koregaon Bhima",
      "Korochi",
      "Krushnoor MIDC",
      "Kudal",
      "Kudal MIDC",
      "Kudus",
      "Kuhi",
      "Kumbhari",
      "Kundalwadi",
      "Kurduwadi",
      "Kurduwadi MIDC",
      "Kurkheda",
      "Kurkumbh MIDC",
      "Kurul",
      "Kurundvad",
      "Kusgaon Budruk",
      "Lakhamapur MIDC",
      "Lakhandhur",
      "Lakshmi Cooperative Industrial Estate",
      "Lanja",
      "Lasalgaon",
      "Lat",
      "Latur",
      "Latur Industrial Area Additional",
      "Lavasa",
      "Loha",
      "Lohara",
      "Lohara Osmanabad",
      "Lonand",
      "Lonar",
      "Lonavala",
      "Loni",
      "Loni Kalbhor",
      "Lote Parshuram MIDC",
      "Madangad",
      "Madha",
      "Madhavnagar",
      "Mahabaleshwar",
      "Mahad",
      "Mahad MIDC",
      "Mahad MIDC Additional",
      "Mahadula",
      "Mahagaon",
      "Mahagaon MIDC",
      "Mahapoli",
      "Mahoor",
      "Maindargi",
      "Majgaon",
      "Makranifali",
      "Malde",
      "Malegaon",
      "Malegaon Jahangir",
      "Malegaon MIDC",
      "Malkapur",
      "Malkapur Buldana District",
      "Malkapur MIDC",
      "Malshiras",
      "Malwan",
      "Manchar",
      "Mandur",
      "Mangalvedha",
      "Mangrulpir",
      "Mangrulpir MIDC",
      "Manjlegaon",
      "Manmad",
      "Manor",
      "Manora",
      "Mansar",
      "Mantha",
      "Manwath",
      "Maregaon",
      "Matheran",
      "Mauda",
      "Medha",
      "Mehkar",
      "Mehkar MIDC",
      "Mhasla",
      "Mhaswad",
      "MIHAN",
      "Mohadi",
      "Mohadi MIDC",
      "Mohol",
      "Mohol MIDC",
      "Mohpa",
      "Mohpada Alias Wasambe",
      "Mokhada",
      "Morshi",
      "Morshi MIDC",
      "Motala",
      "Mouje Anjanvel",
      "Mowad",
      "Mudkhed",
      "Mukhed",
      "Muktainagar",
      "Mul",
      "Mul MIDC",
      "Mulchera",
      "Mumbai",
      "Murbad",
      "Murbad MIDC",
      "Murgud",
      "Murmadi",
      "Murud",
      "Murum",
      "Musalgaon MIDC",
      "Nagbhid MIDC",
      "Nagbhind",
      "Nagothana",
      "Nagpur",
      "Naigaon",
      "Nakoda",
      "Naldurg",
      "Nampur",
      "Nanda",
      "Nanded Waghala",
      "Nandgaon",
      "Nandgaon Khandeshwar",
      "Nandgaon Pode",
      "Nandgaon Tarf Birwadi",
      "Nandgaonpeth MIDC",
      "Nandura",
      "Nandurbar",
      "Narkher",
      "Nashik",
      "Natepute",
      "Navapur",
      "Navi Mumbai",
      "Ner",
      "Neral",
      "Newasa",
      "Nijampur",
      "Nilanga",
      "Nimbhore Budruk",
      "Niphad",
      "Nira",
      "Ordnance Factory Bhadravati",
      "Osmanabad",
      "Ozar",
      "Pachora",
      "Padagha",
      "Padoli",
      "Paithan",
      "Palam",
      "Palghar",
      "Pali",
      "Palus",
      "Palus MIDC",
      "Pampalner",
      "Panchgani",
      "Pandare MIDC",
      "Pandharkaoda",
      "Pandharpur",
      "Panhala",
      "Paranda",
      "Paratwada",
      "Parbhani",
      "Parli",
      "Parner",
      "Parola",
      "Parshioni",
      "Partur",
      "Partur MIDC",
      "Patalganga MIDC",
      "Patan",
      "Patan MIDC",
      "Pathan MIDC",
      "Pathardi",
      "Pathri",
      "Patoda",
      "Patur",
      "Pauni",
      "Peint",
      "Peint MIDC",
      "Pen",
      "Phaltan",
      "Pimpalgaon Baswant",
      "Pimpri Chinchwad",
      "Pipri",
      "Pirangut",
      "Poladpur",
      "Pombhurna",
      "Pulgaon",
      "Pune",
      "Purna",
      "Purushottamnagar",
      "Pusad",
      "Pusad MIDC",
      "Radhanagari",
      "Rahata",
      "Rahimatpur",
      "Rahuri",
      "Rajapur",
      "Rajgurunagar",
      "Rajur",
      "Rajur Ahmadnagar District",
      "Sawangi Megh",
      "Rajura",
      "Sawantwadi",
      "Ralegaon",
      "Ramtek",
      "Ranjangaon",
      "Ranjangaon MIDC",
      "Rasal Industrial Area",
      "Ratnagiri",
      "Raver",
      "Rees",
      "Renapur",
      "RIL Township",
      "Risod",
      "Roha Ashtami",
      "Roha MIDC",
      "Sadak Arjuni",
      "Sadavali MIDC",
      "Saidapur",
      "Sakoli",
      "Sakri",
      "Salekasa",
      "Samudrapur",
      "Samudrapur MIDC",
      "Sanaswadi",
      "Sangameshwar",
      "Sangamner",
      "Sangamner MIDC",
      "Sangli Miraj Kupwad",
      "Sangole",
      "Saoner MIDC",
      "Sasti",
      "Sasvad",
      "Satana",
      "Satara",
      "Savda",
      "Savner",
      "Sawali",
      "Seloo",
      "Selu",
      "Sengaon",
      "Shahada",
      "Shahapur",
      "Shahapur Bhandara District",
      "Shani Shingnapur",
      "Shegaon",
      "Shendra MIDC",
      "Shendurjana",
      "Shevgaon",
      "Shikrapur",
      "Shinde MIDC",
      "Shirala",
      "Shirala MIDC",
      "Shirdi",
      "Shirgaon",
      "Shirgaon Industrial Area",
      "Shirol",
      "Shirpur",
      "Shirur",
      "Shirur Anantpal",
      "Shirur Kasar",
      "Shirwal",
      "Shivaji Nagar",
      "Shrigonda",
      "Shrirampur",
      "Shrivardhan",
      "Sillewara",
      "Sillod",
      "Sindewahi",
      "Sindewahi MIDC",
      "Sindi",
      "Sindkhed Raja",
      "Sindkheda",
      "Singnapur Kopargaon",
      "Sinnar",
      "Sinnar MIDC",
      "Sironcha",
      "Solapur",
      "Sonai",
      "Sonegaon",
      "Songirwadi",
      "Sonpeth",
      "Supa MIDC",
      "Surgana",
      "Tadali",
      "Takalghat",
      "Takwe Industrial Area",
      "Tala",
      "Talasari",
      "Talegaon Dabhade",
      "Talegaon MIDC",
      "Taloda",
      "Tarapur",
      "Tarapur Textile Park",
      "Tasawade MIDC",
      "Tasgaon",
      "Tekadi",
      "Telhara",
      "Tembhurni MIDC",
      "Thana",
      "Thane",
      "Tirora",
      "Tirora MIDC",
      "Toranmal",
      "Totaladoh",
      "Trimbak",
      "Tuljapur",
      "Tumsar",
      "Tumsar Road",
      "Udgir",
      "Umarga",
      "Umarga MIDC",
      "Umarsara",
      "Umbar Pada Nandade",
      "Umred",
      "Umred MIDC",
      "Umri",
      "Uran Islampur",
      "Urjagram",
      "Usar MIDC",
      "Utekhol",
      "Vada",
      "Vadgaon",
      "Vaduj",
      "Vaibhawadi",
      "Vaijapur",
      "Vaijapur MIDC",
      "Vangani",
      "Vanvadi",
      "Varangaon",
      "Vasai Virar",
      "Vasantnagar",
      "Vashind",
      "Velhe",
      "Vengurla",
      "Vijay Durg",
      "Vikramgad",
      "Vile Bhagad MIDC",
      "Vinchur",
      "Vinchur MIDC",
      "Visapur",
      "Vita",
      "Vita MIDC",
      "Wada",
      "Wadgaon",
      "Wadi Ratnagiri",
      "Wadwani",
      "Waghapur",
      "Waghoda",
      "Wai",
      "Wai MIDC",
      "Walani",
      "Waluj MIDC",
      "Walwa",
      "Wani",
      "Wardha",
      "Warora",
      "Warora MIDC",
      "Warthi",
      "Warud",
      "Warud MIDC",
      "Washi",
      "Washim",
      "WCL Umred",
      "Yavatmal",
      "Yavatmal Industrial Area",
      "Yawal",
      "Yeola",
      "Yerkheda",
      "Other"
  ]
  
  },

  {
    name: 'Manipur',
    cities: [
      "Andro", "Bishnupur", "Heirok", "Imphal", "Jiribam", "Kakching", "Kakching Khunou", "Kangpokpi", 
      "Kumbi", "Kwakta", "Lamlai", "Lamsang", "Lilong", "Lilong Imphal West", "Lilong Thoubal", "Mayang Imphal", 
      "Moirang", "Moreh", "Nambol", "Ningthoukhong", "Oinam", "Samurou", "Samurou Imphal West District", 
      "Sekmai Bazar", "Senapati", "Sikhong Sekmai", "Sugnu", "Tamenglong", "Thongkhong Laxmi Bazar", "Thoubal", 
      "Torban", "Ukhrul", "Wangjing", "Wangoi", "Yairipok", "Zenhang Lamka", "Other"
  ]
  },

  {
    name: 'Meghalaya',
    cities: ['Baghmara', 'Cherrapunji', 'Jowai', 'Lawsohtun', 'Madanriting', 'Mairang', 'Mawlai', 'Mawpat',
    'Nongkseh', 'Nongmynsong', 'Nongpoh', 'Nongstoin', 'Nongthymmai', 'Resubelpara', 'Shillong', 
    'Cantonment', 'Tura', 'Umlyngka', 'Umpling', 'Umroi', 'Williamnagar', 'Other']
  },
  {
    name: 'Mizoram',
    cities: [ "Aizawl", "Bairabi", "Biate", "Champhai", "Darlawn", "Hnahthial", "Khawhai", "Khawzawl", "Kolasib", 
    "Lawngtlai", "Lengpui", "Lunglei", "Mamit", "Kawnpui", "Vanlaiphai", "Saiha", "Sairang", "Saitual", 
    "Serchhip", "Thenzawl", "Tlabung", "Vairengte", "Zawlnuam", 'Other'],
  },

  {
    name: 'Nagaland',
    cities: ["Changtongya", "Chumukedima", "Dimapur", "Jalukie", "Kiphire", "Kohima", "Longleng", "Medziphema", 
    "Mokokchung", "Mon Town", "Naginimora", "Peren", "Pfutsero", "Phek", "Satakha", "Tseminyu", 
    "Tuensang", "Tuli", "Tzudikong", "Wokha", "Zunheboto", 'Other'],
  },

  {
    name: 'Orissa',
    cities: ["Agastinuagan", "Anandapur", "Anandpur", "Anjira", "Anjira Jagatsinghapur District", "Anugul", "Arjyapalli", 
    "Asika", "Athagad", "Athmallik", "Badagada", "Badakodanda", "Badamba", "Badmal", "Balagoda", "Balakati", 
    "Balangir", "Baleshwar", "Baliguda", "Balimela", "Balipatapur", "Balugaon", "Banapur", "Bandhbahal", 
    "Bangomunda", "Bangura", "Banki", "Barapali", "Barbil", "Bardol", "Bargarh", "Baripada", "Basudebpur", 
    "Baudhgarh", "Belagachhia", "Bellaguntha", "Belpahar", "Berhampur", "Bhadrak", "Bhakarsahi", 
    "Bhanjanagar", "Bhapur", "Bhatli", "Bhawanipatna", "Bhuban", "Bhubaneswar", "Bhushan Steel Plant Jharsuguda", 
    "Bhushan Steel Plant Meramandali", "Bijepur", "Binika", "Biramitrapur", "Birapratappur", "Bishama Katak", 
    "Bonaigarh", "Borigam", "Boriguma", "Brahmabarada", "Brajarajnagar", "Budhapanka", "Buguda", "Bundia", 
    "Burla", "Byasanagar", "Champua", "Chandapur", "Chandili", "Charibatia", "Chhatrapur", "Chikiti", 
    "Chitrakonda", "Choudwar", "Cuttack", "Dadhapatna", "Daitari", "Damanjodi", "Danara", "Daringbadi", 
    "Debagarh", "Dera Colliery Township", "Dhamanagar", "Dhenkanal", "Digapahandi", "Dungamal", "FCI Township", 
    "G Udayagiri", "Ganjam", "Ghantapada", "Ghatagaon", "GMR Power Plant Kmalanga", "Godiputamatiapara", 
    "Golabandha", "Gopalpur", "Gotamara", "Gudari", "Gunupur", "Hinjilicut", "Hirakud", "IBM Thermal Power Plant Banharpali", 
    "IMFA Therubali", "Indipur", "Itamati", "Jagatsinghapur", "Jajanga", "Jajapur", "Jaleswar", "Jashipur", 
    "Jatani", "Jeypur", "Jharsuguda", "Jhumpura", "Joda", "JSPL Town Anugul", "Junagarh", "Kabatabandha", 
    "Kaipadar", "Kalarangiata", "Kaliapani", "Kalinga Nagar Industrial Area", "Kalyanasingpur", "Kamakshyanagar", 
    "Kanheipur", "Kansbahal", "Kantabanji", "Kantilo", "Karanjia", "Kashinagara", "Kavisurjyanagar", "Kendrapara", 
    "Kendujhar", "Kesinga", "Khaliapali", "Khalikote", "Khandapada", "Khariar", "Khariar Road", "Khariar Road", 
    "Khatiguda", "Khordha", "Kochinda", "Kodala", "Koida", "Konark", "Koraput", "Kotpad", "Krushnanandapur", 
    "Kuanrmunda", "Kukudakhandi", "Kullada", "Kunjabangarh", "L And T Cement Plant Jharsuguda", "Lanjigarh", 
    "Lathikata", "Lochapada", "Loisinga", "Madanpur Rampur", "Majhihara", "Makundapur", "Malkangiri", "Meghahatuburu", 
    "Mohana", "Mukhiguda", "Mundamarai", "Nabarangapur", "Nalco", "Nayagarh", "New Lalsingi", "Nilagiri", 
    "Nimapada", "NTPC Kaniha", "Nuahata", "Nuapatna", "OCL Industrial Township", "Odagaon", "Padmapur", 
    "Palalahada", "Palurgada", "Papadahandi", "Paradip", "Parlakhemundi", "Pathar", "Patnagarh", "Patrapur", 
    "Pattamundai", "Phulabani", "Pipili", "Pitala", "Polasara", "Puri", "Purusottampur", "Udayagiri", "Raighar", 
    "Rairangpur", "Rajagangapur", "Rajasunakhala", "Rambha", "Ranapurgada", "Rayagada", "Redhakhol", "Remuna", 
    "Rengali", "Rengali Dam Project Township", "Rourkela", "Sambalpur", "Saranga", "Sayadpur", "Sheragada", 
    "Sohela", "Sonapur", "Soro", "Subalaya", "Sunabeda", "Sundargarh", "Surada", "Surala", "Suvani", "Talcher", 
    "Talcher Thermal Power Station Township", "Tangi", "Tarbha", "Tensa", "Tikarpada", "Tikarpada Ganjam District", 
    "Tipo", "Titlagarh", "Tusura", "Udala", "Umarkote", "Vedanta Jharsuguda", "Venketraipur", 'Other'],
  },
  {
name:'Pondicherry',
cities:[ "Karaikal", "Mahe", "Puducherry", "Thirumalairayan Pattinam", "Yanam","Other"]
  },

  {
    name: 'Punjab',
    cities: ["Abohar", "Adampur", "Ahmedgarh", "Ajnala", "Akalgarh", "Alawalpur", "Amargarh", "Amloh", "Amritsar", 
    "Anandpur Sahib", "Apra", "Aur", "Baba Bakala", "Baddowal", "Badhni Kalan", "Balachaur", "Banga", 
    "Banur", "Bareta", "Bariwala", "Barnala", "Baryar", "Bassi Pathana", "Batala", "Bathinda", "Beas", 
    "Begowal", "Beharnpur", "Behrampur", "Bhabat", "Bhadaur", "Bhadson", "Bhagha Purana", "Bhagta BhaiKa", 
    "Bhankarpur", "Bhattian", "Bhawanigarh", "Bhikhi", "Bhikhiwind", "Bhisiana", "Bhogpur", "Bhucho Mandi", 
    "Bhulath", "Budha Theh", "Budhlada", "Bungal", "Chamkaur Sahib", "Cheema", "Chogawan", "Chohal", "Daper", 
    "Dasuya", "Dera Baba", "Dera Bassi", "Dhanaula", "Dharamkot", "Dhariwal", "Dhilwan", "Dhuri", "Dinanagar", 
    "Dirba", "Doraha", "Faridkot", "Fatehgarh Churian", "Fazilka", "Firozpur", "Gardhiwala", "Garhshankar", 
    "Ghagga", "Ghanaur", "Ghoh", "Giddarbaha", "Goindwal", "Goniana", "Goraya", "Gurdaspur", "Guru Har Sahai", 
    "Hajipur", "Halwara", "Handiaya", "Hariana", "Hoshiarpur", "Hussainpur", "Jagraon", "Jaitu", "Jalalabad", 
    "Jalandhar", "Jandiala", "Jodhan", "Jugial", "Kalan", "Kalanaur", "Kapurthala", "Karoran", "Kartarpur", 
    "Kathanian", "Khamanon", "Khambra", "Khanauri", "Khanna", "Kharar", "Khemkaran", "Khilchian", "Korianwali", 
    "Kot Fatta", "Kot Ise Khan", "Kotkapura", "Kotla Nihang", "Kurali", "Lalru", "Leather Complex Industrial Area", 
    "Lehragaga", "Lohian Khas", "Longowal", "Ludhiana", "Machhiwara", "Mahilpur", "Majitha", "Makhu", "Malaut", 
    "Malerkotla", "Mallanwala Khass", "Maloud", "Mamun", "Mandi Govindgarh", "Mansa", "Manwal", "Maur", "Mirpur", 
    "Moga", "Mohali", "Moonak", "Morinda", "Mubarakpur", "Mudal", "Mudki", "Mukerian", "Muktsar", "Mullanpur Dakha", 
    "Mullanpur Garib Dass", "Nabha", "Nag Kalan Industrial Area", "Nakodar", "Nangal", "Nangli", "Narot Mehra", 
    "Nawanshahr", "Nehon", "Nurmahal", "Pathankot", "Patiala", "Patran", "Patti", "Payal", "Phagwara", "Phillaur", 
    "Qadian", "Rahon", "Raikot", "Rail", "Raipur", "Rasulpur", "Raja Sansi", "Rajpura", "Rakri", "Raman", "Ramdas", 
    "Rampura Phul", "Rayya", "Rupnagar", "Rurki Kasba", "Sahnewal", "Saloh", "Samana", "Samrala", "Sanaur", 
    "Sangat", "Sangrur", "Sansarpur", "Sarai Khas", "Sardulgarh", "Satyewala", "Shahkot", "Sham ChaurasI", "Shikar", 
    "Sirhind Fatehgarh Sahib", "Sri Hargobindpur", "Sujanpur", "Sultanpur", "Sunam", "Talwandi Bhai", "Talwandi Sabo", 
    "Talwara", "Tapa", "Tarantaran", "Tharial", "Tibri", "Tungaheri", "Urmar Tanda", "Zira", "Zirakpur", 'Other'],
  },

  {
    name: 'Rajasthan',
    cities: [
      "SGM", "STR", "Abu Road", "Abohar", "Ajeetgarh", "Ajmer", "Aklera", "Aligarh", "Alwar", "Amet", "Antah", 
      "Anupgarh", "Asind", "Jolion Ka Khera Industrial Bhadra Area", "Atro", "Babai", "Baggar", "Bagru", "Bakani", 
      "Bali", "Balotra", "Banasthali", "Bandikui", "Banera", "Bangur Nagar", "Banswara", "Bar", "Baral", "Baran", 
      "Bari", "Bari Sadri", "Barmer", "Baskhoh", "Basni Belima", "Bassi", "Bay", "Bayana", "Beawar", 
      "Beejoliya Kalan", "Begun", "Behror", "Bewanja Industrial Area", "Bhiwadi", "Bhusawar", "Bicciwara Industrial Area", 
      "Bichhiwara", "Bichhri", "Bidasar", "Bigod", "Bikaner", "Bilara", "Bishangarh", "Bissau", "BITS Pilani Campus", 
      "Boranada Special Economic Zone", "Borawar", "Budhpura", "Bundi", "Chaksu", "Chawand", "Chechat", "Chhabra", 
      "Chhapar", "Chhipabarod", "Chhoti Sadri", "Chirawa", "Chittaurgarh", "Chomu", "Churu", "Danta", "Dariba", 
      "Dausa", "Deeg", "Delwara", "Deogarh", "Deoli", "Deshnoke", "Dewrighata Industrial Area", "Bhadra", "Dhariawad", 
      "Dhaulpur", "Dhorimanna", "Didwana", "Dudu", "Dungargarh", "Dungarpur", "Emri", "Falna", "Fatehnagar", 
      "Fatehpur", "Gajsinghpur", "Galiakot", "Gangapur", "Gangapur City", "Garhi", "Gharsana", "Gogunda", "Goredi Chancha", 
      "Gothan", "Gothra", "Govindgarh, Alwar District", "Govindgarh, Jaipur District", "Jalore Industrial Area", 
      "Govindpur Baori Industrial Area", "Goyli", "Guhala", "Gulabpura", "Hamirgarh", "Hamirgarh Growth Centre", 
      "RIICO", "Hanumangarh", "Hindaun", "Hindaun City Industrial Area", "Indragarh", "Industrial Area Bidiyad", 
      "Jamwa Ramgarh", "Jhagarwas", "Jhalawar", "Jhalrapatan", "Jhunjhunun", "Jobner", "Jodhpur", "Jodhpur Stone Park", 
      "Kaithoon", "Kaladwas Industrial Area", "Kaman", "Kanor", "Kanwat", "Kheri", "Swaroopganj Industrial Area", 
      "Islampur", "Jahazpur", "Jahazpur Industrial Area", "Jaipur", "Jaisalmer", "Jaitaran", "Jalor", "Jalore", 
      "Jalore Industrial Area", "Jalore Industrial Area", "Jalore Industrial Area", "Jalore Industrial Area", 
      "Jalore Industrial Area", "Kapasan", "Kaprain", "Karanpur", "Karauli", "Kasba Bonli", "Kawai", "Kekri", "Kelwa", 
      "Keshoraipatan", "Khairabad", "Khairthal", "Khajuwala", "Khandela,", "Khandela,", "Khanpur", "Khara", "Khatu", 
      "Kherli", "Kherliganj", "Kherwara Chhaoni", "Khetri", "Kinchan", "Kishangarh", "Kishangarh Renwal", 
      "Kishangarh, Alwar District", "Kolayat", "Kolvi Mandi Rajendrapur", "Kota", "Kotputli", "Kuchaman City", 
      "Kuchera", "Kumbhkot", "Kumher", "Kushalgarh", "Lachhmangarh", "Ladnu", "Lakheri", "Lalsot", "Losal", "Lunkaransar", 
      "Mahu Kalan", "Mahwa", "Makrana", "Malpura", "Malsisar", "Mandalgarh", "Mandawa", "Mandawar", "Mandpiya", 
      "Mangrol", "Manoharpur", "Manoharthana", "Marwar Junction", "Mathania", "Mavli", "Mehandipur", "Merta City", 
      "Merta Road", "Modak", "Mokalsar", "Mount Abu", "Mukandgarh", "Mundwa", "Nadbai", "Nagar", "Nagaur", 
      "Nagaur Industrial Area", "Nainwa", "Nandri", "Napasar", "Nasirabad", "Nathdwara", "Nawa", "Nawalgarh", 
      "Neem Ka Thana Industrial Area", "Neem-Ka-Thana", "Neemrana", "Newa Talai", "Nimbahera", "Niwai", 
      "Niwai Industrial Area", "Nohar", "Nokha", "Nooan", "Padampur", "Pali", "Parbatsar", "Partapur", 
      "Partapur Industrial Area", "Phalodi", "Phalodi Industrial Area", "Phulera", "Pilani Town", "Pilibanga", 
      "Pindwara", "Pipar City", "Pirawa", "Pokaran", "Pratapgarh", "Pushkar", "Raila", "Raisinghnagar", "Rajakhera", 
      "Rajaldesar", "Rajgarh", "Rajgarh, Churu District", "Rajsamand", "Ramganj Mandi", "Ramgarh", 
      "Ramgarh, Alwar District", "Ramgarh, Sikar District", "Rani", "Rani Sagar", "RIICO", "Ranthambore", "Ratangarh", 
      "Ratannagar", "Rawatbhata", "Rawatsar", "Reengus", "Renwal", "Reodar", "RIICO Industrial Area", 
      "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", 
      "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", 
      "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", "RIICO Industrial Area", 
      "RIICO Institutional Area", "Ranpur", "Rupangarh", "Rikhabdeo", "Sadri", "Sadulshahar", "Sagwara", "Salasar", 
      "Salumbar", "Sambhar", "Samdari", "Sanchore", "Sanderao", "Sangaria", "Sangod", "Sapotra", "Saradhana", 
      "RIICO", "Sardargarh", "Sardarshahar", "Sarekhurd Industrial Area", "RIICO", "Sariska", "Sarmathura", 
      "Sarwar", "Satalkheri", "Sawa", "Sawai Madhopur", "Seemalwara", "Semari", "Shahjahanpur", 
      "Shahjahanpur Industrial Area", "Shahpura", "Shahpura, Bhilwara District", "Sheoganj", "Shiwar", "Sikar", 
      "Silora Industrial Area", "Singhana", "Sirohi", "Sirohi Industrial Area", "Siwana", "Sojat", "Sojat Road", 
      "Sri Ganganagar", "Sri Madhopur", "Sri Madhopur Indusrtial Area", "Sudarshanpura RIICO", "Sujangarh", "Suket", 
      "Suket Industrail Area", "Sumerganj Mandi", "Sumerpur", "Sumerpur Industrial Area", "Sundlak RIICO Baran", 
      "Surajgarh", "Suratgarh", "Swaroopganj", "Takhatgarh", "Talera", "Tapookra", "Taranagar", "Thana Gazi RIICO", 
      "Tijara", "Todabhim", "Todaraisingh", "Todra", "Tonk", "Tonk Industrial Area", "Udaipur", "Udaipurwati", 
      "Udpura", "Uniara", "Utarlai", "Vijainagar", "Vijainagar, Ganganagar District", "Viratnagar", "Weir","Other"
  ]
  },

  {
    name: 'Sikkim',
    cities: ["Gangtok", "Gyalshing", "Jorethang", "Mangan", "Meli", "Namchi", "Nayabazar", "Pelling", "Rangpo", 
    "Rhenock", "Singtam", "Upper Tadong", 'Other'],
  },

  {
    name: 'Tamil Nadu',
    cities: [  "VA Vellalapatti", "Abiramam", "Achampudur", "Acharapakkam", "Achipatti", "Adikaratti", "Adiramapattinam",
    "Adiyanuthu", "Aduthurai", "Agaram", "Agastheeswaram", "Alagappapuram", "Alanganallur", "Alangayam",
    "Alangudi", "Alangulam", "Alangulam, Virudhunagar District", "Alanthurai", "Alathur, Chengalpattu Taluka",
    "Aliyar", "Alur", "Alwarkurichi", "Alwarthirunagiri", "Amathur", "Ambasamudram", "Ambur", "Ammainaickanur",
    "Ammanur", "Ammapattinam", "Ammapettai", "Ammapettai, Erode District", "Ammavarikuppam", "Ammoor",
    "AMRL Sez Vaigaikulam", "Anaimalai", "Anaiyur", "Ananthapuram", "Andipalayam", "Andipatti Jakkampatti",
    "Anjugrammam", "Annamalai Nagar", "Annanji", "Annavasal", "Annur", "Anthiyur", "Anuppankulam", "Appakudal",
    "Arachalur", "Arakandanallur", "Arakonam", "Aralvaimozhi", "Arani", "Arani, Thiruvallur District", "Aranthangi",
    "Arasiramani", "Arasur", "Aravakurichi", "Arcot", "Arimalam", "Ariyalur", "Ariyappampalayam", "Ariyur", "Arumanai",
    "Arumbanur", "Arumbavur", "Arumuganeri", "Aruppukkottai", "Asaripallam", "Athani", "Athanur", "Athimarapatti",
    "Athipatti", "Athur", "Athur, Thoothukkudi District", "Attayampatti", "Attur", "Avadattur", "Avalapalli",
    "Avalpoondurai", "Avanashi", "Avaniapuram", "Ayakudi", "Aygudi", "Ayothiapattinam", "Ayyalur", "Ayyampalayam",
    "Ayyampettai", "Ayyampettai, Thanjavur District", "Azhagiapandipuram", "B Mallapuram", "Balakrishnampatti",
    "Balakrishnapuram", "Balapallam", "Balasamudram", "Bargur", "Batlagundu", "Belur", "Bharathi Nagar", "Bhavani",
    "Bhavanisagar", "Bhuvanagiri", "Bikketti", "Bodinayakanur", "Boothapandi", "Boothipuram", "Chakkarapalli",
    "Chathirareddipatti", "Chatrapatti", "Chenbagaramanputhur", "Chengalpattu", "Chengam", "Chengappalli", "Chennagiri",
    "Chennai", "Chennasamudram", "Chennimalai", "Cheranmadevi", "Chetpet", "Chettiarpatti", "Chettipalayam",
    "Chettipalayam, Coimbatore District", "Chettithangal", "Chidambaram", "Chinnakalayamputhur", "Chinnakkampalayam",
    "Chinnalapatti", "Chinnamanur", "Chinnampalayam", "Chinnasalem", "Chinnathadagam", "Chinniampalayam, Erode District",
    "Chinthamani", "Chithode", "Chockampatti", "Cholapuram", "Coimbatore", "Coonoor", "Courtalam", "Cuddalore",
    "Damalerimuthur", "Dasanaickenpatti", "Denkanikottai", "Desur", "Devadanapatti", "Devakottai", "Devanangurichi",
    "Devarshola", "Devasthanam", "Devikapuram", "Devipattinam", "Dhalavoipuram", "Dhali", "Dhaliyur", "Dharamapuram",
    "Dharapadavedu", "Dharapuram", "Dharmapuri", "Dindigul", "Doramangalam", "Dusi", "Edaganasalai", "Edaikodu",
    "Edakalinadu", "Edappadi", "Elathur", "Elayirampannai", "ELCOT Gangaikodan IT Park", "Ellandaikuttai", "Elumalai",
    "Eral", "Eranapuram", "Eraniel", "Eriodu", "Erode", "Erumaipatti", "Eruvadi", "Ethapur", "Ettayapuram", "Ettimadai",
    "Ezhudesam", "Frontier Mediville SEZ", "Ganapathipuram", "Gandhi Nagar", "Gangaikondan", "Gangavalli", "Ganguvarpatti",
    "Gingee", "GK Industrial Park", "Gobichettipalayam", "Gopalasamudram", "Gudalur", "Gudalur, The Nilgiris District",
    "Gudalur, Theni District", "Gudiyatham", "Gummidipoondi", "Hale Dharmapuri", "Hanumanthampatti", "Harur",
    "Harveypatti", "Highways", "Hosur", "Hubbathala", "Idikarai", "Iduvai", "Ilampillai", "Ilanji", "Ilayangudi",
    "Iluppaiyurani", "Iluppur", "Irugur", "Jaffrabad", "Jalakandapuram", "Jambai", "Jayankondam", "Jeyamangalam",
    "Jolarpet", "K Madapur", "Kadambathur", "Kadambur", "Kadathur", "Kadayal", "Kadayam", "Kadayampatti", "Kadayanallur",
    "Kailasagiri", "Kakkalur", "Kalakad", "Kalakattupular", "Kalambur", "Kalappanaickenpatti", "Kalavai", "Kaliyakkavilai",
    "Kaliyapuram", "Kalladaikurichi", "Kallakkurichi", "Kallakudi", "Kallangudy", "Kallipalaym", "Kallukoottam", "Kalparapatti",
    "Kalugumalai", "Kamalakannanji SIDCO Industrial Estate", "Kamayagoundanpatti", "Kambainallur", "Kambam", "Kamuthi",
    "Kanadukathan", "Kanakkampalayam", "Kanakkanpatti", "Kanam", "Kancheepuram", "Kandanur", "Kangayampalayam", "Kangeyam",
    "Kaniyambadi", "Kaniyampoondl", "Kaniyur", "Kaniyur, Coimbatore District", "Kanjikoil", "Kannamangalam", "Kannampalayam",
    "Kannanendal", "Kannanoor", "Kannivadi", "Kannivadi, Dindigul District", "Kanniyakumari", "Kappalur SIDCO Industrial Estate",
    "Kappiyarai", "Karadipatti", "Karaikkudi", "Karaikudi SIDCO Industrial Estate", "Karamadai", "Karambakkudi",
    "Kariamangalam", "Kariapatti", "Karugampattur", "Karukkalvadi", "Karumandi Chellipalayam", "Karumathampatti",
    "Karungal", "Karunguzhi", "Karuppur", "Karur", "Kasipalayam", "Kathujuganapalli", "Kattathurai", "Kattumannarkoil",
    "Kattupakkam", "Kattuputhur", "Kaveripakkam", "Kaveripattinam", "Kavindapadi", "Kayalpattinam", "Kayatharu", "Keelakarai",
    "Keeramangalam", "Keeranur", "Keeranur, Pudukkottai District", "Keeripatti", "Keezhkulam", "Kelamangalam", "Kembainaickenpalayam",
    "Kethi", "Kila Ambur", "Kilampadi", "Kilapavoor", "Kilkunda", "Killai", "Killiyoor", "Kilpennathur", "Kilvaithinankuppam",
    "Kilvelur", "Kinathukadavu", "Kodaikanal", "Kodavasal", "Kodivalasa", "Kodumudi", "Koil Palayam", "Kolachal", "Kolappalur",
    "Kolathupalayam", "Kolathur", "Kollancode", "Kollankoil", "Komaralingam", "Kombai", "Kondur", "Konganapuram", "Koonavelampatti",
    "Kooraikundu", "Koothappar", "Koradacheri", "Kotagiri", "Kothanallur", "Kottaiyur", "Kottakuppam", "Kottaram", "Kottur",
    "Kovalam", "Kovilpatti", "Krishnagiri", "Krishnarayapuram", "Kuchanur", "Kuchipulayam", "Kuhalur", "Kulappuram",
    "Kulasekaram", "Kulasekarapuram", "Kulathur", "Kulithalai", "Kullursandai", "Kumaragiri", "Kumarapalayam", "Kumarapuram",
    "Kumbakonam", "Kunnathur", "Kurinjipadi", "Kurudampalayam", "Kurukkupatti", "Kurumbalur", "Kurumbapatti", "Kuruppanaickenpalayam",
    "Kuthalam", "Kuthanallur", "Kuzhithurai", "Labbaikudikadu", "Lakkampatti", "Lakshmi Puram", "Lalgudi", "Lalpet", "Lampalayam",
    "Madaharpakkam", "Madathukulam", "Madukkarai", "Madukkur", "Madurai", "Maduranthakam", "Mahindra World City", "Makkinampatti",
    "Mallankinaru", "Mallasamudram", "Mallur", "Malumichampatti", "Mamallapuram", "Mamsapuram", "Manachanallur", "Manalikarai",
    "Manalmedu", "Manalurpet", "Manamadurai", "Manapparai", "Manavalakurichi", "Mancad", "Mandaikadu", "Mandapam", "Mangalam",
    "Mangalampet", "Mangalampettai", "Manimutharu", "Mannargudi", "Manthithoppu", "Maraimalainagar", "Marakkanam",
    "Maramangalathupatti", "Marandahalli", "Markayankottai", "Marthandam", "Marudur", "Marungur", "Maruthancode", "Masinaickenpatty",
    "Mathicode", "Mathigiri", "Mathur", "Mayiladuthurai", "Mecheri", "Melacheval", "Melachokkanathapuram", "Melagaram",
    "Melaparthibanur", "Melathiruppanthuruthi", "Melattur", "Melmangalam", "Melur", "Melvisharam", "Methukummal", "Mettamalai",
    "Mettunasuvanpalayam", "Mettupalayam", "Mettupalayam, Tiruchirappalli District", "Mettur", "Mevalurkuppam", "Milavittan",
    "Minampalli-Pachamadevi", "Modakurichi", "Mohamed Bundur", "Mohanur", "Moolakaraipatti", "Morattupalayam",
    "Mudalipalayam SIDCO", "Mudukulathur", "Mudumalai", "Mukasipidariyur", "Mukkudal", "Mulanur", "Mullipattu", "Muruganpalayam",
    "Musiri", "Muthanampalayam", "Muthukadu", "Muthupet", "Muthur", "Muttayyapuram", "Muzhucode", "Mylaudy", "Nadaikavu",
    "Naduvaneri", "Naduvattam", "Nagamalaipudukottai", "Nagamangalam", "Nagapattinam", "Nagercoil", "Nagojanahalli", "Nallampatti",
    "Nallipalayam", "Nallur", "Namagiripettai", "Namakkal", "Nambiyur", "Nandivaram-Guduvancheri", "Nangavalli", "Nangavaram",
    "Nanguneri", "Nanjaiuthukuli Industrial Estate", "Omala",
"Nanjikottai", "Ooty", "Nannilam", "Naranammalpuram", "Naranapuram", "Narasimhanaickenpalayam", "Narasingapuram",
    "Narasingapuram, Salem District", "Nasiyanur", "Natchiarkoil", "Natham", "Nathampannai", "Natrampalli", "Nattalam",
    "Nattapettai", "Nattarasankottai", "Nazerath", "Needamangalam", "Neelagiri", "Neikkarapatti", "Nellikuppam", "Nelliyalam",
    "Nemili", "Neripperichal", "Nerkuppai", "Nerunjipettai", "Neyveli", "Neyyoor", "Nilaiyur", "Nilakkottai",    "Odaipatti", "Rodaiyakulam", "Roddanchatram", "Rodugathur", "Rolgadam",
    "Periyamanali", "Periyanaickenpalayam", "Pernampattu", "Perumagalur", "Perumagoundampatti",
    "Perumanallur", "Perumandi", "Perundurai", "Perungulam", "Pethampalayam", "Pethanaickenpalayam",
    "Pettai", "Pillanallur", "PJ Cholapuram", "Pollachi", "Polur", "Pongaliyur", "Ponmanai",
    "Ponnamaravathi", "Ponnampatti", "Ponneri", "Poolambadi", "Poolampatti", "Poolankinar", "Pooluvapatti",
    "Porayar", "Pothanur", "Pothatturpettai", "Pudiyamputhur", "Pudukkottai", "Pudupalayam",
    "Pudupalayam Agraharam", "Pudupatti", "Pudupattinam", "Pudur", "Puduvayal", "Puliankudi",
    "Puliyur", "Pullampadi", "Punjai Thottakurichi", "Punjaipugalur", "Punjaipuliampatti", "Puthalam",
    "Putheri", "Puthukkadai", "Puvalur", "Pudupatti Rajapalayam", "Rakkiya Palayam", "Ramalingapuram","Ramanathapuram", "Rameswaram", "Ranipettai", "Rasipuram", "Rayagiri","S Mangalam",
    "Rudravathi",
    "S Kannanur",
    "S Kodikulam",
    "S Nallur",
    "Sakkimangalam",
    "Salangapalayam",
    "Salem",
    "Samalapuram",
    "Samanatham",
    "Samathur",
    "Sambavar Vadagarai",
    "Sankaramanallur",
    "Sankarankoil",
    "Sankaraperi",
    "Sankarapuram",
    "Sankari",
    "Sankarnagar",
    "Sarcarsamakulam",
    "Sathankulam",
    "Sathiyavijayanagaram",
    "Sathyamangalam",
    "Sattur",
    "Sayalgudi",
    "Sayapuram",
    "Seerapalli",
    "Seevur",
    "Seithur",
    "Semmipalayam",
    "Senthamangalam",
    "Sentharapatti",
    "Senur",
    "Sethiathoppu",
    "Sevilimedu",
    "Sevugampatti",
    "Sevur",
    "Shenkottai",
    "Sholavandan",
    "Sholingur",
    "Sholur",
    "SIDCO Bargur",
    "Singampuneri",
    "Singaperumalkoil",
    "Singilipatti",
    "IPCOT Cheyyar",
    "IPCOT Industrial Complex Pillaipakkam",
    "IPCOT Industrial Park",
    "Sirugamani",
    "Sirumugai",
    "Sithayankottai",
    "Sithurajapuram",
    "Sivaganga",
    "Sivagiri",
    "Sivagiri, Erode District",
    "Sivagiripatti",
    "Sivagnanapuram",
    "Sivakasi",
    "Sivanthipatti",
    "Sivanthipuram",
    "Srikalikapuram",
    "Sriperumbudur",
    "Sriramapuram",
    "Srivaikuntam",
    "Srivilliputhur",
    "Suchindrum",
    "Suleeswaranpatti",
    "Sulur",
    "Sundarapandiam",
    "Sundarapandianpattinam",
    "Sundarapandiapuram",
    "Surandai",
    "Suriyampalayam",
    "Swamimalai",
    "T Kallipatti",
    "T Kallupatti",
    "Tajpura",
    "Tayilupatti",
    "Tenkasi",
    "Terkukallikulam",
    "Sriperumbudur",
    "IPCOT Perundurai",
    "IPCOT Thervoy Kandigal",
    "Thadikarankonam",
    "IPCOT Thoothukudi",
    "Sircar Periapalayam",
    "Sirkali",
    "Thadikombu",
    "Thakkolam",
    "Thalainayar",
    "Thamaraikulam",
    "Thammampatti",
    "Thanjavur",
    "Thanthoni",
    "Thappakuttai",
    "Tharamangalam",
    "Tharangambadi",
    "Thathaiyangarpet",
    "Thathankuttai",
    "Thazhakudy",
    "Thedavur",
    "Thenambakkam",
    "Thengampudur",
    "Theni Allinagaram",
    "Thenkarai",
    "Thenkarai, Coimbatore District",
    "Thenthamaraikulam",
    "Thenthiruperai",
    "Therur",
    "Thevaram",
    "Thevarappan Patti",
    "Thevur",
    "Thiagadurgam",
    "Thikkanamcode",
    "Thimmaiyanpettai",
    "Thingalnagar",
    "Thirikoodapuram",
    "Thirukarungavur",
    "Thirukarungudi",
    "Thirukkadaiyur",
    "Thirukkattupalli",
    "Thirumalayampalayam",
    "Thirumalpur",
    "Thirumangalampoondi",
    "Thirunageswaram",
    "Thiruparankundram",
    "Thiruparappu",
    "Thiruporur",
    "Thiruppalai",
    "Thiruppanandal",
    "Thirupuvanam",
    "Thirupuvanam, Thanjavur District",
    "Thiruthangal",
    "Thiruthuraipoondi",
    "Thiruvaiyaru",
    "Thiruvalam",
    "Thiruvallur",
    "Thiruvarur",
    "Thiruvattar",
    "Thiruvenkadam",
    "Thiruvennainallur",
    "Thiruverumbur",
    "Thiruvidaimarudur",
    "Thiruvithancode",
    "Thisayanvilai",
    "Thondamuthur",
    "Thondi",
    "Thookkanaikampalayam",
    "Thorapadi",
    "Thorapadi, Cuddalore District",
    "Thottiyam",
    "Thuraiyur",
    "Thuthipattu",
    "Thuvakudi",
    "Timiri",
    "Tindivanam",
    "Tiruchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirukalukundram",
    "Tirukkoyilur",
    "Tirumalaigiri",
    "Tirunelveli",
    "Tirupathur",
    "Tirupathur, Vellore District",
    "Tiruppur",
    "Tiruttani",
    "Tiruvannamalai",
    "Tiruvethipuram",
    "Tittacheri",
    "Tittakudi",
    "NPL Pugalur",
    "Tuticorin",
    "TVS Plant Hosur",
    "Udangudi",
    "Udayarpalayam",
    "Udumalaipettai",
    "Ullur",
    "Ulundurpet",
    "Unjalur",
    "Unnamalaikadai",
    "Uppidamangalam",
    "Uppiliapuram",
    "Usilampatti",
    "Uthamapalayam",
    "Uthangarai",
    "Uthayendram",
    "Uthiramerur",
    "Uthukkottai",
    "Uthukuli",
    "V Pudupatti",
    "V Pudur",
    "Vadakarai Keezhpadugai",
    "Vadakkanandal",
    "Vadakkuvalliyur",
    "Vadalur",
    "Vadamadurai",
    "Vadapudupatti",
    "Vaddakkankulam",
    "Yercaud",
    "Zamin Uthukuli", 'Other'],
  },


  {
    name: 'Telangana',
    cities: ["Achampet",
    "Adilabad",
    "Alampur",
    "Allipur",
    "Ananthapuram",
    "Anjani Portland Cement Factory",
    "APIIC Polepally SEZ",
    "Armur",
    "Ashwaraopet",
    "Asifabad",
    "Atmakur Mahbubnagar District",
    "Ballepalle",
    "Banswada",
    "Bellampalle",
    "Bhadrachalam",
    "Bhainsa",
    "Bheema Cements Limited Ramapuram",
    "Bhimaram",
    "Bhimaram Warangal District",
    "Bhongir",
    "Bhupalpalle",
    "Bibinagar",
    "Bodhan",
    "Boyapalle",
    "Chandur",
    "Chanukya Cement Factory",
    "Chatakonda",
    "Chegunta",
    "Chelpur",
    "Chennur Adilabad District",
    "Chinnachitakunta",
    "Chitkul",
    "Chityala",
    "Choppadandi",
    "Choutuppal",
    "Chunchupalle",
    "Dasnapur",
    "Deccan Cement Factory",
    "Devapur",
    "Devarakonda",
    "Devarkadra",
    "Dharmapuri",
    "Dharmaram",
    "Dharmaram District",
    "Dharmaram",
    "Ichoda",
    "Ieeja",
    "Isnapur",
    "Jadcherla",
    "Jagtial",
    "Jainoor",
    "Jallaram",
    "Jammikunta",
    "Jangaon",
    "Jannaram",
    "Jogipet",
    "Kaddam Peddur",
    "Kadipikonda",
    "Kagaznagar",
    "Kalwakurthy",
    "Kamalapuram",
    "Kamalapuram Karimnagar",
    "Mulugu Karimnagar Warangal Area",
    "Mamda",
    "Mamnoor",
    "Mancherial",
    "Mandamarri",
    "Manugur",
    "Medak",
    "Medipalle",
    "Metapally",
    "Miryalaguda",
    "Muhammadabad District",
    "Dornakal",
    "Eddumailaram",
    "Farooqnagar",
    "Gadwal",
    "Gajwel",
    "Ghanpur",
    "Ghanpur Warangal District",
    "Kodad",
    "Gorrekunta",
    "Gurralapadu Industrial Area",
    "Haliya",
    "Hanwada",
    "Husnabad",
    "Huzur Nagar",
    "Huzurabad",
    "Hyderabad",
    "Ibrahimpatnam Rangareddy District Area",
    "Kodangal District",
    "Kamareddy",
    "Karimnagar",
    "Kasipet",
    "Khammam",
    "Khanapur",
    "Kisan Nagar Industrial",
    "Nagarjuna Sagar",
    "Nagarkurnool",
    "Nakrekal",
    "Nalgonda",
    "Narayanapuram",
    "Narayankhed",
    "Narayanpet",
    "Narsampet",
    "Narsapur",
    "Naspur",
    "Navandgi",
    "Nirmal",
    "Nizamabad",
    "Palakurthy",
    "Palwancha",
    "Pargi",
    "Peddapalle",
    "Perur",
    "Pochampalle",
    "Pothreddipalle",
    "Kondamallapalle",
    "Koratla",
    "Kosigi",
    "Kothagudem",
    "Kothakota",
    "Kothur",
    "Kyathampalle",
    "Laxmidevipalle",
    "Luxettipet",
    "Madaram",
    "Nagarjuna Sagar",
    "Nagarkurnool",
    "Nakrekal",
    "Nalgonda",
    "Narayanapuram",
    "Narayankhed",
    "Narayanpet",
    "Narsampet",
    "Narsapur",
    "Naspur",
    "Navandgi",
    "Nirmal",
    "Nizamabad",
    "Palakurthy",
    "Palwancha",
    "Pargi",
    "Peddapalle",
    "Perur",
    "Pochampalle",
    "Pothreddipalle",
    "Raasi Cement Factory Wazirabad",
    "Raghunathpur",
    "Ramagundam",
    "Ramannapeta",
    "Ratnapur",
    "Ratnapur Medak District",
    "Rudraram",
    "Rudraram Industrial Area",
    "Sadasivpet",
    "Sangareddy",
    "Sarangapur",
    "Sarapaka",
    "Sathupally",
    "Secunderabad",
    "Shamshabad",
    "Shankarampet",
    "Shivunipalle",
    "Siddipet",
    "Singapuram",
    "Sircilla",
    "Soanpet",
    "Sri Vishnu Cement Limited Dondapadu",
    "Suryapet",
    "Tallada",
    "Tandur",
    "Teegalpahad",
    "Thallapalle",
    "Thimmapur",
    "Thorrur",
    "Turkapally",
    "Utnur",
    "Vatwarlapalle",
    "Vemulawada",
    "Vicarabad",
    "Wanaparthy",
    "Warangal",
    "Warangal Industrial Area",
    "Yadagirigutta",
    "Yellandu",
    "Yellareddy",
    "Yenugonda",
    "Yerrabalem",
    "Zahirabad",
     'Other']
  },

  {
    name: 'Tripura',
    cities: ["Agartala",
    "Amarpur",
    "Ambassa",
    "Anandanagar",
    "Badharghat",
    "Belonia",
    "Briddhanagar",
    "Dharmanagar",
    "Fatikroy",
    "Gakulnagar",
    "Gakulpur",
    "Gandhigram",
    "Indranagar",
    "Jogendranagar",
    "Kailasahar",
    "Kalachhari",
    "Kamalpur",
    "Kanchanpur",
    "Khowai",
    "Kumarghat",
    "Kunjaban",
    "Madhupur",
    "Manu",
    "Matarbari",
    "Narsingarh",
    "Panisagar",
    "Ranirbazar",
    "Sabroom",
    "Santirbazar",
    "Sonamura",
    "Taranagar",
    "Teliamura",
    "Udaipur",
     'Other'],
  },

  {
    name: 'Uttar Pradesh',
    cities: ["Abupur",
    "Achalaganj",
    "Achalda",
    "Achnera",
    "Adari",
    "Afzalgarh",
    "Agarwal Mandi",
    "Agra",
    "Agro Park Karkhiyon",
    "Ahraura",
    "Ailum",
    "Air Force Area",
    "Ajhuwa",
    "Akbarpur",
    "Akbarpur Near Kanpur",
    "Aliganj",
    "Aligarh",
    "Allahabad",
    "Allahganj",
    "Allapur",
    "Amanpur",
    "Ambehta",
    "Amethi",
    "Amethi Sultanpur District",
    "Amila",
    "Amilo",
    "Aminagar Sarai",
    "Amraudha",
    "Amroha",
    "Anandnagar",
    "Anpara",
    "Antu",
    "Anupshahr",
    "Anurudhpur Purab Patti",
    "Aonla",
    "Artauni",
    "Ashrafpur Kichhauchha",
    "Atarra",
    "Atasu",
    "Atrauli",
    "Atraulia",
    "Auraiya",
    "Aurangabad",
    "Auras",
    "Awagarh",
    "Ayodhya",
    "Azamgarh",
    "Azmatgarh",
    "Babarpur Ajitmal",
    "Baberu",
    "Babina",
    "Babrala",
    "Babugarh",
    "Bachhgaon",
    "Bachhraon",
    "Bachhrawan",
    "Bad",
    "Badaun Industrial Area",
    "Baghpat",
    "Bah",
    "Bahadurganj",
    "Baheri",
    "Bahjoi",
    "Bahraich",
    "Bahsuma",
    "Bahuwa",
    "Bajna",
    "Bakewar",
    "Bakiabad",
    "Baksar",
    "Bakshi Ka Talab",
    "Baldeo",
    "Ballia",
    "Balrampur",
    "Banat",
    "Banda",
    "Bangarmau",
    "Banguwan Kalan",
    "Banjarepur",
    "Bansdih",
    "Bansgaon",
    "Bansi",
    "Bara Gaon",
    "Barabanki",
    "Barabanki Industrial Area",
    "Baragaon",
    "Barahatir Jagdishpur",
    "Baraut",
    "Bareilly",
    "Barhalganj",
    "Barhani Bazar",
    "Barkhera",
    "Baroun",
    "Barsana",
    "Barua Sagar",
    "Barwar",
    "Barwara Mazra",
    "Basantpur Saitli",
    "Basta",
    "Basti",
    "Beelna",
    "Behat",
    "Behta Hajipur",
    "Bela Pratapgarh",
    "Belthara Road",
    "Beniganj",
    "Benipur",
    "Beswan",
    "Bewar",
    "Bhabnan Bazar",
    "Bhadarsa",
    "Bhadohi",
    "Bhadohi Industrial Area",
    "Bhagwant Nagar",
    "Bharatganj",
    "Bhargain",
    "Bharthana",
    "Bharuhana",
    "Bharwari",
    "Bhatni Bazar",
    "Bhatpar Rani",
    "Bhatpar Rani",
    "Bhikapur",
    "Bijauli Industrial Area",
    "Bijnor",
    "Bijpur",
    "Bikapur",
    "Bilari",
    "Bilariaganj",
    "Bilaspur",
    "Bilaspur Gautam Buddha Nagar District",
    "Bilgram",
    "Bilhaur",
    "Bilram",
    "Bilsanda",
    "Bilsi",
    "Bindki",
    "Birbhanpur",
    "Bisalpur",
    "Bisanda Buzurg",
    "Bisauli",
    "Bisharatganj",
    "Bishunipur",
    "Biswan",
    "Bithoor",
    "Budaun",
    "Budhana",
    "Bugrasi",
    "Bulandshahr",
    "Chail",
    "Chakia",
    "Chakmano",
    "Chandauli",
    "Chandausi",
    "Chandpur",
    "Charkhari",
    "Charthaval",
    "Chaumuhan",
    "Chaurhat",
    "Chhaprauli",
    "Chharra Rafatpur",
    "Chhata",
    "Chhatari",
    "Chhibramau",
    "Chhitauni",
    "Chhutmalpur",
    "Chilkana Sultanpur",
    "Chirgaon",
    "Chitbara Gaon",
    "Chitrakoot",
    "Chopan",
    "Choubepur Kalan",
    "Chunar",
    "Churk Ghurma",
    "Colonelganj",
    "Dadri",
    "Dalmau",
    "Dankaur",
    "Dariyabad",
    "Dasna",
    "Dataganj",
    "Daurala",
    "Debai",
    "Deoband",
    "Deoranian",
    "Deoria",
    "Deosaini",
    "Derapur",
    "Devinagar",
    "Dewa",
    "Dhakauli",
    "Dhampur",
    "Dhanauha",
    "Dhanaura",
    "Dharoti Khurd",
    "Dhaura Tanda",
    "Dhaurahara",
    "Dibiyapur",
    "Dildarnagar Fatehpur Bazar",
    "Dindaspur",
    "Dindaspur Chandauli District",
    "Doghat",
    "Dohrighat",
    "Domariyaganj",
    "Dostpur",
    "Dudhi",
    "Dulhipur",
    "Ekdil",
    "Erich",
    "Etah",
    "Etah Industrial Area",
    "Etawah",
    "Etmadpur",
    "Faizabad",
    "Faizganj",
    "Farah",
    "Faridnagar",
    "Faridpur Bareilly District",
    "Fariha",
    "Farrukhabad",
    "Fatehabad",
    "Fatehganj Pashchimi",
    "Fatehganj Purvi",
    "Fatehgarh",
    "Fatehpur",
    "Fatehpur Chaurasi",
    "Fatehpur Sikri",
    "Fatehpur Barabanki District",
    "Fazi Nagar",
    "Firozabad",
    "Firozabad UPSIDC",
    "Gabhana",
    "Gadhi",
    "Gagalhedi Must",
    "Gajraula",
    "Gangapur",
    "Gangiri",
    "Gangoh",
    "Ganj Dundwara",
    "Ganj Muradabad",
    "Ganwaria Tulsipur",
    "Garauri",
    "Garautha",
    "Garhi Pukhta",
    "Garhmukteshwar",
    "Gaura",
    "Gaura Barhaj",
    "Gaura Kala",
    "Gauri Bazar",
    "Gausganj",
    "Gawan",
    "Ghatampur",
    "Ghaziabad",
    "Ghazipur",
    "Ghiraur",
    "Ghorawal",
    "Ghosi",
    "Ghosia",
    "Ghughuli",
    "GIDA Gorakhpur",
    "Gird Baragaon",
    "Gohand",
    "Gokul",
    "Gola Bazar",
    "Gola Gokarannath",
    "Gonda",
    "Gopamau",
    "Gopi Ganj",
    "Gorakhpur",
    "Gosainganj",
    "Gosainganj Lucknow District",
    "Got",
    "Govardhan",
    "Greater Noida",
    "Gulaothi",
    "Gulariya",
    "Gunnaur",
    "Gursahaiganj",
    "Gursarai",
    "Gyanpur",
    "Hafiz Ganj",
    "Haidergarh",
    "Haldaur",
    "Hallaur",
    "Hamirpur",
    "Handia",
    "Hapur",
    "Haqiqatpur",
    "Hardoi",
    "Harduaganj",
    "Hargaon",
    "Hariharpur",
    "Hariyawan",
    "Harraiya",
    "Hasanpur",
    "Hasayan",
    "Hastinapur",
    "Hata",
    "Hathgram",
    "Hathras",
    "Hyderabad",
    "Ibrahimpur",
    "Iglas",
    "Ikauna",
    "Iltifatganj Bazar",
    "Indian Telephone Industry Joya",
    "Indian Telephone Industry Islamnagar",
    "Itaunja",
    "Itwa",
    "Jafarabad",
    "Jagner",
    "Jahanabad",
    "Jahangirabad",
    "Jahangirpur",
    "Jais",
    "Jaithara",
    "Jalalabad",
    "Jalalabad Bijnor District",
    "Jalalabad Muzaffarnagar District",
    "Jalali",
    "Jalalpur",
    "Jalaun",
    "Jalesar",
    "Jamshila",
    "Jamuhan",
    "Jangipur",
    "Jansath",
    "Jarwal",
    "Jasra",
    "Jasrana",
    "Jaswantnagar",
    "Jatari",
    "Jaunpur",
    "Jewar",
    "Jhalu",
    "Jhansi",
    "Kabrai",
    "Kachhauna Patseni",
    "Kachhla",
    "Kachhwa Kachnar",
    "Kadaura",
    "Kadipur",
    "Kailashpur",
    "Kaimganj",
    "Kairana",
    "Kakari",
    "Kakod",
    "Kakori",
    "Kakrala",
    "Kalinagar",
    "Kalpi",
    "Kamalganj",
    "Kampil",
    "Kanaudia Chamical & Industries Ltd",
    "Kandharpur",
    "Kandhla",
    "Kannauj",
    "Kanpur",
    "Kanth",
    "Kanth Shahjahanpur District",
    "Kaptanganj",
    "Karari",
    "Karhal",
    "Karnawal",
    "Karwi",
    "Kasba Khanpur",
    "Kasganj",
    "Kasiya",
    "Kataka",
    "Kataria",
    "Katghar Lalganj",
    "Kathaura",
    "Kathera",
    "Katra",
    "Katra Medniganj",
    "Kaulakha",
    "Kauria ganj",
    "Kemri",
    "Kerakat",
    "Kewalpur",
    "Khadda",
    "Khaga",
    "Khailar",
    "Khair",
    "Khairabad",
    "Khairabad Mau District",
    "Khalilabad",
    "Khamaria",
    "Khandauli",
    "Khanpur Kharela",
    "Khargupur",
    "Khariya",
    "Kharkhoda",
    "Khatauli",
    "Khet",
    "Khekada",
    "Kheragarh",
    "Kheri Kheta Sarai",
    "Khudaganj",
    "Khurja",
    "Khutar",
    "Kiraoli",
    "Kiratpur",
    "Kishni",
    "Kishunpur",
    "Kithaur",
    "Koeripur",
    "Konch",
    "Kopaganj",
    "Kora Jahanabad",
    "Koraon",
    "Korwa",
    "Kosi Kalan",
    "Kota",
    "Kota Sonbhadra District",
    "Kotra",
    "Kotwa",
    "Kotwali",
    "Kulpahar",
    "Kunda",
    "Kundarki",
    "Kunwargaon",
    "Kuraoli",
    "Kurara",
    "Kursath",
    "Kursath Hardoi District",
    "Kursi Road Industrial Area",
    "Kurthi Jafarpur",
    "Kushinagar",
    "Kusmara",
    "Laharpur",
    "Lakhimpur",
    "Lakhna",
    "Lal Gopalganj Nindaura",
    "Lalganj",
    "Lalitpur",
    "Lar",
    "Lawar",
    "Leather Park Banthar",
    "Ledwa Mahua",
    "Loni",
    "Lucknow",
    "Machhlishahr",
    "Madhoganj",
    "Madhogarh",
    "Madiya",
    "Maghar",
    "Mahaban",
    "Maharajganj",
    "Mahimapur",
    "Mahmudabad",
    "Mahoba",
    "Maholi",
    "Mahona",
    "Mahrajganj",
    "Mahrajganj Azamgarh District",
    "Mahroni",
    "Mahul Khas M",
    "Majhauliraj",
    "Makhanpur",
    "Malhipur",
    "Malihabad",
    "Mallawan",
    "Mandawar",
    "Manikpur",
    "Manikpur Sarhat",
    "Maniyar",
    "Manjhanpur",
    "Mankapur",
    "Marehra",
    "Mariahu",
    "Maswasi",
    "Mataundh",
    "Mathura",
    "Mau Aima",
    "Maudaha",
    "Maurawan Mawana",
    "Meerut",
    "Mehdawal",
    "Mehnagar",
    "Mendu",
    "Middha",
    "Milak",
    "Mirganj",
    "Mirzapur cum Vindhyachal",
    "Nautanwa",
    "Misrikh Cum Neemsar",
    "Modinagar",
    "Mogra Badshahpur",
    "Mohammadabad",
    "Mohammadabad",
    "Mohammadi",
    "Mohan",
    "Mohanpur",
    "Mohiuddinpur",
    "Moradabad",
    "Moth",
    "Mubarakpur",
    "Mughalsarai",
    "Muhammadabad",
    "Mundera Bazar",
    "Mundia",
    "Muradnagar",
    "Mursan",
    "Musafirkhana",
    "Muzaffarnagar",
    "Nadigaon",
    "Nagina",
    "Nagram",
    "Nai Bazar",
    "Naini Industrial Area",
    "Najibabad",
    "Nawabganj",
    "Nawabganj",
    "Nawabganj",
    "Nehtaur",
    "Nichlaul",
    "Nidhauli Kalan",
    "Nihal Garh Chak Jangla",
    "Nivi",
    "Niwari",
    "Nizamabad",
    "Noida",
    "Noorpur",
    "Nyoria Husainpur",
    "Nyotini",
    "Obra",
    "Oel Dhakwa",
    "Orai",
    "Orai Industrial Area",
    "Orai Jalaun District",
    "Oran",
    "Pachperwa",
    "Padarathpur",
    "Padrauna",
    "Pahar Ganj",
    "Pahasu",
    "Pakbara",
    "Pali",
    "Pali",
    "Palpur",
    "Parasi",
    "Parichha",
    "Parikshitgarh",
    "Parsadepur",
    "Parsakhera Industrial Area",
    "Parsona",
    "Patadi",
    "Patala",
    "Patiyali",
    "Patti",
    "Pavi Sadakpur",
    "Phalauda",
    "Phaphund",
    "Phulpur",
    "Phulpur",
    "Phulpur Allahabad District",
    "Pihani",
    "Pilibhit",
    "Pilkhana",
    "Pilkhuwa",
    "Pinahat",
    "Pipalsana Chaudhari",
    "Pipara Dand",
    "Pipiganj",
    "Pipraich",
    "Piprayli Bujurg",
    "Pipri",
    "Powayan",
    "Pratapgarh City",
    "Pukhrayan",
    "Puranpur",
    "Purdilnagar",
    "Pure Tiwari",
    "Purquazi",
    "Purwa",
    "Qasimpur Power House Colony",
    "Rabupura",
    "Radhakund",
    "Rae Bareli",
    "Raja ka Rampur",
    "Rajapur",
    "Ram Nagar Industrial Area",
    "Ramdaspur Urf Nagal",
    "Ramkola",
    "Ramnagar",
    "Rampur",
    "Rampur Bhawanipur",
    "Rampur Karkhana",
    "Rampur Maniharan",
    "Rampura",
    "Ranipur",
    "Raniyan",
    "Rasra",
    "Rasulabad",
    "Rasulabad Unnao District",
    "Ratanpura",
    "Rath",
    "Rati Ka Nagla Industrial Area",
    "Raya",
    "Renukoot",
    "Reoti",
    "Richha",
    "Risia Bazar",
    "Rithora",
    "Robertsganj",
    "Rori",
    "Rudauli",
    "Rudayan",
    "Rudrapur",
    "Runkata",
    "Rura",
    "Rustamnagar Sahaspur",
    "Sadabad",
    "Sadat",
    "Sadatmasaura",
    "Sadruddin Nagar",
    "Safipur",
    "Sahanpur",
    "Saharanpur",
    "Sahaspur",
    "Sahaswan",
    "Sahatwar",
    "Sahawar",
    "Sahjanwan",
    "Sahpau",
    "Saidpur",
    "Saidpur Khajuria",
    "Saidpur Budaun District",
    "Sainthal",
    "Saiyad Raja",
    "Sakaldiha",
    "Sakhanu",
    "Sakit",
    "Salarpur",
    "Salempur",
    "Salon",
    "Sambhal",
    "Samdhan",
    "Samthar",
    "Sandi",
    "Sandila",
    "Sandilla",
    "Sankhol",
    "Sant Kabir Nagar",
    "Sant Ravidas Nagar",
    "Sarai Akil",
    "Sarai Khwaja",
    "Sarai Meer",
    "Sarai Mir",
    "Sarai Sidhu",
    "Sardhana",
    "Sarila",
    "Sarkarpur",
    "Sarojini Nagar",
    "Sarsaul",
    "Sasni",
    "Satrikh",
    "Satyamurthi Nagar",
    "Saurikh",
    "Sewai",
    "Shahabad",
    "Shahabad",
    "Shahbad",
    "Shahdara",
    "Shahganj",
    "Shahi",
    "Shahjahanpur",
    "Shahjahanpur",
    "Shahjahanpur",
    "Shahjahanpur Kutchery",
    "Shahjahanpur South",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahpur",
    "Shahzad Nagar",
    "Shamli",
    "Shamsabad",
    "Shamsabad",
    "Shamsabad",
    "Shankargarh",
    "Shergarh",
    "Sherkot",
    "Shikarpur",
    "Shikohabad",
    "Shivrajpur",
    "Shivrajpur",
    "Shivrajpur",
    "Shivrajpur",
    "Shivrajpur",
    "Shivrajpur",
    "Shivrajpur",
    "Shohratgarh",
    "Siana",
    "Siddharthnagar",
    "Siddharth Nagar",
    "Sidhauli",
    "Sidhpura",
    "Sikanderpur",
    "Sikandra",
    "Sikandra Rao",
    "Sikandrabad",
    "Singahi Bhiraura",
    "Sirathu",
    "Sirsa",
    "Sirsaganj",
    "Sirsi",
    "Sitapur",
    "Siyana",
    "Soron",
    "Suar",
    "Sukhmalpur Nizamabad",
    "Sultanpur",
    "Sumerpur",
    "Suriyawan",
    "Swamibagh",
    "Tajpur",
    "Talbehat",
    "Talgram",
    "Tanda",
    "Tanda",
    "Tanda",
    "Tetri Bazar",
    "Thakurdwara",
    "Thana Bhawan",
    "Thiriya Nizamat Khan",
    "Tikaitnagar",
    "Tikri",
    "Tikri Kalan",
    "Tilhar",
    "Tindwari",
    "Titron",
    "Tori Fatehpur",
    "Tulsipur",
    "Tundla",
    "Tundla Kham",
    "Tundla Rly. Colony",
    "Tundla Rura",
    "Tundla Tsi Colony",
    "Tundla West",
    "Tundla Yard",
    "Tundla Yard Colony",
    "Tundla Yard Etmadpur",
    "Tundla Yard P.W.",
    "Tundla Yard Power House",
    "Tundla Yard Rly. Quarters",
    "Tundla Yard Shastripuram",
    "Tundla Yard South",
    "Tundla Yard Tundla Junction",
    "Tundla Yard West",
    "Tundla Yard Yamuna Bridge",
    "Tundla Yard Yamuna Pust",
    "Tundla Yard Yard Colony",
    "Tundla Yard Yard Power House",
    "Tundla Yard Yamuna Bridge",
    "Tundla Yard Yamuna Pust",
    "Tundla Yard Yard Colony",
    "Tundla Yard Yard Power House",
    "Tanda",
    "Tanda, Rampur District",
    "Tatarpur Lallu",
    "Thakurdwara",
    "Thana Bhawan",
    "Thiriya Nizamat Khan",
    "Tikait Nagar",
    "Tikri",
    "Tikuniya",
    "Tilhar",
    "Tilthi",
    "Tindwari",
    "Tirwaganj",
    "Titron",
    "Tondi Fatehpur",
    "Tronica City",
    "Tulsipur",
    "Tundla",
    "Tundla Kham",
    "Tundla Rly Colony",
    "Ugu",
    "Ujhani",
    "Ujhari",
    "Umarha",
    "Umri",
    "Umri Kalan",
    "Un",
    "Unchahar",
    "Unnao",
    "Usawan",
    "Usehat",
    "Uska Bazar",
    "Utraula",
    "Varanasi",
    "Vijaigarh",
    "Vrindavan",
    "Walidpur",
    "Warhapur",
    "Wazirganj",
    "Zaidpur",
    "Zamania",
    'Other'],
  },

  {
    name: 'Uttarakhand',
    cities: [
      "Almora",
      "Auli",
      "Badrinath",
      "Bageshwar",
      "Bahadarabad",
      "Banbasa",
      "Bandia",
      "Bandiya",
      "Barkot",
      "Bazpur",
      "Begumpur",
      "Berinag",
      "Bhagwanpur",
      "Bhimtal",
      "Bhowali",
      "Chakrata",
      "Chamba",
      "Chamoli",
      "Champawat",
      "Chaukori",
      "Corbett",
      "Dehradun",
      "Devaprayag",
      "Devaprayag, Garhwal District",
      "Dhanaulti",
      "Dharchula",
      "Dharchula Dehat",
      "Didihat",
      "Dineshpur",
      "Dogadda",
      "Doiwala",
      "Dwarahat",
      "Gadarpur",
      "Gangolihat",
      "Gangotri",
      "Gochar",
      "Gopeshwar",
      "Haldwani",
      "Haridwar",
      "Herbertpur",
      "Jaspur",
      "Jhabrera",
      "Joshimath",
      "Kachnal Gosain",
      "Kaladhungi",
      "Karnaprayag",
      "Kashipur",
      "Kathgodam",
      "Kausani",
      "Kela Khera",
      "Khatima",
      "Kichha",
      "Kirtinagar",
      "Kotdwara",
      "Laksar",
      "Lalkuan",
      "Landaur",
      "Landhaura",
      "Lansdowne",
      "Lohaghat",
      "Mahua Dabra Haripura",
      "Mahua Kheraganj",
      "Manglaur",
      "Mehwar Kalan",
      "Mohanpur Mohammadpur",
      "Mukteshwar",
      "Mussoorie",
      "Nagala Imarti",
      "Nagla",
      "Nainital",
      "Nandprayag",
      "Narendranagar",
      "Natthuwa Wala",
      "New Tehri",
      "Pantnagar",
      "Pauri",
      "Piran Kaliyar",
      "Pithoragarh",
      "Pratitnagar",
      "Raipur",
      "Ramnagar",
      "Ranikhet",
      "Rishikesh",
      "Roorkee",
      "Rudraprayag",
      "Rudrapur",
      "Saidpura",
      "Sara Industrial Estate",
      "Selaqui",
      "Shaktigarh",
      "SIDCUL Haridwar",
      "SIDCUL Kotadwara",
      "Sitarganj",
      "Srinagar",
      "Sultanpur",
      "Tanakpur",
      "Tehri",
      "Uttarkashi",
      "Vikasnagar",
      "Other"
  ],
  },

  {
    name: 'West Bengal',
    cities: [
      "Adra",
      "Ahmadpur",
      "Aiho",
      "Ajodhyanagar",
      "Alipukur",
      "Alipurduar",
      "Amrashi Kasba",
      "Amlagora",
      "Amlajora",
      "Amta",
      "Amtala",
      "Anantabati",
      "Andal",
      "Andul",
      "Ankurhati",
      "Anup Nagar",
      "Arambagh",
      "Argari",
      "Arjunpur",
      "Arra",
      "Asansol",
      "Ashadtalia",
      "Aurangabad",
      "Badamtam Tea Garden",
      "Badkulla",
      "Baduria",
      "Bagnan",
      "Bagula",
      "Bahirgram",
      "Bahula",
      "Baidyabati",
      "Bakreshwar Thermal Power Township",
      "Baksa",
      "Balarampota",
      "Balarampur",
      "Balichak",
      "Balihati",
      "Bally",
      "Baluhati",
      "Balurghat",
      "Bamangram",
      "Bamanpukur",
      "Bamna",
      "Bamunari",
      "Ban Harishpur",
      "Banarhat Tea Garden",
      "Bandhail",
      "Bandipur",
      "Bandoan",
      "Baneshwarpur",
      "Bargachhia",
      "Barijhati",
      "Barijpur",
      "Barjora",
      "Barkalikapur",
      "Barrackpur",
      "Barua Gopalpur",
      "Baruipara",
      "Baruipur",
      "Barunda",
      "Basantapur",
      "Basanti",
      "Basantia",
      "Basirhat",
      "Baska",
      "Basudebpur",
      "Batika",
      "Batul",
      "Bayarsing",
      "Begampur",
      "Bhangri Pratham Khanda",
      "Bhasa",
      "Bhasaipaikar",
      "Bhatpara",
      "Bhimram",
      "Bholar Dabri",
      "Bidhannagar",
      "Bikihakola",
      "Bilandapur",
      "Bilpahari",
      "Bipra Noapara",
      "Bira",
      "Birlapur",
      "Birnagar",
      "Birodhi",
      "Birpara",
      "Bishnupur",
      "Bishnupur Birbhum District",
      "Bishnupur Industrial Growth Centre",
      "Bolpur",
      "Bora Gagangohalia",
      "Borai",
      "Bowali",
      "Brindabanpur",
      "Budge Budge",
      "Buita",
      "Burdwan",
      "Cart Road",
      "Chachanda",
      "Chak Alampur",
      "Chak Bankola",
      "Chak Baria",
      "Chinchuria",
      "Chittaranjan",
      "Chong Ghurali",
      "Chongtong Tea Garden",
      "Chopra",
      "Contai",
      "Dafahat",
      "Dakhin Rampur",
      "Dakshin Baguan",
      "Dakshin Chatra",
      "Dakshin Jhapardaha",
      "Dakshin Odlabari",
      "Dakshin Rajyadharpur",
      "Dakshin Raypur",
      "Dakshin Santoshpur",
      "Dalkhola",
      "Dalurband",
      "Danga",
      "Dankuni",
      "Darappur",
      "Darjeeling",
      "Daulatpur",
      "Deara",
      "Debipur",
      "Deora",
      "Deulgram",
      "Deuli",
      "Deulia",
      "Dhakuria",
      "Dhaliabari",
      "Dhamua",
      "Dhandadihi",
      "Dhania",
      "Dhanyakuria",
      "Dharmapur",
      "Dhatrigram",
      "Dhola",
      "Dhuilya",
      "Dhulasimla",
      "Dhulian",
      "Dhunki",
      "Dhupguri",
      "Dhusaripara",
      "Diamond Harbour",
      "Digha",
      "Dighirpar",
      "Dignala",
      "Dihimandalghat",
      "Dinga Khola",
      "Dinhata",
      "Dogachhia",
      "Domjur",
      "Dubra",
      "Dubrajpur",
      "Dudhkalmi",
      "Durgapur",
      "Durllabhganj",
      "Egra",
      "Ekabbarpur",
      "Eksara",
      "Erashal",
      "Falakata",
      "Falta Industrial Growth Centre",
      "Farakka Barrage Township",
      "Farakka PTS Township",
      "Fatehpur",
      "Fatellapur",
      "Fatepur",
      "Gabberia",
      "Gairkata",
      "Gangadharpur",
      "Gangarampur",
      "Gangi",
      "Gangnapur",
      "Gangni",
      "Garalgachha",
      "Garbeta",
      "Garh Kamalpur",
      "Garshyamnagar",
      "Garulia",
      "Gaur Daha",
      "Geni",
      "Ghatal",
      "Ghola Noapara",
      "Ghoraberia",
      "Ghutgarya",
      "Ging Tea Garden",
      "Giria",
      "Goasafat",
      "Gobardanga",
      "Gobindapur",
      "Gopalpur",
      "Guskara",
      "Habra",
      "Haldia",
      "Haldibari",
      "Halisahar",
      "Halyan",
      "Hanskunda",
      "Hanspukuria",
      "Haora",
      "Harharia Chak",
      "Harinadibhastsala",
      "Harindanga",
      "Haringhata Farm",
      "Haripur",
      "Harirampur",
      "Hasimnagar",
      "Hatgachha",
      "Hatsimla",
      "Hijuli",
      "Hincha Gerya",
      "Hindusthan Cables Town",
      "Hingalganj",
      "Hirapur",
      "Hugli-Chinsurah",
      "Hutmura",
      "Ichhapur Defence Estate",
      "Ichhlampur",
      "Ilambazar",
      "Islampur",
      "Itahar",
      "Itinda",
      "Jadupur",
      "Jafarpur",
      "Jagadanandapur",
      "Jagadishpur",
      "Jagatballavpur",
      "Jagatnagar",
      "Jaluidanga",
      "Jamuria",
      "Janai",
      "Jangalpara",
      "Jangipur",
      "Jateshwar",
      "Jaygaon",
      "Jaykrishnapur",
      "Jaynagar Mazilpur",
      "Jaypur",
      "Jemari",
      "Jetia",
      "Jhalda",
      "Jhorhat",
      "Jiaganj Azimganj",
      "Jirat",
      "Joka",
      "Joypul",
      "Jujarsaha",
      "Kachu Pukur",
      "Kaijuri",
      "Kajora",
      "Kakdihi",
      "Kakramari",
      "Kalara",
      "Kalaria",
      "Kalas North",
      "Kaliaganj",
      "Kalikapota",
      "Kalikapur",
      "Kalimpong",
      "Kalipur",
      "Kalna",
      "Kalyani",
      "Kalyanpur",
      "Kamalapur",
      "Kamat Phulbari",
      "Kanaipur",
      "Kanchrapara",
      "Kandi",
      "Kanganbaria",
      "Kanki",
      "Kankuria",
      "Kanpur",
      "Kantlia",
      "Kanyanagar",
      "Kapashanria",
      "Karari Chandpur",
      "Karia",
      "Karidhya",
      "Karimpur",
      "Kashimnagar",
      "Katwa",
      "Kaugachhi",
      "Kenda",
      "Kendra Khottamdi",
      "Kendua",
      "Kesabpur",
      "Khajutti",
      "Khalia",
      "Khalor",
      "Khandra",
      "Khanpur",
      "Khantora",
      "Kharagpur",
      "Kharar",
      "Khardaha",
      "Kharibari",
      "Kharsarai",
      "Khatra",
      "Khidirpur",
      "Khidirpur, Murshidabad District",
      "Khorddabamonia",
      "Koch Bihar",
      "Kodalia",
      "Kokapur",
      "Kolaghat",
      "Kolkata",
      "Komarhat",
      "Konardihi",
      "Konnagar",
      "Kotbar",
      "Kotulpur",
      "Koyra",
      "Kriparampur",
      "Krishna Sali",
      "Krishnanagar",
      "Krishnapur",
      "Krishnapur Maldah District",
      "Kshidirpur",
      "Kshirpai",
      "Kulia",
      "Kulihanda",
      "Kulti",
      "Kurseong",
      "Labhpur",
      "Lagda",
      "Lalpur",
      "Lapara",
      "Laskarpara",
      "Lataguri",
      "Madhyamgram",
      "Madna",
      "Mahadeb Nagar",
      "Mahal",
      "Mahiari",
      "Mahira",
      "Mahishrekha",
      "Mainaguri",
      "Makardaha",
      "Makhal Tala",
      "Malbazar",
      "Malda",
      "Mamrejpur",
      "Manbazar",
      "Mandarbani",
      "Mangalbari",
      "Mangarjung Tea Garden",
      "Manikpur",
      "Manirampur",
      "Manushpur",
      "Masat",
      "Maslandapur",
      "Mathabhanga",
      "Matialihat",
      "Matla",
      "Medinipur",
      "Mejia Thermal PowerTownship",
      "Mekliganj",
      "Memari",
      "Milki",
      "Minakhan",
      "Mira",
      "Mirdhanga",
      "Mirik",
      "Mirzapur",
      "Mohanpur",
      "Mugkalyan",
      "Muragachha",
      "Murarai",
      "Murshidabad",
      "Murulia",
      "Nababpur",
      "Nabadwip",
      "Nabaghanapur",
      "Nabagram",
      "Nadabhanga",
      "Nagar Changrabandha",
      "Nagdaha",
      "Nahazari",
      "Naihati",
      "Nainan",
      "Naiti",
      "Nalahati",
      "Naldanga",
      "Nalpur",
      "Nandigram",
      "Naridana",
      "Nasaratpur",
      "Nasibpur",
      "Natibpur",
      "Naul",
      "Naupala",
      "Nawapara",
      "Nayabahadurpur",
      "Nebadhai Duttapukur",
      "New Barrackpur",
      "New Town",
      "Nibra",
      "Nischintapur",
      "Nokpul",
      "North Barrackpur",
      "Nrisinghapur",
      "Odlabari",
      "Pairagachha",
      "Palashban",
      "Palashi",
      "Panchghara",
      "Panchla",
      "Panchpara",
      "Pandua",
      "Paniara",
      "Panihati",
      "Panuria",
      "Par Patiram",
      "Para",
      "Parangarpar",
      "Paranpara",
      "Parashkol",
      "Parasia",
      "Parota",
      "Paschim Bainan",
      "Paschim Gazipur",
      "Paschim Jitpur",
      "Paschim Panchla",
      "Paschim Punropara",
      "Pashchim Khalna",
      "Patdaha",
      "Patharberia",
      "Patihal",
      "Patuli",
      "Patulia",
      "Phulia",
      "Poali",
      "Podara",
      "Prayagpur",
      "Pujali",
      "Purba Bishnupur",
      "Purba Narayanpur",
      "Purba Tajpur",
      "Puruliya",
      "Radhapur",
      "Raghudebbati",
      "Raghunathpur",
      "Raichak",
      "Raigachhi",
      "Raiganj",
      "Raipur",
      "Rajbalhat",
      "Rajnagar",
      "Rajpur Bazar",
      "Rajpur Sonarpur",
      "Ramakantapur",
      "Ramanathpur",
      "Rameswarpur",
      "Ramchandrapur",
      "Rameswarpur",
      "Ramjibanpur",
      "Ramkrishnapur",
      "Ramnagar",
      "Rampurhat",
      "Ranaghat",
      "Rangabhita",
      "Raniganj",
      "Raninagar Industrial Growth Centre",
      "Raynagar",
      "Rishra",
      "Rongmook Ceder Tea Garden",
      "Ruiya",
      "Sadigachhi",
      "Sahapur",
      "Sahebganj",
      "Sahebpur",
      "Sainthia",
      "Salap",
      "Salar",
      "Samali",
      "Samudragarh",
      "Samuktola",
      "Sangrampur",
      "Sankarpur",
      "Sankrail",
      "Santaldih",
      "Santipur",
      "Santoshpur",
      "Sarenga",
      "Sarpi",
      "Sehara",
      "Serampore",
      "Serpur",
      "Shankara",
      "Shashati",
      "Shilda",
      "Shimulpur",
      "Shyamdhan",
      "Shyampur",
      "Sibnagar",
      "Siduli",
      "Silampur",
      "Siliguri",
      "Simhat",
      "Simla",
      "Simlapal",
      "Singtam Tea Garden",
      "Singur",
      "Sirakol",
      "Sirsha",
      "Sisha-Jumrha",
      "Sobhaganj",
      "Solgohalia",
      "Sonada Khasmahal",
      "Sonamukhi",
      "Sonatikiri",
      "Srimantapur",
      "Sripur",
      "Subarnapur",
      "Sukdal",
      "Sukhiapokhri",
      "Suri",
      "Taki",
      "Talbandha",
      "Taldi",
      "Tamluk",
      "Tarakeswar",
      "Tehatta",
      "Teleni Para",
      "Telipara Tea Garden",
      "Tentulkuli",
      "Tisa",
      "Titagarh",
      "Tufanganj",
      "Udang",
      "Ukhra",
      "Uluberia",
      "Usthi",
      "Uttar Bagdogra",
      "Uttar Bishnupur",
      "Uttar Jhapardaha",
      "Uttar Kamakhyaguri",
      "Uttar Kusum",
      "Uttar Latabari",
      "Uttar Madarihat",
      "Uttar Mahammadpur",
      "Uttar Pirpur",
      "Uttar Raypur",
      "Uttar Satali",
      "Uttarpara Kotrung",
      "WBIIDC Growth Centre Uluberia", 
      'Other'],
  },
  {
    name: 'Andaman and Nicobar Islands',
    cities: ['Port Blair', 'Other'],
  },


  {
    name: 'Dadra and Nagar Haveli and Daman and Diu',
    cities: ['Daman', 'Diu', 'Other'],
  },
  {
    name: 'Lakshadweep',
    cities: ['Kavaratti', 'Other'],
  },
];